import {
    DashboardViewModeMiniature,
    DashboardViewModeList,
    DashboardViewModeMiniature2x,
    DashboardViewModeList2x,
} from './images';
import { FileModeView } from '../util/FileModeView';

import './ButtonFileModeView.scss';

function ButtonFileModeView(props) {
    let title;
    const { viewMode, onClick } = props;
    const isViewModeList = viewMode === FileModeView.LIST;

    title = isViewModeList ? 'Visualizar em grade' : 'Visualizar em lista';

    return (
        <button
            className={'button-file-mode-view'}
            title={title}
            onClick={onClick}
        >
            {isViewModeList ? (
                <img
                    src={`${DashboardViewModeMiniature}`}
                    srcSet={`${DashboardViewModeMiniature} 1x, ${DashboardViewModeMiniature2x} 2x`}
                    alt={''}
                />
            ) : (
                <img
                    src={`${DashboardViewModeList}`}
                    srcSet={`${DashboardViewModeList} 1x, ${DashboardViewModeList2x} 2x`}
                    alt={''}
                />
            )}
        </button>
    );
}

export default ButtonFileModeView;
