import {
    ButtonOrientationPortraitSelected,
    ButtonOrientationPortraitSelected2x,
    ButtonOrientationPortraitUnselected,
    ButtonOrientationPortraitUnselected2x,
    ButtonOrientationLandscapeSelected,
    ButtonOrientationLandscapeSelected2x,
    ButtonOrientationLandscapeUnselected,
    ButtonOrientationLandscapeUnselected2x,
} from './images';
import { OrientationEnum } from 'plataforma-braille-common';
import './ButtonOrientation.scss';

function ButtonOrientation(props) {
    let selected,
        selected2x,
        unselected,
        unselected2x,
        altSelected,
        altUnselected;

    if (!props.orientation) {
        throw new Error('No orientation was been passed.');
    } else if (props.orientation === OrientationEnum.PORTRAIT) {
        selected = ButtonOrientationPortraitSelected;
        selected2x = ButtonOrientationPortraitSelected2x;
        unselected = ButtonOrientationPortraitUnselected;
        unselected2x = ButtonOrientationPortraitUnselected2x;
        // I18N
        altSelected = 'Retrato selecionado';
        // I18N
        altUnselected = 'Retrato não selecionado';
    } else if (props.orientation === OrientationEnum.LANDSCAPE) {
        selected = ButtonOrientationLandscapeSelected;
        selected2x = ButtonOrientationLandscapeSelected2x;
        unselected = ButtonOrientationLandscapeUnselected;
        unselected2x = ButtonOrientationLandscapeUnselected2x;
        // I18N
        altSelected = 'Paisagem selecionado';
        // I18N
        altUnselected = 'Paisagem não selecionado';
    } else {
        throw new Error(`Unsupported orientation: ${props.orientation}`);
    }

    return (
        <div
            tabIndex={0}
            className={'button-orientation'}
            onClick={props.onClick}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    if (props.onClick) props.onClick();
                }
            }}
        >
            {props.selected ? (
                <img
                    src={selected}
                    srcSet={`${selected} 1x, ${selected2x} 2x`}
                    onDragStart={(e) => {
                        e.preventDefault();
                    }}
                    // I18N
                    alt={altSelected}
                />
            ) : (
                <img
                    src={unselected}
                    srcSet={`${unselected} 1x, ${unselected2x} 2x`}
                    onDragStart={(e) => {
                        e.preventDefault();
                    }}
                    // I18N
                    alt={altUnselected}
                />
            )}
        </div>
    );
}

export default ButtonOrientation;
