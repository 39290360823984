import './ZoomModule.scss';
import * as ScrollModule from '../ScrollModule';
export class ZoomModule {
    /**
     * @type {EditorCustom}
     */
    editor;

    /**
     * @type {HTMLElement | null}
     */
    zoomContainer = null;

    /**
     * @type {boolean}
     */
    disabled = false;

    constructor(editor) {
        this.editor = editor;
    }

    install() {
        this.hackCreateDocumentZoom();
        this.editor.on('documentReady', () => {
            this.restoreZoom();
        });
    }

    /**
     * @param zoom {number}
     * @param updateScroll {boolean | undefined}
     */
    applyZoom(zoom, updateScroll = true) {
        if (this.disabled || !this.editor.getBody()) return;
        const statusBar = document.querySelector('.tox-statusbar');
        if (!statusBar) {
            console.error('Cannot find TinyMce status bar');
            return;
        }
        const zoomLabel = statusBar.querySelector('.zoom-label');
        const zoomInput = statusBar.querySelector('.zoom-input');

        const htmlElement = this.editor.getBody().parentElement;
        const bodyElement = this.editor.getBody();
        // noinspection JSUnresolvedReference
        const reason = isNaN(zoom) ? 1 : zoom / 100;
        htmlElement.style.transform = `scale(${reason})`;
        if (zoomLabel) {
            zoomLabel.innerText = `${isNaN(zoom) ? 100 : zoom}%`;
        }
        this.editor.custom.zoom = reason;
        htmlElement.style.removeProperty('width');
        htmlElement.style.removeProperty('position');
        htmlElement.style.removeProperty('left');
        htmlElement.style.removeProperty('margin-left');
        const scrollingH =
            htmlElement.clientWidth < bodyElement.clientWidth * reason;
        if (scrollingH) {
            htmlElement.style.width = 'min-content';
            htmlElement.style.transformOrigin = 'top left';

            /**
             * There is a bug in Chrome when using scaling and scroll
             * Puts scroll on right edge to get real center
             */
            htmlElement.scrollTo({
                behavior: 'instant',
                left: htmlElement.scrollWidth,
            });
            const maxScrollLeft = htmlElement.scrollLeft;
            htmlElement.scrollTo({
                behavior: 'instant',
                left: maxScrollLeft / 2,
            });
        } else {
            htmlElement.style.transformOrigin = 'top left';
            htmlElement.style.position = 'absolute';
            htmlElement.style.left = '50%';
            htmlElement.style.marginLeft = `-${bodyElement.getBoundingClientRect().width / 2}px`;
        }
        if (zoomInput) {
            zoomInput.value = zoom.toString();
        }
        if (updateScroll) ScrollModule.updateScroll(this.editor);
    }

    restoreZoom() {
        const zoom = parseInt(localStorage?.getItem(this.getZoomStorageKey()));
        this.applyZoom(zoom, true);
        let tScreenResized;
        const self = this;
        function screenResized() {
            if (self.disabled) return;
            clearTimeout(tScreenResized);
            tScreenResized = setTimeout(() => {
                self.applyZoom(self.editor.custom.zoom * 100, true);
                tScreenResized = null;
            }, 50);
        }
        window.addEventListener('resize', screenResized);
        this.editor.on('documentDestroyed', () => {
            window.removeEventListener('resize', screenResized);
        });
    }

    /**
     * @return {string}
     */
    getZoomStorageKey() {
        return `documentZoom:${window.location.pathname}`;
    }

    hackCreateDocumentZoom() {
        const container =
            this.editor.custom.customStatusBarContainer.parentElement;

        this.zoomContainer = document.createElement('div');
        this.zoomContainer.className = 'zoom-container';
        this.zoomContainer.title = 'Zoom';

        const zoomLabel = document.createElement('div');
        zoomLabel.innerText = '100%';
        zoomLabel.className = 'zoom-label';
        const zoomInput = document.createElement('input');
        zoomInput.className = 'zoom-input';
        zoomInput.type = 'range';
        zoomInput.min = '20';
        zoomInput.max = '170';
        zoomInput.value = '100';
        zoomInput.step = '10';

        const self = this;
        zoomInput.addEventListener('input', (e) => {
            // noinspection JSUnresolvedReference
            const zoom = parseInt(e.target.value);
            self.applyZoom(zoom);
            localStorage?.setItem(self.getZoomStorageKey(), zoom.toString());
        });
        this.zoomContainer.appendChild(zoomInput);
        this.zoomContainer.appendChild(zoomLabel);
        container.appendChild(this.zoomContainer);
    }

    disable() {
        if (this.disabled) return;
        this.zoomContainer.style.display = 'none';
        this.applyZoom(100, true);
        this.disabled = true;
    }

    enable() {
        if (!this.disabled) return;
        this.zoomContainer.style.removeProperty('display');
        this.disabled = false;
        this.restoreZoom();
    }
}
