const fileModeViewKey = 'fileMode';
export const FileModeView = {
    LIST: 'list',
    MINIATURE: 'grid',
};

export const modeViewToggle = (currentMode) => {
    const mode =
        currentMode === FileModeView.MINIATURE
            ? FileModeView.LIST
            : FileModeView.MINIATURE;
    setFileModeView(mode);

    return mode;
};

export function getFileModeView() {
    let fileModeView = localStorage.getItem(fileModeViewKey);
    const fileModeIsValid = !(
        fileModeView ||
        FileModeView[fileModeView ?? FileModeView.MINIATURE.toUpperCase()]
    );

    if (fileModeIsValid) {
        fileModeView = FileModeView.MINIATURE;
        setFileModeView(fileModeView);
    }

    return fileModeView;
}

function setFileModeView(value) {
    localStorage.setItem(fileModeViewKey, value);
}
