import { LoginLogo, LoginLogo2x } from '../images';
import './LoginLeftBar.scss';
import { getVersion } from '../../version';

function Logo() {
    return (
        <div className={'logo-container'}>
            {/*I18N*/}
            <img
                src={`${LoginLogo}`}
                srcSet={`${LoginLogo} 1x, ${LoginLogo2x} 2x`}
                width={223}
                height={486}
                alt={'Fundação Dorina Nowill Para Cegos'}
            />
        </div>
    );
}

function SupportInfo() {
    return (
        <div className={'support-info'}>
            {/*I18N*/}
            <h4>{'Precisa de suporte?'}</h4>
            <p>{'Fone: (11) 5087-0999'}</p>
            <p>{'suportebraille@fundacaodorina.org.br'}</p>
        </div>
    );
}

function LoginLeftBar() {
    return (
        <div className={'gd-col-5--desktop bg-yellow login-left-bar'}>
            <Logo />
            <SupportInfo />
            <div className={'app-version'}>{`ver ${getVersion()}`}</div>
        </div>
    );
}

export default LoginLeftBar;
