export class RevisionRecord {
    /**
     * @param revisionGravity {RevisionGravityEnum}
     * @param inspectionError {RevisionErrorEnum}
     * @param page {number}
     * @param paragraph {number}
     * @param fragment {number}
     * @param pathIndex {number}
     * @param length {number}
     * @param word {string | null}
     * @param editorElementId {string | null | undefined}
     */
    constructor(
        revisionGravity,
        inspectionError,
        page,
        paragraph,
        fragment,
        pathIndex,
        length,
        word,
        editorElementId = null,
    ) {
        this.revisionGravity = revisionGravity;
        this.inspectionError = inspectionError;
        this.page = page;
        this.paragraph = paragraph;
        this.fragment = fragment;
        this.pathIndex = pathIndex;
        this.length = length;
        this.word = word;
        this.editorElementId = editorElementId;
    }
}
