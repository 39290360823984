import CloseModalButton from './CloseModalButton';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

function ConfirmModal({
    show,
    zIndex,
    onCancel,
    title,
    message,
    cancelText,
    onConfirm,
    confirmText,
}) {
    return (
        <div
            className={`modal default-modal ${show ? 'show' : ''}`}
            style={{ zIndex: zIndex ?? 10010 }}
        >
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={onCancel} />
                <h2
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(title),
                    }}
                />
                <div
                    className={'message'}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(message),
                    }}
                />
                <div className={'controls'}>
                    <button className={'button'} onClick={onCancel}>
                        {/*I18N*/}
                        {cancelText ?? 'Cancelar'}
                    </button>
                    <button className={'button primary'} onClick={onConfirm}>
                        {/*I18N*/}
                        {confirmText ?? 'Confirmar'}
                    </button>
                </div>
            </div>
        </div>
    );
}

ConfirmModal.propTypes = {
    show: PropTypes.bool.isRequired,
    zIndex: PropTypes.number,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    cancelText: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    confirmText: PropTypes.string,
};

export default ConfirmModal;
