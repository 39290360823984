import {
    RoleEnumToString,
    UserStatusEnumToString,
} from 'plataforma-braille-common';
import { useNavigate } from 'react-router-dom';

/**
 * @param users {UsersSummaryDto[]}
 * @param loadingLine {JSX.Element}
 * @return {JSX.Element}
 * @constructor
 */
function UserTable({ users, loadingLine }) {
    const navigate = useNavigate();

    return (
        <div>
            <table className={'crud-table'}>
                <thead>
                    <tr>
                        {/*I18N*/}
                        <th scope={'col'}>{'Nome do usuário'}</th>
                        {/*I18N*/}
                        <th scope={'col'}>{'E-mail'}</th>
                        {/*I18N*/}
                        <th scope={'col'}>{'Funções'}</th>
                        {/*I18N*/}
                        <th scope={'col'}>{'Status'}</th>
                        {/*I18N*/}
                        <th scope={'col'}>{'Último login'}</th>
                        <th scope={'col'}></th>
                    </tr>
                </thead>
                <tbody>
                    {users?.map((user) => {
                        let roles = [];
                        user.roles.sort();
                        user.roles.forEach((role) => {
                            roles.push(RoleEnumToString(role));
                        });

                        return (
                            <tr key={user.id}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{roles.join(', ')}</td>
                                <td>{UserStatusEnumToString(user.status)}</td>
                                <td>
                                    {user.lastLogin
                                        ? new Date(
                                              user.lastLogin,
                                          ).toLocaleString()
                                        : ''}
                                </td>
                                <td className={'controls'}>
                                    <a
                                        onClick={() =>
                                            navigate(
                                                `/gestao-usuarios/editar/${user.id}`,
                                            )
                                        }
                                    >
                                        {'Editar'}
                                    </a>
                                </td>
                            </tr>
                        );
                    })}
                    {loadingLine && (
                        <tr>
                            <td colSpan={6}>{loadingLine}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default UserTable;
