import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { MeasureEnum } from 'plataforma-braille-common';

export function MeasureInput(props) {
    if (!props.measure) {
        console.warn(
            'No measure defined in `MeasureInput`, using millimeter as default.',
        );
    }
    const measure = props.measure ?? MeasureEnum.MILLIMETER;

    const defaultMaskOptions = {
        prefix: '',
        suffix: measure.toLowerCase(),
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 0, // how many digits allowed after the decimal
        integerLimit: 4, // limit length of integer numbers
        allowNegative: false,
        allowLeadingZeroes: false,
    };

    const customMask = createNumberMask({ ...defaultMaskOptions });

    return <MaskedInput mask={customMask} {...props} />;
}

export function unmask(str) {
    if (!str) return null;
    const value = parseInt(str.replace(/\D/g, ''));
    return isNaN(value) ? null : value;
}
