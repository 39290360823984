import React, { useEffect, useRef, useState } from 'react';
import { status } from '../services/LoginService';
import InfoModal from '../components/modal/InfoModal';
import LoadingModal from '../components/modal/LoadingModal';
import * as Sentry from '@sentry/react';
import ConfirmModal from '../components/modal/ConfirmModal';
import { setCsrfToken } from '../services/Api';
import { removeAppLoading } from '../util/AppLoading';
import NewUserModal from '../components/modal/NewUserModal';
import { RoleEnum } from 'plataforma-braille-common';
import UpdateModal from '../components/UpdateModal';
import PopupMenu from '../components/PopupMenu';

/**
 * @typedef {object} EnvirionmentContextValue
 * @property {boolean | null} isLogged
 * @property {StatusResDto | null} user
 * @property {function(StatusResDto) | function(function(StatusResDto): StatusResDto)} setUser
 * @property {InfoModalParams} infoModal
 * @property {function(InfoModalParams) | function(function(InfoModalParams): InfoModalParams)} setInfoModal
 * @property {ConfirmModalParams} confirmModal
 * @property {function(ConfirmModalParams) | function(function(ConfirmModalParams): ConfirmModalParams)} setConfirmModal
 * @property {function(message: string, e: Error | any | null, onClose: function() | null, title: string | null, validationErrorMessageFn: function(string): string | null) | function(message: string, e: Error | any | null)} backendConnectionError
 * @property {function(show: boolean, transparent: boolean | null, text: string | null, progress: string | null) | function(show: boolean) | function(show: boolean)} setLoading
 * @property {function(NewUserModalParams) | function(function(NewUserModalParams): NewUserModalParams)} setNewUserModal
 * @property {function(onClose: function())} recaptchaError
 * @property {function(element: HTMLElement, menuItems: PopupMenuItem[])} showPopup
 * @property {boolean} isAdmin
 * @property {boolean} isModerator
 * @property {boolean} isEvaluator
 * @property {boolean} isPrintShop
 */

/**
 * @typedef {object} InfoModalParams
 * @property {string} title
 * @property {string} message
 * @property {boolean} show
 * @property {function() | null} onClose
 */

/**
 * @typedef {object} ConfirmModalParams
 * @property {string} title
 * @property {string} message
 * @property {boolean} show
 * @property {string | null} cancelText
 * @property {string | null} confirmText
 * @property {function() | null} onCancel
 * @property {function() | null} onConfirm
 */

/**
 * @typedef {object} LoadingParams
 * @property {boolean} show
 * @property {boolean | undefined} transparent
 * @property {string | undefined} text
 * @property {number | null | undefined} progress
 */

/**
 * @typedef {object} NewUserModalParams
 * @property {boolean} show
 * @property {function()} onFinished
 */

/**
 * @type {EnvirionmentContextValue | null}
 */
const environmentInitialValue = null;
const EnvironmentContext = React.createContext(environmentInitialValue);

/**
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const EnvironmentProvider = ({ children }) => {
    const [isLogged, setIsLogged] = useState(null);
    /**
     * @type {StatusResDto | null}
     */
    const userInitialValue = null;
    const [user, setUser] = useState(userInitialValue);

    /**
     * @type {InfoModalParams}
     */
    const infoModalInitialValue = {
        title: '',
        message: '',
        show: false,
        onClose: null,
    };
    const [infoModal, setInfoModal] = useState(infoModalInitialValue);

    /**
     * @type {NewUserModalParams}
     */
    const newUserModalInitialValue = {
        show: false,
        onFinished: null,
    };
    const [newUserModal, setNewUserModal] = useState(newUserModalInitialValue);

    /**
     * @type {ConfirmModalParams}
     */
    const confirmModalInitialValue = {
        title: '',
        message: '',
        show: false,
        cancelText: null,
        confirmText: null,
        onCancel: null,
        onConfirm: null,
    };
    const [confirmModal, setConfirmModal] = useState(confirmModalInitialValue);

    /**
     * @type {LoadingParams}
     */
    const loadingInitialValue = {
        show: false,
        transparent: false,
        text: '',
        progress: null,
    };
    const [loading, _setLoading] = useState(loadingInitialValue);
    /**
     * @type {React.MutableRefObject<PopupMenuFunctions | null>}
     */
    const popupMenuRef = useRef(null);

    function setLoading(
        show,
        transparent = false,
        text = null,
        progress = null,
    ) {
        _setLoading((loading) => ({
            text: !show && !text ? loading.text : text, // this avoids to change the text when the loading is hiding
            show,
            transparent,
            progress,
        }));
    }

    /**
     * @param message {string}
     * @param e {Error | any | null}
     * @param onClose {function() | null | undefined}
     * @param title {string | null | undefined}
     * @param validationErrorMessageFn {function(string): string | null | undefined}
     */
    function backendConnectionError(
        message,
        e,
        onClose = null,
        title = null,
        validationErrorMessageFn = null,
    ) {
        Sentry.captureException(e);
        const { response } = e || {};
        console.error(
            message,
            e,
            response?.status || undefined,
            response?.data?.message,
        );
        // I18N
        let userMessage =
            '<strong>Erro ao comunicar com o servidor</strong>. Por favor, verifique a conexão e tente novamente em alguns minutos.';
        if (!response) {
            if (e?.code === 'ERR_NETWORK') {
                // I18N
                userMessage =
                    '<strong>Não foi possível conectar ao servidor backend</strong>. Servidor está fora do ar ou a conexão com a internet não está disponível.';
            } else if (e?.code === 'ECONNABORTED') {
                // I18N
                userMessage =
                    '<strong>O servidor demorou muito para responder</strong>. Tente novamente em alguns minutos.';
            } else {
                // I18N
                userMessage =
                    '<strong>Erro ao processar requisição</strong>. Nosso time foi notificado e estamos trabalhando para resolver o problema.';
            }
        } else if (response?.status === 500) {
            // I18N
            userMessage =
                '<strong>Erro interno do servidor</strong>. Nosso time foi notificado e estamos trabalhando para resolver o problema.';
        } else if (response?.status === 403) {
            // I18N
            userMessage =
                '<strong>Acesso negado</strong>. O servidor recusou acesso ao recurso solicitado.';
        } else if (response?.status === 412) {
            // I18N
            userMessage =
                '<strong>Validação do Recaptcha falhou</strong>. Por favor, tente novamente em alguns minutos.';
        } else if (response?.status === 422) {
            if (response?.data?.errors && validationErrorMessageFn) {
                if (response.data.errors.length === 1) {
                    userMessage = validationErrorMessageFn(
                        response.data.errors[0],
                    );
                } else {
                    // I18N
                    userMessage =
                        'Corrija os erros de validação abaixo e tente novamente:';
                    userMessage += '<ul>';
                    for (const error of response.data.errors) {
                        userMessage += `<li>${validationErrorMessageFn(error)}</li>`;
                    }
                    userMessage += '</ul>';
                }
            } else {
                // I18N
                userMessage =
                    'O servidor indicou um <strong>erro de validação</strong> nos dados enviados. Se o problema persistir, entre em contato com o suporte.';
            }
        }

        // I18N
        setInfoModal({
            title: title ?? 'Plataforma Braille',
            message: userMessage,
            show: true,
            onClose,
        });
    }

    function recaptchaError(onClose) {
        // I18N
        setInfoModal({
            title: 'Plataforma Braille',
            message:
                'Validação do Recaptcha falhou. Por favor, tente novamente em alguns minutos.',
            show: true,
            onClose,
        });
    }

    /**
     * @param element {HTMLElement}
     * @param menuItems {PopupMenuItem[]}
     */
    function showPopup(element, menuItems) {
        popupMenuRef.current?.showPopup(element, menuItems);
    }

    useEffect(() => {
        if (isLogged == null || (isLogged && !user)) {
            console.debug('Retrieving authentication status...');
            status()
                .then((user) => {
                    if (user) {
                        setIsLogged(true);
                        setCsrfToken(user['csrfToken']);
                        delete user['csrfToken'];
                        setUser(user);
                        Sentry.setUser(user);
                        console.debug('User logged.', user);
                    } else {
                        setIsLogged(false);
                        setUser(null);
                        Sentry.setUser(null);
                        console.debug('No user logged.');
                    }
                })
                .catch((e) => {
                    removeAppLoading(() => {
                        setIsLogged(false);
                        setUser(null);
                        backendConnectionError(
                            'Fail to retrieve authentication status.',
                            e,
                            () => window.location.reload(),
                        );
                    });
                });
        }
    }, [isLogged, user]);

    const isAdmin = user?.roles?.includes(RoleEnum.ADMINISTRATOR);
    const isModerator = user?.roles?.includes(RoleEnum.MODERATION);
    const isEvaluator = user?.roles?.includes(RoleEnum.EVALUATION);
    const isPrintShop = user?.roles?.includes(RoleEnum.PRINT_SHOP);

    /**
     * @type {EnvirionmentContextValue}
     */
    const value = {
        isLogged,
        setIsLogged,
        user,
        setUser,
        infoModal,
        setInfoModal,
        confirmModal,
        setConfirmModal,
        backendConnectionError,
        setLoading,
        setNewUserModal,
        recaptchaError,
        showPopup,
        isAdmin,
        isModerator,
        isEvaluator,
        isPrintShop,
    };

    // noinspection JSXUnresolvedComponent
    return (
        <EnvironmentContext.Provider value={value}>
            {children}
            <NewUserModal
                show={newUserModal.show}
                onFinished={newUserModal.onFinished}
            />
            <InfoModal
                title={infoModal.title}
                message={infoModal.message}
                show={infoModal.show}
                onClose={() => {
                    // noinspection JSCheckFunctionSignatures
                    setInfoModal((infoModal) => ({
                        ...infoModal,
                        show: false,
                    }));
                    if (infoModal.onClose) infoModal.onClose();
                }}
            />
            <ConfirmModal
                title={confirmModal.title}
                message={confirmModal.message}
                show={confirmModal.show}
                cancelText={confirmModal.cancelText}
                confirmText={confirmModal.confirmText}
                onCancel={() => {
                    // noinspection JSCheckFunctionSignatures
                    setConfirmModal((confirmModal) => ({
                        ...confirmModal,
                        show: false,
                    }));
                    if (confirmModal.onCancel) confirmModal.onCancel();
                }}
                onConfirm={() => {
                    // noinspection JSCheckFunctionSignatures
                    setConfirmModal((confirmModal) => ({
                        ...confirmModal,
                        show: false,
                    }));
                    if (confirmModal.onConfirm) confirmModal.onConfirm();
                }}
            />
            <LoadingModal
                text={loading.text}
                transparent={loading.transparent}
                show={loading.show}
                progress={loading.progress}
            />
            <UpdateModal />
            <PopupMenu ref={popupMenuRef} />
        </EnvironmentContext.Provider>
    );
};

export { EnvironmentContext, EnvironmentProvider };
