/**
 * @param resource {string}
 * @param onlyData {boolean | undefined}
 * @return {Promise<string>}
 */
export async function downloadToBase64(resource, onlyData = true) {
    if (resource.startsWith('data:')) {
        return onlyData ? resource.split(',')[1] : resource;
    } else {
        const response = await fetch(resource, { credentials: 'include' });
        if (response.status !== 200) {
            throw new Error(`Failed to fetch resource: ${response.status}`);
        }
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(onlyData ? reader.result.split(',')[1] : reader.result);
            };
            reader.onerror = (e) => {
                reject(new Error('Failed to read blob: ' + e.target.error));
            };
            reader.readAsDataURL(blob);
        });
    }
}
