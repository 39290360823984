import nextId from 'react-id-generator';
import DOMPurify from 'dompurify';
import { forwardRef, useImperativeHandle, useRef } from 'react';

function Field(
    {
        idInput,
        idError,
        fieldGroup,
        label,
        validationError,
        children,
        hasIcon,
        bottomChildren,
    },
    ref,
) {
    const labelRef = useRef(null);

    useImperativeHandle(ref, () => ({ label: labelRef.current }));

    const labelText = label
        ? new DOMParser().parseFromString(label, 'text/html').body.firstChild
              ?.textContent
        : '';

    function renderLabel() {
        return label ? (
            <label
                ref={labelRef}
                className={'field-label'}
                htmlFor={idInput}
                title={labelText}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }}
            />
        ) : (
            <></>
        );
    }

    return (
        <div
            className={`
            field-text
            ${fieldGroup ?? true ? 'field-group' : ''}
            ${validationError ? 'error' : ''}
            ${hasIcon ? 'has-icon' : ''}
        `}
        >
            {renderLabel()}
            {children}
            <div id={idError} className={'validation-error'}>
                {validationError}
            </div>
            {bottomChildren}
        </div>
    );
}

export default forwardRef(Field);
