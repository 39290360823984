export enum DocumentsFilterEnum {
    LAST_DOCUMENTS = "LAST_DOCUMENTS",
    FAVORITES = "FAVORITES",
}

export function DocumentsFilterEnumToString(key: string): string {
    const showDocuments = DocumentsFilterEnum[key];
    switch (showDocuments) {
        // I18N
        case DocumentsFilterEnum.LAST_DOCUMENTS:
            return "Últimos documentos";
        // I18N
        case DocumentsFilterEnum.FAVORITES:
            return "Favoritos";
        default:
            throw new Error("Invalid enum value");
    }
}
