import Api from './Api';

/**
 * @typedef {object} FiguresDto
 * @property {number} id
 * @property {string} name
 * @property {string} figure
 * @property {string | null} caption
 * @property {string[] | null} tags
 */

/**
 * @param filter { { page: number | null | undefined, search: string | null} }
 * @param config {import('axios').AxiosRequestConfig | null | undefined}
 * @returns {Promise<PaginationResDto<FiguresDto>>}
 */
export async function getFigures(filter, config = null) {
    return (
        await Api.get('/figures', {
            params: filter,
            ...(config ?? {}),
        })
    )?.data;
}

/**
 * @param formData {FormData}
 * @returns {Promise<FiguresDto>}
 */
export async function createFigure(formData) {
    return (await Api.put('/figures', formData)).data;
}

/**
 * @param id {number}
 * @param formData {FormData}
 * @returns {Promise<FiguresDto>}
 */
export async function editFigure(id, formData) {
    return (await Api.patch(`/figures/${id}`, formData)).data;
}

/**
 * @param id {number}
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export async function deleteFigure(id) {
    return await Api.delete(`/figures/${id}}`);
}
