import './UpdateModal.scss';
import { useEffect, useRef, useState } from 'react';
import { getServerVersion } from '../services/VersionService';
import { getVersion } from '../version';

const CHECK_PERIOD = 300000; // 5 minutes

function UpdateModal() {
    const [show, setShow] = useState(false);

    /**
     * @type {React.MutableRefObject<number | null>}
     */
    const timerRef = useRef();

    useEffect(() => {
        let mounted = true;
        if (!show) {
            const checkForUpdate = async () => {
                if (show) return;
                try {
                    const serverVersion = await getServerVersion();
                    const localVersion = getVersion();
                    if (serverVersion === localVersion) {
                        console.debug('Application is updated.');
                        setShow(false);
                        if (mounted) {
                            timerRef.current = setTimeout(
                                checkForUpdate,
                                CHECK_PERIOD,
                            );
                        }
                    } else {
                        console.info(
                            `A new version of frontend is available: ${serverVersion}; Local version: ${localVersion}`,
                        );
                        setShow(true);
                    }
                } catch (e) {
                    if (e.response?.status === 404) {
                        console.warn(
                            'Version data not found at server (running in development environment or missing information on CI/CD).',
                        );
                    } else {
                        console.error('Fail checking for version update.', e);
                    }
                }
                if (mounted) {
                    timerRef.current = setTimeout(checkForUpdate, CHECK_PERIOD);
                }
            };
            if (mounted) {
                timerRef.current = setTimeout(checkForUpdate, 5000);
            }
        }

        return () => {
            clearTimeout(timerRef.current);
            mounted = false;
        };
    }, []);

    const classList = ['update-modal'];
    if (show) {
        classList.push('show');
    }

    return (
        <div className={classList.join(' ')}>
            <div>
                {/*I18N*/}
                {'Uma nova versão está disponível!'}
            </div>
            <a onClick={() => window.location.reload()}>
                {/*I18N*/}
                {'Clique aqui para atualizar'}
            </a>
        </div>
    );
}

export default UpdateModal;
