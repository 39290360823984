/**
 * @param milliseconds {number}
 * @return {Promise<void>}
 */
export async function delay(milliseconds) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, milliseconds);
    });
}
