import Api from './Api';

export async function getTemplates(filter) {
    return (await Api.get('/templates', { params: filter })).data;
}

export async function saveTemplate(document) {
    return (await Api.post('/templates', document)).data;
}

export async function removeTemplate(id) {
    return (await Api.delete(`/templates/${id}`)).data;
}
