import Api from './Api';
// eslint-disable-next-line no-unused-vars

/**
 * @typedef {object} UsersSummaryDto
 * @property {number} id
 * @property {string} name
 * @property {string} email
 * @property {import('plataforma-braille-common').UsersOrderByEnumValue | null | undefined} order
 * @property {import('plataforma-braille-common').UserStatusEnumValue | null | undefined} status
 * @property {import('plataforma-braille-common').RoleEnumValue[]} roles
 * @property {string} lastLogin
 */

/**
 * @typedef {object} FilterUsersReqDto
 * @property {number} page
 * @property {string | null | undefined} search
 * @property {import('plataforma-braille-common').UsersOrderByEnumValue | null | undefined} order
 * @property {import('plataforma-braille-common').UserStatusEnumValue | null | undefined} status
 * @property {import('plataforma-braille-common').RoleEnumValue | null | undefined } role
 */

/**
 * @param filter {FilterUsersReqDto}
 * @param config {import('axios').AxiosRequestConfig | undefined}
 *
 * @return {Promise<PaginationResDto<UsersSummaryDto>>}
 */
export async function getUsers(filter, config = null) {
    return (await Api.get('/users', { params: filter, ...(config ?? {}) }))
        ?.data;
}

export async function getUser(id) {
    return await Api.get(`/users/${id}`);
}

export async function inviteUser(email, name, roles) {
    const data = {
        email,
        name,
        roles,
    };
    return await Api.put('/users', data);
}

export async function resendInviteUser(id) {
    const data = { id: parseInt(id) };
    return await Api.post('/users/resend-invite', data);
}

export async function editUser(data) {
    return await Api.put(`/users/${data.id}`, data);
}

export async function removeUser(id) {
    return await Api.delete(`/users/${id}`);
}
