import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function RouteErrorPage(props) {
    const { error } = props;
    if (error?.status !== 404 && error?.error instanceof Error) {
        Sentry.captureException(error.error);
    }
    const navigate = useNavigate();

    useEffect(() => {
        if (error?.status === 404) {
            navigate('/');
        }
    }, []);

    function reload() {
        window.location.reload();
    }

    return (
        <div className={'modal default-modal show'}>
            <div className={'backdrop'} />
            <div className={'container'}>
                <h2>
                    {/*I18N*/}
                    {'Erro de aplicação'}
                </h2>
                <div className={'message'}>
                    {/*I18N*/}
                    {
                        'Um relatório sobre o erro foi enviado automaticamente para o time de desenvolvimento. '
                    }
                    {/*I18N*/}
                    {
                        'Caso necessário, entre em contato com mais detalhes do que estava fazendo no momento do problema.'
                    }
                </div>
                <div className={'controls'}>
                    <a className={'button primary'} onClick={reload}>
                        {/*I18N*/}
                        {'Recarregar'}
                    </a>
                </div>
            </div>
        </div>
    );
}

export default RouteErrorPage;
