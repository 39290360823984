export enum SaveUserValidationErrorEnum {
    USER_EMAIL_ALREADY_IN_USE = "USER_EMAIL_ALREADY_IN_USE",
    USER_ALREADY_ACCEPTED_INVITE = "USER_ALREADY_ACCEPTED_INVITE",
}

export function SaveUserValidationErrorTxt(
    inviteUserResponseEnum: SaveUserValidationErrorEnum,
): string {
    switch (inviteUserResponseEnum) {
        case SaveUserValidationErrorEnum.USER_EMAIL_ALREADY_IN_USE:
            // I18N
            return "O email já está em uso por outro usuário";
        case SaveUserValidationErrorEnum.USER_ALREADY_ACCEPTED_INVITE:
            // I18N
            return "O usuário já aceitou o convite";
    }
    throw new Error("Invalid enum value");
}
