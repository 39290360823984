import { Navigate, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import UserTable from './UserTable';
import { getUsers } from '../services/UserService';
import {
    UsersOrderByEnum,
    RoleEnum,
    UserStatusEnum,
    UserStatusEnumToString,
    RoleEnumToString,
} from 'plataforma-braille-common';
import CrudContainer from '../components/logged/CrudContainer';
import ErrorLoading from '../components/ErrorLoading';
import FieldSelect from '../components/FieldSelect';
import { enumToSelectOptions } from '../util/EnumToSelectOptions';
import FieldText from '../components/FieldText';
import ScrollPagination from '../components/ScrollPagination';

const STORAGE_KEY = 'userManagementFilter';

function UserManagement() {
    const navigate = useNavigate();
    /**
     * @type {UsersSummaryDto[]}
     */
    const usersInitialStatus = [];
    const [users, setUsers] = useState(usersInitialStatus);
    const [loading, setLoading] = useState(new AbortController());

    const [scrollControl, setScrollControl] = useState({
        records: 0,
        pageSize: 0,
    });

    /**
     * @type {FilterUsersReqDto | null}
     */
    let filterInitialValue = {
        search: '',
        status: null,
        role: null,
        order: UsersOrderByEnum.NAME_ASC,
        page: 0,
    };
    try {
        const storedFilterStr = sessionStorage.getItem(STORAGE_KEY);
        if (storedFilterStr) {
            /**
             * @type {FilterUsersReqDto}
             */
            const storedFilter = JSON.parse(storedFilterStr);
            filterInitialValue = {
                ...filterInitialValue,
                ...storedFilter,
            };
        }
    } catch (e) {}
    const [filter, setFilter] = useState(filterInitialValue);

    const { user: loggedUser } = useContext(EnvironmentContext);
    if (!loggedUser.roles.includes(RoleEnum.ADMINISTRATOR)) {
        return <Navigate to={'/'} />;
    }

    /**
     * @type {React.MutableRefObject<HTMLElement | null>}
     */
    const scrollRef = useRef(null);

    async function fetchUsers() {
        if (loading?.abort) {
            loading.abort();
        }
        const abortController = new AbortController();
        try {
            setLoading(abortController);
            const data = await getUsers(filter, {
                signal: abortController.signal,
            });
            setUsers((prevUsers) =>
                data.page === 0
                    ? data.records
                    : [...prevUsers, ...data.records],
            );
            setScrollControl({
                records: data.records.length,
                pageSize: data.pageSize,
            });
            setLoading(null);
        } catch (e) {
            if (e.name === 'CanceledError') return;
            console.error('Failed to fetch users', e);
            setLoading(e);
        }
    }

    useEffect(() => {
        fetchUsers().then();
    }, [filter]);

    const updateFilter = (newFilter) => {
        setFilter((prevFilter) => {
            const updatedFilter = {
                ...prevFilter,
                ...newFilter,
                page: 0,
            };
            sessionStorage.setItem(STORAGE_KEY, JSON.stringify(updatedFilter));
            return updatedFilter;
        });
    };

    const error = loading instanceof Error;

    const scrollPagination = () => {
        if (loading && error) {
            return (
                <ErrorLoading
                    onTryAgain={() => {
                        setFilter({ ...filter });
                    }}
                />
            );
        } else if (scrollControl.records >= scrollControl.pageSize) {
            return (
                <ScrollPagination
                    scrollElement={scrollRef.current}
                    suspended={!!loading}
                    onPageRequested={() => {
                        // if it has no user, is the first page, so no increment
                        const page = (filter.page ?? 0) + (users ? 1 : 0);
                        setFilter((filter) => {
                            filter.page = page;
                            return { ...filter };
                        });
                    }}
                />
            );
        } else {
            return null;
        }
    };

    const table = useMemo(() => {
        if (!users.length && !loading) {
            return (
                <div className={'info-container'}>
                    {'Nenhum usuário encontrado'}
                </div>
            );
        } else {
            return (
                <div className={'table-container'}>
                    <div className={'scroll'} ref={scrollRef}>
                        <UserTable
                            users={users}
                            loadingLine={scrollPagination()}
                        />
                    </div>
                </div>
            );
        }
    }, [users, loading, error]);

    return (
        <CrudContainer
            // I18N
            title={'Gestão de usuários'}
            // I18N
            subtitle={'Usuários cadastrados'}
            onBack={() => navigate('/')}
            submenu={
                <>
                    <div
                        className={'search-field'}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <FieldText
                            // I18N
                            placeholder={'Buscar usuário...'}
                            className={'small search-field'}
                            value={filter.search ?? ''}
                            onChange={(e) =>
                                updateFilter({
                                    ...filter,
                                    search: e.target.value,
                                })
                            }
                        />

                        <FieldSelect
                            value={filter.status ?? ''}
                            className={'condensed search-field'}
                            onChange={(e) =>
                                updateFilter({
                                    ...filter,
                                    status:
                                        e.target.value === 'ALL'
                                            ? undefined
                                            : e.target.value,
                                })
                            }
                            options={[
                                {
                                    key: 'ALL',
                                    // I18N
                                    value: 'Todos',
                                },
                                ...enumToSelectOptions(
                                    UserStatusEnum,
                                    UserStatusEnumToString,
                                ),
                            ]}
                        />

                        <FieldSelect
                            value={filter.role ?? ''}
                            className={'condensed search-field'}
                            onChange={(e) =>
                                updateFilter({
                                    ...filter,
                                    role:
                                        e.target.value === 'ALL'
                                            ? undefined
                                            : e.target.value,
                                })
                            }
                            options={[
                                {
                                    key: 'ALL',
                                    // I18N
                                    value: 'Todos',
                                },
                                ...enumToSelectOptions(
                                    RoleEnum,
                                    RoleEnumToString,
                                ),
                            ]}
                        />
                    </div>

                    <div>
                        <a
                            className={'button primary'}
                            // I18N
                            onClick={() => navigate('/gestao-usuarios/novo')}
                        >
                            {/*I18N*/}
                            {'Cadastrar novo usuário'}
                        </a>
                    </div>
                </>
            }
        >
            {table}
        </CrudContainer>
    );
}

export default UserManagement;
