import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import {
    generateId,
    getBrailleDocument,
    getClosestEditorElementFigure,
    isInsideEditorElementFigure,
} from '../EditorUtil';
import {
    EditorElements,
    elementCanBeInsertedAtSelection,
    setEditorElementLinesCount,
} from '../EditorElements';
import { registerEditorElement } from './Instances';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';

import { showBrailleInGrid } from '../BrailleView';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import { convertElementToBraille } from '../../../../../conversion/braille/HtmlToBraille';

export const EDITOR_ELEMENT_FIGURE = 'EDITOR_ELEMENT_FIGURE';

/**
 * @param editor {EditorCustom | null}
 * @param selection {string | null | undefined}
 * @returns {HTMLElement}
 */
export function createEditorElementFigure(editor, selection = null) {
    const editorElement = document.createElement('editor-element');
    const elementId = generateId(editor, 'editor-element-figure');
    editorElement.setAttribute('contentEditable', 'false');
    editorElement.setAttribute('type', 'figure');
    editorElement.setAttribute('id', elementId);

    /**
     * @type {HTMLElement}
     */
    const figureText = document.createElement('div');
    figureText.className = 'text';
    figureText.style.display = 'none';
    figureText.setAttribute('contentEditable', 'false');
    figureText.innerHTML = selection?.trim()
        ? selection.trim()
        : ZERO_WIDTH_NB_CHAR;

    const figureTest = document.createElement('div');
    figureTest.className = 'preview';
    figureTest.setAttribute('contentEditable', 'false');

    editorElement.appendChild(figureText);
    editorElement.appendChild(figureTest);

    return editorElement;
}

/**
 * @implements {EditorElement}
 */
export class EditorElementFigure {
    constructor() {}

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'figure';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementFigure(node);
    }

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.text'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null) {
        return createEditorElementFigure(editor);
    }

    /**
     * @param editor {EditorCustom}
     * @param data {object}
     * @return {boolean}
     */
    insertElementAtCursor(editor, data) {
        const editorElementFigure = getClosestEditorElementFigure(
            editor.selection.getNode(),
        );

        if (editorElementFigure) {
            // remove figure
            editor.undoManager.transact(() => {
                const fragmentText = document.createDocumentFragment();
                const containerFigure =
                    editorElementFigure.querySelector('.text');
                for (const child of [...containerFigure.childNodes]) {
                    fragmentText.appendChild(child);
                }

                editorElementFigure.replaceWith(fragmentText);
            });
            return true;
        }

        let editorElement = createEditorElementFigure(editor);
        if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
            return false;
        }

        editor.undoManager.transact(() => {
            const id = editorElement.getAttribute('id');
            editor.selection.setContent(editorElement.outerHTML);
            editorElement = editor.dom.get(id);
            editorElement.setAttribute('figure-id', data.id);

            const valueDiv = editorElement.querySelector('.text');
            valueDiv.setAttribute(
                'data-text',
                `${data.figure.join('<br/>')}<br/>`,
            );

            const tmpDiv = document.createElement('div');
            tmpDiv.innerHTML = data.figure.join('<br>');
            const brailleData = convertElementToBraille(
                tmpDiv,
                new EditorElements(null),
                {},
                [BrailleFacilConversionFlag.CONTEXT_FIGURE],
            );

            /**
             * @type {HTMLElement}
             */
            const valueDivTest = editorElement.querySelector('.preview');
            const maxColumn = getBrailleDocument(editor).brailleCellColCount;

            valueDivTest.style.display = 'grid';
            valueDivTest.style.gridTemplateRows = `repeat(${data.figure.length}, 20px)`;
            valueDivTest.style.gridTemplateColumns = `repeat(${maxColumn}, 1fr)`;

            showBrailleInGrid(brailleData.split('\r\n'), valueDivTest, {
                brailleCellColCount: maxColumn,
                brailleCellRowCount: data.figure.length,
            });
        });
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @return {string}
     */
    convertToBraille(element, flags) {
        const rawOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );
        const ignoreCustomElement = flags.includes(
            BrailleFacilConversionFlag.IGNORE_CUSTOM_ELEMENT,
        );

        const textFigure = element.querySelector('.text');

        const lines = textFigure.getAttribute('data-text').split('<br/>');

        const figure = lines
            .map((line) => (rawOutput ? MARK_CHAR.RAW_DATA : '') + line)
            .join('\r\n');

        if (!ignoreCustomElement) {
            setEditorElementLinesCount(element, lines.length);
        }

        if (rawOutput) {
            return figure;
        } else {
            return `<F->\r\n${figure}\r\n<F+>`;
        }
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [...container.querySelectorAll('editor-element[type="figure"]')];
    }

    /**
     * @param element {HTMLElement}
     */
    // noinspection JSUnusedLocalSymbols
    // eslint-disable-next-line no-unused-vars
    checkAndRepairElement(element) {}

    /**
     * @return {string[]}
     */
    getContextMenu() {
        return [
            'customContextMenuAddParagraphBreakAbove',
            'customContextMenuAddParagraphBreakBellow',
            '|',
            'customContextMenuRemove',
        ];
    }
}

registerEditorElement(EDITOR_ELEMENT_FIGURE, new EditorElementFigure());
