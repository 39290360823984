import { isBrNumber, isRomanNumeral } from './TextUtil';

export const coins = new Set(['$', 'r$']);

/**
 * Must be in lower case
 * @type {Set<string>}
 */
export const unitsOfMeasurementPtBr = new Set([
    'm',
    'metro',
    'cm',
    'centímetro',
    'mm',
    'milímetro',
    'km',
    'quilômetro',
    'kg',
    'quilograma',
    'g',
    'grama',
    'mg',
    'miligrama',
    'l',
    'litro',
    'ml',
    'mililitro',
    'hz',
    'hertz',
    's',
    'segundo',
    'min',
    'minuto',
    'h',
    'hora',
    'a.c',
    'ac',
    'a.c.',
    'd.c',
    'dc',
    'd.c.',
    '°',
    '%',
    'Ω',
    '¬w', // same as Ω, but in txt version
    'kwh',
    'km/h',
    'kmh',
    'gb',
    'gb/s',
    'gib',
    'gib/s',
    'tb',
    'tb/s',
    'tib',
    'tib/s',
    'kb',
    'kb/s',
    'kib',
    'kib/s',
    'b',
    'bit',
    'bit/s',
    'bits',
    'bits/s',
    'byte',
    'byte/s',
    'bytes',
    'bytes/s',
    'c',
    'f',
    'k',
    'kcal/g',
    '"',
    'm/s',
    'dm',
    't',
    'ton',
    'w',
    'mw',
    'kj',
    'j',
    '-', // not a unit of measurement, but act like one in simple parentheses context (#51680); feels wrong put here, may need a refactory in future
]);

export function containsUnitOfMeasurementPtBr(txt) {
    function splitNumber(str) {
        let numbersWithLetterSplit =
            /((?:(?:[-+÷—×=]* *)?[0-9]*[.,]?)*[0-9]+)([A-Za-z%°Ω/]*)/g;
        let splitBySpace = str.split(' ');
        let result = [];
        splitBySpace.forEach((item) => {
            let splitNumbersWithLetter = item
                .split(numbersWithLetterSplit)
                .filter(Boolean);
            result.push(...splitNumbersWithLetter);
        });
        return result
            .filter((value) => value.trim().length && !value.match(/[%°Ω]/g))
            .map((value) => value.trim());
    }
    const chunks = splitNumber(txt);

    // check if is an ordinary measurement: number followed by the measure unit
    if (chunks.length === 2) {
        if (isBrNumber(chunks[0]) || isRomanNumeral(chunks[0])) {
            if (
                unitsOfMeasurementPtBr.has(chunks[1].toLowerCase()) ||
                unitsOfMeasurementPtBr.has(chunks[1])
            ) {
                return true;
            }
        } else if (coins.has(chunks[0].toLowerCase()) || coins.has(chunks[0])) {
            if (isBrNumber(chunks[1])) {
                return true;
            }
        }
    } else if (chunks.length === 1) {
        if (isBrNumber(chunks[0]) || isRomanNumeral(chunks[0])) {
            return true;
        }
    }

    //check if it is a time pattern
    if (
        txt.trim().match(/\d+ *[h:] *\d+ *(?:(?:min|[m:])(?: *\d+ *[s:]?)?)?/gi)
    ) {
        return true;
    }

    return false;
}
