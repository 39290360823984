export enum DocumentTypeEnum {
    BRAILLE = "BRAILLE",
    IMAGE_DESCRIPTION = "IMAGE_DESCRIPTION",
    IMAGE_DESCRIPTION_ARC = "IMAGE_DESCRIPTION_ARC",
}

export type DocumentTypeEnumValue =
    (typeof DocumentTypeEnum)[keyof typeof DocumentTypeEnum];

export function DocumentTypeEnumToString(key: DocumentTypeEnumValue): string {
    const documentType = DocumentTypeEnum[key];
    switch (documentType) {
        // I18N
        case DocumentTypeEnum.BRAILLE:
            return "Braille";
        // I18N
        case DocumentTypeEnum.IMAGE_DESCRIPTION:
            return "Descrição de imagens";
        case DocumentTypeEnum.IMAGE_DESCRIPTION_ARC:
            return "Arco (descrição de imagens)";
        default:
            throw new Error("Invalid enum value");
    }
}

export function isDocumentTypeImageDescription(
    key: DocumentTypeEnumValue,
): boolean {
    return key?.startsWith(DocumentTypeEnum.IMAGE_DESCRIPTION);
}
