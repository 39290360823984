import axios from 'axios';

/**
 * @typedef {object} PaginationResDto
 * @template T
 * @property {number} count
 * @property {number} page
 * @property {number} pageSize
 * @property {T[]} records
 */

const Api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    withCredentials: true,
    timeout: 45000,
});

export function setCsrfToken(token) {
    Api.defaults.headers.common['X-Csrf-Token'] = token;
}

export default Api;
