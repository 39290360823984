import Field from './Field';
import FieldText from './FieldText';
import { forwardRef, useRef, useState } from 'react';
import nextId from 'react-id-generator';
import './FieldTag.scss';

function FieldTag({ children, value, onChange, disabled, ...props }, ref) {
    const { validationError } = props;

    const id = props.id ?? nextId('field-tags');
    const idError = `${id}-error`;
    const fieldRef = useRef();

    const [inputValue, setInputValue] = useState('');

    const handleAddTag = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            if (!value.includes(inputValue)) {
                const newTags = [...value, inputValue];
                onChange(newTags);
            }
            setInputValue('');
            e.preventDefault();
        }
    };

    const handleRemoveTag = (tagToRemove) => {
        const newTags = value.filter((tag) => tag !== tagToRemove);
        onChange(newTags);
    };

    return (
        <Field
            ref={fieldRef}
            {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
        >
            <div
                className={'tag-input'}
                aria-invalid={!!validationError}
                aria-errormessage={idError}
                ref={ref}
                id={id}
            >
                <FieldText
                    type={'text'}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleAddTag}
                    placeholder={'Adicione uma tag e pressione Enter'}
                    disabled={disabled}
                />
                <div className={'tags-container'}>
                    {value.map((tag, index) => (
                        <div key={index} className={'tag'}>
                            {tag}
                            <button
                                type={'button'}
                                className={'remove-tag'}
                                onClick={() => handleRemoveTag(tag)}
                            >
                                &times;
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </Field>
    );
}

export default forwardRef(FieldTag);
