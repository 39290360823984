import LoginContainer from '../components/login/LoginContainer';
import ChangePasswordForm from './ChangePasswordForm';

function RecoveryPassword() {
    return (
        <LoginContainer>
            <ChangePasswordForm></ChangePasswordForm>
        </LoginContainer>
    );
}

export default RecoveryPassword;
