import ConfirmModal from '../components/modal/ConfirmModal';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import CloseModalButton from '../components/modal/CloseModalButton';
import DOMPurify from 'dompurify';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { useState } from 'react';

function RouteChangePrompt({ enabled, title, message, onSave }) {
    const { state, proceed, reset } = useBlocker(enabled);
    const { setLoading, backendConnectionError } =
        useContext(EnvironmentContext);
    const [loadingSave, setLoadingSave] = useState(false);

    // I18N
    const defaultMessage =
        'Há modificações não salvas no documento. Tem certeza que deseja sair da página?';

    useEffect(() => {
        window.onbeforeunload = () => {
            if (enabled) {
                // I18N
                return defaultMessage;
            }
        };
        return () => {
            window.onbeforeunload = undefined;
        };
    }, [enabled]);

    useEffect(() => {
        setLoading(true, true);
    }, [loadingSave]);

    const show = state === 'blocked';
    title = title ?? 'Sair da página';
    message = message ?? defaultMessage;
    const onConfirm = proceed;
    const onCancel = reset;

    async function saveAndProceed() {
        setLoadingSave(true);
        try {
            await onSave();
            proceed();
        } catch (e) {
            backendConnectionError('Fail to save document', e);
        } finally {
            setLoadingSave(false);
        }
    }

    return (
        <div
            className={`modal default-modal ${show ? 'show' : ''}`}
            style={{ zIndex: 10000 }}
        >
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={onCancel} />
                <h2
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(title),
                    }}
                />
                <div
                    className={'message'}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(message),
                    }}
                />
                <div className={'controls'}>
                    <a className={'button'} onClick={onConfirm}>
                        {/*I18N*/}
                        {'Perder modificações e sair'}
                    </a>
                    <a className={'button primary'} onClick={onCancel}>
                        {/*I18N*/}
                        {'Continuar editando o documento'}
                    </a>
                    <a
                        className={`button primary ${loadingSave ? 'loading' : ''}`}
                        onClick={saveAndProceed}
                    >
                        {/*I18N*/}
                        {'Salvar e sair'}
                    </a>
                </div>
            </div>
        </div>
    );
}

export default RouteChangePrompt;
