import Api from './Api';

/**
 * @typedef {object} StatusResDto
 * @property {string} name
 * @property {string} email
 * @property {string} csrfToken
 * @property {number} id
 * @property {import('plataforma-braille-common').RoleEnumValue[]} roles
 */

export async function login(email, password, recaptchaToken) {
    const data = {
        email,
        password,
        recaptchaToken,
    };
    try {
        return await Api.post('/auth/login', data);
    } catch (e) {
        if (e.response && e.response.status === 401) {
            return e.response;
        }
        throw e;
    }
}

/**
 * @returns {Promise<StatusResDto>}
 */
export async function status() {
    try {
        return (await Api.get('/auth/status')).data;
    } catch (e) {
        if (e.response?.status === 401) {
            return null;
        }
        throw e;
    }
}

export async function logoff() {
    try {
        const response = await Api.get('/auth/logoff');
        if (response.status !== 200) {
            console.warn(
                `Unexpected status code from logoff: ${response.status}`,
                response,
            );
            return false;
        }
        return response.status === 200;
    } catch (e) {
        if (e.response?.status === 401) {
            return true;
        }
        throw e;
    }
}

export async function recoveryPassword(email, recaptchaToken) {
    const data = {
        email,
        recaptchaToken,
    };
    await Api.post('/users/recovery-password', data);
}

export async function checkToken(email, token) {
    const data = {
        email,
        token,
    };
    try {
        await Api.post('/users/check-token', data);
        return true;
    } catch (e) {
        if (e.response?.status === 404) {
            return false;
        }
        throw e;
    }
}

export async function changePassword(password, email, token, recaptchaToken) {
    const data = {
        email,
        password,
        token,
        recaptchaToken,
    };
    try {
        await Api.patch('/users/change-password', data);
        return true;
    } catch (e) {
        if (e.response?.status === 404) {
            return false;
        }
        throw e;
    }
}
