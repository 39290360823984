import './Loading.scss';

function Loading() {
    return (
        <div className={'loading-indicator'}>
            <button className={'button loading'} />
            {/*I18N*/}
            {'Carregando...'}
        </div>
    );
}

export default Loading;
