import FieldSelect from '../components/FieldSelect';
import { enumToSelectOptions } from '../util/EnumToSelectOptions';
import PropTypes from 'prop-types';

/**
 * @typedef {('NIMBUS_ROMAN' | 'DEJAVU_SANS' | 'DEJAVU_SANS_BOLD')} FontNameEnumValue
 */

/**
 * @type {Object<string, FontNameEnumValue>}
 */
export const FontNameEnum = {
    NIMBUS_ROMAN: 'NIMBUS_ROMAN',
    DEJAVU_SANS: 'DEJAVU_SANS',
    DEJAVU_SANS_BOLD: 'DEJAVU_SANS_BOLD',
};

export function FontNameEnumToString(fontName) {
    switch (fontName) {
        case FontNameEnum.DEJAVU_SANS:
            return 'DeJavu Sans';
        case FontNameEnum.DEJAVU_SANS_BOLD:
            return 'DeJavu Sans Bold';
        case FontNameEnum.NIMBUS_ROMAN:
            return 'Nimbus Roman';
        default:
            throw new Error('Invalid enum value');
    }
}

/**
 * @param formData {FormData}
 * @param formError {FormError}
 * @param onChange {function(FormData):void}
 * @return {JSX.Element}
 * @constructor
 */
export function FieldsFontType({ formData, formError, onChange }) {
    return (
        <>
            <div className={'gd-col gd-col-2--desktop'}>
                <FieldSelect
                    // I18N
                    label={'Nome'}
                    // I18N
                    placeholder={'Selecione a fonte'}
                    options={enumToSelectOptions(
                        FontNameEnum,
                        FontNameEnumToString,
                    )}
                    required={true}
                    validationError={formError.inkFontType}
                    value={formData.inkFontType ?? '-1'}
                    onChange={(e) => {
                        let inkFontSize = null;
                        let inkPageLineHeight;
                        switch (e.target.value) {
                            case FontNameEnum.NIMBUS_ROMAN:
                                inkFontSize = 12;
                                inkPageLineHeight = 13;
                                break;
                            case FontNameEnum.DEJAVU_SANS:
                                inkFontSize = 20;
                                inkPageLineHeight = 24;
                                break;
                            case FontNameEnum.DEJAVU_SANS_BOLD:
                                inkFontSize = 24;
                                inkPageLineHeight = 34;
                                break;
                        }
                        onChange({
                            inkFontType: e.target.value,
                            inkFontSize,
                            inkPageLineHeight,
                        });
                    }}
                />
            </div>
            <div className={'gd-col gd-col-1--desktop'}>
                <FieldSelect
                    // I18N
                    label={'Tamanho'}
                    // I18N
                    placeholder={'Selecione o tamanho da fonte'}
                    options={[12, 20, 24].map((size) => ({
                        key: size.toString(),
                        value: size.toLocaleString(),
                    }))}
                    required={true}
                    validationError={formError.inkFontSize}
                    value={formData.inkFontSize ?? '-1'}
                    onChange={(e) => {
                        const inkFontSize = parseInt(e.target.value);
                        let inkPageLineHeight;
                        switch (inkFontSize) {
                            case 12:
                                inkPageLineHeight = 13;
                                break;
                            case 20:
                                inkPageLineHeight = 24;
                                break;
                            case 24:
                                inkPageLineHeight = 34;
                                break;
                        }

                        onChange({
                            inkFontSize,
                            inkPageLineHeight,
                        });
                    }}
                />
            </div>
        </>
    );
}

FieldsFontType.propTypes = {
    formData: PropTypes.object,
    formError: PropTypes.object,
    onChange: PropTypes.func,
};
