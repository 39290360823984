import { PunctuationMarksPtBr } from '../../util/PunctuationMarks';

/**
 * @param line {string}
 * @return {string}
 */
export function removeEmptyHighlight(line) {
    // remove empty highlight blocks
    line = line.replace(/(?:`\( *)?\*( *)\*(?: *[\d.,]* *`\))?/g, (match) => {
        // do not remove footnotes (#50321)
        if (match.match(/`\( *\* *\* *[\d.,]* *`\)/g)) {
            return match;
        } else {
            return match.replace(/\*( *)\*/g, '$1');
        }
    });

    // source: https://sgm.codebit.com.br/manutencao/36455
    const punctuationMarks = PunctuationMarksPtBr.join('|');
    const regExp = new RegExp(
        `(\\*)( *)([${punctuationMarks}]?)( *)(.*?)( *)([${punctuationMarks}]?)( *)(\\*)`,
        'g',
    );
    const match = line.match(regExp);
    if (match) {
        const lineTrimmed = line.trim();
        if (
            match.length === 1 &&
            lineTrimmed.startsWith('*') &&
            lineTrimmed.endsWith('*')
        ) {
            return line;
        }
        return line.replace(regExp, '$3$2$4$1$5$9$7$6$8');
    } else {
        return line;
    }
}
