import { jsPDF as JsPDF } from 'jspdf';
import { InkFontTypeEnum, MeasureEnum } from 'plataforma-braille-common';
import { ptToMm, pxToMm, pxToPt } from '../../util/MeasureConversionFunctions';
import { downloadToBase64 } from '../../util/DownloadToBase64';
import {
    EditorElementAngle,
    EditorElementNthRoot,
    EditorElementNthRootRadicand,
    EditorElementArrowDown2x,
    EditorElementArrowUp2x,
} from '../../components/images';
import {
    getImageUrlDataFromBackgroundImage,
    OrientationEnum,
} from 'plataforma-braille-common';
import { getImageDimensions } from '../../util/GetImageDimensions';
import { getImageFormatFromBase64 } from '../../util/GetImageFormatFromBase64';
import { delay } from '../../util/Delay';
import pLimit from 'p-limit';
import { getVersion } from '../../version';
import {
    getClosestTextNode,
    getNextSiblingElement,
    isEditorElementParagraphBreak,
    isEditorElementParagraphBreakHyphen,
} from '../../edit-document/editor-mods/modules/core/EditorUtil';
import { symbols } from '../../edit-document/editor-mods/modules/SymbolsAndSpecialCharacters';
import {
    isEditorElementImage,
    isInsideEditorElementImage,
} from '../../edit-document/editor-mods/modules/core/editor-element/EditorElementImage';
import { isInsideEditorElementAngle } from '../../edit-document/editor-mods/modules/core/editor-element/EditorElementAngle';
import {
    isEditorElementNthRoot,
    isInsideEditorElementNthRoot,
} from '../../edit-document/editor-mods/modules/core/editor-element/EditorElementNthRoot';
import { isInsideEditorElementLineSegment } from '../../edit-document/editor-mods/modules/core/editor-element/EditorElementLineSegment';
import {
    isEditorElementArrowDown,
    isEditorElementArrowUp,
} from '../../edit-document/editor-mods/modules/core/editor-element/EditorElementArrow';
import { isEditorBrailleView } from '../../edit-document/editor-mods/modules/core/BrailleView';
import { isEditorElementHeading } from '../../edit-document/editor-mods/modules/core/editor-element/EditorElementHeading';
import { removeInvisibleSpacesFromText } from '../../util/TextUtil';
import { isEditorElementHeader } from '../../edit-document/editor-mods/modules/core/editor-element/EditorElementHeader';

const FONT_DEJAVU_SANS_ID = 'DeJavu Sans'; // replacement to Verdana
const FONT_DEJAVU_SANS_MONO_ID = 'DeJavu Sans Mono'; // replacement to Courier New

/**
 * @param node {Node | HTMLElement}
 * @return {DOMRect}
 */
function getBoundingRect(node) {
    if (node.nodeType === Node.TEXT_NODE) {
        let range = document.createRange();
        range.selectNodeContents(node);
        return range.getBoundingClientRect();
    } else {
        return node.getBoundingClientRect();
    }
}

/**
 * @param parent {Node | HTMLElement}
 * @param node {Node | HTMLElement}
 * @param scaleAdj {number}
 * @return {{x: number, y: number, width: number, height: number}}
 */
function getRelativeRect(parent, node, scaleAdj) {
    const parentRect = getBoundingRect(parent);
    const nodeRect = getBoundingRect(node);
    return {
        x: (nodeRect.left - parentRect.left) * scaleAdj,
        y: (nodeRect.top - parentRect.top) * scaleAdj,
        width: nodeRect.width * scaleAdj,
        height: nodeRect.height * scaleAdj,
    };
}

/**
 * @param brailleDocument {BrailleDocument}
 * @param scaleAdj {number}
 * @param pages {HTMLElement[] | NodeListOf<Element>}
 * @param fnCallbackProgress {function(current: number, total: number):void | null}
 * @return {Promise<void>}
 */
export async function exportPdf(
    brailleDocument,
    scaleAdj,
    pages,
    fnCallbackProgress,
) {
    if (fnCallbackProgress) {
        fnCallbackProgress(0, pages.length);
        await delay(0); // give a breath to update the progress
    }

    const doc = new JsPDF({ unit: 'mm' });
    doc.deletePage(1);

    let {
        pageWidth,
        pageHeight,
        pageMeasure,
        pageOrientation,
        inkFontType,
        inkFontSize,
        interPoint,
    } = brailleDocument;
    if (pageMeasure === MeasureEnum.CENTIMETER) {
        pageWidth *= 10;
        pageHeight *= 10;
    }
    let pageOrientationPdf;
    switch (pageOrientation) {
        default:
        case OrientationEnum.PORTRAIT:
            pageOrientationPdf = 'p';
            break;
        case OrientationEnum.LANDSCAPE:
            pageOrientationPdf = 'l';
            break;
    }

    let fontId;

    let promises = [];
    const limit = pLimit(8);

    if (brailleDocument.convertedToBraille) {
        fontId = FONT_DEJAVU_SANS_ID;
        switch (inkFontType) {
            default:
            case InkFontTypeEnum.DEJAVU_SANS:
                promises.push(
                    limit(async () => {
                        doc.addFileToVFS(
                            'dejavu-sans-regular.ttf',
                            await downloadToBase64(
                                `${process.env.PUBLIC_URL}/assets/fonts/dejavu-sans-regular.ttf?version=${getVersion()}`,
                            ),
                        );
                        doc.addFont(
                            'dejavu-sans-regular.ttf',
                            FONT_DEJAVU_SANS_ID,
                            'normal',
                        );
                    }),
                );
                promises.push(
                    limit(async () => {
                        doc.addFileToVFS(
                            'dejavu-sans-bold.ttf',
                            await downloadToBase64(
                                `${process.env.PUBLIC_URL}/assets/fonts/dejavu-sans-bold.ttf?version=${getVersion()}`,
                            ),
                        );
                        doc.addFont(
                            'dejavu-sans-bold.ttf',
                            FONT_DEJAVU_SANS_ID,
                            'bold',
                        );
                    }),
                );
                promises.push(
                    limit(async () => {
                        doc.addFileToVFS(
                            'dejavu-sans-italic.ttf',
                            await downloadToBase64(
                                `${process.env.PUBLIC_URL}/assets/fonts/dejavu-sans-italic.ttf?version=${getVersion()}`,
                            ),
                        );
                        doc.addFont(
                            'dejavu-sans-italic.ttf',
                            FONT_DEJAVU_SANS_ID,
                            'italic',
                        );
                    }),
                );
                promises.push(
                    limit(async () => {
                        doc.addFileToVFS(
                            'dejavu-sans-bold-italic.ttf',
                            await downloadToBase64(
                                `${process.env.PUBLIC_URL}/assets/fonts/dejavu-sans-bold-italic.ttf?version=${getVersion()}`,
                            ),
                        );
                        doc.addFont(
                            'dejavu-sans-bold-italic.ttf',
                            FONT_DEJAVU_SANS_ID,
                            'bold italic',
                        );
                    }),
                );
                break;
            case InkFontTypeEnum.DEJAVU_SANS_BOLD:
                promises.push(
                    limit(async () => {
                        doc.addFileToVFS(
                            'dejavu-sans-bold.ttf',
                            await downloadToBase64(
                                `${process.env.PUBLIC_URL}/assets/fonts/dejavu-sans-bold.ttf?version=${getVersion()}`,
                            ),
                        );
                        doc.addFont(
                            'dejavu-sans-bold.ttf',
                            FONT_DEJAVU_SANS_ID,
                            'normal',
                        );
                        doc.addFont(
                            'dejavu-sans-bold.ttf',
                            FONT_DEJAVU_SANS_ID,
                            'bold',
                        );
                    }),
                );
                promises.push(
                    limit(async () => {
                        doc.addFileToVFS(
                            'dejavu-sans-bold-italic.ttf',
                            await downloadToBase64(
                                `${process.env.PUBLIC_URL}/assets/fonts/dejavu-sans-bold-italic.ttf?version=${getVersion()}`,
                            ),
                        );
                        doc.addFont(
                            'dejavu-sans-bold-italic.ttf',
                            FONT_DEJAVU_SANS_ID,
                            'italic',
                        );
                        doc.addFont(
                            'dejavu-sans-bold-italic.ttf',
                            FONT_DEJAVU_SANS_ID,
                            'bold italic',
                        );
                    }),
                );
                break;
        }
    } else {
        fontId = FONT_DEJAVU_SANS_MONO_ID;

        promises.push(
            limit(async () => {
                doc.addFileToVFS(
                    'dejavu-sans-mono-regular.ttf',
                    await downloadToBase64(
                        `${process.env.PUBLIC_URL}/assets/fonts/dejavu-sans-mono-regular.ttf?version=${getVersion()}`,
                    ),
                );
                doc.addFont(
                    'dejavu-sans-mono-regular.ttf',
                    FONT_DEJAVU_SANS_MONO_ID,
                    'normal',
                );
            }),
        );
        promises.push(
            limit(async () => {
                doc.addFileToVFS(
                    'dejavu-sans-mono-bold.ttf',
                    await downloadToBase64(
                        `${process.env.PUBLIC_URL}/assets/fonts/dejavu-sans-mono-bold.ttf?version=${getVersion()}`,
                    ),
                );
                doc.addFont(
                    'dejavu-sans-mono-bold.ttf',
                    FONT_DEJAVU_SANS_MONO_ID,
                    'bold',
                );
            }),
        );
        promises.push(
            limit(async () => {
                doc.addFileToVFS(
                    'dejavu-sans-mono-italic.ttf',
                    await downloadToBase64(
                        `${process.env.PUBLIC_URL}/assets/fonts/dejavu-sans-mono-italic.ttf?version=${getVersion()}`,
                    ),
                );
                doc.addFont(
                    'dejavu-sans-mono-italic.ttf',
                    FONT_DEJAVU_SANS_MONO_ID,
                    'italic',
                );
            }),
        );
        promises.push(
            limit(async () => {
                doc.addFileToVFS(
                    'dejavu-sans-mono-bold-italic.ttf',
                    await downloadToBase64(
                        `${process.env.PUBLIC_URL}/assets/fonts/dejavu-sans-mono-bold-italic.ttf?version=${getVersion()}`,
                    ),
                );
                doc.addFont(
                    'dejavu-sans-mono-bold-italic.ttf',
                    FONT_DEJAVU_SANS_MONO_ID,
                    'bold italic',
                );
            }),
        );
    }

    await Promise.all(promises);

    for (const [idx, page] of pages.entries()) {
        const original = page.cloneNode(true);
        dismemberTextNodes(page); // this will fragment text node and may increase memory usage
        if (!brailleDocument.convertedToBraille) {
            const pageRect = page.getBoundingClientRect();
            pageWidth = Math.round(pxToMm(pageRect.width * scaleAdj));
            pageHeight = Math.round(pxToMm(pageRect.height * scaleAdj));
            pageOrientationPdf = pageWidth >= pageHeight ? 'l' : 'p';
            inkFontSize = 14;
        }
        doc.addPage([pageWidth, pageHeight], pageOrientationPdf);
        let walk = page.firstChild;
        promises = [];
        while (walk) {
            const element = walk;
            walk = walk.nextSibling;
            if (isEditorBrailleView(element)) continue;
            if (element?.tagName === 'BR') continue;
            if (isEditorElementParagraphBreak(element)) continue;
            promises.push(
                draw(
                    doc,
                    page,
                    element,
                    {
                        scaleAdj,
                        fontId,
                        fontSize: inkFontSize,
                        interPoint,
                        pageNumber: idx + 1,
                    },
                    promises,
                ),
            );
        }
        await Promise.all(promises);
        page.replaceWith(original);

        if (fnCallbackProgress) fnCallbackProgress(idx + 1, pages.length);
        await delay(0); // give a breath to update the progress
    }
    doc.save(brailleDocument.name);
}

/**
 * @typedef {object} Options
 * @property {boolean | undefined} bold
 * @property {boolean | undefined} italic
 * @property {boolean | undefined} underline
 * @property {number} scaleAdj
 * @property {string} fontId
 * @property {number} fontSize
 * @property {boolean} interPoint
 * @property {number} pageNumber
 */

/**
 * @param node {HTMLElement | Node}
 */
function dismemberTextNodes(node) {
    if (node.nodeType === Node.TEXT_NODE) {
        const textSplit = removeInvisibleSpacesFromText(node.textContent).split(
            /(\W+)/,
        );
        if (textSplit.length > 1) {
            let chunks = [];
            for (let chunk of textSplit) {
                chunks.push(document.createTextNode(chunk));
            }
            node.replaceWith(...chunks);
        }
    } else {
        const children = [...node.childNodes];
        for (let child of children) {
            dismemberTextNodes(child);
        }
    }
}
/**
 * @param doc {module:jspdf.jsPDF}
 * @param page {HTMLElement}
 * @param node {Node | HTMLElement}
 * @param options {Options}
 * @param promises {Promise[]}
 * @return {Promise<void>}
 */
async function draw(doc, page, node, options, promises) {
    if (node.nodeType === Node.TEXT_NODE) {
        const { x, y } = getRelativeRect(page, node, options.scaleAdj);
        let textChunks = node.textContent
            .split(new RegExp(`(?<=[${symbols.join('')} ])`))
            .filter((chunk) => !!chunk);
        let xOffset = 0;
        for (let [idx, chunk] of textChunks.entries()) {
            const lastChunk = idx === textChunks.length - 1;
            const fontId = options.fontId;

            if (options.bold && !options.italic) {
                doc.setFont(fontId, 'bold');
            } else if (!options.bold && options.italic) {
                doc.setFont(fontId, 'italic');
            } else if (options.bold && options.italic) {
                doc.setFont(fontId, 'bold italic');
            } else {
                doc.setFont(fontId, 'normal');
            }

            // last chunk only
            if (
                lastChunk &&
                isEditorElementParagraphBreakHyphen(
                    getNextSiblingElement(node),
                ) &&
                node.nextSibling?.nodeType !== Node.TEXT_NODE
            ) {
                chunk += '-';
            }
            const fontSize = pxToPt(options.fontSize);
            doc.setFontSize(fontSize);
            doc.setDrawColor('#000');
            doc.setTextColor('#000');
            doc.text(chunk, pxToMm(x) + xOffset, pxToMm(y), {
                baseline: 'top',
            });
            xOffset += doc.getTextWidth(chunk);

            if (lastChunk && options.underline) {
                const fontSize = doc.getFontSize();
                const lineHeight = ptToMm(fontSize);
                const textWidth = doc.getTextWidth(node.textContent);
                const yUnderline = pxToMm(y) + lineHeight + 0.1;
                doc.setLineWidth(pxToMm(1.5));
                doc.line(
                    pxToMm(x),
                    yUnderline,
                    pxToMm(x) + textWidth,
                    yUnderline,
                );
            }
        }
    } else {
        const fontSize = options.fontSize;
        const bold = !options.bold && node.tagName === 'STRONG';
        const italic = !options.italic && node.tagName === 'EM';
        const underline =
            !options.underline &&
            node.tagName === 'SPAN' &&
            node.style?.textDecoration === 'underline';
        if (bold) options.bold = true;
        if (italic) options.italic = true;
        if (underline) options.underline = true;

        if (isInsideEditorElementNthRoot(node)) {
            promises.push(drawNthRoot(doc, page, node, options));
        } else if (isInsideEditorElementLineSegment(node)) {
            promises.push(drawLineSegment(doc, page, node, options));
        } else if (isInsideEditorElementAngle(node)) {
            promises.push(drawAngle(doc, page, node, options));
        } else if (isInsideEditorElementImage(node)) {
            promises.push(drawImageElement(doc, page, node, options));
        } else if (isEditorElementArrowUp(node)) {
            promises.push(
                drawEditorElementArrow(doc, page, node, options, true),
            );
        } else if (isEditorElementArrowDown(node)) {
            promises.push(
                drawEditorElementArrow(doc, page, node, options, false),
            );
        } else if (isEditorElementHeading(node)) {
            promises.push(drawEditorElementHeading(doc, page, node, options));
        } else if (isEditorElementHeader(node)) {
            promises.push(drawEditorElementHeader(doc, page, node, options));
        }
        for (const child of node.childNodes) {
            await draw(doc, page, child, options, promises);
        }
        if (bold) options.bold = false;
        if (italic) options.italic = false;
        if (underline) options.underline = false;
        options.fontSize = fontSize;
    }
}

/**
 * @param doc {module:jspdf.jsPDF}
 * @param page {HTMLElement}
 * @param node {HTMLElement | Node}
 * @param options {Options}
 * @return {Promise<void>}
 */
async function drawNthRoot(doc, page, node, options) {
    if (isEditorElementNthRoot(node)) {
        const nthRootImg = await downloadToBase64(`${EditorElementNthRoot}`);
        const rect = node.getBoundingClientRect();
        const width = pxToMm(16); // same width of image in css
        const height = pxToMm(rect.height * options.scaleAdj);
        const { x, y } = getRelativeRect(page, node, options.scaleAdj);
        doc.addImage(
            nthRootImg,
            'PNG',
            pxToMm(x),
            pxToMm(y - 2),
            width,
            height,
            'editor-element-nth-root.png',
            'NONE',
        );
    } else if (node.classList.contains('index')) {
        options.fontSize = 12;
    } else if (node.classList.contains('radicand')) {
        const radicand = await downloadToBase64(
            `${EditorElementNthRootRadicand}`,
        );
        const rect = node.getBoundingClientRect();
        const width = rect.width * options.scaleAdj; // same width of image in css
        const height = 1.1;
        const { x, y } = getRelativeRect(page, node, options.scaleAdj);
        doc.addImage(
            radicand,
            'PNG',
            pxToMm(x + 0.5),
            pxToMm(y - 2),
            pxToMm(width - 0.5),
            pxToMm(height),
            'editor-element-nth-root-radicand.png',
            'NONE',
        );
    }
}

/**
 * @param doc {module:jspdf.jsPDF}
 * @param page {HTMLElement}
 * @param node {HTMLElement | Node}
 * @param options {Options}
 * @return {Promise<void>}
 */
async function drawLineSegment(doc, page, node, options) {
    const rect = node.getBoundingClientRect();
    const width = rect.width * options.scaleAdj; // same width of image in css
    const { x, y } = getRelativeRect(page, node, options.scaleAdj);
    doc.setLineWidth(pxToMm(2)); // same of css border
    doc.line(pxToMm(x), pxToMm(y), pxToMm(x + width), pxToMm(y));
}

/**
 * @param doc {module:jspdf.jsPDF}
 * @param page {HTMLElement}
 * @param node {HTMLElement | Node}
 * @param options {Options}
 * @return {Promise<void>}
 */
async function drawAngle(doc, page, node, options) {
    const radicand = await downloadToBase64(`${EditorElementAngle}`);
    const rect = node.getBoundingClientRect();
    const width = rect.width * options.scaleAdj;
    const height = 7.5; // same as css
    const { x, y } = getRelativeRect(page, node, options.scaleAdj);
    doc.addImage(
        radicand,
        'PNG',
        pxToMm(x),
        pxToMm(y - 2.5),
        pxToMm(width),
        pxToMm(height),
        'editor-element-angle.png',
        'NONE',
    );
}

/**
 * @param doc {module:jspdf.jsPDF}
 * @param page {HTMLElement}
 * @param node {HTMLElement | Node}
 * @param options {Options}
 * @return {Promise<void>}
 */
async function drawImageElement(doc, page, node, options) {
    const legend = node.classList.contains('info-legend');
    const description = node.classList.contains('info-description');
    const pageNumber = node.classList.contains('page-number');
    if (isEditorElementImage(node)) {
        let { x, y } = getRelativeRect(page, node, options.scaleAdj);
        const rect = node.getBoundingClientRect();
        const width = rect.width * options.scaleAdj;
        const height = rect.height * options.scaleAdj;
        doc.setLineWidth(pxToMm(1)); // same as css
        doc.rect(pxToMm(x), pxToMm(y), pxToMm(width), pxToMm(height));

        let imageAlias = null;
        // draws the image box
        const { backgroundImage } = getComputedStyle(node);
        const image = getImageUrlDataFromBackgroundImage(backgroundImage);
        if (!node.style.backgroundImage) {
            // the image is inherited in css
            imageAlias = 'no-image.png';
        }
        if (image) {
            try {
                let imageData = await downloadToBase64(image, false);
                const imageFormat = getImageFormatFromBase64(imageData);
                imageData = imageData.split(',')[1];

                let { width: imgWidth, height: imgHeight } =
                    await getImageDimensions(imageData);
                const maxSize = 64;
                if (imgWidth > imgHeight) {
                    imgHeight = maxSize * (imgHeight / imgWidth);
                    imgWidth = maxSize;
                } else {
                    imgWidth = maxSize * (imgWidth / imgHeight);
                    imgHeight = maxSize;
                }
                x += 8; // css margin in background
                y += height / 2 - imgHeight / 2; // center in y axis

                doc.addImage(
                    imageData,
                    imageFormat.toUpperCase(),
                    pxToMm(x),
                    pxToMm(y),
                    pxToMm(imgWidth),
                    pxToMm(imgHeight),
                    imageAlias,
                    'SLOW',
                );
            } catch (e) {
                console.error('Fail to download image.', e);
            }
        }
    } else if (node.classList.contains('page-container')) {
        const { x, y } = getRelativeRect(page, node, options.scaleAdj);
        const rect = node.getBoundingClientRect();
        const width = pxToMm(rect.width * options.scaleAdj);
        const height = pxToMm(rect.height * options.scaleAdj);
        doc.setDrawColor('#878787'); // css color
        doc.setLineWidth(pxToMm(1)); // 1px line width
        doc.roundedRect(
            pxToMm(x),
            pxToMm(y),
            width,
            height,
            pxToMm(4),
            pxToMm(4),
            'S',
        );
    } else if (legend || description || pageNumber) {
        let closestTextNode = getClosestTextNode(node);
        if (!closestTextNode) {
            node.innerHTML = '&nbsp;';
            closestTextNode = getClosestTextNode(node);
        }
        let { x, y } = getRelativeRect(page, closestTextNode, options.scaleAdj);
        doc.setFont(options.fontId, 'bold');
        doc.setFontSize(pxToPt(options.fontSize));
        let text;
        if (legend) {
            // I18N
            text = 'Legenda: ';
        } else if (description) {
            // I18N
            text = 'Descrição: ';
        } else {
            text = 'Pág.: ';
        }
        x = pxToMm(x);
        if (page) {
            x -= doc.getTextWidth(text); // box width defined in css
        } else {
            x -= pxToMm(90) - doc.getTextWidth(text); // box width defined in css
        }
        doc.setTextColor('#878787');
        doc.text(text, x, pxToMm(y), { baseline: 'top' });
    }
}

/**
 * @param doc {module:jspdf.jsPDF}
 * @param page {HTMLElement}
 * @param node {HTMLElement | Node}
 * @param arrowUp {boolean}
 * @param options {Options}
 *
 * @return {Promise<void>}
 */
async function drawEditorElementArrow(doc, page, node, options, arrowUp) {
    let imageSrc;
    let alias;
    if (arrowUp) {
        imageSrc = EditorElementArrowUp2x;
        alias = 'editor-element-arrow-up.png';
    } else {
        imageSrc = EditorElementArrowDown2x;
        alias = 'editor-element-arrow-down.png';
    }

    const imageData = await downloadToBase64(imageSrc);
    const {
        height,
        x: containerX,
        y: containerY,
    } = getRelativeRect(page, node, options.scaleAdj);

    let { width: imgWidth, height: imgHeight } =
        await getImageDimensions(imageData);

    const widthReason = imgWidth / imgHeight;
    const cssHeightReason = 0.6;
    const cssYOffset = 3;

    imgHeight = height * cssHeightReason;
    imgWidth = imgHeight * widthReason;
    let y;
    if (arrowUp) {
        y = cssYOffset;
    } else {
        y = height - imgHeight - cssYOffset;
    }

    doc.addImage(
        imageData,
        'PNG',
        pxToMm(containerX),
        pxToMm(containerY + y),
        pxToMm(imgWidth),
        pxToMm(imgHeight),
        alias,
        'NONE',
    );
}

/**
 * @param doc {module:jspdf.jsPDF}
 * @param page {HTMLElement}
 * @param node {HTMLElement | Node}
 * @param options {Options}
 *
 * @return {Promise<void>}
 */
async function drawEditorElementHeading(doc, page, node, options) {
    const headingStyle = Number(node.getAttribute('type').split('-')[1]);
    const container = node.querySelector('.container');
    const value = node.querySelector('.value');
    switch (headingStyle) {
        case 1:
        case 2:
            const rect = value.getBoundingClientRect();
            const { x, y } = getRelativeRect(page, container, options.scaleAdj);
            doc.setLineWidth(pxToMm(1));
            const width = rect.width * options.scaleAdj;
            const height = rect.height * options.scaleAdj;
            doc.line(
                pxToMm(x),
                pxToMm(y + height - 3),
                pxToMm(x + width),
                pxToMm(y + height - 3),
            );
            break;
        case 3:
        case 4:
        case 5:
            break;
    }
}

/**
 * @param doc {module:jspdf.jsPDF}
 * @param page {HTMLElement}
 * @param node {HTMLElement | Node}
 * @param options {Options}
 *
 * @return {Promise<void>}
 */
async function drawEditorElementHeader(doc, page, node, options) {
    const { interPoint = false } = options;

    if (interPoint) {
        const braillePagination =
            parseInt(node.querySelector('.braille-pagination')?.textContent) ||
            0;

        const isEvenPage = braillePagination % 2 === 0;
        if (isEvenPage) {
            node.remove();
            return;
        }
    }

    /**
     *
     * @type {HTMLElement}
     */
    const identification = node.querySelector('.identification');
    const suppressIdentification =
        identification.getAttribute('data-suppress') === 'true';

    if (suppressIdentification) {
        identification.innerText = '';
    }
}
