/**
 * @implements {DocumentUpdateVersion}
 */
export class Version6 {
    /**
     * @returns {number}
     */
    version() {
        return 6;
    }

    /**
     * @param editor {EditorCustom}
     * @param page {HTMLElement}
     * @returns {Promise<void>}
     */
    async updatePage(editor, page) {
        const computerRelatedElements = page.querySelectorAll(
            'editor-element[type="informative"]',
        );
        for (let element of computerRelatedElements) {
            element.setAttribute('type', 'computer-related');
        }
    }
}
