import FloatDialog from '../components/FloatDialog';
import PropTypes from 'prop-types';
import './ImageMiniatureDialog.scss';
import { useEffect, useRef, useState } from 'react';
import Loading from '../components/Loading';
import ErrorLoading from '../components/ErrorLoading';

export function ImageMiniatureDialog({ editor, attachEditorElement }) {
    /**
     * @type {React.MutableRefObject<HTMLImageElement | null>}
     */
    const imgRef = useRef(null);
    /**
     * @type {React.MutableRefObject<FloatDialogFunctions | undefined>}
     */
    const floatDialogRef = useRef();
    /**
     * @type {React.MutableRefObject<string>}
     */
    const dataImageRef = useRef();
    const [loading, setLoading] = useState(null);

    let dataImage = null;
    if (attachEditorElement) {
        dataImage = attachEditorElement.getAttribute('data-image');
        if (dataImage) {
            const match = dataImage.match(/url\(["'](.*)["']\)/i);
            dataImage = match[1] ?? dataImage;
            dataImageRef.current = dataImage;
        }
    }

    function finished() {
        setLoading(false);
        imgRef.current['error'] = false;
    }

    function error() {
        if (imgRef.current?.src) {
            setLoading(new Error('Fail to load image'));
            imgRef.current['error'] = true;
        } else {
            imgRef.current['error'] = false;
            setLoading(false);
        }
    }

    useEffect(() => {
        imgRef.current?.addEventListener('load', finished);
        imgRef.current?.addEventListener('error', error);
        return () => {
            imgRef.current?.removeEventListener('load', finished);
            imgRef.current?.removeEventListener('error', error);
        };
    }, []);

    useEffect(() => {
        setLoading(true);
    }, [attachEditorElement]);

    useEffect(() => {
        if (attachEditorElement) {
            floatDialogRef.current?.recalculePosition();
        }
        if (!(loading instanceof Error) && imgRef.current?.complete) {
            // image cached into browser
            if (imgRef.current['error']) {
                error();
            } else {
                finished();
            }
        }
    }, [loading]);

    return (
        <FloatDialog
            ref={floatDialogRef}
            editor={editor}
            attachEditorElement={dataImage ? attachEditorElement : null}
            topOffset={4}
        >
            <div className={'image-miniature-dialog'}>
                {loading && loading instanceof Error ? (
                    <ErrorLoading />
                ) : (
                    (loading ?? true) && (
                        <div className={'loading-container'}>
                            <Loading />
                        </div>
                    )
                )}
                <img
                    ref={imgRef}
                    src={dataImageRef.current ?? ''}
                    alt={''}
                    style={{ display: loading ? 'none' : 'unset' }}
                />
            </div>
        </FloatDialog>
    );
}

ImageMiniatureDialog.propTypes = {
    editor: PropTypes.any,
    attachEditorElement: PropTypes.any,
};
