import PropTypes from 'prop-types';
import {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { imageDescription } from '../services/DocumentService';
import {
    extractImgData,
    getBrailleDocument,
    getClosestEditorElement,
    getClosestPage,
} from './editor-mods/modules/core/EditorUtil';
import { getImageUrlDataFromBackgroundImage } from 'plataforma-braille-common';
import '../components/FloatDialog.scss';
import FloatDialog from '../components/FloatDialog';
import { isInsideEditorElementImage } from './editor-mods/modules/core/editor-element/EditorElementImage';

/**
 * @typedef {object} ButtonRetrieveDescriptionAiParams
 * @property {EditorCustom} editor
 * @property {HTMLElement} attachEditorElement
 * @property {function():Promise} uploadImages
 */

/**
 * @typedef {object} ButtonRetrieveDescriptionAiFunctions
 * @property {function} refresh
 */

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<ButtonRetrieveDescriptionAiParams>>}
 */
const FloatButtonEditorImageElement = forwardRef(
    ({ editor, attachEditorElement, uploadImages }, ref) => {
        const [key, setKey] = useState(Math.random());

        /**
         * @type {React.MutableRefObject<FloatDialogFunctions | undefined>}
         */
        const floatDialogRef = useRef();
        const attachEditorElementImageRef = useRef(attachEditorElement);
        const { setLoading, backendConnectionError } =
            useContext(EnvironmentContext);

        useEffect(() => {
            attachEditorElementImageRef.current = attachEditorElement;
        }, [attachEditorElement]);

        const hasImage = !!attachEditorElement?.style?.backgroundImage;
        const hasScore =
            attachEditorElement?.getAttribute('data-has-score') === 'true';
        const score = attachEditorElement?.getAttribute('data-score');
        const scoreRevised =
            attachEditorElement?.getAttribute('data-score-revised') === 'true';
        const hasChanged =
            attachEditorElement?.getAttribute('data-score-has-changed') ===
            'true';
        const infoDescription =
            attachEditorElementImageRef.current?.querySelector(
                '.info-description',
            );
        const hasDescription = infoDescription?.innerText?.trim() !== '';
        const showMarkImageAsRevised =
            hasDescription &&
            hasScore &&
            !scoreRevised &&
            (score === 'good' || hasChanged) &&
            hasImage;

        const dontShowRetrieveDescriptionAi =
            showMarkImageAsRevised || (scoreRevised && hasDescription);

        function markAsRevised() {
            editor.undoManager.transact(() => {
                attachEditorElementImageRef.current.setAttribute(
                    'data-score-revised',
                    'true',
                );
                // I18N
                attachEditorElementImageRef.current.setAttribute(
                    'title',
                    'Descrição aprovada',
                );
            });
            editor.custom?.hasChange();
            editor.custom?.updateDocumentScore();
            refresh();
        }

        function imageChanged(event) {
            if (event.srcElement) {
                const imageElement = getClosestEditorElement(event.srcElement);
                if (imageElement && isInsideEditorElementImage(imageElement)) {
                    imageElement.setAttribute('data-score-has-changed', 'true');
                    refresh();
                }
            }
        }

        useEffect(() => {
            editor?.on('dataChanged', imageChanged);
            return () => {
                editor?.off('dataChanged', imageChanged);
            };
        }, [editor]);

        function refresh() {
            setKey(Math.random());
            floatDialogRef.current?.recalculePosition();
        }

        useImperativeHandle(ref, () => ({
            refresh,
        }));

        /**
         * @returns {Promise<void>}
         */
        async function retrieveImageDescription() {
            if (!attachEditorElementImageRef.current) return;
            const editorImageElement = attachEditorElementImageRef.current;
            const infoDescription =
                editorImageElement.querySelector('.info-description');
            const loadingMsg = 'Gerando descrição da imagem...';
            let imageData = getImageUrlDataFromBackgroundImage(
                editorImageElement.style.backgroundImage,
            );
            if (!imageData) {
                console.warn(
                    'No image data found in image element.',
                    editorImageElement,
                );
                return;
            }
            imageData = await extractImgData(imageData);
            if (imageData.base64) {
                await uploadImages();
                imageData = getImageUrlDataFromBackgroundImage(
                    editorImageElement.style.backgroundImage,
                );
                imageData = await extractImgData(imageData);
                setLoading(true, false, loadingMsg);
            }
            imageData = imageData.url;
            editor.undoManager.transact(async () => {
                setLoading(true, false, loadingMsg);
                try {
                    const brailleDocument = getBrailleDocument(editor);
                    infoDescription.innerText = await imageDescription(
                        imageData,
                        brailleDocument.id,
                    );
                    infoDescription.setAttribute('contentEditable', 'true');
                    editorImageElement.removeAttribute(
                        'data-loading-background',
                    );
                    const attributesToRemove = [
                        'data-loading-background',
                        'data-has-score',
                        'data-score-revised',
                        'data-score-has-changed',
                    ];
                    for (const attr of attributesToRemove) {
                        attachEditorElementImageRef.current.removeAttribute(
                            attr,
                        );
                    }
                    floatDialogRef.current?.recalculePosition();
                    editor.custom.coreModule.updatePage(
                        getClosestPage(editorImageElement),
                    );
                } catch (e) {
                    backendConnectionError(
                        'Fail to retrieve image description.',
                        e,
                    );
                } finally {
                    setLoading(false);
                }
            });
        }

        const showRetrieveDescriptionAi =
            !dontShowRetrieveDescriptionAi &&
            hasImage &&
            attachEditorElementImageRef.current?.getAttribute(
                'data-loading-background',
            ) !== 'true';

        function body() {
            if (showMarkImageAsRevised) {
                return (
                    <a onClick={() => markAsRevised()}>
                        {/*I18N*/}
                        {'Aprovar descrição'}
                    </a>
                );
            } else {
                return (
                    <a onClick={retrieveImageDescription}>
                        {/*I18N*/}
                        {'Usar IA para gerar descrição'}
                    </a>
                );
            }
        }

        return (
            <FloatDialog
                ref={floatDialogRef}
                key={key}
                editor={editor}
                attachEditorElement={
                    showMarkImageAsRevised || showRetrieveDescriptionAi
                        ? attachEditorElement
                        : null
                }
            >
                {body()}
            </FloatDialog>
        );
    },
);

FloatButtonEditorImageElement.displayName = 'FloatButtonEditorImageElement';

FloatButtonEditorImageElement.propTypes = {
    editor: PropTypes.any,
    attachEditorElement: PropTypes.any,
    uploadImages: PropTypes.func.isRequired,
};

export default FloatButtonEditorImageElement;
