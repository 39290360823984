export enum RoleEnum {
    LINEARIZATION = "LINEARIZATION",
    LAYOUT = "LAYOUT",
    EVALUATION = "EVALUATION",
    DESCRIPTION = "DESCRIPTION",
    ADMINISTRATOR = "ADMINISTRATOR",
    PRINT_SHOP = "PRINT_SHOP",
    MODERATION = "MODERATION",
}

export type RoleEnumValue = (typeof RoleEnum)[keyof typeof RoleEnum];

export function RoleEnumToString(key: string): string {
    const role = RoleEnum[key];
    switch (role) {
        case RoleEnum.ADMINISTRATOR:
            // I18N
            return "Administrador";
        case RoleEnum.LINEARIZATION:
            // I18N
            return "Linearização";
        case RoleEnum.LAYOUT:
            // I18N
            return "Diagramação";
        case RoleEnum.DESCRIPTION:
            // I18N
            return "Descrição";
        case RoleEnum.PRINT_SHOP:
            // I18N
            return "Gráfica";
        case RoleEnum.EVALUATION:
            // I18N
            return "Avaliação";
        case RoleEnum.MODERATION:
            // I18N
            return "Moderação";
        default:
            console.error(`Invalid enum value: ${key}`);
            return key;
    }
}
