import './ErrorLoading.scss';
import PropTypes from 'prop-types';

function ErrorLoading({ onTryAgain }) {
    return (
        <div className={'error-loading'}>
            {/*I18N*/}
            <div>{'Erro ao carregar.'}</div>
            {onTryAgain && (
                <div>
                    <button onClick={onTryAgain}>
                        {/*I18N*/}
                        {'Tentar novamente'}
                    </button>
                </div>
            )}
        </div>
    );
}

ErrorLoading.propTypes = { onTryAgain: PropTypes.func };

export default ErrorLoading;
