/**
 * @param enumeration {Object<string | number>}
 * @param descriptionFn {function(string | number): string}
 * @return {*[]}
 */
export function enumToSelectOptions(enumeration, descriptionFn) {
    let options = [];
    for (const measure in enumeration) {
        if (typeof measure !== 'string') continue;
        const unit = enumeration[measure];
        options.push({
            key: unit,
            value: descriptionFn(measure),
        });
    }
    return options;
}
