import PropTypes from 'prop-types';
import './SummaryModalRecord.scss';

/**
 * @typedef {object} SummaryRecord
 * @property {string} breakPageNumber
 * @property {number} nextPage
 */

/**
 * @param record {SummaryRecord}
 * @param onClick {function(SummaryRecord)}
 * @return {JSX.Element}
 * @constructor
 */
export function SummaryModalRecord({ record, onClick }) {
    return (
        <div className={'record'} onClick={() => onClick(record)}>
            <div className={'description'}>
                <div
                    className={'document-break'}
                >{`${record.breakPageNumber}ª quebra de parte`}</div>
            </div>
        </div>
    );
}

SummaryModalRecord.propTypes = {
    record: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};
