import { useContext, useEffect, useRef, useState } from 'react';
import * as DocumentService from '../services/DocumentService';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import CloseModalButton from '../components/modal/CloseModalButton';
import Loading from '../components/Loading';
import { SaveDocumentValidationErrorTxt } from 'plataforma-braille-common';
import FieldInteger from '../components/FieldInteger';
import { unmask } from '../components/MeasureInput';
import FieldCheckbox from '../components/FieldCheckbox';
import { hasValidationErrorHyphenation } from '../dashboard/DocumentModal';
import PropTypes from 'prop-types';

function HyphenationSettingsModal({ show, documentId, onFinished }) {
    /**
     * @typedef {object} FormType
     * @property {boolean | null} hyphenation
     * @property {string | null} hyphenationLettersMin
     * @property {string | null} hyphenationSyllablesMin
     * @property {string | null} hyphenationParagraphMax
     * @property {string | null} hyphenationDistanceBetweenHyphens
     */
    /**
     * @type {FormType}
     */
    const emptyForm = {
        hyphenation: null,
        hyphenationLettersMin: null,
        hyphenationSyllablesMin: null,
        hyphenationParagraphMax: null,
        hyphenationDistanceBetweenHyphens: null,
    };
    const [formData, setFormData] = useState(emptyForm);
    const [formError, setFormError] = useState(emptyForm);
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { setLoading, backendConnectionError } =
        useContext(EnvironmentContext);
    const hyphenationLettersMinErrorRef = useRef();

    const title = 'Hifenização';

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    useEffect(() => {
        if (!show) {
            setTimeout(() => {
                setValidateOnChange(false);
                setFormData(emptyForm);
                setFormError(emptyForm);
            }, 200); // clear the form after css transition (200ms)
        } else {
            setTimeout(() => {
                hyphenationLettersMinErrorRef.current?.inputElement.focus();
            }, 200); // css transition
        }
    }, [show]);

    async function fetchDocument() {
        try {
            setLoadingForm(true);
            const {
                hyphenation,
                hyphenationLettersMin,
                hyphenationSyllablesMin,
                hyphenationParagraphMax,
                hyphenationDistanceBetweenHyphens,
            } = await DocumentService.getDocument(documentId, null, false);
            setFormData({
                hyphenation,
                hyphenationLettersMin,
                hyphenationParagraphMax,
                hyphenationSyllablesMin,
                hyphenationDistanceBetweenHyphens,
            });
        } catch (e) {
            backendConnectionError('Fail to fetch document.', e);
        } finally {
            setLoadingForm(false);
        }
    }

    useEffect(() => {
        if (show) {
            // noinspection JSIgnoredPromiseFromCall
            fetchDocument();
        }
    }, [show]);

    useEffect(() => {
        setLoading(loadingSave || loadingForm, true);
    }, [loadingSave, loadingForm]);

    /**
     * @param obj {FormType | object}
     */
    function updateFormData(obj) {
        setFormData((oldData) => {
            return { ...oldData, ...obj };
        });
    }

    async function saveDocument() {
        if (hasValidationError()) return;

        try {
            setLoadingSave(true);
            const response = await DocumentService.editDocument(
                documentId,
                formData,
            );
            onFinished(response.entity);
        } catch (e) {
            backendConnectionError(
                'Fail to edit document.',
                e,
                null,
                title,
                SaveDocumentValidationErrorTxt,
            );
        } finally {
            setLoadingSave(false);
        }
    }

    function hasValidationError() {
        let hasErrors = false;
        let errors = {};

        const hyphenation = hasValidationErrorHyphenation(formData);
        hasErrors = hasErrors || hyphenation.hasErrors;
        errors = { ...errors, ...hyphenation.errors };

        if (document) setValidateOnChange(true);
        setFormError(errors);
        return hasErrors;
    }

    return (
        <div className={`modal default-modal ${show ? 'show' : ''}`}>
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={() => onFinished(null)} />
                {
                    <div className={'gd-col-4--desktop'}>
                        <div>
                            <div className={'gd-inner'}>
                                <div className={'gd-col gd-col-4--desktop'}>
                                    <h2>
                                        {/*I18N*/}
                                        {title}
                                    </h2>
                                </div>
                            </div>

                            {loadingForm ? (
                                <div className={'gd-inner field-group'}>
                                    <div className={'gd-col gd-col-4--desktop'}>
                                        <div className={'loading-container'}>
                                            <Loading />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <form>
                                    <div className={'gd-inner field-group'}>
                                        <div
                                            className={
                                                'gd-col gd-col-2--desktop'
                                            }
                                        >
                                            <div
                                                className={
                                                    'gd-col gd-col-2--desktop'
                                                }
                                            >
                                                <FieldCheckbox
                                                    fieldGroup={false}
                                                    inputs={(() => [
                                                        {
                                                            label: 'Desativar',
                                                            checked:
                                                                !formData.hyphenation,
                                                            onChange: (e) =>
                                                                updateFormData({
                                                                    hyphenation:
                                                                        !e
                                                                            .target
                                                                            .checked,
                                                                }),
                                                        },
                                                    ])()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'gd-inner field-group'}>
                                        <div
                                            className={
                                                'gd-col gd-col-2--desktop'
                                            }
                                        >
                                            <FieldInteger
                                                ref={
                                                    hyphenationLettersMinErrorRef
                                                }
                                                // I18N
                                                label={
                                                    'Quantidade mínima de letras'
                                                }
                                                disabled={!formData.hyphenation}
                                                placeholder={'0'}
                                                required={true}
                                                validationError={
                                                    formError.hyphenationLettersMin
                                                }
                                                value={
                                                    formData.hyphenationLettersMin
                                                }
                                                onChange={(e) =>
                                                    updateFormData({
                                                        hyphenationLettersMin:
                                                            unmask(
                                                                e.target.value,
                                                            ),
                                                    })
                                                }
                                            />
                                        </div>
                                        <div
                                            className={
                                                'gd-col gd-col-2--desktop'
                                            }
                                        >
                                            <FieldInteger
                                                // I18N
                                                label={
                                                    'Quantidade mínima de sílabas'
                                                }
                                                disabled={!formData.hyphenation}
                                                placeholder={'0'}
                                                required={true}
                                                validationError={
                                                    formError.hyphenationSyllablesMin
                                                }
                                                value={
                                                    formData.hyphenationSyllablesMin
                                                }
                                                onChange={(e) =>
                                                    updateFormData({
                                                        hyphenationSyllablesMin:
                                                            unmask(
                                                                e.target.value,
                                                            ),
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className={'gd-inner'}>
                                        <div
                                            className={
                                                'gd-col gd-col-2--desktop gd-col--valign-bottom'
                                            }
                                        >
                                            <FieldInteger
                                                // I18N
                                                label={
                                                    'Quantidade máxima por parágrafo'
                                                }
                                                disabled={!formData.hyphenation}
                                                placeholder={'0'}
                                                required={true}
                                                validationError={
                                                    formError.hyphenationParagraphMax
                                                }
                                                value={
                                                    formData.hyphenationParagraphMax
                                                }
                                                onChange={(e) =>
                                                    updateFormData({
                                                        hyphenationParagraphMax:
                                                            unmask(
                                                                e.target.value,
                                                            ),
                                                    })
                                                }
                                            />
                                        </div>
                                        <div
                                            className={
                                                'gd-col gd-col-2--desktop gd-col--valign-bottom'
                                            }
                                        >
                                            <FieldInteger
                                                // I18N
                                                label={'Distância entre hífens'}
                                                disabled={!formData.hyphenation}
                                                placeholder={'0'}
                                                required={true}
                                                validationError={
                                                    formError.hyphenationDistanceBetweenHyphens
                                                }
                                                value={
                                                    formData.hyphenationDistanceBetweenHyphens
                                                }
                                                onChange={(e) =>
                                                    updateFormData({
                                                        hyphenationDistanceBetweenHyphens:
                                                            unmask(
                                                                e.target.value,
                                                            ),
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </form>
                            )}

                            <div className={'controls'}>
                                <button
                                    className={'button'}
                                    onClick={() => onFinished(null)}
                                >
                                    {/*I18N*/}
                                    {'Cancelar'}
                                </button>
                                <button
                                    className={`button primary ${loadingSave ? 'loading' : ''}`}
                                    onClick={saveDocument}
                                >
                                    {/*I18N*/}
                                    {'Salvar'}
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

HyphenationSettingsModal.propTypes = {
    show: PropTypes.bool,
    documentId: PropTypes.string,
    onFinished: PropTypes.func,
};

export default HyphenationSettingsModal;
