import { BrailleView, isEditorBrailleView } from './BrailleView';
import {
    fixSpaceChain,
    isShowingNonPrintableChars,
    removeNonPrintableChars,
    showNonPrintableChars,
} from './ShowNonPrintableChars';
import { getCaretPosition, scanCaretPath, setCaretPosition } from './CaretPath';
import {
    getBrailleDocument,
    getClosestEditorElement,
    getSelectedNodes,
    isEditorElement,
    isEditorElementRepresentation,
    isEditorPage,
    isInsideContextCatalog,
    isInsideContextComputerRelated,
    isInsideContextMath,
    isInsideContextSuppression,
    isInsideEmptyFormattingCaretContainer,
    isMultipleSelection,
    standardizeChar,
} from './EditorUtil';
import { RoleEnum } from 'plataforma-braille-common';
import { compareArrays } from '../../../../util/CompareArrays';
import {
    fixPageTermination,
    getBrailleView,
    getCurrentPage,
    getNextPage,
    getPages,
} from './PageManipulation';

import {
    applyContextInSelection,
    removeSpaceFromContextMath,
    sanitizeFormattingElements,
} from './Formatting';
import { cacheContent, setCacheDisabled, uncacheContent } from './Cache';
import { delay } from '../../../../util/Delay';
import {
    EditorElements,
    elementCanBeInsertedAtSelection,
} from './EditorElements';
import { contexts } from '../menu/MenuModule';
import { applyUseAltFont, getUseAltFont } from './UseAltFont';
import { isInsideEditorElementImage } from './editor-element/EditorElementImage';
import { removeParagraphBreaks } from '../../../../conversion/braille/HtmlToBraille';
import { DocumentUpdate } from './document-update/DocumentUpdate';
import { isPageNeedsUpdate } from '../ScrollModule';

/**
 * @typedef  {Object} EditorCustomProperties
 * @property {boolean | undefined} isShowingNonPrintableChars
 * @property {boolean | undefined} cacheDisabled
 * @property {HTMLElement | Node | undefined} lastScrollVisiblePage
 * @property {BrailleDocument | object | undefined} brailleDocument
 * @property {number | null | undefined} zoom
 * @property {Object<string, DocumentFragment> | null | undefined} pageCache
 * @property {boolean | null | undefined} isShowingTinyDialog
 * @property {(function()) | undefined} hasChange
 * @property {HTMLElement | undefined} customStatusBarContainer
 * @property {CoreModule | undefined} coreModule
 * @property {KeyboardModule | undefined} keyboardModule
 * @property {MouseModule | undefined} mouseModule
 * @property {ScrollModule | undefined} scrollModule
 * @property {RevisionModule | undefined} revisionModule
 * @property {SimpleViewModule | undefined} simpleViewModule
 * @property {ExcessLinesControlModule | undefined} excessLinesControlModule
 * @property {ZoomModule | undefined} zoomModule
 * @property {FormatPainterModule | undefined} formatPainterModule
 * @property {SummaryModule | undefined} summaryModule
 * @property {boolean | undefined} destroyed
 * @property {function() | undefined} updateDocumentScore
 */

/**
 * @typedef {Editor} EditorCustom
 * @property {EditorCustomProperties} custom
 */

/**
 * @typedef {DocumentDto} BrailleDocument
 */

/**
 * @typedef {object} PageDataChangedEvent
 * @property {CaretPosition | null} caretPosition
 * @property {InputEvent | null | undefined} inputEvent
 */

export const CORE_LOGGER_ID = '[CoreModule]';

/**
 * @typedef {'suppression' | 'math' | 'computer-related' | 'catalog'} ContextType
 */
export const CONTEXT_TYPES = [
    'suppression',
    'math',
    'computer-related',
    'catalog',
];

/**
 * @returns {boolean}
 */
export function isDebugEnabled() {
    return process.env.REACT_APP_EDITOR_DEBUG_ENABLED === 'true';
}

export class CoreModule {
    /**
     * @type {CaretPosition | null}
     */
    inputCaretPosition = null;

    /**
     * @type {number | null}
     */
    pageDataChangedTimer = null;
    execCmdInsertEditorElement = false;
    /**
     * @type {CaretPosition | null}
     */
    execCmdInsertContentCaretPosition = null;
    /**
     * @type {number | null}
     */
    formatTimer = null;

    /**
     * @param editor {EditorCustom}
     * @param readOnly {boolean}
     * @param userDocumentRoles {RoleEnum[]}
     */
    constructor(editor, readOnly, userDocumentRoles) {
        this.editor = editor;
        this.documentUpdate = new DocumentUpdate(editor);
        this.readOnly = readOnly;
        this.userDocumentRoles = userDocumentRoles;
        this.brailleView = new BrailleView(editor);
        this.editorElements = new EditorElements(editor);
    }

    debug(...data) {
        if (isDebugEnabled()) {
            console.debug(CORE_LOGGER_ID, ...data);
        }
    }

    /**
     * @returns {boolean}
     */
    isInsideImage() {
        const node = this.editor.selection.getNode();
        return isInsideEditorElementImage(node);
    }

    /**
     * @param e {InputEvent}
     */
    hasDataChanged(e) {
        // insertLineBreak is ignored and handled in KeyboardModule
        return [
            'insertText',
            'deleteContentBackward',
            'deleteContentForward',
        ].includes(e.inputType);
    }

    /**
     * @param e {InputEvent}
     */
    beforeInput(e) {
        //TODO: review because this is preventing remove multiple pages
        // const page = getCurrentPage(this.editor);
        // if (!page) {
        //     e.preventDefault();
        //     return;
        // }

        if (!this.hasDataChanged(e)) return;
        if (
            isInsideEmptyFormattingCaretContainer(
                this.editor.selection.getRng().endContainer,
            )
        )
            return;

        if (
            e.inputType.startsWith('deleteContent') &&
            isEditorBrailleView(this.editor.selection.getNode())
        ) {
            this.debug('Prevented user to delete braille view');
            e.preventDefault();
            return;
        }

        if (
            e.data === ' ' &&
            isInsideContextMath(this.editor.selection.getNode())
        ) {
            this.debug('Space cannot be added in math context');
            e.preventDefault();
            return;
        }

        if (isMultipleSelection(this.editor)) return;

        this.inputCaretPosition = getCaretPosition(this.editor);
        if (this.inputCaretPosition?.page) {
            if (this.editor.custom.isShowingNonPrintableChars) {
                removeNonPrintableChars(this.inputCaretPosition.contextElement);
            }
            fixPageTermination(this.editor, this.inputCaretPosition.page);
            setCaretPosition(
                this.editor,
                this.inputCaretPosition.contextElement,
                this.inputCaretPosition.path,
            );
        }
    }

    /**
     * @param e {InputEvent}
     */
    input(e) {
        if (!this.hasDataChanged(e) || !this.inputCaretPosition) return;

        if (e.data) {
            if (
                e.data !== ' ' ||
                !isInsideContextMath(this.editor.selection.getNode())
            ) {
                this.inputCaretPosition.path.push(e.data);
            }
        } else {
            switch (e.inputType) {
                case 'deleteContentBackward':
                    this.inputCaretPosition.path.pop();
                    break;
            }
        }

        if (!this.editor.custom.isShowingNonPrintableChars) {
            removeNonPrintableChars(this.inputCaretPosition.contextElement);
        } else {
            showNonPrintableChars(
                this.editor,
                this.inputCaretPosition.contextElement,
            );
        }

        setCaretPosition(
            this.editor,
            this.inputCaretPosition.contextElement,
            this.inputCaretPosition.path,
        );

        this.inputCaretPosition = null;
    }

    /**
     * @param currentPage {HTMLElement | null}
     */
    removeStackedPages(currentPage) {
        if (!currentPage) return;
        const stackedPages = currentPage.querySelectorAll('editor-page');
        for (const stackedPage of stackedPages) {
            stackedPage.remove();
        }
    }

    /**
     * @param page {HTMLElement}
     */
    updateNormalPage(page) {
        getBrailleView(page)?.remove();

        page.style.padding = '10mm';
        page.style.fontSize = '14px';
        page.style.fontWeight = 'normal';
        page.style.removeProperty('fontFamily');
        page.style.width = '210mm';
        page.style.removeProperty('height');
        page.style.removeProperty('margin-right');
        page.style.removeProperty('line-height');

        if (this.editor.custom.isShowingNonPrintableChars) {
            const caretPosition = getCaretPosition(this.editor);
            removeNonPrintableChars(page);
            showNonPrintableChars(this.editor, page);
            if (caretPosition)
                setCaretPosition(
                    this.editor,
                    caretPosition.contextElement,
                    caretPosition.path,
                );
        }
    }

    /**
     * @param page {HTMLElement | Node}
     */
    updatePage(page) {
        if (!page) return;
        const start = new Date().getTime();
        try {
            this.prepareEditorElements(page);
            const brailleDocument = getBrailleDocument(this.editor);
            if (brailleDocument) {
                removeSpaceFromContextMath(page);
                if (brailleDocument?.convertedToBraille) {
                    if (this.editor.custom.isShowingNonPrintableChars) {
                        removeNonPrintableChars(page);
                    }
                    this.prepareEditorElements(page); // the first call may convert or fix elements
                    this.brailleView.updatePage(page);
                    this.prepareEditorElements(page); // the second call will regenerate some caret control chars removed by `updateBrailleViewPage` function
                    if (this.editor.custom.isShowingNonPrintableChars) {
                        showNonPrintableChars(this.editor, page);
                    }
                    page.setAttribute('data-converted-to-braille', 'true');
                } else {
                    this.updateNormalPage(page);
                    page.setAttribute('data-converted-to-braille', 'false');
                    this.prepareEditorElements(page);
                }
            } else {
                this.prepareEditorElements(page);
            }
            this.documentUpdate.updatePage(page).then();
        } finally {
            page.removeAttribute('data-needs-update');
            this.debug(
                `Time to update page ${new Date().getTime() - start} ms`,
            );
        }
    }

    /**
     * @param page {HTMLElement}
     */
    removeGarbageAttributes(page) {
        /**
         * @type {HTMLElement[]}
         */
        const elements = [...page.querySelectorAll('[data-mce-style]')];
        for (const element of elements) {
            element.removeAttribute('data-mce-style');
        }
    }

    /**
     * @param page {HTMLElement | Node}
     */
    prepareEditorElements(page) {
        if (!page) return;
        this.removeGarbageAttributes(page);
        const brailleDocument = getBrailleDocument(this.editor);
        if (!brailleDocument.convertedToBraille) {
            removeParagraphBreaks(page);
        }
        this.editorElements.removeLostParagraphBreak(page);
        this.editorElements.checkAndRepairElements(page);
    }

    /**
     * @param e {PageDataChangedEvent}
     */
    pageDataChanged(e) {
        clearTimeout(this.pageDataChangedTimer);
        this.pageDataChangedTimer = setTimeout(() => {
            this.pageDataChangedTimer = null;
            const caretPosition = getCaretPosition(this.editor);
            const currentPage = caretPosition?.page;
            if (!currentPage) return;
            this.updatePage(currentPage);
            this.removeStackedPages(currentPage);
            if (this.editor.custom.isShowingNonPrintableChars) {
                fixSpaceChain(currentPage);
            }

            let pagePath = scanCaretPath(caretPosition.contextElement);
            if (
                pagePath.length < caretPosition.path.length &&
                isEditorPage(caretPosition.contextElement)
            ) {
                if (
                    compareArrays(pagePath, caretPosition.path, pagePath.length)
                ) {
                    caretPosition.path.splice(0, pagePath.length);
                    caretPosition.page = getNextPage(caretPosition.page);
                    if (
                        !caretPosition.page ||
                        !compareArrays(
                            caretPosition.path,
                            scanCaretPath(caretPosition.page),
                            caretPosition.path.length,
                        )
                    ) {
                        console.error('Cannot determine cursor position');
                    } else {
                        this.debug('Caret moved to another page');
                        setCaretPosition(
                            this.editor,
                            caretPosition.page,
                            caretPosition.path,
                        );
                    }
                }
            } else {
                const brailleDocument = getBrailleDocument(this.editor);
                if (brailleDocument.convertedToBraille) {
                    setCaretPosition(
                        this.editor,
                        caretPosition.contextElement,
                        caretPosition.path,
                    );
                }
            }
            this.editor.fire('pageUpdated', e);
        }, 150);
    }

    /**
     * @param page {HTMLElement | Node}
     * @return {boolean}
     */
    isPageOutdated(page) {
        if (isPageNeedsUpdate(page)) {
            return true;
        }
        const brailleDocument = getBrailleDocument(this.editor);
        const compStr = brailleDocument.convertedToBraille ? 'true' : 'false';
        const documentVersion = parseInt(
            page.getAttribute('data-document-version') ?? '0',
        );

        const needsConversionToBraille =
            page.getAttribute('data-converted-to-braille') !== compStr;
        const documentVersionOutdated =
            isNaN(documentVersion) ||
            documentVersion < this.documentUpdate.getDocumentVersion();

        // if (needsConversionToBraille) {
        //     this.debug('Page needs conversion to braille.', page);
        // }
        // if (documentVersionOutdated) {
        //     this.debug(
        //         `Document version outdated: ${documentVersion} of ${this.documentUpdate.getDocumentVersion()}.`,
        //     );
        // }

        return needsConversionToBraille || documentVersionOutdated;
    }

    /**
     * @param forceUpdate {boolean | null}
     * @param callbackProgress {(currentPage: number, pageCount: number, pageUpdated: boolean) => void|undefined}
     * @param startAtIndex {number | undefined}
     */
    async updatePages(forceUpdate, callbackProgress = null, startAtIndex = 0) {
        setCacheDisabled(this.editor, true);
        uncacheContent(this.editor);
        let release = true;
        try {
            let pages = [...getPages(this.editor)];
            if (callbackProgress) callbackProgress(0, pages.length, false);
            await delay(0); // give a breath to update progress in screen
            for (let idx = startAtIndex; idx < pages.length; idx++) {
                const page = pages[idx];
                // abort if editor is destroyed
                if (this.editor.custom.destroyed) {
                    if (callbackProgress)
                        callbackProgress(pages.length, pages.length, false);
                    break;
                }

                let pageUpdated = false;
                if (this.isPageOutdated(page) || forceUpdate) {
                    // this.debug(`Page ${idx + 1} needs update.`);
                    this.editor.fire('pageOutdated', { page });
                    this.updatePage(page);
                    let hasExcessLines =
                        this.editor.custom?.excessLinesControlModule?.pushExcessLinesToNextPage(
                            page,
                            true,
                        );
                    pageUpdated = true;
                    if (hasExcessLines) {
                        release = false;
                        return this.updatePages(
                            forceUpdate,
                            callbackProgress,
                            idx + 1,
                        );
                    }
                }
                if (callbackProgress) {
                    callbackProgress(idx + 1, pages.length, pageUpdated);
                }
                await delay(0); // give a breath to update progress in screen
            }
        } finally {
            if (release) {
                setCacheDisabled(this.editor, false);
                cacheContent(this.editor);
            }
        }
    }

    /**
     * @param event {Event | any}
     */
    beforeExecCommand(event) {
        let execCmdApplyContextInSelection = false;
        let { endContainer, endOffset } = this.editor.selection.getRng();
        if (endContainer.nodeType !== Node.TEXT_NODE) {
            endContainer = endContainer.childNodes[endOffset];
        }
        if (!endContainer) {
            event.preventDefault();
            return;
        }

        if (event?.command === 'mceToggleFormat') {
            if (['subscript', 'superscript'].includes(event.value)) {
                const nodes = getSelectedNodes(this.editor);
                if (!nodes.length) nodes.push(this.editor.selection.getNode());
                sanitizeFormattingElements(nodes);
            } else if (CONTEXT_TYPES.includes(event.value)) {
                const editorElement = this.editor.selection.getNode();
                if (
                    !editorElement ||
                    !CONTEXT_TYPES.includes(editorElement.getAttribute('type'))
                ) {
                    if (this.editor.selection.getContent().trim()) {
                        execCmdApplyContextInSelection = true;
                    }
                }
            }
        }

        let editorElement = getClosestEditorElement(endContainer);
        let type = editorElement?.getAttribute('type');

        if (isEditorElementRepresentation(editorElement)) {
            editorElement = null;
            type = null;
        }

        const formatApplied =
            event?.command === 'mceToggleFormat' ||
            event?.command === 'Bold' ||
            event?.command === 'Italic' ||
            event?.command === 'Underline' ||
            event?.command === 'Superscript' ||
            event?.command === 'Subscript';

        function htmlToElement(html) {
            const element = document.createElement('div');
            element.innerHTML = html;
            return element.firstChild;
        }

        if (event?.command === 'mceInsertContent') {
            if (event?.value?.content) {
                event.value.content = standardizeChar(event.value.content);
            }
            const element = htmlToElement(event?.value);
            if (!elementCanBeInsertedAtSelection(this.editor, element)) {
                event.preventDefault();
                return;
            }
            if (!isMultipleSelection(this.editor)) {
                this.execCmdInsertContentCaretPosition = getCaretPosition(
                    this.editor,
                );
            }
        }

        if (formatApplied || type) {
            if (isEditorPage(endContainer)) {
                this.editor.notificationManager.open({
                    // I18N
                    text: 'Posicione o cursor na página antes de aplicar contexto ou formatação',
                    type: 'warning',
                    timeout: 5000,
                });
                event.preventDefault();
                return;
            }
        }

        if (!contexts.includes(type)) {
            const selNode = this.editor.selection.getNode();
            if (isInsideContextSuppression(selNode)) {
                type = 'suppression';
            } else if (isInsideContextMath(selNode)) {
                type = 'math';
            } else if (isInsideContextComputerRelated(selNode)) {
                type = 'computer-related';
            } else if (isInsideContextCatalog(selNode)) {
                type = 'catalog';
            }
        }

        // catalog context allows stacks other contexts
        if (
            (type &&
                contexts.includes(event?.value) &&
                ![
                    'image',
                    'summary',
                    'nth-root',
                    'line-segment',
                    'angle',
                    'recoil',
                ].includes(type) &&
                type !== event?.value &&
                type !== 'catalog') ||
            (type === 'catalog' && event?.value === 'suppression')
        ) {
            this.editor.notificationManager.open({
                // I18N
                text: 'Não é possível aplicar contexto na seleção atual',
                type: 'warning',
                timeout: 5000,
            });
            event.preventDefault();
            return;
        }

        if (
            formatApplied &&
            type === 'suppression' &&
            event?.value !== 'suppression'
        ) {
            this.editor.notificationManager.open({
                // I18N
                text: 'Não é possível aplicar formatação dentro do contexto de supressão',
                type: 'warning',
                timeout: 5000,
            });
            event.preventDefault();
            return;
        }

        if (
            event?.command === 'mceToggleFormat' &&
            contexts.includes(event?.value)
        ) {
            const selRng = this.editor.selection.getRng();
            const context = event?.value;
            this.execCmdInsertEditorElement =
                !this.editor.formatter.match(context) &&
                selRng.startContainer === selRng.endContainer &&
                selRng.startOffset === selRng.endOffset;
        }

        if (execCmdApplyContextInSelection) {
            applyContextInSelection(this.editor, event.value);
            return false;
        }

        return true;
    }

    install() {
        const userDescriptor =
            this.userDocumentRoles.includes(RoleEnum.DESCRIPTION) &&
            // users may have another roles in same document
            this.userDocumentRoles.length === 1;

        if (this.readOnly) {
            this.editor.on('beforeInput', (e) => {
                e.preventDefault();
            });
            this.editor.on('beforeExecCommand', (e) => {
                e.preventDefault();
            });
        } else if (userDescriptor) {
            this.editor.on('beforeInput', (e) => {
                if (!this.isInsideImage()) {
                    e.preventDefault();
                    return;
                }
                this.beforeInput(e);
            });
            this.editor.on('beforeExecCommand', (e) => {
                if (!this.isInsideImage()) {
                    e.preventDefault();
                    return;
                }
                if (!this.beforeExecCommand(e)) {
                    e.preventDefault();
                }
            });
        } else {
            this.editor.on('beforeInput', (e) => {
                this.beforeInput(e);
            });
            this.editor.on('beforeExecCommand', (e) => {
                if (!this.beforeExecCommand(e)) {
                    e.preventDefault();
                }
            });
        }
        this.editor.on('input', (e) => this.input(e));
        this.editor.on('pageDataChanged', (e) => this.pageDataChanged(e));
        this.editor.on('ShowCaret', (e) => e.preventDefault());

        const self = this;
        this.editor.on('ExecCommand', (event) => {
            if (self.execCmdInsertEditorElement) {
                self.execCmdInsertEditorElement = false;
                // noinspection JSUnresolvedReference
                const context = event.value;
                const selectedNode = self.editor.selection.getNode();
                // check if is already inside a same editor element (disallow stacking)
                if (
                    !isEditorElement(selectedNode) &&
                    selectedNode.getAttribute('type') !== context
                ) {
                    const editorElement =
                        document.createElement('editor-element');
                    editorElement.setAttribute('type', context);
                    editorElement.innerHTML = selectedNode.innerHTML;
                    selectedNode.innerHTML = '';
                    selectedNode.appendChild(editorElement);
                    self.editor.selection.setCursorLocation(editorElement, 1);
                    return;
                }
            }

            // noinspection JSUnresolvedReference
            if (event.command === 'mceInsertContent') {
                if (
                    self.execCmdInsertContentCaretPosition &&
                    !isMultipleSelection(self.editor)
                ) {
                    // noinspection JSUnresolvedReference
                    const contentInserted = event.value?.content;
                    if (contentInserted) {
                        const tmpContainer = document.createElement('div');
                        tmpContainer.innerHTML = contentInserted;
                        const insertedPath = scanCaretPath(tmpContainer);
                        self.execCmdInsertContentCaretPosition.path = [
                            ...self.execCmdInsertContentCaretPosition.path,
                            ...insertedPath,
                        ];
                    }

                    setTimeout(() => {
                        if (self.execCmdInsertContentCaretPosition) {
                            /**
                             * @type {PageDataChangedEvent}
                             */
                            const pageDataChangedEvent = {
                                caretPosition:
                                    self.execCmdInsertContentCaretPosition,
                            };
                            self.editor.fire(
                                'pageDataChanged',
                                pageDataChangedEvent,
                            );
                        }
                    }, 0);
                }
            }
        });

        this.editor.on('FormatApply FormatRemove', () => {
            const currentPage = getCurrentPage(self.editor);
            if (currentPage) {
                if (self.formatTimer) clearTimeout(self.formatTimer);
                self.formatTimer = setTimeout(() => {
                    // this should be async to not affect formatting in blank lines (braille view)
                    self.editor.undoManager.transact(() => {
                        self.formatTimer = null;
                        const rng = self.editor.selection.getRng();
                        let node = rng.endContainer;
                        if (
                            node.nodeType !== Node.TEXT_NODE &&
                            rng.endOffset < node.childNodes.length
                        ) {
                            node = node.childNodes[rng.endOffset];
                        }
                        if (
                            !isMultipleSelection(self.editor) &&
                            isInsideEmptyFormattingCaretContainer(node)
                        ) {
                            return;
                        }
                        const caretPosition = getCaretPosition(self.editor);
                        /**
                         * @type {PageDataChangedEvent}
                         */
                        const pageDataChangedEvent = { caretPosition };
                        self.editor.fire(
                            'pageDataChanged',
                            pageDataChangedEvent,
                        );
                    });
                }, 50);
            }
        });

        this.editor.on('SetContent', () => {
            for (const page of getPages(self.editor)) {
                fixPageTermination(this.editor, page);
            }
            if (!self.editor.custom.isShowingNonPrintableChars) {
                self.editor.custom.isShowingNonPrintableChars =
                    isShowingNonPrintableChars(
                        self.editor.getDoc().firstElementChild,
                    );
            }
        });

        this.editor.on('pageUncached', (event) => {
            const page = event['page'];
            if (this.isPageOutdated(page)) {
                this.updatePage(page);
            }
        });

        applyUseAltFont(this.editor, getUseAltFont());
    }
}
