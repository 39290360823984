/**
 * @param base64 {string}
 * @return {Promise<{width: number, height: number}>}
 */
export function getImageDimensions(base64) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve({ width: img.width, height: img.height });
        };
        img.onerror = reject;
        img.src = 'data:image;base64,' + base64;
    });
}
