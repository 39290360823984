import { isDebugEnabled } from './core/CoreModule';
import { EditorFormatPainter } from '../../../components/images';
import { getCaretPosition } from './core/CaretPath';
import {
    getClosestEditorElement,
    getClosestPage,
    isEditorPage,
} from './core/EditorUtil';

export class FormatPainterModule {
    /**
     * @type {HTMLElement | null}
     */
    formatPainterElement = null;
    preventUsageInsideEditorElementAlertLastCall = null;
    preventUsageInsideEditorElementAlertLastCallDelay = 500;

    /**
     * @param editor {EditorCustom}
     */
    constructor(editor) {
        this.editor = editor;
    }

    debug(...data) {
        if (isDebugEnabled()) {
            console.debug('[FormatPainterModule]', ...data);
        }
    }

    preventUsageInsideEditorElement() {
        const selectedNode = this.editor.selection.getNode();
        if (
            getClosestEditorElement(selectedNode) ||
            !getClosestPage(selectedNode)
        ) {
            /**
             * This code prevents the alert from being triggered when the
             * "click" and "mouseup" events are fired at same time
             */
            if (
                this.preventUsageInsideEditorElementAlertLastCall &&
                Date.now() - this.preventUsageInsideEditorElementAlertLastCall <
                    this.preventUsageInsideEditorElementAlertLastCallDelay
            ) {
                return true;
            }

            this.preventUsageInsideEditorElementAlertLastCall = Date.now();
            this.editor.notificationManager.open({
                // I18N
                text: 'Não é possível usar esse recurso na seleção atual',
                type: 'warning',
                timeout: 5000,
            });

            return true;
        } else {
            return false;
        }
    }

    /**
     * @param dataChanged {boolean | undefined}
     */
    reset(dataChanged = false) {
        this.formatPainterElement = null;
        this.editor.getBody().style.cursor = 'auto';

        if (dataChanged) {
            /**
             * @type {PageDataChangedEvent}
             */
            const pageDataChangedEvent = {
                caretPosition: getCaretPosition(this.editor),
            };
            this.editor.fire('pageDataChanged', pageDataChangedEvent);
        }
    }

    formatPainterMenuButtonAction() {
        if (this.preventUsageInsideEditorElement()) {
            return;
        }

        if (this.formatPainterElement) {
            this.reset();
        } else {
            let newElement = this.editor.selection?.getNode();
            if (newElement) {
                while (newElement) {
                    if (
                        isEditorPage(newElement) ||
                        isEditorPage(newElement.parentElement)
                    ) {
                        break;
                    } else {
                        newElement = newElement.parentElement;
                    }
                }
                this.formatPainterElement = newElement.cloneNode(true);
                this.clearAllTextsFromElement(this.formatPainterElement);
                this.editor.getBody().style.cursor = `url(${EditorFormatPainter}) 14 8, text`;
            }
        }
    }

    removeTextFormatMenuButtonAction() {
        if (this.preventUsageInsideEditorElement()) {
            return;
        }

        this.editor.execCommand('RemoveFormat');
    }

    /**
     * @param element {HTMLElement}
     */
    clearAllTextsFromElement(element) {
        if (!element) return;
        const nodesToRemove = [];
        function traverseNodes(node) {
            node.childNodes.forEach((child) => {
                if (child.nodeType === Node.TEXT_NODE) {
                    nodesToRemove.push(child);
                } else if (child.nodeType === Node.ELEMENT_NODE) {
                    traverseNodes(child);
                }
            });
        }
        traverseNodes(element);
        nodesToRemove.forEach((node) => node.remove());
    }

    /**
     * @param element {HTMLElement}
     */
    getLastNestedElement(element) {
        if (!element) return null;
        let current = element;
        while (current.children.length > 0) {
            current = current.children[current.children.length - 1];
        }
        return current;
    }

    click() {
        if (this.formatPainterElement) {
            if (this.preventUsageInsideEditorElement()) {
                return;
            }

            this.editor.undoManager.transact(() => {
                if (isEditorPage(this.formatPainterElement)) {
                    this.editor.execCommand('RemoveFormat');
                    this.reset(true);
                } else {
                    let selection = this.editor.selection.getSel();
                    let range = selection.getRangeAt(0);

                    if (range.collapsed) {
                        let offset = range.startOffset;
                        let textNode = range.startContainer;

                        if (textNode.nodeType === Node.TEXT_NODE) {
                            let text = textNode.nodeValue;
                            let start = offset;
                            let end = offset;

                            while (start > 0 && !/\s/.test(text[start - 1])) {
                                start--;
                            }

                            while (end < text.length && !/\s/.test(text[end])) {
                                end++;
                            }

                            if (start !== end) {
                                range.setStart(textNode, start);
                                range.setEnd(textNode, end);

                                const lastElement = this.getLastNestedElement(
                                    this.formatPainterElement,
                                );
                                lastElement.innerText = text.substring(
                                    start,
                                    end,
                                );

                                range.deleteContents();
                                range.insertNode(this.formatPainterElement);
                                range.collapse(false);
                                this.reset(true);
                                selection.removeAllRanges();
                            }
                        }
                    }
                }
            });
        }
    }

    mouseup() {
        const selectedText = this.editor.selection.getContent({
            format: 'html',
        });
        if (selectedText && this.formatPainterElement) {
            if (this.preventUsageInsideEditorElement()) {
                return;
            }

            this.editor.undoManager.transact(() => {
                if (isEditorPage(this.formatPainterElement)) {
                    this.editor.execCommand('RemoveFormat');
                } else {
                    const lastElement = this.getLastNestedElement(
                        this.formatPainterElement,
                    );
                    lastElement.innerText = selectedText;
                    this.editor.selection.setContent(
                        this.formatPainterElement.outerHTML,
                    );
                }
            });
            this.reset(true);
        }
    }

    install() {
        this.editor.on('click', () => this.click());
        this.editor.on('mouseup', () => this.mouseup());
    }
}
