import './PopupMenu.scss';
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

/**
 * @typedef {object} PopupMenuItem
 * @property {string} description
 * @property {string[] | object[] | null | undefined} icon
 * @property {function()} action
 */

/**
 * @typedef {object} PopupMenuFunctions
 * @property {function(HTMLElement, PopupMenuItem[])} showPopup
 * @property {function()} hidePopup
 */

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<object>>}
 */
const PopupMenu = forwardRef(({}, ref) => {
    /**
     * @type {PopupMenuItem[]}
     */
    const initialMenuItems = [];
    const [menuItems, setMenuItems] = useState(initialMenuItems);
    const [show, setShow] = useState(false);
    /**
     * @type {HTMLElement | null}
     */
    const initialElementToAttach = null;
    const elementToAttachRef = useRef(initialElementToAttach);

    /**
     * @type {React.MutableRefObject<HTMLElement | null>}
     */
    const popupMenuRef = useRef();

    useImperativeHandle(ref, () => ({
        showPopup,
        hidePopup,
    }));

    useEffect(() => {
        window.addEventListener('resize', hidePopup);
        return () => {
            window.removeEventListener('resize', hidePopup);
        };
    }, []);

    /**
     * @param elementToAttach {HTMLElement}
     * @param menuItems {PopupMenuItem[]}
     */
    function showPopup(elementToAttach, menuItems) {
        setMenuItems(menuItems);
        elementToAttachRef.current = elementToAttach;
        setShow(true);
    }

    function hidePopup() {
        elementToAttachRef.current = null;
        setShow(false);
    }

    useEffect(() => {
        setTimeout(() => {
            if (!elementToAttachRef.current) return;
            const rect = elementToAttachRef.current.getBoundingClientRect();
            popupMenuRef.current.style.left = `${rect.left}px`;
            let top = rect.top + rect.height;
            let left = rect.left;
            if (top + popupMenuRef.current.clientHeight > window.innerHeight) {
                top = window.innerHeight - popupMenuRef.current.clientHeight;
            }
            if (left + popupMenuRef.current.clientWidth > window.innerWidth) {
                left = left - popupMenuRef.current.clientWidth;
            }
            popupMenuRef.current.style.top = `${top}px`;
            popupMenuRef.current.style.left = `${left}px`;
        }, 0);
    }, [show]);

    const backdropClassName = ['popup-menu-backdrop'];
    const menuClassName = ['popup-menu'];
    if (show) {
        backdropClassName.push('show');
        menuClassName.push('show');
    }
    let hasIcons = menuItems.findIndex((menuItem) => !!menuItem.icon) !== -1;

    return (
        <>
            <div
                className={backdropClassName.join(' ')}
                onClick={() => hidePopup()}
                onContextMenu={(e) => {
                    e.preventDefault();
                    hidePopup();
                }}
            />
            <div ref={popupMenuRef} className={menuClassName.join(' ')}>
                {menuItems.map((menuItem, idx) => (
                    <div
                        key={idx}
                        className={'item'}
                        onClick={() => {
                            hidePopup();
                            menuItem.action();
                        }}
                        title={menuItem.description}
                    >
                        {hasIcons && (
                            <div className={'icon'}>
                                {menuItem.icon && (
                                    <img
                                        src={`${menuItem.icon[0]}`}
                                        srcSet={`${menuItem.icon[0]} 1x, ${menuItem.icon[1]} 2x`}
                                        alt={''}
                                    />
                                )}
                            </div>
                        )}
                        {menuItem.description}
                    </div>
                ))}
            </div>
        </>
    );
});

PopupMenu.displayName = 'PopupMenu';

PopupMenu.propTypes = {};

export default PopupMenu;
