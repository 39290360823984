import {
    MenuHome,
    MenuHome2x,
    MenuFavorite,
    MenuFavorite2x,
    MenuLogoff,
    MenuLogoff2x,
} from '../images';
import { EnvironmentContext } from '../../contexts/EnviromentContext';
import { useContext } from 'react';
import './MainMenu.scss';
import * as LoginService from '../../services/LoginService';
import { useNavigate } from 'react-router-dom';

function MainMenu() {
    const { setLoading, setIsLogged, setUser, backendConnectionError } =
        useContext(EnvironmentContext);

    const navigate = useNavigate();

    async function logoff() {
        setLoading(true);
        try {
            await LoginService.logoff();
            setIsLogged(false);
            setUser(null);
            console.debug('Logoff successfully.');
        } catch (e) {
            backendConnectionError('Fail to logoff.', e);
        }
        setLoading(false);
    }

    return (
        <div className={'main-menu'}>
            <div className={'controls'}>
                <button onClick={() => navigate('/')}>
                    <img
                        src={`${MenuHome}`}
                        srcSet={`${MenuHome} 1x, ${MenuHome2x} 2x`}
                        alt={'Página principal'}
                        width={30}
                        height={24}
                        onDragStart={(e) => e.preventDefault()}
                    />
                </button>
                {/*<a>*/}
                {/*    <img*/}
                {/*        src={MenuFavorite}*/}
                {/*        srcSet={`${MenuFavorite} 1x, ${MenuFavorite2x} 2x`}*/}
                {/*        alt={'Favoritos'}*/}
                {/*        width={25}*/}
                {/*        height={24}*/}
                {/*        onDragStart={(e) => e.preventDefault()}*/}
                {/*    />*/}
                {/*</a>*/}
            </div>
            <div className={'controls'}>
                <button onClick={() => logoff()}>
                    <img
                        src={`${MenuLogoff}`}
                        srcSet={`${MenuLogoff} 1x, ${MenuLogoff2x} 2x`}
                        alt={'Sair'}
                        width={28}
                        height={28}
                        onDragStart={(e) => e.preventDefault()}
                    />
                </button>
            </div>
        </div>
    );
}

export default MainMenu;
