import IntegerInput from './IntegerInput';
import nextId from 'react-id-generator';
import Field from './Field';
import { FieldTextLabelStyle } from './FieldText';
import { forwardRef, useRef } from 'react';

const FieldInteger = forwardRef(
    (
        {
            children,
            required,
            icon,
            icon2x,
            className,
            type,
            value,
            onChange,
            onKeyUp,
            onKeyDown,
            placeholder,
            autoComplete,
            disabled,
            ...props
        },
        ref,
    ) => {
        const { labelText, labelStyle, validationError } = props;

        const id = props.id ?? nextId('field-integer');
        const idError = `${id}-error`;
        const fieldRef = useRef();
        let inputStyle = {};
        if (icon) {
            if (icon2x) {
                inputStyle.backgroundImage = `image-set(url(${icon}) 1x, url(${icon2x}) 2x) `;
            } else {
                inputStyle.backgroundImage = `url(${icon})`;
            }
        }

        return (
            <Field
                ref={fieldRef}
                {...props}
                idInput={id}
                idError={idError}
                bottomChildren={children}
                hasIcon={!!icon}
            >
                <IntegerInput
                    ref={ref}
                    id={id}
                    type={type ?? 'text'}
                    required={!!required}
                    aria-required={!!required}
                    aria-invalid={!!validationError}
                    aria-errormessage={idError}
                    style={inputStyle}
                    title={labelText}
                    value={value}
                    disabled={disabled ?? false}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    onChange={(e) => {
                        if (
                            labelStyle === FieldTextLabelStyle.LABEL_INLINE &&
                            fieldRef.current?.label &&
                            e.target.value !== ''
                        ) {
                            fieldRef.current.label.style.visibility = 'hidden';
                        } else {
                            fieldRef.current?.label?.style.removeProperty(
                                'visibility',
                            );
                        }
                        if (onChange) onChange(e);
                    }}
                    onKeyUp={onKeyUp}
                    onKeyDown={onKeyDown}
                    className={className}
                />
            </Field>
        );
    },
);

FieldInteger.displayName = 'FieldInteger';

export default FieldInteger;
