import './SimpleViewModule.scss';
import { DocumentStatusEnum } from 'plataforma-braille-common';
import { getBrailleDocument } from '../core/EditorUtil';
import { isDebugEnabled } from '../core/CoreModule';
import {
    EditorSimpleView,
    EditorSimpleView2x,
    EditorSimpleViewAlt,
    EditorSimpleViewAlt2x,
} from '../../../../components/images';

export class SimpleViewModule {
    /**
     * @type {EditorCustom}
     */
    editor;

    /**
     * @type {boolean}
     */
    active = false;

    debug(...data) {
        if (isDebugEnabled()) {
            console.debug('[SimpleViewModule]', ...data);
        }
    }

    /**
     * @param editor {EditorCustom}
     */
    constructor(editor) {
        this.editor = editor;
    }

    install() {
        this.createSimpleView();
        this.restoreSimpleView();
        const self = this;

        let currentActive = false;
        this.editor.on('beforeExportPdf', () => {
            if (!self.active) return;
            currentActive = true;
            this.setSimpleView(null, false);
        });
        this.editor.on('afterExportPdf', () => {
            if (!currentActive) return;
            currentActive = false;
            this.setSimpleView(null, true);
        });
    }

    createSimpleView() {
        const simpleViewButtonToggle = document.createElement('button');
        simpleViewButtonToggle.className = 'simple-view-button';
        simpleViewButtonToggle.title = 'Visualização simples';
        simpleViewButtonToggle.addEventListener('click', () => {
            this.setSimpleView(false);
        });

        const simpleViewButtonImage = document.createElement('img');

        let icon1x, icon2x;

        if (this.editor.custom?.brailleDocument?.convertedToBraille) {
            icon1x = EditorSimpleView;
            icon2x = EditorSimpleView2x;
        } else {
            icon1x = EditorSimpleViewAlt;
            icon2x = EditorSimpleViewAlt2x;
        }

        simpleViewButtonImage.src = `${icon1x}`;
        simpleViewButtonImage.srcset = `${icon1x} 1x, ${icon2x} 2x`;
        simpleViewButtonToggle.appendChild(simpleViewButtonImage);

        const simpleView = document.createElement('div');
        simpleView.className = 'simple-view';
        simpleView.appendChild(simpleViewButtonToggle);

        const container =
            this.editor.custom.customStatusBarContainer.parentElement;
        container.appendChild(simpleView);
    }

    /**
     * @return {string}
     */
    getSimpleViewStorageKey() {
        return `simpleView:${window.location.pathname}`;
    }

    /**
     * @param onInit {boolean | null}
     * @param forcedValue {boolean | null}
     */
    setSimpleView(onInit = false, forcedValue = null) {
        const htmlClassName = 'simple-view';
        const withoutBrailleViewClassName = 'without-braille-view';
        const htmlElement = this.editor.getBody().parentElement;
        const simpleViewButton = document.querySelector('.simple-view-button');
        const currentValue =
            localStorage?.getItem(this.getSimpleViewStorageKey()) === 'true';
        const newValue =
            forcedValue !== null
                ? forcedValue
                : onInit
                  ? currentValue
                  : !currentValue;

        const doc = getBrailleDocument(this.editor);

        if (newValue) {
            htmlElement.scrollLeft = 0;
            htmlElement.classList.add(htmlClassName);
            if (
                [
                    DocumentStatusEnum.LINEARIZATION.toString(),
                    DocumentStatusEnum.DESCRIPTION.toString(),
                ].includes(doc.status)
            ) {
                htmlElement.classList.add(withoutBrailleViewClassName);
            }
        } else {
            htmlElement.classList.remove(htmlClassName);
            htmlElement.classList.remove(withoutBrailleViewClassName);
            if (!onInit) {
                htmlElement.scrollLeft =
                    (htmlElement.scrollWidth - htmlElement.clientWidth) / 2;
            }
        }
        if (simpleViewButton) {
            simpleViewButton.dataset.active = newValue.toString();
        }

        if (newValue) {
            this.editor.custom.zoomModule?.disable();
        } else {
            this.editor.custom.zoomModule?.enable();
        }

        if (!onInit) {
            localStorage?.setItem(
                this.getSimpleViewStorageKey(),
                newValue.toString(),
            );
        }
        this.active = newValue;
    }

    restoreSimpleView() {
        this.setSimpleView(true);
    }
}
