export function getTinyMCESelectedHTML(editor) {
    const selection = editor.selection.getSel();
    if (selection.rangeCount) {
        const container = document.createElement('div');
        let i = 0,
            len = selection.rangeCount;
        for (; i < len; ++i) {
            container.appendChild(selection.getRangeAt(i).cloneContents());
        }
        return container.innerHTML;
    }
    return '';
}
