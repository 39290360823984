import CloseModalButton from './CloseModalButton';
import {
    Form,
    hasValidationErrorFn,
    sendInviteFn,
} from '../../user-management/UserForm';
import { useContext, useEffect, useRef, useState } from 'react';
import { EnvironmentContext } from '../../contexts/EnviromentContext';

export default function NewUserModal({ show, onFinished }) {
    const emptyFormData = {
        name: '',
        email: '',
        roles: [],
    };
    const [formData, setFormData] = useState(emptyFormData);
    const emptyValidationError = {
        name: '',
        email: '',
        roles: '',
    };
    const [validationError, setValidationError] =
        useState(emptyValidationError);
    const [sendInviteLoading, setSendInviteLoading] = useState(false);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { setInfoModal, backendConnectionError } =
        useContext(EnvironmentContext);
    const currentShowRef = useRef(show);
    const inputNameRef = useRef();

    const modalRef = useRef();

    function hasValidationError() {
        setValidateOnChange(true);
        return hasValidationErrorFn(setValidationError, formData);
    }

    useEffect(() => {
        if (validateOnChange) {
            hasValidationError();
        }
    }, [formData]);

    useEffect(() => {
        if (currentShowRef.current === show) return;
        currentShowRef.current = show;
        if (show) {
            setTimeout(() => {
                inputNameRef.current?.focus();
            }, 200); // css transition time
        } else {
            setTimeout(() => {
                setFormData(emptyFormData);
                setValidationError(emptyValidationError);
                setValidateOnChange(false);
            }, 200); // css transition time
        }
    }, [show]);

    function updateFormData(data) {
        // noinspection JSCheckFunctionSignatures
        setFormData((formData) => ({ ...formData, ...data }));
    }

    async function sendInvite() {
        await sendInviteFn(
            hasValidationError,
            setSendInviteLoading,
            formData,
            setInfoModal,
            backendConnectionError,
            () => {
                if (onFinished) onFinished();
            },
        );
    }

    return (
        <div
            ref={modalRef}
            className={`modal default-modal ${show ? 'show' : ''}`}
            style={{ zIndex: 10001 }}
        >
            <div className={'backdrop'} />
            <div className={'container'}>
                <h2>
                    {/*I18N*/}
                    {'Convidar usuário'}
                </h2>

                <CloseModalButton onClick={onFinished} />
                <Form
                    inputNameRef={inputNameRef}
                    formData={formData}
                    validationError={validationError}
                    updateFormData={updateFormData}
                />

                <div className={'controls'}>
                    <button className={'button'} onClick={onFinished}>
                        {/*I18N*/}
                        {'Cancelar'}
                    </button>
                    <button
                        onClick={sendInvite}
                        className={`primary ${sendInviteLoading ? 'loading' : ''}`}
                    >
                        {/*I18N*/}
                        {'Enviar convite'}
                    </button>
                </div>
            </div>
        </div>
    );
}
