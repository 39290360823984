import Miniature from './Miniature';
import DOMPurify from 'dompurify';
import './DocumentMiniature.scss';
import moment from 'moment';
import {
    DocumentStatusEnum,
    DocumentStatusEnumToString,
} from 'plataforma-braille-common';
import PropTypes from 'prop-types';
import { Padlock, Padlock2x } from '../components/images';

function DocumentMiniature({
    name,
    firstPageHtml,
    updatedAt,
    modeView,
    status,
    lockedByUser,
    ...miniatureProps
}) {
    status = status ?? DocumentStatusEnum.LINEARIZATION;
    const statusStr = DocumentStatusEnumToString(status).toLowerCase();

    let statusColor;
    switch (status) {
        default:
        case DocumentStatusEnum.LINEARIZATION:
            statusColor = 'pink';
            break;
        case DocumentStatusEnum.DESCRIPTION:
            statusColor = 'purple';
            break;
        case DocumentStatusEnum.LAYOUT:
            statusColor = 'yellow';
            break;
        case DocumentStatusEnum.EVALUATION:
            statusColor = 'red';
            break;
        case DocumentStatusEnum.EDITORIAL_COMPLETED:
            statusColor = 'blue';
            break;
        case DocumentStatusEnum.PRINTING_APPROVAL:
            statusColor = 'green';
            break;
    }

    const momentUpdatedAt = moment(updatedAt);
    const lastUpdated = `Ultima alteração ${momentUpdatedAt.fromNow()}`;

    function details() {
        return (
            <div className={'document-details'}>
                <div className={'document-name'} title={name}>
                    {name}
                </div>

                <div className={'document-status'}>
                    {/*I18N*/}
                    <strong>{'Status: '}</strong>
                    <span>{statusStr} </span>
                    <span
                        className={'status-indicator'}
                        style={{ backgroundColor: statusColor }}
                    ></span>
                    {lockedByUser ? (
                        // I18N
                        <span
                            className={'status-indicator'}
                            title={`Em edição: ${lockedByUser}`}
                        >
                            <img
                                src={`${Padlock}`}
                                srcSet={`${Padlock} 1x, ${Padlock2x} 2x`}
                                // I18N
                                alt={`Em edição: ${lockedByUser}`}
                                width={12}
                                height={12}
                            />
                        </span>
                    ) : null}
                </div>
                <div className={'document-info'} title={lastUpdated}>
                    {lastUpdated}
                </div>
            </div>
        );
    }

    return (
        <Miniature
            {...miniatureProps}
            className={`document view-mode-${modeView}`}
            content={
                <>
                    <div
                        className={'content'}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(firstPageHtml, {
                                ALLOWED_TAGS: [
                                    'editor-page',
                                    'editor-element',
                                    'div',
                                    'br',
                                    'span',
                                    'strong',
                                    'sup',
                                    'sub',
                                    'em',
                                ],
                                ADD_ATTR: ['type', 'data'],
                            }),
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                        }}
                    ></div>
                </>
            }
            details={details}
        />
    );
}

DocumentMiniature.propTypes = {
    name: PropTypes.string.isRequired,
    firstPageHtml: PropTypes.string,
    updatedAt: PropTypes.string.isRequired,
    modeView: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    ...Miniature.propTypes,
};

export default DocumentMiniature;
