const PunctuationMarksPtBr = ['.', '!', '?', ',', ';', ':', '—', '...'];

const PunctuationMarksExtendedPtBr = PunctuationMarksPtBr.concat([
    '(',
    ')',
    '[',
    ']',
    '/',
    '\\',
]);

const PunctuationMarksFinishPhrasePtBr = ['.', '!', '?'];

const QuotationMarksPtBr = ['“', '”', 'ʻ', 'ʼ', "'", '"', '`'];

export {
    PunctuationMarksPtBr,
    PunctuationMarksExtendedPtBr,
    PunctuationMarksFinishPhrasePtBr,
    QuotationMarksPtBr,
};
