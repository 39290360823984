import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const IntegerInput = forwardRef((props, ref) => {
    const defaultMaskOptions = {
        prefix: '',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 0, // how many digits allowed after the decimal
        integerLimit: 9, // limit length of integer numbers
        allowNegative: false,
        allowLeadingZeroes: false,
    };

    const customMask = createNumberMask({ ...defaultMaskOptions });

    return <MaskedInput ref={ref} mask={customMask} {...props} />;
});

IntegerInput.displayName = 'IntegerInput';

export default IntegerInput;
