import * as DictionaryService from '../services/DictionaryService';
import pLimit from 'p-limit';

const PREFIX = 'dictionaryUnbreakable:';
const LAST_UPDATE_DATE_KEY = `${PREFIX}lastUpdateDate`;
const WORD_PREFIX = `${PREFIX}word:`;

/**
 * @returns {Date}
 */
function getLastUpdateDate() {
    const str = localStorage.getItem(LAST_UPDATE_DATE_KEY);
    if (!str) return new Date(0);
    return new Date(str);
}

/**
 * @param date {Date}
 */
function setLastUpdateDate(date) {
    // adds a second because the server returns grate or EQUALS
    date = new Date(date.getTime() + 1000);
    localStorage.setItem(LAST_UPDATE_DATE_KEY, date.toISOString());
}

/**
 * @param word {DictionaryUnbreakableDto}
 */
function removeWord({ name }) {
    localStorage.removeItem(`${WORD_PREFIX}${name}`);
}

/**
 * @param word {DictionaryUnbreakableDto}
 */
function storeWord(word) {
    localStorage.setItem(`${WORD_PREFIX}${word.name}`, JSON.stringify(word));
}

/**
 * @param word {string}
 * @return {boolean}
 */
export function isWordUnbreakable(word) {
    return !!localStorage.getItem(`${WORD_PREFIX}${word}`);
}

export async function syncDictionaryUnbreakable(setLoadingFn) {
    try {
        const lastUpdateDate = await getLastUpdateDate();
        let { count, pageSize } = await DictionaryService.getUnbreakableWords({
            lastUpdateDate,
            page: 0,
        });

        if (count === 0) {
            console.info('Unbreakable words dictionary already updated.');
            return;
        }

        const limit = pLimit(8);
        const promises = [];
        const pageCount = Math.ceil(count / pageSize);
        let pageProgress = 0;
        let failCount = 0;
        /**
         * @type {Date|null}
         */
        let mostlyRecentDate = null;

        for (let page = 0; page < pageCount; page++) {
            const pageToFetch = page;
            promises.push(
                limit(async () => {
                    try {
                        let { records } =
                            await DictionaryService.getUnbreakableWords({
                                lastUpdateDate: lastUpdateDate,
                                page: pageToFetch,
                            });
                        for (let word of records) {
                            if (
                                mostlyRecentDate === null ||
                                mostlyRecentDate.getTime() <
                                    word.deletedAt?.getTime()
                            ) {
                                mostlyRecentDate = word.deletedAt;
                            }
                            if (
                                mostlyRecentDate === null ||
                                mostlyRecentDate.getTime() <
                                    word.updatedAt?.getTime()
                            ) {
                                mostlyRecentDate = word.updatedAt;
                            }

                            if (word.deletedAt) {
                                removeWord(word);
                            } else {
                                storeWord(word);
                            }
                        }
                        if (setLoadingFn) {
                            // I18N
                            const loadingMessage =
                                'Sincronizando dicionário...';
                            setLoadingFn(
                                true,
                                false,
                                loadingMessage,
                                (++pageProgress / pageCount) * 100,
                            );
                        }
                    } catch (e) {
                        failCount++;
                        console.error(
                            `Fail to sync dictionary page ${pageToFetch}`,
                            e,
                        );
                    }
                }),
            );
        }
        await Promise.allSettled(promises);

        if (failCount === 0 && mostlyRecentDate) {
            await setLastUpdateDate(mostlyRecentDate);
        }
    } finally {
        if (setLoadingFn) setLoadingFn(false);
    }
}
