import {
    centerText,
    extractRecursively,
} from '../../../../../conversion/txt/HtmlToBrailleFacil';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import {
    breakParagraphsEditorElementContainer,
    clearEditorElementLinesCount,
    createInvisibleParagraphBreak,
    elementCanBeInsertedAtSelection,
    fixInvisibleParagraphBreak,
    markLines,
    removeInvisibleParagraphBreak,
    setEditorElementLinesCount,
} from '../EditorElements';
import {
    backPropagateBreaksToText,
    convertElementToBraille,
    getBrailleDataRealLength,
    removeParagraphBreaks,
} from '../../../../../conversion/braille/HtmlToBraille';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';
import { getBrailleParagraphs } from '../BrailleView';
import {
    generateId,
    getSelectedParagraphs,
    isEditorElement,
} from '../EditorUtil';
import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import { registerEditorElement } from './Instances';
import { getCaretPosition } from '../CaretPath';

export const EDITOR_ELEMENT_UNDERLINE = 'EDITOR_ELEMENT_UNDERLINE';

/**
 * @param node {HTMLElement | Node | null}
 * @returns {boolean}
 */
export function isEditorElementUnderline(node) {
    if (!node) return false;
    return isEditorElement(node) && node.getAttribute('type') === 'underline';
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {boolean}
 */
export function isInsideEditorElementUnderline(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementUnderline(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {HTMLElement | null}
 */
export function getClosestEditorElementUnderline(node) {
    if (!node) return null;
    let walk = node;
    while (walk) {
        if (isEditorElementUnderline(walk)) return walk;
        walk = walk.parentNode;
    }
    return null;
}

/**
 * @implements {EditorElement}
 */
export class EditorElementUnderline {
    constructor() {}

    /**
     * @return {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.text'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @param selection {string | undefined | null}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null, selection = null) {
        /**
         * @type {HTMLElement}
         */
        const editorElement = document.createElement('editor-element');
        const idPrefix = 'editor-element-underline';
        const elementId = generateId(editor, idPrefix);
        editorElement.setAttribute('contentEditable', 'false');
        editorElement.setAttribute('type', 'underline');
        editorElement.setAttribute('id', elementId);

        const valueDiv = document.createElement('div');
        valueDiv.setAttribute('class', 'text');
        valueDiv.setAttribute('contentEditable', 'true');
        valueDiv.innerText = selection?.trim() ? selection : ZERO_WIDTH_NB_CHAR;
        editorElement.appendChild(valueDiv);

        return editorElement;
    }

    /**
     * @param {EditorCustom} editor
     * @return {boolean}
     */
    insertElementAtCursor(editor) {
        const editorElementUnderline = getClosestEditorElementUnderline(
            editor.selection.getNode(),
        );

        if (editorElementUnderline) {
            // remove underline
            editor.undoManager.transact(() => {
                /**
                 * @type {Element | Node}
                 */
                const fragment = document.createDocumentFragment();
                /**
                 * @type {HTMLDivElement}
                 */
                const container = editorElementUnderline.querySelector('.text');
                removeParagraphBreaks(container, true);
                for (const child of [...container.childNodes]) {
                    fragment.appendChild(child);
                }
                /**
                 * @type {HTMLBRElement}
                 */
                const br = document.createElement('br');
                fragment.appendChild(br);
                removeInvisibleParagraphBreak(editorElementUnderline);
                editorElementUnderline.replaceWith(fragment);
                editor.focus();
                editor.selection.setCursorLocation(container, 0);
            });
            return true;
        }

        let editorElement = this.createEditorElement(editor);
        if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
            return false;
        }

        editor.undoManager.transact(() => {
            const id = editorElement.getAttribute('id');
            const selectedParagraphs = getSelectedParagraphs(editor);
            if (selectedParagraphs.length !== 1 || selectedParagraphs.length) {
                // has content
                const contentFragment = document.createDocumentFragment();
                for (const [i, paragraph] of selectedParagraphs.entries()) {
                    const br =
                        paragraph[paragraph.length - 1]?.nextElementSibling;
                    if (br?.tagName === 'BR') {
                        br.remove();
                    }
                    for (const node of paragraph) {
                        contentFragment.appendChild(node);
                    }
                    if (i < selectedParagraphs.length - 1) {
                        contentFragment.appendChild(
                            document.createElement('br'),
                        );
                    }
                }
                editorElement
                    .querySelector('.text')
                    ?.appendChild(contentFragment);
            }
            editor.selection.setContent(editorElement.outerHTML);
            editorElement = editor.dom.get(id);
            editorElement.after(createInvisibleParagraphBreak());
            const textContext = editorElement.querySelector('.text');
            /**
             * @type {Text}
             */
            const cursorMark = document.createTextNode(ZERO_WIDTH_NB_CHAR);
            textContext.appendChild(cursorMark);
            editor.focus();
            editor.selection.setCursorLocation(cursorMark, 0);

            /**
             * @type {PageDataChangedEvent}
             */
            const pageDataChangedEvent = {
                caretPosition: getCaretPosition(editor),
            };
            editor.fire('pageDataChanged', pageDataChangedEvent);
        });

        return true;
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [
            ...container.querySelectorAll(`editor-element[type="underline"]`),
        ];
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        /**
         * @type {HTMLElement}
         */
        let text = element.querySelector('.text');
        if (!text) {
            text = document.createElement('div');
            text.className = 'text';
            text.contentEditable = 'true';
            text.innerHTML = ZERO_WIDTH_NB_CHAR;
            element.querySelector('.container')?.appendChild(text);
        }
        if (!text.innerText.trim().length) {
            text.innerHTML = ZERO_WIDTH_NB_CHAR;
        }

        fixInvisibleParagraphBreak(element);
    }

    /**
     * @returns {string[]}
     */
    getContextMenu() {
        return [
            'customContextMenuAddParagraphBreakAbove',
            'customContextMenuAddParagraphBreakBellow',
            '|',
            'customContextMenuRemove',
        ];
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementUnderline(node);
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'underline';
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    generateTxt(element, flags, editorElements, brailleDocument) {
        const textContainer = element.querySelector('.text');

        const text = extractRecursively(
            textContainer,
            flags,
            editorElements,
            brailleDocument,
        );

        const textBrailleData = convertElementToBraille(
            textContainer,
            editorElements,
            brailleDocument,
            [...flags],
        ).trim();

        const { breaks } = getBrailleParagraphs(
            textBrailleData,
            brailleDocument,
        );

        const textParagraphs = backPropagateBreaksToText(text, breaks);

        let txt = '';
        let lineWidth = 0;

        for (let paragraph of textParagraphs) {
            txt += centerText(paragraph + '\r\n');
            if (paragraph.length > lineWidth) {
                lineWidth = paragraph.length;
            }
        }
        lineWidth += 2;
        if (lineWidth > brailleDocument.brailleCellColCount) {
            lineWidth = brailleDocument.brailleCellColCount;
        }
        txt += centerText(':'.repeat(lineWidth)) + '\r\n';

        return txt;
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    generateBraille(element, flags, editorElements, brailleDocument) {
        const ignoreElement = flags.includes(
            BrailleFacilConversionFlag.IGNORE_CUSTOM_ELEMENT,
        );

        const textContainer = element.querySelector('.text');

        if (!ignoreElement) {
            clearEditorElementLinesCount(element);
        }

        let textBrailleData = convertElementToBraille(
            textContainer,
            editorElements,
            brailleDocument,
            flags,
        ).trim();

        textBrailleData = markLines(textBrailleData, MARK_CHAR.CENTER_BLOCK);

        const { breaks: breaksText, paragraphs: paragraphsText } =
            getBrailleParagraphs(textBrailleData, brailleDocument);

        if (!ignoreElement) {
            if (textContainer)
                breakParagraphsEditorElementContainer(
                    textContainer,
                    breaksText,
                );
            let lineCount = 0;
            if (paragraphsText) lineCount += paragraphsText.length;
            lineCount++;
            setEditorElementLinesCount(element, lineCount);
        }

        let lineWidth = 0;
        for (const line of paragraphsText ?? []) {
            const width = getBrailleDataRealLength(line.trim());
            if (width > lineWidth) {
                lineWidth = width;
            }
        }
        lineWidth += 2;
        if (lineWidth > brailleDocument.brailleCellColCount) {
            lineWidth = brailleDocument.brailleCellColCount;
        }

        const paragraphs = [];
        if (paragraphsText) paragraphs.push(...paragraphsText);
        const line =
            MARK_CHAR.EMPTY_CHAR.repeat(
                (brailleDocument.brailleCellColCount - lineWidth) / 2,
            ) + ':'.repeat(lineWidth);
        paragraphs.push(line);

        return markLines(paragraphs, MARK_CHAR.RAW_DATA).join('\r\n');
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        if (flags.includes(BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT)) {
            return this.generateBraille(
                element,
                flags,
                editorElements,
                brailleDocument,
            );
        } else {
            return this.generateTxt(
                element,
                flags,
                editorElements,
                brailleDocument,
            );
        }
    }
}

registerEditorElement(EDITOR_ELEMENT_UNDERLINE, new EditorElementUnderline());
