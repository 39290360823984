// noinspection JSCheckFunctionSignatures

import './LoginForm.scss';
import * as LoginService from '../services/LoginService';
import { useContext, useEffect, useState } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import FieldText from '../components/FieldText';
import { useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function LoginForm() {
    const [loginLoading, setLoginLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const navigate = useNavigate();

    function updateFormData(obj) {
        setFormData((formData) => {
            return { ...formData, ...obj };
        });
    }

    const [loginError, setLoginError] = useState();
    const [passwordError, setPasswordError] = useState();
    const [validateOnChange, setValidateOnChange] = useState(false);
    const {
        setIsLogged,
        setUser,
        backendConnectionError,
        setLoading,
        recaptchaError,
    } = useContext(EnvironmentContext);
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        setLoading(loginLoading, true);
    }, [loginLoading]);

    function hasValidationError() {
        let hasErrors = false;
        setLoginError(null);
        setPasswordError(null);

        if (!formData.email || formData.email.trim() === '') {
            // I18N
            setLoginError('Campo obrigatório.');
            hasErrors = true;
        }
        if (!formData.password || formData.password.trim() === '') {
            // I18N
            setPasswordError('Campo obrigatório.');
            hasErrors = true;
        }
        setValidateOnChange(true);
        return hasErrors;
    }

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    async function login(e) {
        e.preventDefault();
        if (hasValidationError()) return;

        try {
            setLoginLoading(true);
            let recaptchaToken;
            if (process.env.REACT_APP_RECAPTCHA_KEY && executeRecaptcha) {
                // I18N
                recaptchaToken = await executeRecaptcha('login');
            } else {
                console.warn('Recaptcha is disabled on this environment.');
            }
            const response = await LoginService.login(
                formData.email,
                formData.password,
                recaptchaToken,
            );
            const status = response?.status;

            if (status === 200) {
                setIsLogged(true);
                setUser(null);
            } else if (status === 401) {
                // I18N
                const errorMessage = 'Usuário ou senha incorretos.';
                setLoginError(errorMessage);
                setPasswordError(errorMessage);
            } else if (status === 412) {
                recaptchaError();
            } else {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error('Unexpected server return: ' + status);
            }
        } catch (e) {
            backendConnectionError('Fail to login.', e);
        } finally {
            setLoginLoading(false);
            setLoading(false); // avoid a bug that sometimes keeps the invisible loading active after page change
        }
    }

    return (
        <div className={'gd-col-7--desktop gd-col--valign-middle login-form'}>
            <div>
                <form
                    // I18N
                    aria-label={'Login'}
                >
                    <h1 className={'highlight'}>
                        {/*I18N*/}
                        {'Plataforma Braille'}
                    </h1>

                    <h2>
                        {/*I18N*/}
                        {'Login'}
                    </h2>

                    <FieldText
                        // I18N
                        label={'E-mail'}
                        // I18N
                        placeholder={'E-mail'}
                        autoComplete={'email'}
                        required={true}
                        validationError={loginError}
                        value={formData.email}
                        onChange={(e) =>
                            updateFormData({ email: e.target['value'] })
                        }
                    />

                    <FieldText
                        // I18N
                        label={'Senha'}
                        // I18N
                        placeholder={'Senha'}
                        type={'password'}
                        autoComplete={'password'}
                        required={true}
                        validationError={passwordError}
                        value={formData.password}
                        onChange={(e) =>
                            updateFormData({ password: e.target['value'] })
                        }
                    />

                    <div className={'form-controls'}>
                        <button
                            className={`primary ${loginLoading ? 'loading' : ''}`}
                            disabled={loginLoading}
                            onClick={login}
                        >
                            {/*I18N*/}
                            {'Entrar'}
                        </button>
                    </div>
                </form>

                <div>
                    {/*I18N*/}
                    <button onClick={() => navigate('/recuperar-senha')}>
                        {'Esqueceu sua senha?'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;
