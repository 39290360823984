import Field from './Field';
import { forwardRef, useRef } from 'react';
import nextId from 'react-id-generator';

export const FieldTextStyle = {
    FIELD_NORMAL: 'field-normal', // css class correspondent
    FIELD_LOGIN: 'field-login', // css class correspondent
};

export const FieldTextLabelStyle = {
    LABEL_ABOVE: 'label-above', // css class correspondent
    LABEL_INLINE: 'label-inline', // css class correspondent
};

function FieldText(
    {
        children,
        required,
        icon,
        icon2x,
        className,
        type,
        value,
        onChange,
        onKeyUp,
        onKeyDown,
        placeholder,
        autoComplete,
        style,
        disabled,
        maxLength,
        ...props
    },
    ref,
) {
    const { labelText, labelStyle, validationError } = props;

    const id = props.id ?? nextId('field-text');
    const idError = `${id}-error`;
    const fieldRef = useRef();
    let inputStyle = { ...(style ?? {}) };
    if (icon) {
        if (icon2x) {
            inputStyle.backgroundImage = `image-set(url(${icon}) 1x, url(${icon2x}) 2x) `;
        } else {
            inputStyle.backgroundImage = `url(${icon})`;
        }
    }

    return (
        <Field
            ref={fieldRef}
            {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
            hasIcon={!!icon}
        >
            <input
                ref={ref}
                id={id}
                type={type ?? 'text'}
                required={!!required}
                aria-required={!!required}
                aria-invalid={!!validationError}
                aria-errormessage={idError}
                style={inputStyle}
                title={labelText}
                value={value ?? ''}
                maxLength={maxLength}
                disabled={disabled ?? false}
                placeholder={placeholder}
                autoComplete={autoComplete}
                onChange={(e) => {
                    if (
                        labelStyle === FieldTextLabelStyle.LABEL_INLINE &&
                        fieldRef.current?.label &&
                        e.target.value !== ''
                    ) {
                        fieldRef.current.label.style.visibility = 'hidden';
                    } else {
                        fieldRef.current?.label?.style.removeProperty(
                            'visibility',
                        );
                    }
                    if (onChange) onChange(e);
                }}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                className={className}
            />
        </Field>
    );
}

export default forwardRef(FieldText);
