import React from 'react';
import PropTypes from 'prop-types';
import './FiguresTable.scss';
import DOMPurify from 'dompurify';
import { BraillePreview } from '../components/BraillePreview';

/**
 * @param records {FiguresDto[] | null}
 * @param loadingRow {Element | null}
 * @param selected {number | null}
 * @param onSelected {function(FiguresDto)}
 * @param showPreviewBraille {boolean | null}
 * @param onDoubleClick {function(FiguresDto)}
 * @return {JSX.Element}
 * @constructor
 */

function FiguresTable({
    records,
    loadingRow,
    selected,
    onSelected,
    showPreviewBraille,
    onDoubleClick,
}) {
    return (
        <table className={'figures-table selectable'}>
            {showPreviewBraille && (
                <thead>
                    <tr>
                        {showPreviewBraille && <th>{'Preview braille'}</th>}
                        <th>{'Tags'}</th>
                    </tr>
                </thead>
            )}
            <tbody>
                {records?.map((figure) => {
                    let tags = figure?.tags?.join(', ');
                    if (!tags.trim()) {
                        // I18N
                        tags = '<i>Figura sem tag</i>';
                    }

                    const classList = [];
                    if (selected === figure.id) {
                        classList.push('selected');
                    }

                    return (
                        <tr
                            key={figure.id}
                            onClick={() => {
                                onSelected(figure);
                            }}
                            className={classList.join(' ')}
                            onDoubleClick={() => {
                                onDoubleClick(figure);
                            }}
                        >
                            {showPreviewBraille && (
                                <td className={'column-preview-braille'}>
                                    <div
                                        className={'preview-braille-container'}
                                        id={`${figure.id}`}
                                    >
                                        <BraillePreview
                                            text={figure.figure}
                                            alignCenter={true}
                                        />
                                    </div>
                                </td>
                            )}
                            <td
                                className={'tags'}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(tags),
                                }}
                            ></td>
                        </tr>
                    );
                })}

                {loadingRow && (
                    <tr>
                        <td colSpan={6}>{loadingRow}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

FiguresTable.propTypes = {
    records: PropTypes.arrayOf(PropTypes.object),
    loadingRow: PropTypes.node,
    selected: PropTypes.number,
    onSelected: PropTypes.func.isRequired,
    showPreviewBraille: PropTypes.bool,
    onDoubleClick: PropTypes.func,
};

export default FiguresTable;
