export enum UsersOrderByEnum {
    NAME_ASC = "NAME_ASC",
    NAME_DESC = "NAME_DESC",
    EMAIL_ASC = "EMAIL_ASC",
    EMAIL_DESC = "EMAIL_DESC",
}

export type UsersOrderByEnumValue =
    (typeof UsersOrderByEnum)[keyof typeof UsersOrderByEnum];

export function UsersOrderByEnumToString(key: string): string {
    const orderUserBy = UsersOrderByEnum[key];
    switch (orderUserBy) {
        case UsersOrderByEnum.NAME_ASC:
            // I18N
            return "Nome asc";
        case UsersOrderByEnum.NAME_DESC:
            // I18N
            return "Nome dec";
        case UsersOrderByEnum.EMAIL_ASC:
            // I18N
            return "Email asc";
        case UsersOrderByEnum.EMAIL_DESC:
            // I18N
            return "Email dec";
        default:
            throw new Error("Invalid enum value");
    }
}
