import {
    addParagraphBreakAboveElement,
    addParagraphBreakBellowElement,
} from '../core/PageManipulation';
import { removeEditorElement } from '../core/EditorElements';
import {
    getBrailleDocument,
    getClosestEditorElement,
} from '../core/EditorUtil';

/**
 * @param editor {EditorCustom}
 * @param params {MenuModuleParams}
 */
// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line no-unused-vars
export function createContextMenu(editor, params) {
    let contextMenuElement = null;

    editor.ui.registry.addMenuItem('customContextMenuAddParagraphBreakAbove', {
        icon: 'action-prev',
        // I18N
        text: 'Inserir parágrafo acima',
        onAction: function () {
            if (!contextMenuElement) return;
            const refreshedElement = editor.dom.get(
                contextMenuElement.getAttribute('id'),
            );
            addParagraphBreakAboveElement(editor, refreshedElement);
            contextMenuElement = null;
        },
        onSetup: (api) => {
            api.setEnabled(!getBrailleDocument(editor).readOnly);
        },
    });

    editor.ui.registry.addMenuItem('customContextMenuAddParagraphBreakBellow', {
        icon: 'action-next',
        // I18N
        text: 'Inserir parágrafo abaixo',
        onAction: function () {
            if (!contextMenuElement) return;
            const refreshedElement = editor.dom.get(
                contextMenuElement.getAttribute('id'),
            );
            addParagraphBreakBellowElement(editor, refreshedElement);
            contextMenuElement = null;
        },
        onSetup: (api) => {
            api.setEnabled(!getBrailleDocument(editor).readOnly);
        },
    });
    editor.ui.registry.addMenuItem('customContextMenuRemove', {
        icon: 'remove',
        // I18N
        text: 'Remover',
        onAction: function () {
            if (!contextMenuElement) return;
            const refreshedElement = editor.dom.get(
                contextMenuElement.getAttribute('id'),
            );
            removeEditorElement(editor, refreshedElement);
            contextMenuElement = null;
        },
        onSetup: (api) => {
            api.setEnabled(!getBrailleDocument(editor).readOnly);
        },
    });

    // do not change the menu name, will stop to work
    editor.ui.registry.addContextMenu('image', {
        update: function (element) {
            const editorElements = editor.custom.coreModule?.editorElements;
            contextMenuElement = getClosestEditorElement(element);
            return editorElements?.getContextMenu(contextMenuElement);
        },
    });
}
