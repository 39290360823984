import './LoadingModal.scss';
import { useRef } from 'react';

function LoadingModal({ text, show, transparent, progress }) {
    // noinspection DuplicatedCode
    const focused = useRef(null);
    const hasShowing = useRef(false);

    if (hasShowing.current !== show) {
        if (show) {
            focused.current = document.activeElement;
            document.activeElement?.blur();
        } else {
            focused.current?.focus();
            focused.current = null;
        }
        hasShowing.current = show;
    }

    return (
        <div
            className={`modal loading-modal ${show ? 'show' : ''} ${transparent ? 'transparent' : ''}`}
        >
            <div className={'backdrop'} />
            <div className={'container'}>
                <button
                    className={'loading loading-progress'}
                    data-progress={progress ? Math.round(progress) + '%' : ''}
                />
                {/*I18N*/}
                {text && text.length ? text : 'Carregando...'}
            </div>
        </div>
    );
}

export default LoadingModal;
