import Api from './Api';

/**
 * @typedef {object} UserWithAccessDto
 * @property {number} id
 * @property {string} name
 * @property {string} email
 * @property {import('plataforma-braille-common').RoleEnumValue[]} roles
 */

/**
 * @typedef {object} TemplateDto
 * @property {number | null | undefined} id
 * @property {string | null | undefined} name
 * @property {string | null | undefined} createdAt
 * @property {string | null | undefined} updatedAt
 * @property {import('plataforma-braille-common').MeasureEnumValue |  null | undefined} pageMeasure
 * @property {number | null | undefined} pageWidth
 * @property {number | null | undefined} pageHeight
 * @property {import('plataforma-braille-common').OrientationEnumValue | null | undefined} pageOrientation
 * @property {number | null | undefined} pageCount
 * @property {number | null | undefined} pageLimit
 * @property {number | null | undefined} brailleCellRowCount
 * @property {number | null | undefined} brailleCellColCount
 * @property {boolean | null | undefined} interPoint
 * @property {import('plataforma-braille-common').InkFontTypeEnumValue | null | undefined} inkFontType
 * @property {number | null | undefined} inkFontSize
 * @property {number | null | undefined} braillePageMarginLeft
 * @property {number | null | undefined} braillePageMarginTop
 * @property {number | null | undefined} braillePageMarginRight
 * @property {number | null | undefined} braillePageMarginBottom
 * @property {boolean | null | undefined} braillePageMarginLocked
 * @property {number | null | undefined} inkPageMarginLeft
 * @property {number | null | undefined} inkPageMarginTop
 * @property {number | null | undefined} inkPageMarginRight
 * @property {number | null | undefined} inkPageMarginBottom
 * @property {boolean | null | undefined} inkPageMarginLocked
 * @property {boolean | null | undefined} convertedToBraille
 * @property {boolean | null | undefined} hyphenation
 * @property {number | null | undefined} hyphenationLettersMin
 * @property {number | null | undefined} hyphenationSyllablesMin
 * @property {number | null | undefined} hyphenationParagraphMax
 * @property {number | null | undefined} hyphenationDistanceBetweenHyphens
 * @property {boolean | null | undefined} templateCreate
 * @property {number | null | undefined} inkPageLineHeight
 * @property {import('plataforma-braille-common').DocumentTypeEnumValue | null | undefined} type
 */

/**
 * @typedef {TemplateDto} DocumentDto
 * @property {string | null | undefined} firstPageHtml
 * @property {string | null | undefined} html
 * @property {number | null | undefined} templateId
 * @property {string | null | undefined} templateName
 * @property {UserWithAccessDto[] | null | undefined} userWithAccess
 * @property {string | null | undefined} lockedByUser
 * @property {number | null | undefined} lockId
 * @property {boolean | null | undefined} readOnly
 * @property {boolean | null | undefined} ownedByUser
 * @property {import('plataforma-braille-common').DocumentStatusEnumValue | undefined} status
 */

/**
 * @typedef {object} DocumentsFilter
 * @property {number} page
 * @property {string | null} search
 * @property {import('plataforma-braille-common').DocumentsOrderByEnumValue | null} order
 *
 */

/**
 * @param document {DocumentDto}
 * @returns {Promise<DocumentDto>}
 */
export async function createDocument(document) {
    return (await Api.put('/documents', document)).data;
}

/**
 * @param filter { DocumentsFilter }
 * @param config {import('axios').AxiosRequestConfig | null | undefined}
 * @returns {Promise<PaginationResDto<DocumentDto>>}
 */
export async function getDocuments(filter, config) {
    return (await Api.get('/documents', { params: filter, ...(config ?? {}) }))
        .data;
}

/**
 * @param id {string | number}
 * @param name {string | null}
 * @param retrieveHtml {boolean | null}
 * @param lockDocument {boolean  |  null  |  undefined}
 * @returns {Promise<DocumentDto>}
 */
export async function getDocument(
    id,
    name,
    retrieveHtml = true,
    lockDocument = false,
) {
    return (
        await Api.get(
            `/documents/${id}/${name ?? ''}?retrieveHtml=${retrieveHtml}&lockDocument=${lockDocument}`,
        )
    ).data;
}

/**
 * @param id {number | string}
 * @param document {DocumentDto}
 * @returns {Promise<DocumentDto>}
 */
export async function editDocument(id, document) {
    return (await Api.patch(`/documents/${id}`, document)).data;
}

/**
 * @param id {number | string}
 * @returns {Promise<DocumentDto>}
 */
export async function removeDocument(id) {
    return (await Api.delete(`/documents/${id}`)).data;
}

/**
 * @param imgData {string}
 * @param documentId {number}
 * @returns {Promise<import('axios').AxiosResponse<string>>}
 */
export async function imageDescription(imgData, documentId) {
    const data = { imgData, documentId };
    return (await Api.post('/documents/image-description', data)).data;
}

/**
 * @param name {string}
 * @param base64 {string}
 * @returns {Promise<string>}
 */
export async function createFile(name, base64) {
    const data = {
        name,
        base64,
    };
    return (await Api.put('/files', data))?.data?.url;
}

/**
 * @param documentId {string | number}
 * @param lockId {number | null}
 * @param force {boolean | null}
 * @return {Promise<number>}
 */
export async function unlockDocument(documentId, lockId, force = null) {
    try {
        return (
            await Api.post(`/documents/unlock-document/${documentId}`, {
                lockId,
                force: force ?? undefined,
            })
        )?.status;
    } catch (e) {
        if (e.response?.status) {
            return e.response.status;
        } else {
            throw e;
        }
    }
}

/**
 * @param documentId {string | number}
 * @param lockId {number}
 * @return {Promise<void>}
 */
export async function keepDocumentLock(documentId, lockId) {
    await Api.post(`/documents/keep-document-lock/${documentId}`, { lockId });
}

/**
 * @param documentId {string | number}
 * @return {Promise<void>}
 */
export async function retrieveImagesDescriptionsBackground(documentId) {
    await Api.post(
        `/documents/retrieve-images-descriptions-background/${documentId}`,
    );
}

/**
 * @param imageUrl {string}
 * @param documentId {number}
 * @return {Promise<{error: boolean, description: string | null} | null>}
 */
export async function imageDescriptionBackground(imageUrl, documentId) {
    const data = { imgData: imageUrl, documentId };
    try {
        return (await Api.post('/documents/image-description-background', data))
            ?.data;
    } catch (e) {
        if (e?.response?.status === 404) {
            return {
                error: true,
                description: null,
            };
        }
        throw e;
    }
}
