import {
    useEffect,
    useState,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import * as DictionaryService from '../services/DictionaryService';
import ScrollPagination from '../components/ScrollPagination';
import './DictionaryModalList.scss';
import ErrorLoading from '../components/ErrorLoading';
import DictionaryTable from './DictionaryTable';
import PropTypes from 'prop-types';

const STORAGE_KEY = 'dictionaryFilter';

/**
 * @typedef {object} DictionaryModalListParams
 * @property {string | null} selected
 * @property {boolean} showingModal
 * @property {function(DictionaryUnbreakableDto)} onSelected
 */

/**
 * @typedef {object} DictionaryModalListFunctions
 * @property {function()} refresh
 */

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<DictionaryModalListParams>>}
 */
const DictionaryModalList = forwardRef(
    ({ selected, onSelected, showingModal }, ref) => {
        /**
         * @type {DictionaryUnbreakableDto[] | null}
         */
        const recordsInitialValue = null;
        const [records, setRecords] = useState(recordsInitialValue);
        const [loading, setLoading] = useState(null);
        const scrollControlInitialState = {
            records: 0,
            pageSize: 0,
        };
        const [scrollControl, setScrollControl] = useState(
            scrollControlInitialState,
        );
        /**
         * @typedef {object} FilterState
         * @property {string | null | undefined} search
         * @property {number} page
         */

        /**
         * @type {FilterState}
         */
        let filterInitialState = {
            search: '',
            page: 0,
        };
        try {
            const storedFilter = sessionStorage.getItem(STORAGE_KEY);
            if (storedFilter) {
                filterInitialState = {
                    ...filterInitialState,
                    ...JSON.parse(storedFilter),
                    page: 0,
                };
            }
        } catch (e) {}
        const [filter, setFilter] = useState(filterInitialState);
        const dictionaryScrollRef = useRef();

        useEffect(() => {
            if (showingModal) {
                fetchDictionary().then();
            }
        }, [filter]);

        async function fetchDictionary() {
            if (loading?.abort) {
                loading.abort();
            }
            const abortController = new AbortController();
            try {
                setLoading(abortController);
                const data = await DictionaryService.getUnbreakableWords(
                    filter,
                    {
                        signal: abortController.signal,
                    },
                );
                setRecords((prevDictionary) =>
                    data.page === 0
                        ? data.records
                        : [...prevDictionary, ...data.records],
                );
                setScrollControl({
                    records: data.records.length,
                    pageSize: data.pageSize,
                });
                setLoading(null);
            } catch (e) {
                if (e.name === 'CanceledError') return;
                console.error('Error to fetch dictionary.', e);
                setLoading(e);
            }
        }

        const updateFilter = (newFilter) => {
            setFilter((prevFilter) => {
                const updatedFilter = {
                    ...prevFilter,
                    ...newFilter,
                    page: 0,
                };
                sessionStorage.setItem(
                    STORAGE_KEY,
                    JSON.stringify(updatedFilter),
                );
                return updatedFilter;
            });
        };

        const error = loading instanceof Error;
        const scrollPagination = () => {
            if (loading && error) {
                return (
                    <ErrorLoading
                        onTryAgain={() => {
                            setFilter({ ...filter });
                        }}
                    />
                );
            } else if (
                scrollControl.records >= scrollControl.pageSize &&
                dictionaryScrollRef.current
            ) {
                return (
                    <ScrollPagination
                        scrollElement={dictionaryScrollRef.current}
                        suspended={!showingModal || !!loading}
                        onPageRequested={() => {
                            const page = (filter.page ?? 0) + (records ? 1 : 0);
                            setFilter((filter) => {
                                filter.page = page;
                                return { ...filter };
                            });
                        }}
                    />
                );
            } else {
                return null;
            }
        };

        function table() {
            if (records?.length === 0 && !loading) {
                return (
                    <div className={'status'}>
                        <p>{'Nenhuma palavra encontrada'}</p>
                    </div>
                );
            } else {
                return (
                    <div ref={dictionaryScrollRef} className={'scroll'}>
                        <DictionaryTable
                            records={records}
                            loadingRow={scrollPagination()}
                            selected={selected}
                            onSelected={onSelected}
                        />
                    </div>
                );
            }
        }

        useImperativeHandle(ref, () => ({
            refresh: () => {
                setFilter((prevFilter) => ({
                    ...prevFilter,
                    page: 0,
                }));
            },
        }));

        return (
            <div className={'dictionary-modal-list'}>
                {/*<label className={'field-label'}> {'Palavras'} </label>*/}
                <div>
                    <input
                        // I18N
                        placeholder={'Digite a palavra ou descrição'}
                        className={'small search-field'}
                        value={filter.search ?? ''}
                        onChange={(e) =>
                            updateFilter({ ...filter, search: e.target.value })
                        }
                    />
                </div>

                <div className={'table-container'}>{table()}</div>
            </div>
        );
    },
);

DictionaryModalList.displayName = 'DictionaryModalList';

DictionaryModalList.propTypes = {
    selected: PropTypes.string,
    onSelected: PropTypes.func.isRequired,
    showingModal: PropTypes.bool,
};

export default DictionaryModalList;
