import CloseModalButton from './CloseModalButton';
import DOMPurify from 'dompurify';
import { useRef } from 'react';

function InfoModal({ show, zIndex, onClose, title, message }) {
    // noinspection DuplicatedCode
    const focused = useRef(null);
    const hasShowingRef = useRef(false);

    if (hasShowingRef.current !== show) {
        if (show) {
            focused.current = document.activeElement;
            document.activeElement?.blur();
        } else {
            focused.current?.focus();
            focused.current = null;
        }
        hasShowingRef.current = show;
    }

    return (
        <div
            className={`modal default-modal ${show ? 'show' : ''}`}
            style={{ zIndex: zIndex ?? 10010 }}
        >
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={onClose} />
                <h2
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(title),
                    }}
                />
                <div
                    className={'message'}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(message),
                    }}
                />
                <div className={'controls'}>
                    <a className={'button primary'} onClick={onClose}>
                        {/*I18N*/}
                        {'Fechar'}
                    </a>
                </div>
            </div>
        </div>
    );
}

export default InfoModal;
