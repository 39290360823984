export enum UserStatusEnum {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    PENDING = "PENDING",
}

export type UserStatusEnumValue =
    (typeof UserStatusEnum)[keyof typeof UserStatusEnum];

export function UserStatusEnumToString(key: string): string {
    if (!key) return null;
    const role = UserStatusEnum[key];
    switch (role) {
        case UserStatusEnum.ACTIVE:
            // I18N
            return "Ativo";
        case UserStatusEnum.INACTIVE:
            // I18N
            return "Inativo";
        case UserStatusEnum.PENDING:
            // I18N
            return "Pendente de login";
        default:
            console.error(`Invalid enum value: ${key}`);
            return key;
    }
}
