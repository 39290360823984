import { generateId, isEditorElement, selectAllTextNode } from '../EditorUtil';
import {
    elementCanBeInsertedAtSelection,
    surroundElementWithZeroWidthSpace,
} from '../EditorElements';
import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';
import { registerEditorElement } from './Instances';

import { MATH_OPERATORS_WITH_CONVERTED_CHARS } from '../../../../../conversion/braille/HtmlToBraille';

export const EDITOR_ELEMENT_NTH_ROOT = 'EDITOR_ELEMENT_NTH_ROOT';

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementNthRoot(node) {
    if (!node) return false;
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.toLowerCase() === 'nth-root'
    );
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideEditorElementNthRoot(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementNthRoot(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @param editor {EditorCustom | undefined | null}
 * @param value {string | undefined | null}
 * @return {HTMLElement}
 */
export function createEditorElementNthRoot(editor = null, value = null) {
    const idPrefix = 'editor-element-nth-root';
    const elementId = generateId(editor, idPrefix);
    const editorElement = document.createElement('editor-element');
    editorElement.setAttribute('id', elementId);
    editorElement.setAttribute('type', 'nth-root');
    editorElement.setAttribute('contentEditable', 'false');

    const indexDiv = document.createElement('div');
    indexDiv.className = 'index';
    indexDiv.setAttribute('contentEditable', 'true');

    const radicandDiv = document.createElement('div');
    radicandDiv.className = 'radicand';
    radicandDiv.setAttribute('contentEditable', 'true');

    indexDiv.innerHTML = ZERO_WIDTH_NB_CHAR;
    radicandDiv.innerHTML = value?.trim() ? value.trim() : ZERO_WIDTH_NB_CHAR;
    editorElement.appendChild(indexDiv);
    editorElement.appendChild(radicandDiv);

    return editorElement;
}

/**
 * @implements {EditorElement}
 */
export class EditorElementNthRoot {
    constructor() {}

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'nth-root';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementNthRoot(node);
    }

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.radicand', '.index'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return false;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null) {
        return createEditorElementNthRoot(editor);
    }

    /**
     * @param editor {EditorCustom}
     * @return {boolean}
     */
    insertElementAtCursor(editor) {
        const selection = editor.selection?.getContent().trim() ?? '';
        let editorElement = createEditorElementNthRoot(editor, selection);
        if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
            return false;
        }

        editor.undoManager.transact(() => {
            const id = editorElement.getAttribute('id');
            editor.selection.setContent(editorElement.outerHTML);
            editorElement = editor.dom.get(id);
            const indexDiv = editorElement.querySelector('.index');
            surroundElementWithZeroWidthSpace(editorElement);
            editor.focus();
            selectAllTextNode(editor, indexDiv);
        });
        return true;
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        let index =
            extractRecursively(
                element.querySelector('.index'),
                flags,
                editorElements,
                brailleDocument,
            ) ?? '';
        index = index.trim();
        let radicand =
            extractRecursively(
                element.querySelector('.radicand'),
                flags,
                editorElements,
                brailleDocument,
            ) ?? '';
        const needsAuxParenthesis =
            radicand.split(
                new RegExp(
                    `[${MATH_OPERATORS_WITH_CONVERTED_CHARS.join('')}]`,
                    'g',
                ),
            ).length > 1;
        if (index === '' || parseInt(index) === 2) index = '';
        let nthRoot = `à${index}@`;
        if (needsAuxParenthesis) {
            nthRoot += `?${radicand}*`;
        } else {
            nthRoot += radicand;
        }
        return nthRoot;
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [
            ...container.querySelectorAll('editor-element[type="nth-root"]'),
        ];
    }

    /**
     *@param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        /**
         * @type {HTMLDivElement}
         */
        let indexDiv = element.querySelector('.index');
        /**
         * @type {HTMLDivElement}
         */
        let radicandDiv = element.querySelector('.radicand');

        if (!indexDiv) {
            indexDiv = document.createElement('div');
            indexDiv.className = 'index';
            indexDiv.setAttribute('contentEditable', 'true');
            if (element.firstElementChild) {
                element.insertBefore(indexDiv, element.firstElementChild);
            } else {
                element.appendChild(indexDiv);
            }
        }

        if (!radicandDiv) {
            radicandDiv = document.createElement('div');
            radicandDiv.className = 'radicand';
            radicandDiv.setAttribute('contentEditable', 'true');
            indexDiv.after(radicandDiv);
        }

        if (!indexDiv || !radicandDiv) {
            element.remove();
            return;
        }
        if (!indexDiv.innerHTML.trim()) {
            indexDiv.innerHTML = ZERO_WIDTH_NB_CHAR;
        }
        if (!radicandDiv.innerHTML.trim()) {
            radicandDiv.innerHTML = ZERO_WIDTH_NB_CHAR;
        }
        surroundElementWithZeroWidthSpace(element);
    }

    /**
     *@return {string[]}
     */
    getContextMenu() {
        return [
            'customContextMenuAddParagraphBreakAbove',
            'customContextMenuAddParagraphBreakBellow',
            '|',
            'customContextMenuRemove',
        ];
    }
}

registerEditorElement(EDITOR_ELEMENT_NTH_ROOT, new EditorElementNthRoot());
