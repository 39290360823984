import './CrudContainer.scss';
import LoggedContainer from './LoggedContainer';
import ButtonBack from '../ButtonBack';
import Loading from '../Loading';

function CrudContainer({
    title,
    subtitle,
    onBack,
    submenu,
    gdConfigClassName,
    children,
    className,
    loading,
}) {
    function content() {
        if (loading) {
            return (
                <div className={'info-container'}>
                    <Loading />
                </div>
            );
        }

        return (
            <div className={'gd-container sub-container-1'}>
                <div className={'gd gd--full-height'}>
                    <div className={gdConfigClassName ?? 'gd-col-12--desktop'}>
                        <div className={'sub-container-2'}>
                            <h2>{title}</h2>
                            <div className={'subtitle-container'}>
                                {subtitle && (
                                    <h3 className={'highlight bold'}>
                                        {subtitle}
                                    </h3>
                                )}
                                <div className={'submenu-container'}>
                                    {submenu}
                                </div>
                            </div>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <LoggedContainer>
            <div className={`crud-container ${className ?? ''}`}>
                <ButtonBack onClick={onBack} />
                {content()}
            </div>
        </LoggedContainer>
    );
}

export default CrudContainer;
