export enum SaveTemplateValidationErrorEnum {
    TEMPLATE_NAME_ALREADY_IN_USE = "TEMPLATE_NAME_ALREADY_IN_USE",
    TEMPLATE_HYPHENATION_LETTERS_MIN_REQUIRED = "TEMPLATE_HYPHENATION_LETTERS_MIN_REQUIRED",
    TEMPLATE_HYPHENATION_SYLLABLES_MIN_REQUIRED = "TEMPLATE_HYPHENATION_SYLLABLES_MIN_REQUIRED",
    TEMPLATE_HYPHENATION_PARAGRAPH_MAX_REQUIRED = "TEMPLATE_HYPHENATION_PARAGRAPH_MAX_REQUIRED",
    TEMPLATE_NAME_REQUIRED = "TEMPLATE_NAME_REQUIRED",
}

export function SaveTemplateValidationErrorTxt(key: string): string {
    const validationError = SaveTemplateValidationErrorEnum[key];
    switch (validationError) {
        case SaveTemplateValidationErrorEnum.TEMPLATE_NAME_ALREADY_IN_USE:
            // I18N
            return "O nome do template informado já foi utilizado.";
        case SaveTemplateValidationErrorEnum.TEMPLATE_HYPHENATION_LETTERS_MIN_REQUIRED:
            // I18N
            return "A quantidade mínima de letras da hifenização não foi informada.";
        case SaveTemplateValidationErrorEnum.TEMPLATE_HYPHENATION_SYLLABLES_MIN_REQUIRED:
            // I18N
            return "A quantidade mínima de sílabas da hifenização não foi informada.";
        case SaveTemplateValidationErrorEnum.TEMPLATE_HYPHENATION_PARAGRAPH_MAX_REQUIRED:
            // I18N
            return "A quantidade máxima de letras por parágrafo da hifenização não foi informada.";
        case SaveTemplateValidationErrorEnum.TEMPLATE_NAME_REQUIRED:
            // I18N
            return "O nome do template é obrigatário.";
        default:
            throw new Error("Invalid enum value");
    }
}
