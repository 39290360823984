import { MeasureInput } from './MeasureInput';
import nextId from 'react-id-generator';

function FieldMeasure(props) {
    const id = props.id ?? nextId('field-measure');
    const idError = `${id}-error`;
    const { label, validationError, required, className } = props;

    props = { ...props };

    delete props.required;
    delete props.validationError;
    delete props.label;

    const classNameList = ['field-group'];
    if (validationError) {
        classNameList.push('error');
    }
    if (className) {
        classNameList.push(
            className.split(' ').filter((className) => className.trim().length),
        );
    }

    return (
        <div className={classNameList.join(' ')}>
            <label className={'field-label'} htmlFor={id}>
                {label}
            </label>
            <MeasureInput
                id={id}
                required={Boolean(required)}
                aria-required={Boolean(required)}
                aria-invalid={Boolean(validationError)}
                aria-errormessage={idError}
                {...props}
            />
            <div id={idError} className={'validation-error'}>
                {validationError}
            </div>
        </div>
    );
}

export default FieldMeasure;
