export enum DocumentsOrderByEnum {
    MOST_RECENT = "MOST_RECENT",
    OLDEST = "OLDEST",
    ASCENDING = "ASCENDING",
    DESCENDING = "DESCENDING",
}

export type DocumentsOrderByEnumValue =
    (typeof DocumentsOrderByEnum)[keyof typeof DocumentsOrderByEnum];

export function DocumentsOrderByEnumToString(key: string): string {
    const orderDocumentsBy = DocumentsOrderByEnum[key];
    switch (orderDocumentsBy) {
        case DocumentsOrderByEnum.MOST_RECENT:
            // I18N
            return "Mais recente";
        case DocumentsOrderByEnum.OLDEST:
            // I18N
            return "Mais antigo";
        case DocumentsOrderByEnum.ASCENDING:
            // I18N
            return "A-Z";
        case DocumentsOrderByEnum.DESCENDING:
            // I18N
            return "Z-A";
        default:
            throw new Error("Invalid enum value");
    }
}
