// noinspection JSUnresolvedVariable

import './ScrollPagination.scss';
import Loading from './Loading';
import { useEffect, useRef } from 'react';

export function isElementVisible(element, container) {
    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    let elementTop = elementRect.top;
    let elementBottom = elementRect.bottom;
    let containerTop = containerRect.top;
    let containerBottom = containerRect.bottom;

    return elementTop < containerBottom && elementBottom > containerTop;
}

function ScrollPagination({
    onPageRequested,
    scrollElement,
    suspended = false,
}) {
    const divRef = useRef();

    function removeEvent() {
        scrollElement?.removeEventListener('scroll', checkScroll);
    }

    function installEvent() {
        scrollElement.addEventListener('scroll', checkScroll);
    }

    function checkScroll() {
        if (!divRef.current) return;
        if (isElementVisible(divRef.current, scrollElement)) {
            removeEvent();
            onPageRequested();
        }
    }

    useEffect(() => {
        if (scrollElement) {
            installEvent();
            checkScroll();
            return () => {
                removeEvent();
            };
        }
    }, []);

    useEffect(() => {
        if (suspended) {
            removeEvent();
        } else {
            if (scrollElement) {
                installEvent();
                checkScroll();
            }
        }
    }, [suspended, scrollElement]);

    return (
        <div ref={divRef} className={'scroll-pagination'}>
            <Loading />
        </div>
    );
}

export default ScrollPagination;
