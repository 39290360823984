export enum SaveDocumentValidationErrorEnum {
    DOCUMENT_NAME_ALREADY_IN_USE = "DOCUMENT_NAME_ALREADY_IN_USE",
    DOCUMENT_HYPHENATION_LETTERS_MIN_REQUIRED = "DOCUMENT_HYPHENATION_LETTERS_MIN_REQUIRED",
    DOCUMENT_HYPHENATION_SYLLABLES_MIN_REQUIRED = "DOCUMENT_HYPHENATION_SYLLABLES_MIN_REQUIRED",
    DOCUMENT_HYPHENATION_PARAGRAPH_MAX_REQUIRED = "DOCUMENT_HYPHENATION_PARAGRAPH_MAX_REQUIRED",
    TEMPLATE_NAME_REQUIRED = "TEMPLATE_NAME_REQUIRED",
    DOCUMENT_NAME_REQUIRED = "DOCUMENT_NAME_REQUIRED",
    AT_LEAST_ONE_USER_WITH_PERMISSION_REQUIRED = "AT_LEAST_ONE_USER_WITH_PERMISSION_REQUIRED",
    DOCUMENT_ALREADY_LOCKED_BY_ANOTHER_USER = "DOCUMENT_ALREADY_LOCKED_BY_ANOTHER_USER",
    INVALID_LOCK_ID = "INVALID_LOCK_ID",
    USER_REQUESTED_UNLOCK = "USER_REQUESTED_UNLOCK",
}

export function SaveDocumentValidationErrorTxt(key: string): string {
    const validationError = SaveDocumentValidationErrorEnum[key];
    switch (validationError) {
        case SaveDocumentValidationErrorEnum.DOCUMENT_NAME_ALREADY_IN_USE:
            // I18N
            return "O nome do documento informado já foi utilizado.";
        case SaveDocumentValidationErrorEnum.DOCUMENT_HYPHENATION_LETTERS_MIN_REQUIRED:
            // I18N
            return "A quantidade mínima de letras da hifenização não foi informada.";
        case SaveDocumentValidationErrorEnum.DOCUMENT_HYPHENATION_SYLLABLES_MIN_REQUIRED:
            // I18N
            return "A quantidade mínima de sílabas da hifenização não foi informada.";
        case SaveDocumentValidationErrorEnum.DOCUMENT_HYPHENATION_PARAGRAPH_MAX_REQUIRED:
            // I18N
            return "A quantidade máxima de letras por parágrafo da hifenização não foi informada.";
        case SaveDocumentValidationErrorEnum.TEMPLATE_NAME_REQUIRED:
            // I18N
            return "O nome do modelo é obrigatário.";
        case SaveDocumentValidationErrorEnum.DOCUMENT_NAME_REQUIRED:
            // I18N
            return "O nome do documento é obrigatário.";
        case SaveDocumentValidationErrorEnum.AT_LEAST_ONE_USER_WITH_PERMISSION_REQUIRED:
            // I18N
            return "É necessário ao menos um usuário com acesso ao documento.";
        case SaveDocumentValidationErrorEnum.DOCUMENT_ALREADY_LOCKED_BY_ANOTHER_USER:
            // I18N
            return "O documento está sendo editado por outro usuário.";
        case SaveDocumentValidationErrorEnum.USER_REQUESTED_UNLOCK:
            // I18N
            return "Um usuário solicitou a edição do documento";
        default:
            throw new Error("Invalid enum value");
    }
}
