import {
    createNewPage,
    fixPageTermination,
    getCurrentPage,
    getNextPage,
    getPages,
} from './core/PageManipulation';
import {
    getBrailleDocument,
    getRootNodeInPage,
    isEditorElement,
    isEditorElementParagraphBreak,
} from './core/EditorUtil';
import { getLineCount } from './core/CaretPath';
import { getPageCache } from './core/Cache';
import { markPageNeedsUpdate } from './ScrollModule';
import {
    getEditorElement,
    getEditorElementLinesCount,
    isEditorElementFixed,
    isElementContinuation,
} from './core/EditorElements';
import { isDebugEnabled } from './core/CoreModule';
import { isEditorBrailleView } from './core/BrailleView';

/**
 * @param page {HTMLElement | DocumentFragment}
 * @return {(HTMLElement | Node)[][]}
 */
export function getBrailleLinesElements(page) {
    /**
     * @type {HTMLElement[][]}
     */
    let lines = [];
    getLineCount(page, (lineCount, node) => {
        const i = lineCount;
        let array = lines[i];
        if (!array) {
            array = [];
            lines[i] = array;
        }
        if (!array.includes(node)) {
            array.push(node);
        }
    });

    for (let i = 0; i < lines.length; i++) {
        let array = lines[i];
        if (!array) {
            array = [];
            lines[i] = array;
        }
        const firstElement = array[0];
        if (
            i > 0 &&
            firstElement &&
            (firstElement.tagName === 'BR' ||
                isEditorElementParagraphBreak(firstElement))
        ) {
            lines[i - 1].push(firstElement);
            array.splice(0, 1);
        }
    }
    lines = lines.filter((array) => {
        return array.length;
    });
    return lines;
}

/**
 * @param editorElements {EditorElements}
 * @param page {HTMLElement | DocumentFragment | Node | null}
 * @param first {boolean}
 * @return {HTMLElement | null}
 */
export function getElementOfPageNotFixed(editorElements, page, first) {
    if (!page) return null;

    let pageEndBr = false;
    /**
     * @type {HTMLElement | Node}
     */
    let walk = first ? page.firstChild : page.lastChild;
    while (walk) {
        const editorElement = getEditorElement(walk);
        let isBr;
        if (
            isEditorElementFixed(editorElement) ||
            isEditorBrailleView(walk) ||
            ((isBr = walk?.tagName === 'BR') && !pageEndBr) ||
            (walk?.tagName === 'BR' && walk?.style.display === 'none') ||
            isEditorElementParagraphBreak(walk)
        ) {
            if (isBr) pageEndBr = true;
            walk = first ? walk.nextSibling : walk.previousSibling;
        } else {
            return walk;
        }
    }
    return null;
}

export class ExcessLinesControlModule {
    /**
     * @param {EditorCustom}
     */
    editor;
    /**
     * @param {EditorElements}
     */
    editorElements;
    /**
     * @param {BrailleDocument}
     */
    brailleDocument;
    disableInput = false;

    debug(...data) {
        if (isDebugEnabled()) {
            console.debug('[ExcessLinesControlModule]', ...data);
        }
    }

    /**
     * @param editor {EditorCustom}
     * @param editorElements {EditorElements}
     */
    constructor(editor, editorElements) {
        this.editor = editor;
        this.editorElements = editorElements;
        this.brailleDocument = getBrailleDocument(this.editor);
    }

    /**
     * @returns {BrailleDocument | null}
     */
    getBrailleDocument() {
        return getBrailleDocument(this.editor);
    }

    /**
     * @return {boolean}
     */
    isActive() {
        const brailleDocument = this.getBrailleDocument();
        return !!brailleDocument?.convertedToBraille;
    }

    /**
     * @param page {HTMLElement | null}
     * @param lineCount {number}
     */
    setLineCount(page, lineCount) {
        if (!lineCount) {
            page.removeAttribute('data-line-count');
        } else {
            page.setAttribute('data-line-count', lineCount.toString());
        }
    }

    /**
     * @param page {HTMLElement}
     * @return {number | null}
     */
    getLineCount(page) {
        const lineCount = parseInt(page.getAttribute('data-line-count'));
        if (isNaN(lineCount)) return null;
        return lineCount;
    }

    install() {
        const self = this;

        this.editor.on('beforeInput', () => {
            const page = getCurrentPage(self.editor);
            if (!page) return;
            const lineCount = getBrailleLinesElements(page).length;
            self.setLineCount(page, lineCount);
        });

        /**
         * @param e {BrailleDataChangedEvent}
         */
        const brailleDataChangedEvent = async (e) => {
            const { page } = e;
            if (!self.isActive()) {
                return;
            }
            const maxLines = self.getBrailleDocument().brailleCellRowCount;
            const previousLines = self.getLineCount(page);
            let lines = getBrailleLinesElements(page);

            const diff = previousLines - lines.length;
            let pulledLines;
            let pushedLines;
            if (diff > 0) {
                // some lines are remove, needs to pull from another page
                pulledLines = self.pullLinesFromNextPage(page);
            } else if (lines.length > maxLines) {
                pushedLines = this.pushExcessLinesToNextPage(page);
            }
            if (pushedLines || pulledLines) {
                const pageIdx = getPages(self.editor).indexOf(page) + 1;
                self.debug(`Current lines of page ${pageIdx}: ${lines.length}`);
                self.debug(
                    `Previous lines of page ${pageIdx}: ${previousLines}`,
                );
                if (pushedLines) {
                    self.debug(
                        `Lines pushed from ${pageIdx} to ${pageIdx + 1}: ${pushedLines}`,
                    );
                } else {
                    self.debug(
                        `Lines pulled from ${pageIdx + 1} to ${pageIdx}: ${pulledLines}`,
                    );
                }
                lines = getBrailleLinesElements(page);

                this.disableInput = true;
                await new Promise((resolve) => {
                    setTimeout(() => {
                        try {
                            self.editor.custom?.coreModule.brailleView.showBrailleView(
                                page,
                                true,
                            );
                        } finally {
                            resolve();
                        }
                    }, 300);
                });
                this.disableInput = false;
            }
            self.setLineCount(page, lines.length);
        };
        this.editor.on('brailleDataChanged', brailleDataChangedEvent);

        this.editor.on('beforeInput', (e) => {
            if (self.disableInput && e.inputType === 'insertLineBreak') {
                e.preventDefault();
            }
        });
    }

    /**
     * @param page {HTMLElement}
     * @return {number}
     */
    pullLinesFromNextPage(page) {
        const lines = getBrailleLinesElements(page);
        const { brailleCellRowCount } = getBrailleDocument(this.editor);
        const lineCount = brailleCellRowCount - lines.length;

        let nextPage = getNextPage(page);
        if (!nextPage || !lineCount) {
            return 0;
        }

        let linesToPull = lineCount;

        const nextPageContainer =
            getPageCache(this.editor, nextPage, false) ?? nextPage;
        const nextPageElements = getBrailleLinesElements(nextPageContainer);

        let pageLastElement = getElementOfPageNotFixed(
            this.editorElements,
            page,
            false,
        );

        const nextPageFirstElement = getElementOfPageNotFixed(
            this.editorElements,
            nextPageContainer,
            true,
        );

        const editorElement = getEditorElement(nextPageFirstElement);

        const nextPageElementLineCount = getLineCount(nextPageFirstElement);
        if (
            editorElement?.supportExcessLinesBetweenPages &&
            editorElement?.supportExcessLinesBetweenPages() &&
            nextPageElementLineCount
        ) {
            if (!isElementContinuation(pageLastElement, nextPageFirstElement)) {
                pageLastElement = null;
            }

            let lineCount = linesToPull;
            if (lineCount > nextPageElementLineCount) {
                lineCount = nextPageElementLineCount;
            }

            pageLastElement = editorElement.getExcessElement(
                this.editor,
                nextPageFirstElement,
                pageLastElement,
                lineCount,
                false,
            );
            page.append(pageLastElement);

            linesToPull -= lineCount;

            if (!editorElement.isEmpty(nextPageFirstElement)) {
                pageLastElement.setAttribute('data-has-continuation', 'true');
                nextPageFirstElement.setAttribute('data-continuation', 'true');
                nextPageFirstElement.setAttribute(
                    'data-continuation-element',
                    pageLastElement.getAttribute('id'),
                );
            } else {
                nextPageFirstElement.remove();
                pageLastElement?.removeAttribute('data-has-continuation');
            }
        }

        let lastElementOfPage = getElementOfPageNotFixed(
            this.editorElements,
            page,
            false,
        );
        for (
            let i = 0, pullCount = 0;
            i < nextPageElements.length && pullCount < linesToPull;
            i++
        ) {
            const paragraphElements = nextPageElements[i];
            if (!paragraphElements) break;
            for (const element of paragraphElements) {
                const editorElement = getEditorElement(element);
                if (isEditorElementFixed(editorElement)) {
                    break;
                }
                const rootNodeInPage = getRootNodeInPage(element);
                if (!rootNodeInPage) continue;
                const nodeLineCount =
                    getBrailleLinesElements(rootNodeInPage).length;
                if (
                    isEditorElement(rootNodeInPage) &&
                    nodeLineCount > linesToPull - pullCount
                ) {
                    pullCount = linesToPull;
                    break;
                }
                if (lastElementOfPage) {
                    lastElementOfPage.after(rootNodeInPage);
                } else {
                    page.append(rootNodeInPage);
                }
                lastElementOfPage = rootNodeInPage;
                pullCount++;
            }
        }

        if (lineCount) {
            markPageNeedsUpdate(page);
            markPageNeedsUpdate(nextPage);
            fixPageTermination(this.editor, page);
        }

        return lineCount;
    }

    /**
     * @param page {HTMLElement}
     * @param toNewPage {boolean | undefined}
     * @return {number}
     */
    pushExcessLinesToNextPage(page, toNewPage = false) {
        const excessLinesOfPage = this.removeExcessLines(page);
        for (let i = excessLinesOfPage.length - 1; i >= 0; i--) {
            const line = excessLinesOfPage[i];
            if (line.length === 1) {
                if (
                    line[0].tagName === 'BR' &&
                    line[0].style.display === 'none'
                ) {
                    excessLinesOfPage.pop();
                    continue;
                }
            }
            break;
        }

        if (!excessLinesOfPage.length) return 0;

        let nextPage = toNewPage ? null : getNextPage(page);
        if (!nextPage) {
            nextPage = createNewPage(this.editor);
            page.after(nextPage);
        }

        const excessContainer = document.createDocumentFragment();

        excessLinesOfPage
            .slice()
            .reverse()
            .forEach((row) => {
                row.slice()
                    .reverse()
                    .forEach((line) => excessContainer.prepend(line));
            });

        const nextPageContainer =
            getPageCache(this.editor, nextPage, false) ?? nextPage;
        let firstElementNotFixed = getElementOfPageNotFixed(
            this.editorElements,
            nextPageContainer,
            true,
        );
        if (firstElementNotFixed) {
            firstElementNotFixed.before(excessContainer);
        } else {
            nextPageContainer.append(excessContainer);
        }

        markPageNeedsUpdate(nextPage);
        return excessLinesOfPage.length;
    }

    /**
     * @param page {HTMLElement}
     * @return {HTMLElement[][]}
     */
    removeExcessLines(page) {
        let brailleLinesElements = getBrailleLinesElements(page);
        const originalLength = brailleLinesElements.length;
        brailleLinesElements = brailleLinesElements.filter((line) => {
            let addLine = true;
            for (const element of line) {
                if (isEditorElement(element)) {
                    const editorElement = getEditorElement(element);
                    if (editorElement && isEditorElementFixed(editorElement)) {
                        addLine = false;
                        break;
                    }
                }
            }
            return addLine;
        });
        const brailleDocument = this.getBrailleDocument();
        let maxRows =
            brailleDocument.brailleCellRowCount -
            (originalLength - brailleLinesElements.length);

        /**
         * @type {HTMLElement[][]}
         */
        const excessLines = [];
        for (let i = maxRows; i < brailleLinesElements.length; i++) {
            const lineElements = brailleLinesElements[i];
            /**
             * @type {HTMLElement[]}
             */
            let currentLine = [];
            for (const element of lineElements) {
                const rootNodeInPage = getRootNodeInPage(element);
                if (!rootNodeInPage) continue;
                currentLine.push(rootNodeInPage);
            }
            excessLines.push(currentLine);
        }
        const lastPageElement = getElementOfPageNotFixed(
            this.editorElements,
            page,
            false,
        );
        let lastPageEditorElement = getEditorElement(lastPageElement);
        if (
            lastPageEditorElement?.supportExcessLinesBetweenPages &&
            lastPageEditorElement.supportExcessLinesBetweenPages(
                lastPageElement,
            )
        ) {
            /**
             * @type {number[]}
             */
            let lines = [];
            for (const [i, line] of excessLines.entries()) {
                const element = line[0];
                if (element === lastPageElement) {
                    lines.push(i);
                } else {
                    break;
                }
            }

            if (!lines.length) return excessLines;

            let firstElementNextPage = getElementOfPageNotFixed(
                this.editorElements,
                getNextPage(page),
                true,
            );
            if (
                !firstElementNextPage ||
                !isElementContinuation(lastPageElement, firstElementNextPage)
            ) {
                firstElementNextPage = null;
            }

            const replacement = lastPageEditorElement.getExcessElement(
                this.editor,
                lastPageElement,
                firstElementNextPage,
                lines.length,
                true,
            );
            if (!lastPageEditorElement.isEmpty(lastPageElement)) {
                lastPageElement.setAttribute('data-has-continuation', 'true');
                replacement.setAttribute('data-continuation', 'true');
                replacement.setAttribute(
                    'data-continuation-element',
                    lastPageElement.getAttribute('id'),
                );
                page.append(lastPageElement);
            } else {
                lastPageElement?.removeAttribute('data-has-continuation');
                replacement?.removeAttribute('data-continuation');
                replacement?.removeAttribute('data-continuation-element');
            }
            for (const line of lines) {
                excessLines[line][0] = replacement;
            }
        } else if (lastPageEditorElement) {
            let lineCount = getEditorElementLinesCount(lastPageElement);
            if (!lineCount == null) {
                lineCount = getEditorElementLinesCount(lastPageElement);
            }
            if (lineCount > maxRows) {
                console.warn(
                    'Element does not support excess lines and does not fit in any page.',
                    lastPageElement,
                );
                return [];
            }
        }
        return excessLines;
    }
}
