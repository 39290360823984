import { executeInspection } from '../InspectionExecutor';
import { RevisionGravityEnum } from '../RevisionGravityEnum';
import { RevisionErrorEnum } from '../RevisionErrorEnum';

/**
 * @implements {RevisionCheck}
 */
export class InvalidUseOfApostrophe {
    /**
     * @type {Worker | any}
     */
    worker = null;

    /**
     * @param {number} pageNumber
     * @param {HTMLElement | Node} page
     * @return {Promise<import('RevisionRecord').RevisionRecord[]>}
     */
    async check(pageNumber, page) {
        if (!this.worker) {
            this.worker = new Worker(
                new URL('./InvalidUseOfApostrophe.worker.js', import.meta.url),
            );
        }
        return await executeInspection(
            this.worker,
            page,
            pageNumber,
            RevisionGravityEnum.ERROR,
            RevisionErrorEnum.INVALID_USE_OF_APOSTROPHE,
        );
    }

    async releaseWorker() {
        if (!this.worker) return;
        this.worker.terminate();
        this.worker = null;
    }
}
