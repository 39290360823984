/**
 * Source: https://sgm.codebit.com.br/manutencao/45114
 * @implements {DocumentUpdateVersion}
 */
export class Version1 {
    /**
     * @returns {number}
     */
    version() {
        return 1;
    }

    /**
     * @param node {Node | HTMLElement}
     * @private
     */
    _updateRecursively(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            const bulletCount = node.textContent.split('∙').length - 1;
            // const spaceCount = node.textContent.split(' ').length - 1;
            const startsWithBullet = node.textContent.trim().startsWith('∙ ');
            if (!startsWithBullet || (startsWithBullet && bulletCount > 1)) {
                // console.debug(`Replacing bullets to spaces: ${node.textContent}`);
                node.textContent = node.textContent.replaceAll('∙', ' ');
            }
        } else {
            for (let child of node.childNodes) {
                this._updateRecursively(child);
            }
        }
    }

    /**
     * @param editor {EditorCustom}
     * @param page {HTMLElement}
     * @return {Promise<void>}
     */
    async updatePage(editor, page) {
        this._updateRecursively(page);
    }
}
