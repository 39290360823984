/**
 * Source: https://sgm.codebit.com.br/manutencao/46402
 * @implements {DocumentUpdateVersion}
 */
export class Version2 {
    version() {
        return 2;
    }

    /**
     * @param node {Node | HTMLElement}
     * @private
     */
    _updateRecursively(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            node.textContent = node.textContent.replaceAll('  ', '\u00A0 ');
        } else {
            for (let child of node.childNodes) {
                this._updateRecursively(child);
            }
        }
    }

    /**
     *
     * @param editor {EditorCustom}
     * @param page {HTMLElement}
     */
    async updatePage(editor, page) {
        this._updateRecursively(page);
    }
}
