import { RoleEnum, DocumentStatusEnum } from 'plataforma-braille-common';

/**
 * @param readOnly {boolean}
 * @return {boolean}
 */
export function userCanSave(readOnly) {
    return !readOnly;
}

/**
 * @param isAdmin
 * @param isEvaluator
 * @param isModerator
 * @param isOwner
 * @return {boolean}
 */
export function userCanRemove(isAdmin, isEvaluator, isModerator, isOwner) {
    return isAdmin || isEvaluator || (isModerator && isOwner);
}

/**
 * @param isAdmin {boolean}
 * @param isEvaluator {boolean}
 * @param isModerator {boolean}
 * @param isOwner {boolean}
 * @param readOnly {boolean}
 * @param userDocumentRoles {import('plataforma-braille-common').RoleEnumValue[]}
 * @return {boolean}
 */
export function userCanEditDocumentPreferences(
    isAdmin,
    isEvaluator,
    isModerator,
    isOwner,
    readOnly,
    userDocumentRoles,
) {
    if (readOnly) {
        return false;
    }

    if (isAdmin || isEvaluator || isModerator || isOwner) {
        return true;
    }

    // remove low access roles
    userDocumentRoles = [...userDocumentRoles].filter(
        (record) => ![RoleEnum.PRINT_SHOP.toString()].includes(record),
    );

    if (
        userDocumentRoles.includes(RoleEnum.DESCRIPTION) &&
        userDocumentRoles.length === 1
    ) {
        return false;
    }

    return !!userDocumentRoles.length;
}

/**
 * @param isAdmin {boolean}
 * @param isEvaluator {boolean}
 * @param isModerator {boolean}
 * @param isOwner {boolean}
 * @param readOnly {boolean}
 * @param userDocumentRoles {import('plataforma-braille-common').RoleEnumValue[]}
 * @return {boolean}
 */
export function userCanImportPdf(
    isAdmin,
    isEvaluator,
    isModerator,
    isOwner,
    readOnly,
    userDocumentRoles,
) {
    return userCanEditDocumentPreferences(
        isAdmin,
        isEvaluator,
        isModerator,
        isOwner,
        readOnly,
        userDocumentRoles,
    );
}

/**
 * @param isAdmin {boolean}
 * @param isEvaluator {boolean}
 * @param isModerator {boolean}
 * @param isOwner {boolean}
 * @param readOnly {boolean}
 * @param userDocumentRoles {import('plataforma-braille-common').RoleEnumValue[]}
 * @return {boolean}
 */
export function userCanRenameDocument(
    isAdmin,
    isEvaluator,
    isModerator,
    isOwner,
    readOnly,
    userDocumentRoles,
) {
    return userCanEditDocumentPreferences(
        isAdmin,
        isEvaluator,
        isModerator,
        isOwner,
        readOnly,
        userDocumentRoles,
    );
}

/**
 * @param isAdmin {boolean}
 * @param isEvaluator {boolean}
 * @param isModerator {boolean}
 * @return {boolean}
 */
export function userCanChangeDocumentStatus(isAdmin, isEvaluator, isModerator) {
    return isAdmin || isEvaluator || isModerator;
}

/**
 * @param isAdmin {boolean}
 * @param isEvaluator {boolean}
 * @param isModerator {boolean}
 * @param userDocumentRoles {import('plataforma-braille-common').RoleEnumValue[]}
 * @param status {DocumentStatusEnum | string}
 * @param isPrintShop {boolean}
 * @return {boolean}
 */
export function userCanShareDocument(
    isAdmin,
    isEvaluator,
    isModerator,
    isPrintShop,
    userDocumentRoles,
    status,
) {
    /**
     * @type {DocumentStatusEnum[] | string[]}
     */
    const printShopStatus = [
        DocumentStatusEnum.EDITORIAL_COMPLETED,
        DocumentStatusEnum.PRINTING_APPROVAL,
    ];
    return (
        !(isPrintShop && printShopStatus.includes(status)) ||
        isAdmin ||
        isEvaluator ||
        isModerator ||
        userDocumentRoles.length
    );
}

/**
 * @param isAdmin {boolean}
 * @param isEvaluator {boolean}
 * @param isPrintShop {boolean}
 * @param userDocumentRoles {import('plataforma-braille-common').RoleEnumValue[]}
 * @return {boolean}
 */
export function userCanExport(
    isAdmin,
    isEvaluator,
    isPrintShop,
    userDocumentRoles,
) {
    // permissions are disabled temporarily (requested by Nagila via Chat)
    // return isAdmin || isEvaluator || isPrintShop || userDocumentRoles.includes(RoleEnum.LAYOUT);
    return true;
}

/**
 * @param user
 * @param document {DocumentDto}
 * @return {import('plataforma-braille-common').RoleEnumValue[]}
 */
export function getUserRolesFromDocument(user, document) {
    return (
        document.userWithAccess.find((record) => record.id === user.id)
            ?.roles ?? []
    );
}

/**
 * @param isAdmin {boolean}
 * @return {boolean}
 */

export function userCanChangeDictionary(isAdmin) {
    return isAdmin;
}
