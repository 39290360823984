import { RoleEnum, RoleEnumToString } from 'plataforma-braille-common';
import FieldCheckbox from './FieldCheckbox';

function FieldRole({
    className,
    label,
    roles = [],
    onChange,
    validationError,
}) {
    let list = [];

    const aloneRoles = [
        RoleEnum.ADMINISTRATOR,
        RoleEnum.EVALUATION,
        RoleEnum.MODERATION,
    ];

    function updateRoles(key) {
        let rolesSelected = new Set(roles);
        if (rolesSelected.has(key)) {
            rolesSelected.delete(key);
        } else {
            rolesSelected.add(key);
        }

        for (const role of aloneRoles) {
            if (rolesSelected.has(role)) {
                rolesSelected = new Set([role]);
                break;
            }
        }
        onChange({ roles: Array.from(rolesSelected) });
    }

    const allRoles = Object.entries(RoleEnum);
    let aloneRolesCount = 0;
    let isAdmin = false;
    let isEvaluator = false;
    let isModerator = false;
    if (roles.includes(RoleEnum.ADMINISTRATOR)) {
        isAdmin = true;
        aloneRolesCount++;
    }
    if (roles.includes(RoleEnum.EVALUATION)) {
        isEvaluator = true;
        aloneRolesCount++;
    }
    if (roles.includes(RoleEnum.MODERATION)) {
        isModerator = true;
        aloneRolesCount++;
    }

    for (const [key] of allRoles) {
        let inputData = {
            // I18N
            label: RoleEnumToString(key),
            onChange: () => updateRoles(key),
            checked: roles.includes(key),
        };
        if (
            aloneRolesCount === 1 &&
            ((isAdmin && key !== RoleEnum.ADMINISTRATOR) ||
                (isEvaluator && key !== RoleEnum.EVALUATION) ||
                (isModerator && key !== RoleEnum.MODERATION))
        ) {
            inputData['disabled'] = true;
        }
        list.push(inputData);
    }

    return (
        <FieldCheckbox
            className={className}
            label={label}
            inputs={list}
            validationError={validationError}
        />
    );
}

export default FieldRole;
