import {
    isInsideEditorBrailleView,
    isMultipleSelection,
    preventScroll,
} from './core/EditorUtil';

import { isDebugEnabled } from './core/CoreModule';

export class MouseModule {
    /**
     * @param editor {EditorCustom}
     */
    constructor(editor) {
        this.editor = editor;
    }

    debug(...data) {
        if (isDebugEnabled()) {
            console.debug('[MouseModule]', ...data);
        }
    }

    mouseUp() {
        if (isMultipleSelection(this.editor)) return;
        this.editor.custom.keyboardModule?.fixCaretPosition();
    }

    /**
     * @param e {DragEvent}
     */
    dragStart(e) {
        if (isInsideEditorBrailleView(e.srcElement)) {
            this.debug('Drag of editor braille view aborted.');
            e.preventDefault();
        }
    }

    /**
     * the drop events generate a strange scroll to top: the preventDefault solves,
     * buts don't let the image drops
     *
     * for now, the setTimeout is the savior while root cause is not detected/solved
     */
    drop(e) {
        /**
         * @type {HTMLElement | null}
         */
        const targetElement = e.target;
        if (['HTML', 'BODY', 'EDITOR-PAGE'].includes(targetElement?.tagName)) {
            const start = new Date().getTime();
            const self = this;
            const preventing = () => {
                preventScroll(self.editor);
                if (new Date().getTime() - start < 100) {
                    setTimeout(preventing, 10);
                }
            };
            preventing();
        }
    }

    install() {
        this.editor.on('mouseUp', () => this.mouseUp());
        const self = this;
        this.editor.on('DragStart', function (e) {
            self.dragStart(e);
        });
        /**
         * this avoids a strange behavior: when cursor is inside an element and
         * clicks outside the page, the page scrolls at start
         */
        this.editor.getDoc().addEventListener('mousedown', (e) => {
            /**
             * @type {HTMLElement | null}
             */
            const targetElement = e.target;
            // console.debug(targetElement);
            if (
                ['HTML', 'BODY', 'EDITOR-PAGE'].includes(targetElement?.tagName)
            ) {
                preventScroll(this.editor);
            }
        });

        this.editor.on('Drop', (e) => this.drop(e));
    }
}
