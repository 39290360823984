export enum OrientationEnum {
    PORTRAIT = "PORTRAIT",
    LANDSCAPE = "LANDSCAPE",
}

export type OrientationEnumValue =
    (typeof OrientationEnum)[keyof typeof OrientationEnum];

export function OrientationEnumToString(key: string): string {
    const orientation = OrientationEnum[key];
    switch (orientation) {
        // I18N
        case OrientationEnum.LANDSCAPE:
            return "Paisagem";
        // I18N
        case OrientationEnum.PORTRAIT:
            return "Retrato";
        default:
            throw new Error("Invalid enum value");
    }
}
