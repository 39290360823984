/**
 * @param {string} txt
 * @return {Promise<{match: RegExpExecArray, lastIndex: number}[]>}
 */
export async function findMathExpressions(txt) {
    const worker = new Worker(
        new URL('./FindMathExpressions.worker.js', import.meta.url),
    );

    try {
        return await new Promise((resolve, reject) => {
            worker.postMessage({ txt });
            const timeout = setTimeout(() => {
                worker?.terminate();
                reject(`Timeout finding math expressions on string: ${txt}`);
            }, 3000);
            worker.onmessage = (result) => {
                clearTimeout(timeout);
                resolve(result.data);
            };
            worker.onerror = (error) => {
                clearTimeout(timeout);
                reject(error.error);
            };
        });
    } finally {
        worker.terminate();
    }
}
