import './DocumentModal.scss';
import CloseModalButton from '../components/modal/CloseModalButton';
import {
    getDocumentUrl,
    SaveDocumentValidationErrorTxt,
    SaveTemplateValidationErrorEnum,
    SaveTemplateValidationErrorTxt,
    isDocumentTypeImageDescription,
} from 'plataforma-braille-common';
import { unmask } from '../components/MeasureInput';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import * as DocumentService from '../services/DocumentService';
import * as TemplatesService from '../services/TemplatesService';
import TemplateDetailModal from './TemplateDetailModal';
import DocumentModalTemplates from './DocumentModalTemplates';
import DocumentModalForm from './DocumentModalForm';
import { ModalClose, ModalClose2x } from '../components/images';
import ImportPdf from '../components/ImportPdf';
import { useNavigate } from 'react-router-dom';
import {
    prepareImageElementsToRetrieveImagesDescriptionsBackground,
    uploadImages,
} from '../edit-document/EditDocument';

/**
 * @typedef {object} FormData
 * @property {import('plataforma-braille-common').DocumentTypeEnumValue | null | undefined} type
 * @property {string | null | undefined} name
 * @property {import('plataforma-braille-common').MeasureEnumValue | null | undefined} pageMeasure
 * @property {number | null | undefined} pageWidth
 * @property {number | null | undefined} pageHeight
 * @property {import('plataforma-braille-common').OrientationEnumValue | null | undefined} pageOrientation
 * @property {number | null | undefined} pageLimit
 * @property {number | null | undefined} pageCount
 * @property {boolean | null | undefined} interPoint
 * @property {number | null | undefined} brailleCellColCount
 * @property {number | null | undefined} brailleCellRowCount
 * @property {number | null | undefined} braillePageMarginLeft
 * @property {number | null | undefined} braillePageMarginTop
 * @property {number | null | undefined} braillePageMarginRight
 * @property {number | null | undefined} braillePageMarginBottom
 * @property {boolean | null | undefined} braillePageMarginLocked
 * @property {number | null | undefined} inkPageMarginLeft
 * @property {number | null | undefined} inkPageMarginTop
 * @property {number | null | undefined} inkPageMarginRight
 * @property {number | null | undefined} inkPageMarginBottom
 * @property {boolean | null | undefined} inkPageMarginLocked
 * @property {FontNameEnumValue | null | undefined} inkFontType
 * @property {number | null | undefined} inkFontSize
 * @property {number | null | undefined} inkPageLineHeight
 * @property {boolean | null | undefined} hyphenation
 * @property {number | null | undefined} hyphenationLettersMin
 * @property {number | null | undefined} hyphenationSyllablesMin
 * @property {number | null | undefined} hyphenationParagraphMax
 * @property {number | null | undefined} hyphenationDistanceBetweenHyphens
 * @property {boolean | null | undefined} templateCreate
 * @property {string | null | undefined} templateName
 * @property {UserWithAccessDto | null | undefined} userWithAccess
 */

/**
 * @typedef {object} FormError
 * @property {string | null | undefined} type
 * @property {string | null | undefined} name
 * @property {string | null | undefined} pageMeasure
 * @property {string | null | undefined} pageWidth
 * @property {string | null | undefined} pageHeight
 * @property {string | null | undefined} pageOrientation
 * @property {string | null | undefined} pageLimit
 * @property {string | null | undefined} pageCount
 * @property {string | null | undefined} interPoint
 * @property {string | null | undefined} brailleCellColCount
 * @property {string | null | undefined} brailleCellRowCount
 * @property {string | null | undefined} braillePageMarginLeft
 * @property {string | null | undefined} braillePageMarginTop
 * @property {string | null | undefined} braillePageMarginRight
 * @property {string | null | undefined} braillePageMarginBottom
 * @property {string | null | undefined} braillePageMarginLocked
 * @property {string | null | undefined} inkPageMarginLeft
 * @property {string | null | undefined} inkPageMarginTop
 * @property {string | null | undefined} inkPageMarginRight
 * @property {string | null | undefined} inkPageMarginBottom
 * @property {string | null | undefined} inkPageMarginLocked
 * @property {string | null | undefined} inkFontType
 * @property {string | null | undefined} inkFontSize
 * @property {string | null | undefined} inkPageLineHeight
 * @property {string | null | undefined} hyphenation
 * @property {string | null | undefined} hyphenationLettersMin
 * @property {string | null | undefined} hyphenationSyllablesMin
 * @property {string | null | undefined} hyphenationParagraphMax
 * @property {string | null | undefined} hyphenationDistanceBetweenHyphens
 * @property {string | null | undefined} templateName
 */

/**
 * @param formData {FormData}
 * @param document {boolean}
 * @return {{hasErrors: boolean, errors: FormError}}
 */
export function hasValidationErrorDocument(formData, document = true) {
    let errors = {};
    let hasErrors = false;

    if (document && !formData.type) {
        // I18N
        errors.type = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (document && (!formData.name || formData.name.trim() === '')) {
        // I18N
        errors.name = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (!formData.pageMeasure) {
        // I18N
        errors.pageMeasure = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.pageWidth))) {
        // I18N
        errors.pageWidth = 'Campo obrigatório.';
        hasErrors = true;
    } else if (parseInt(formData.pageWidth) === 0) {
        // I18N
        errors.pageWidth = 'O valor deve ser diferente de zero.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.pageHeight))) {
        // I18N
        errors.pageHeight = 'Campo obrigatório.';
        hasErrors = true;
    } else if (parseInt(formData.pageHeight) === 0) {
        // I18N
        errors.pageHeight = 'O valor deve ser diferente de zero.';
        hasErrors = true;
    }
    if (!formData.pageOrientation) {
        // I18N
        errors.pageOrientation = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.pageLimit))) {
        // I18N
        errors.pageLimit = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.brailleCellColCount))) {
        // I18N
        errors.brailleCellColCount = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.brailleCellRowCount))) {
        // I18N
        errors.brailleCellRowCount = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (!formData.inkFontType) {
        // I18N
        errors.inkFontType = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (!formData.inkFontSize) {
        // I18N
        errors.inkFontSize = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.braillePageMarginLeft))) {
        // I18N
        errors.braillePageMarginLeft = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.braillePageMarginTop))) {
        // I18N
        errors.braillePageMarginTop = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.braillePageMarginRight))) {
        // I18N
        errors.braillePageMarginRight = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.braillePageMarginBottom))) {
        // I18N
        errors.braillePageMarginBottom = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.inkPageMarginLeft))) {
        // I18N
        errors.inkPageMarginLeft = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.inkPageMarginTop))) {
        // I18N
        errors.inkPageMarginTop = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.inkPageMarginRight))) {
        // I18N
        errors.inkPageMarginRight = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.inkPageMarginBottom))) {
        // I18N
        errors.inkPageMarginBottom = 'Campo obrigatório.';
        hasErrors = true;
    }
    if (isNaN(parseInt(formData.inkPageLineHeight))) {
        // I18N
        errors.inkPageLineHeight = 'Campo obrigatório.';
        hasErrors = true;
    }
    return { hasErrors, errors };
}

/**
 * @param formData {FormData}
 * @return {{hasErrors: boolean, errors: FormError}}
 */
export function hasValidationErrorHyphenation(formData) {
    let errors = {};
    let hasErrors = false;
    if (formData.hyphenation) {
        if (isNaN(parseInt(formData.hyphenationLettersMin))) {
            // I18N
            errors.hyphenationLettersMin = 'Campo obrigatório.';
            hasErrors = true;
        } else if (parseInt(formData.hyphenationLettersMin) < 1) {
            // I18N
            errors.hyphenationLettersMin = 'O valor mínimo é 1.';
            hasErrors = true;
        }
        if (isNaN(parseInt(formData.hyphenationSyllablesMin))) {
            // I18N
            errors.hyphenationSyllablesMin = 'Campo obrigatório.';
            hasErrors = true;
        } else if (parseInt(formData.hyphenationSyllablesMin) < 1) {
            // I18N
            errors.hyphenationSyllablesMin = 'O valor mínimo é 1.';
            hasErrors = true;
        }
        if (isNaN(parseInt(formData.hyphenationParagraphMax))) {
            // I18N
            errors.hyphenationParagraphMax = 'Campo obrigatório.';
            hasErrors = true;
        } else if (parseInt(formData.hyphenationParagraphMax) < 1) {
            // I18N
            errors.hyphenationParagraphMax = 'O valor mínimo é 1.';
            hasErrors = true;
        }
        if (isNaN(parseInt(formData.hyphenationDistanceBetweenHyphens))) {
            // I18N
            errors.hyphenationDistanceBetweenHyphens = 'Campo obrigatório.';
            hasErrors = true;
        } else if (parseInt(formData.hyphenationDistanceBetweenHyphens) < 1) {
            // I18N
            errors.hyphenationDistanceBetweenHyphens = 'O valor mínimo é 1.';
            hasErrors = true;
        }
    }
    return { hasErrors, errors };
}

function DocumentModal(props) {
    const { show, importPdf } = props;
    /**
     * @type {React.MutableRefObject<DocumentModalTemplatesFunctions | null>}
     */
    const templatesRef = useRef(null);
    /**
     * @type {React.MutableRefObject<ImportPdfFunctions | null>}
     */
    const importPdfRef = useRef(null);
    const navigate = useNavigate();

    const {
        setLoading,
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
    } = useContext(EnvironmentContext);

    /**
     * @type {FormData}
     */
    const initialFormData = {};
    const [formData, setFormData] = useState(initialFormData);

    /**
     * @type {FormError}
     */
    const initialFormError = {};
    const [formError, setFormError] = useState(initialFormError);

    const [filename, setFilename] = useState(null);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [saveTemplateLoading, setSaveTemplateLoading] = useState(false);
    const [importPdfLoading, setImportPdfLoading] = useState(false);
    const [importPdfProgress, setImportPdfProgress] = useState('0%');
    const [templateDetail, setTemplateDetail] = useState({
        template: null,
        show: false,
    });

    function updateFormData(obj) {
        setFormData((oldData) => {
            return { ...oldData, ...obj };
        });
    }

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    async function fetchTemplates() {
        templatesRef.current?.fetch();
    }

    const modalTitle = !importPdf ? 'Criar novo documento' : 'Importar PDF';

    function hasValidationError(document = true) {
        const documentValidation = hasValidationErrorDocument(
            formData,
            document,
        );
        const hyphenationValidation = hasValidationErrorHyphenation(formData);
        let hasErrors =
            documentValidation.hasErrors || hyphenationValidation.hasErrors;
        const errors = {
            ...documentValidation.errors,
            ...hyphenationValidation.errors,
        };

        if (formData.templateCreate) {
            if (!formData.templateName || formData.templateName.trim() === '') {
                // I18N
                errors.predefinitionName = 'Campo obrigatório.';
                hasErrors = true;
            }
        }

        if (document) setValidateOnChange(true);
        setFormError(errors);
        return hasErrors;
    }

    const [saveDocumentLoading, setSaveDocumentLoading] = useState(false);

    useEffect(() => {
        setLoading(saveDocumentLoading || saveTemplateLoading, true);
    }, [saveDocumentLoading, saveTemplateLoading]);

    useEffect(() => {
        if (importPdf) {
            importPdfRef.current?.openFile();
        }
    }, [importPdf, importPdfRef]);

    async function saveTemplate(e) {
        e.preventDefault();
        if (hasValidationError(false)) return;
        // I18N
        const title = 'Salvar predefinição';

        try {
            setSaveTemplateLoading(true);
            await TemplatesService.saveTemplate(formData);
            setInfoModal({
                // I18N
                title: 'Salvar predefinição',
                // I18N
                message: 'Predefinição salva com sucesso!',
                show: true,
                onClose: fetchTemplates,
            });
        } catch (e) {
            backendConnectionError(
                'Fail to save template',
                e,
                null,
                title,
                SaveTemplateValidationErrorTxt,
            );
        } finally {
            setSaveTemplateLoading(false);
        }
    }

    async function createDocument(e) {
        e.preventDefault();
        if (hasValidationError()) return;
        // I18N
        const title = 'Criar novo documento';

        if (importPdf && !formData.html) {
            setInfoModal({
                // I18N
                title,
                message:
                    'Nenhum PDF selecionado. Envie um arquivo e tente novamente.',
                show: true,
            });
            return;
        }

        try {
            setSaveDocumentLoading(true);
            const auxDiv = document.createElement('div');
            auxDiv.innerHTML = formData.html ?? '';
            await uploadImages(setLoading, (selector) =>
                auxDiv.querySelectorAll(selector),
            );
            if (isDocumentTypeImageDescription(formData.type)) {
                prepareImageElementsToRetrieveImagesDescriptionsBackground(
                    (domQuery) => [...auxDiv.querySelectorAll(domQuery)],
                );
            }
            const dto = {
                ...formData,
                html: auxDiv.innerHTML,
            };
            const newDocument = await DocumentService.createDocument(dto);
            if (isDocumentTypeImageDescription(formData.type)) {
                await DocumentService.retrieveImagesDescriptionsBackground(
                    newDocument.id,
                );
            }
            redirectNewDocument(newDocument);
        } catch (e) {
            backendConnectionError(
                'Fail to create document.',
                e,
                null,
                title,
                SaveDocumentValidationErrorTxt,
            );
        } finally {
            setSaveDocumentLoading(false);
        }
    }

    function redirectNewDocument(response) {
        navigate(getDocumentUrl({ id: response.id, name: formData.name }));
    }

    useEffect(() => {
        if (!show) {
            importPdfRef.current?.abort().then();
            setTimeout(() => {
                setValidateOnChange(false);
                setFormData(initialFormData);
                setFormError({});
                setImportPdfLoading(false);
                setImportPdfProgress('0%');
                setFilename(null);
                templatesRef.current?.reset();
            }, 200); // clear the form after CSS transition (200ms)
        }
    }, [show]);

    function closeTemplateDetailModal() {
        setTemplateDetail((templateDetail) => {
            return { ...templateDetail, show: false };
        });
    }

    function updateInkPageMargin(value) {
        updateFormData({
            inkPageMarginLeft: unmask(value),
            inkPageMarginTop: unmask(value),
            inkPageMarginRight: unmask(value),
            inkPageMarginBottom: unmask(value),
        });
    }

    function updateBraillePageMargin(value) {
        updateFormData({
            braillePageMarginLeft: unmask(value),
            braillePageMarginTop: unmask(value),
            braillePageMarginRight: unmask(value),
            braillePageMarginBottom: unmask(value),
        });
    }

    async function duplicateTemplate(template) {
        // I18N
        const title = 'Duplicar';
        // I18N
        const copyName = `Cópia de ${template.name}`;

        try {
            setLoading(true);
            // I18N
            await TemplatesService.saveTemplate({
                ...template,
                templateName: copyName,
                id: undefined,
            });
            setInfoModal({
                title,
                // I18N
                message: `Predefinição duplicada com o nome "${copyName}".`,
                show: true,
                onClose: fetchTemplates,
            });
            // noinspection ES6MissingAwait
        } catch (e) {
            if (
                e.response?.status === 422 &&
                e.response?.data?.errors.length &&
                e.response?.data?.errors[0] ===
                    SaveTemplateValidationErrorEnum.TEMPLATE_NAME_ALREADY_IN_USE
            ) {
                template.name = `Cópia de ${copyName}`;
                return await duplicateTemplate(template);
            }

            backendConnectionError(
                'Fail to duplicate template.',
                e,
                null,
                title,
                SaveTemplateValidationErrorTxt,
            );
        } finally {
            setLoading(false);
        }
    }

    async function removeTemplate(template) {
        try {
            setConfirmModal({
                // I18N
                title: 'Remover',
                // I18N
                message: `Tem certeza que deseja remover a predefinição "${template.name.trim()}"?`,
                show: true,
                onConfirm: async () => {
                    await TemplatesService.removeTemplate(template.id);
                    // noinspection ES6MissingAwait
                    fetchTemplates();
                },
            });
        } catch (e) {
            setLoading(false);
            backendConnectionError('Fail to remove template.', e);
        }
    }

    const documentForm = useMemo(() => {
        return (
            <DocumentModalForm
                title={modalTitle}
                formError={formError}
                formData={formData}
                updateFormData={updateFormData}
                updateBraillePageMargin={updateBraillePageMargin}
                updateInkPageMargin={updateInkPageMargin}
                saveTemplateLoading={saveTemplateLoading}
                saveTemplate={saveTemplate}
                saveDocumentLoading={saveDocumentLoading}
                createDocument={importPdfLoading ? null : createDocument}
            />
        );
    }, [
        formData,
        formError,
        saveDocumentLoading,
        saveTemplateLoading,
        importPdfLoading,
    ]);

    let importPdfWidgetsClassList = ['primary'];
    if (importPdfLoading) {
        importPdfWidgetsClassList.push('loading');
    } else if (filename) {
        importPdfWidgetsClassList.push('file');
    }
    const importPdfWidgets = importPdf ? (
        <div style={{ display: 'flex' }}>
            <button
                className={importPdfWidgetsClassList.join(' ')}
                data-progress={importPdfProgress}
                onClick={() => {
                    if (importPdfLoading) return;
                    if (!filename) {
                        importPdfRef.current?.openFile();
                    } else {
                        setImportPdfLoading(false);
                        setImportPdfProgress('0%');
                        updateFormData({ html: null });
                        setFilename(null);
                    }
                }}
            >
                {filename ?? 'Selecionar arquivo'}
                <img
                    src={`${ModalClose}`}
                    srcSet={`${ModalClose} 1x, ${ModalClose2x} 2x`}
                    width={14}
                    height={14}
                    style={{
                        display:
                            !importPdfLoading && filename ? 'unset' : 'none',
                    }}
                    // I18N
                    alt={'Fechar'}
                />
            </button>
        </div>
    ) : (
        <></>
    );

    return (
        <>
            <ImportPdf
                ref={importPdfRef}
                onLoading={(loading) => {
                    setImportPdfLoading(loading);
                }}
                onError={(error, defaultErrorHandlingFn) => {
                    defaultErrorHandlingFn();
                    updateFormData({ html: null });
                    setFilename(null);
                    setImportPdfLoading(false);
                }}
                onPdfLoaded={({ fileName, html }) => {
                    setFilename(fileName);
                    updateFormData({ html });
                }}
                onProgress={(current, total) => {
                    const progress = Math.round((current / total) * 100);
                    setImportPdfProgress(`${progress}%`);
                }}
                documentType={formData.type}
            />
            <div className={`modal document-modal ${show ? 'show' : ''}`}>
                <div className={'backdrop'} />
                <div className={'container'}>
                    <CloseModalButton onClick={props.onClose} />

                    <div className={'gd'}>
                        <div className={'gd-col-12--desktop'}>
                            <div className={'top'}>
                                <h2 className={importPdf ? 'left' : null}>
                                    {modalTitle}
                                </h2>
                                {importPdfWidgets}
                            </div>
                        </div>
                    </div>
                    <div className={'gd'}>
                        <div className={'gd-col-5--desktop gd-skip-1--desktop'}>
                            <div className={'templates'}>
                                <div className={'header-divisor'}>
                                    <h3 className={'highlight'}>
                                        {/*I18N*/}
                                        {'Predefinições'}
                                    </h3>
                                </div>
                                <DocumentModalTemplates
                                    ref={templatesRef}
                                    onShowTemplateDetail={(template) =>
                                        setTemplateDetail({
                                            template,
                                            show: true,
                                        })
                                    }
                                    onSelectTemplate={(template) => {
                                        setFormData((formData) => {
                                            return {
                                                ...formData,
                                                ...template,
                                                createdAt: undefined,
                                                updatedAt: undefined,
                                                id: formData.id,
                                                name: formData.name,
                                                templateCreate: false,
                                                templateId: template.id,
                                            };
                                        });
                                    }}
                                    onEdit={(template) => {
                                        setFormData((formData) => {
                                            return {
                                                ...formData,
                                                ...template,
                                                id: formData.id,
                                                name: formData.name,
                                                templateCreate: true,
                                                templateId: template.id,
                                                templateName: template.name,
                                            };
                                        });
                                    }}
                                    onDuplicate={(template) =>
                                        duplicateTemplate(template)
                                    }
                                    onRemove={(template) =>
                                        removeTemplate(template)
                                    }
                                    freeze={!show}
                                />
                            </div>
                        </div>

                        <div className={'gd-col-5--desktop'}>
                            <div>
                                <div className={'gd-inner'}>
                                    <div className={'gd-col gd-col-5--desktop'}>
                                        <div className={'header-divisor'}>
                                            <h3 className={'highlight'}>
                                                {/*I18N*/}
                                                {'Detalhes'}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                {documentForm}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TemplateDetailModal
                template={templateDetail.template}
                show={templateDetail.show}
                onClose={closeTemplateDetailModal}
                onTemplateSelected={() => {
                    setFormData((formData) => {
                        return {
                            ...formData,
                            ...templateDetail.template,
                            id: formData.id,
                            name: formData.name,
                            templateCreate: false,
                            templateId: templateDetail.template.id,
                        };
                    });
                    closeTemplateDetailModal();
                }}
            />
        </>
    );
}

export default DocumentModal;
