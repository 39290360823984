import {
    useEffect,
    useState,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import * as FiguresService from '../services/FigureService';
import ScrollPagination from '../components/ScrollPagination';
import './FiguresModalList.scss';
import ErrorLoading from '../components/ErrorLoading';
import FiguresTable from './FiguresTable';
import PropTypes from 'prop-types';

const STORAGE_KEY = 'figuresFilter';

/**
 * @typedef {object} FiguresModalListParams
 * @property {number | null} selected
 * @property {boolean} showingModal
 * @property {boolean | null} showPreviewBraille
 * @property {function(FiguresDto)} onSelected
 * @property {function(FiguresDto) | undefined} onDoubleClick
 * @property {boolean | undefined} showLabel
 */

/**
 * @typedef {object} FiguresModalListFunctions
 * @property {function()} refresh
 */

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<FiguresModalListParams>>}
 */
const FiguresModalList = forwardRef(
    (
        {
            selected,
            onSelected,
            showingModal,
            showPreviewBraille,
            onDoubleClick = undefined,
            showLabel = false,
        },
        ref,
    ) => {
        /**
         * @type {FiguresDto[] | null}
         */
        const recordsInitialValue = null;
        const [records, setRecords] = useState(recordsInitialValue);
        const [loading, setLoading] = useState(null);
        const scrollControlInitialState = {
            records: 0,
            pageSize: 0,
        };
        const [scrollControl, setScrollControl] = useState(
            scrollControlInitialState,
        );
        /**
         * @typedef {object} FilterState
         * @property {string | null | undefined} search
         * @property {number} page
         */

        /**
         * @type {FilterState}
         */
        let filterInitialState = {
            search: '',
            page: 0,
        };
        try {
            const storedFilter = sessionStorage.getItem(STORAGE_KEY);
            if (storedFilter) {
                filterInitialState = {
                    ...filterInitialState,
                    ...JSON.parse(storedFilter),
                    page: 0,
                };
            }
        } catch (e) {}
        const [filter, setFilter] = useState(filterInitialState);
        const figuresScrollRef = useRef();

        useEffect(() => {
            if (showingModal) {
                fetchFigures().then();
            }
        }, [filter]);

        async function fetchFigures() {
            if (loading?.abort) {
                loading.abort();
            }
            const abortController = new AbortController();
            try {
                setLoading(abortController);
                const data = await FiguresService.getFigures(filter, {
                    signal: abortController.signal,
                });
                setRecords((prevFigure) =>
                    data.page === 0
                        ? data.records
                        : [...prevFigure, ...data.records],
                );
                setScrollControl({
                    records: data.records.length,
                    pageSize: data.pageSize,
                });
                setLoading(null);
            } catch (e) {
                if (e.name === 'CanceledError') return;
                console.error('Error to fetch figures.', e);
                setLoading(e);
            }
        }

        const updateFilter = (newFilter) => {
            setFilter((prevFilter) => {
                const updatedFilter = {
                    ...prevFilter,
                    ...newFilter,
                    page: 0,
                };
                sessionStorage.setItem(
                    STORAGE_KEY,
                    JSON.stringify(updatedFilter),
                );
                return updatedFilter;
            });
        };

        const error = loading instanceof Error;
        const scrollPagination = () => {
            if (loading && error) {
                return (
                    <ErrorLoading
                        onTryAgain={() => {
                            setFilter({ ...filter });
                        }}
                    />
                );
            } else if (
                scrollControl.records >= scrollControl.pageSize &&
                figuresScrollRef.current
            ) {
                return (
                    <ScrollPagination
                        scrollElement={figuresScrollRef.current}
                        suspended={!showingModal || !!loading}
                        onPageRequested={() => {
                            const page = (filter.page ?? 0) + (records ? 1 : 0);
                            setFilter((filter) => {
                                filter.page = page;
                                return { ...filter };
                            });
                        }}
                    />
                );
            } else {
                return null;
            }
        };

        function table() {
            if (records?.length === 0 && !loading) {
                return (
                    <div className={'status'}>
                        <p>{'Nenhuma figura encontrada'}</p>
                    </div>
                );
            } else {
                return (
                    <div ref={figuresScrollRef}>
                        <FiguresTable
                            records={records}
                            loadingRow={scrollPagination()}
                            selected={selected}
                            onSelected={onSelected}
                            showPreviewBraille={showPreviewBraille}
                            onDoubleClick={onDoubleClick}
                        />
                    </div>
                );
            }
        }

        useImperativeHandle(ref, () => ({
            refresh: () => {
                setFilter((prevFilter) => ({
                    ...prevFilter,
                    page: 0,
                }));
            },
        }));

        return (
            <div className={'figures-modal-list'}>
                {/*I18N*/}
                {showLabel && (
                    <label className={'field-label'}> {'Figuras'} </label>
                )}
                <div>
                    <input
                        // I18N
                        placeholder={'Pesquise por legenda ou tag'}
                        className={'small search-field'}
                        value={filter.search ?? ''}
                        onChange={(e) =>
                            updateFilter({ ...filter, search: e.target.value })
                        }
                    />
                </div>

                <div className={'table-container'}>
                    <div className={'scroll'}>{table()}</div>
                </div>
            </div>
        );
    },
);

FiguresModalList.displayName = 'FiguresModalList';

FiguresModalList.propTypes = {
    selected: PropTypes.number,
    onSelected: PropTypes.func.isRequired,
    showingModal: PropTypes.bool,
    showLabel: PropTypes.bool,
};

export default FiguresModalList;
