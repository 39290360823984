import {
    createBrowserRouter,
    RouterProvider,
    useRouteError,
} from 'react-router-dom';
import Login from '../login/Login';
import RecoveryPassword from '../login/RecoveryPassword';
import ChangePassword from '../login/ChangePassword';
import ChangeInvitePassword from '../login/ChangeInvitePassword';
import React from 'react';
import RouteErrorPage from '../components/RouteErrorPage';
import { ConstantsFrontend } from 'plataforma-braille-common';
import { removeAppLoading } from '../util/AppLoading';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Login />,
        errorElement: <HandleError />,
    },
    {
        path: ConstantsFrontend.FRONTEND_URL_RECOVERY_PASSWORD,
        element: <RecoveryPassword />,
        errorElement: <HandleError />,
    },
    {
        path: `${ConstantsFrontend.FRONTEND_URL_RECOVERY_PASSWORD}/:email/:token`,
        element: <ChangePassword />,
        errorElement: <HandleError />,
    },
    {
        path: `${ConstantsFrontend.FRONTEND_URL_ACCEPT_INVITE}/:email/:token`,
        element: <ChangeInvitePassword />,
        errorElement: <HandleError />,
    },
]);

function HandleError() {
    return <RouteErrorPage error={useRouteError()} />;
}

function PublicRouter() {
    removeAppLoading();

    return <RouterProvider router={router} />;
}

export default PublicRouter;
