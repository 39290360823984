import { scanCaretPath } from '../core/CaretPath';
import { RevisionRecord } from './RevisionRecord';

import {
    getEditorElement,
    isInlineEditorElement,
} from '../core/EditorElements';
import { isEditorElement } from '../core/EditorUtil';
import { normalizeSpaces } from '../../../../util/TextUtil';

/**
 * @typedef {object} WorkerResponse
 * @property {number} startPos
 * @property {number} length
 * @property {string} word
 * @property {RevisionErrorEnum | null | undefined} revisionError
 */

/**
 * @param page {HTMLElement | Node}
 * @return {(string | HTMLElement)[]}
 */
export function getParagraphsInText(page) {
    /**
     * @type {(string | HTMLElement)[]}
     */
    const paragraphs = [];
    const path = scanCaretPath(page);
    let i = 0;
    for (let currentPath of path) {
        if (currentPath === '\n') {
            if (paragraphs[i] == null) {
                paragraphs[i] = '';
            }
            i++;
        } else if (
            typeof currentPath === 'string' ||
            isInlineEditorElement(currentPath)
        ) {
            if (paragraphs[i] == null) {
                paragraphs[i] = '';
            }
            if (isEditorElement(currentPath)) {
                const inlinePath = scanCaretPath(
                    currentPath,
                    null,
                    null,
                    null,
                    true,
                );
                paragraphs[i] += normalizeSpaces(inlinePath.join(''));
            } else {
                paragraphs[i] += normalizeSpaces(currentPath);
            }
        } else {
            paragraphs[i] = currentPath;
        }
    }
    return paragraphs;
}

/**
 * @param worker
 * @param page {HTMLElement | Node}
 * @param pageNumber {number}
 * @param revisionGravity {RevisionGravityEnum}
 * @param revisionError {RevisionErrorEnum}
 * @return {Promise<RevisionRecord[]>}
 */
export async function executeInspection(
    worker,
    page,
    pageNumber,
    revisionGravity,
    revisionError,
) {
    const records = [];
    const paragraphs = getParagraphsInText(page);

    for (const [i, paragraphItem] of paragraphs.entries()) {
        if (typeof paragraphItem === 'object') {
            let fragmentIdx = 0;
            for (const containerClass of getEditorElement(
                paragraphItem,
            ).getInnerContextContainerCssClass()) {
                const container = paragraphItem.querySelector(containerClass);
                if (!container) continue;
                const fragmentText = scanCaretPath(
                    container,
                    null,
                    null,
                    null,
                    true,
                ).join('');
                const errors = await executeWorker(worker, fragmentText);
                for (const error of errors) {
                    records.push(
                        new RevisionRecord(
                            revisionGravity,
                            error.revisionError ?? revisionError,
                            pageNumber,
                            i,
                            fragmentIdx,
                            error.startPos,
                            error.length,
                            error.word,
                        ),
                    );
                }
                fragmentIdx++;
            }
        } else {
            const errors = await executeWorker(worker, paragraphItem);
            for (const error of errors) {
                records.push(
                    new RevisionRecord(
                        revisionGravity,
                        error.revisionError ?? revisionError,
                        pageNumber,
                        i,
                        0,
                        error.startPos,
                        error.length,
                        error.word,
                    ),
                );
            }
        }
    }
    return records;
}

/**
 * @param worker
 * @param paragraph {string}
 * @return {Promise<WorkerResponse[]>}
 */
async function executeWorker(worker, paragraph) {
    return await new Promise((resolve, reject) => {
        worker.postMessage({ paragraph });
        worker.onmessage = (result) => {
            resolve(result.data);
        };
        worker.onerror = (error) => {
            reject(error.error);
        };
    });
}
