import { generateId, isEditorElement, selectAllTextNode } from '../EditorUtil';
import {
    elementCanBeInsertedAtSelection,
    surroundElementWithZeroWidthSpace,
} from '../EditorElements';
import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';
import { registerEditorElement } from './Instances';

export const EDITOR_ELEMENT_ANGLE = 'EDITOR_ELEMENT_ANGLE';

/**
 * @param editor {EditorCustom | undefined | null}
 * @param value {string | undefined | null}
 * @returns {HTMLElement}
 */
function createEditorElementAngle(editor = null, value = null) {
    const idPrefix = 'editor-element-angle';
    const elementId = generateId(editor, idPrefix);
    const editorElement = document.createElement('editor-element');
    editorElement.setAttribute('id', elementId);
    editorElement.setAttribute('type', 'angle');
    editorElement.setAttribute('contentEditable', 'false');

    const valueDiv = document.createElement('div');
    valueDiv.className = 'value';
    valueDiv.setAttribute('contentEditable', 'true');
    valueDiv.innerHTML = value?.trim() ? value.trim() : ZERO_WIDTH_NB_CHAR;
    editorElement.appendChild(valueDiv);

    return editorElement;
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementAngle(node) {
    if (!node) return false;
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.toLowerCase() === 'angle'
    );
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideEditorElementAngle(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementAngle(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @implements {EditorElement}
 */
export class EditorElementAngle {
    constructor() {}

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'angle';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementAngle(node);
    }

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.value'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return false;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     */
    createEditorElement(editor = null) {
        return createEditorElementAngle(editor);
    }

    /**
     * @param editor {EditorCustom}
     * @return {boolean}
     */
    insertElementAtCursor(editor) {
        const selection = editor.selection?.getContent().trim() ?? '';
        let editorElement = createEditorElementAngle(editor, selection);
        if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
            return false;
        }

        editor.undoManager.transact(() => {
            const id = editorElement.getAttribute('id');
            editor.selection.setContent(editorElement.outerHTML);
            editorElement = editor.dom.get(id);
            const valueDiv = editorElement.querySelector('.value');
            if (!selection?.trim()) {
                valueDiv.innerText = 'AOB';
            }
            surroundElementWithZeroWidthSpace(editorElement);
            editor.focus();
            selectAllTextNode(editor, valueDiv.firstChild);
        });
        return true;
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return string
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        let value =
            extractRecursively(
                element.querySelector('.value'),
                flags,
                editorElements,
                brailleDocument,
            ) ?? '';
        return `¬:?${value}*`;
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [...container.querySelectorAll('editor-element[type="angle"]')];
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        let valueDiv = element.querySelector('.value');
        if (!valueDiv) {
            valueDiv = document.createElement('div');
            valueDiv.className = 'value';
            valueDiv.setAttribute('contentEditable', 'true');
        }
        if (!valueDiv.innerText.trim()) {
            valueDiv.innerHTML = ZERO_WIDTH_NB_CHAR;
        }
        surroundElementWithZeroWidthSpace(element);
    }

    /**
     * @return {string[]}
     */
    getContextMenu() {
        return [
            'customContextMenuAddParagraphBreakAbove',
            'customContextMenuAddParagraphBreakBellow',
            '|',
            'customContextMenuRemove',
        ];
    }
}

registerEditorElement(EDITOR_ELEMENT_ANGLE, new EditorElementAngle());
