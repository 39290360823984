export enum SaveDictionaryValidationErrorEnum {
    WORD_ALREADY_EXISTS = "WORD_ALREADY_EXISTS",
}

export function SaveDictionaryValidationErrorEnumTxt(
    saveDictionaryValidationErrorEnum: SaveDictionaryValidationErrorEnum,
): string {
    switch (saveDictionaryValidationErrorEnum) {
        case SaveDictionaryValidationErrorEnum.WORD_ALREADY_EXISTS:
            // I18N
            return "A palavra já está cadastrada";
    }
    throw new Error("Invalid enum value");
}
