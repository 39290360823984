import { extractRecursively } from '../txt/HtmlToBrailleFacil';
import { MARK_CHAR, MARK_CHAR_EMPTY_SET, MARK_CHAR_SET } from './CharMap';
import { BrailleFacilConversionFlag } from '../txt/BrailleFacilConversionFlag';
import { breakWordToSyllables } from '../../util/BreakWordToSyllables';
import { scanCaretPath } from '../../edit-document/editor-mods/modules/core/CaretPath';
import {
    generateId,
    getClosestEditorElementWithDataLinesCount,
    isEditorElementParagraphBreak,
} from '../../edit-document/editor-mods/modules/core/EditorUtil';
import { ZERO_WIDTH_SPACE_CHAR } from '../../edit-document/editor-mods/modules/KeyboardModule';
import { replaceBracketsAndParentheses } from '../txt/BracketsAndParentheses';
import { getEditorElementLinesCount } from '../../edit-document/editor-mods/modules/core/EditorElements';
import { normalizeSpaces } from '../../util/TextUtil';

/**
 * @typedef {object} ParagraphBreak
 * @property {number | null | undefined} paragraph
 * @property {string} char
 * @property {number} occurrences
 * @property {string | null | undefined} startBreakWithChar
 * @property {ParagraphBreakType} type
 * @property {string | null | undefined} contextElement
 */

/**
 * @typedef {object} BreakParagraphsResult
 * @property {string[]} resultingLines
 * @property {ParagraphBreak[]} breaks
 */

export const MATH_OPERATORS = ['-', '+', '÷', '—', '×', '=', '/', '*'];
export const MATH_OPERATORS_WITH_CONVERTED_CHARS = [
    ...MATH_OPERATORS,
    'ý',
    'ÿ',
];
export const COMPUTER_RELATED_DIVIDERS = [
    '.',
    '_',
    '/',
    ':',
    '\\-',
    '?',
    '#',
    '+',
];
export const WORD_DIVIDERS = [...MARK_CHAR_EMPTY_SET, MARK_CHAR.EMPTY_CHAR];

/**
 * @param paragraph {string}
 * @param breakIndex {number}
 * @param type {ParagraphBreakType}
 * @return {ParagraphBreak}
 */
function getParagraphBreak(paragraph, breakIndex, type) {
    let char = paragraph[breakIndex];
    while (MARK_CHAR_SET.has(char)) {
        char = paragraph[--breakIndex];
    }
    let occurrences = 1;
    for (let i = 0; i < breakIndex; i++) {
        const charAtPosition = paragraph[i];
        if (charAtPosition === char) {
            occurrences++;
        }
    }
    /**
     * @type {string | null}
     */
    let startBreakWithChar = null;
    switch (type) {
        case ParagraphBreakType.MATH:
            startBreakWithChar = char;
            break;
    }

    return {
        char: char?.toLowerCase(),
        type,
        occurrences,
        startBreakWithChar,
    };
}

/**
 * @param element {HTMLElement | DocumentFragment | Node}
 * @param editorElements {EditorElements}
 * @param brailleDocument {BrailleDocument}
 * @param flags {BrailleFacilConversionFlag[] | undefined}
 * @returns {string}
 */
export function convertElementToBraille(
    element,
    editorElements,
    brailleDocument,
    flags = [],
) {
    flags.push(BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT);
    const brailleData = extractRecursively(
        element,
        flags,
        editorElements,
        brailleDocument,
    );

    const lines = brailleData.split('\r\n');
    for (const [i, line] of lines.entries()) {
        if (
            line.startsWith(MARK_CHAR.RAW_DATA) &&
            line.endsWith(MARK_CHAR.RAW_DATA)
        ) {
            continue;
        }
        lines[i] = normalizeSpaces(
            replaceBracketsAndParentheses(line)
                // numbers
                .replace(/(\d+)/g, (match, g1) => {
                    return MARK_CHAR.NUMBER + g1;
                })
                // numbers followed by lower case letter between a and j
                .replace(/(\d)([a-j])/g, (match, g1, g2) => {
                    return g1 + MARK_CHAR.RETURNER + g2;
                })
                // more than a letter in sequence is upper case followed by a lower case letter
                .replace(/([A-ZÀ-Ý]{2,})([a-zà-ý])/g, (match, g1, g2) => {
                    return g1 + MARK_CHAR.RETURNER + g2;
                })
                // only a letter alone in word is upper case
                .replace(
                    /(?<![A-ZÀ-Ý])([A-ZÀ-Ý])(?![A-ZÀ-Ý])/g,
                    (match, g1) => {
                        return MARK_CHAR.UPPER_CASE + g1.toLowerCase();
                    },
                )
                // more than a letter in sequence is upper case
                .replace(/([A-ZÀ-Ý]{2,})/g, (match, g1) => {
                    return (
                        MARK_CHAR.UPPER_CASE +
                        MARK_CHAR.UPPER_CASE +
                        g1.toLowerCase()
                    );
                })
                // adjust inverse logic of simple parenthesis, brackets and slashes
                .replace(/(?<!`)\//g, 'ý,')
                .replace(/`\//g, '/')
                .replace(/(?<!`)\(/g, '(.')
                .replace(/(?<!`)\)/g, 'ý)')
                .replace(/`\(/g, '(')
                .replace(/`\)/g, ')')
                .replace(/(?<!`)\[/g, '[.')
                .replace(/(?<!`)]/g, 'ý]')
                .replace(/`\[/g, '[')
                .replace(/`]/g, ']'),
        );
    }

    return lines.join('\r\n');
}

/**
 * @enum {number}
 */
export const WordContext = {
    MATH: 0,
    COMPUTER_RELATED: 1,
    TEXT: 2,
};

/**
 * @param word {string}
 * @param context {WordContext}
 * @returns {string[]}
 */
export function breakWordToChunks(word, context) {
    /**
     * @type {string[]}
     */
    const chunks = [];

    // some situations the word can have a context inside
    const wordChunks = word.split(
        new RegExp(`(?=[${[...MARK_CHAR_EMPTY_SET.values()].join('')}])`, 'g'),
    );

    /**
     * @type {WordContext}
     */
    let currentContext;
    for (const wordChunk of wordChunks) {
        if (MARK_CHAR_EMPTY_SET.has(wordChunk.charAt(0))) {
            switch (wordChunk.charAt(0)) {
                default: {
                    currentContext = context;
                    break;
                }
                case MARK_CHAR.MATH_BLOCK: {
                    currentContext = WordContext.MATH;
                    break;
                }
                case MARK_CHAR.COMPUTER_RELATED_BLOCK: {
                    currentContext = WordContext.COMPUTER_RELATED;
                    break;
                }
            }
        } else {
            currentContext = context;
        }

        switch (currentContext) {
            default:
            case WordContext.TEXT: {
                chunks.push(...breakWordToSyllables(wordChunk));
                break;
            }
            case WordContext.MATH: {
                chunks.push(
                    ...wordChunk.split(
                        new RegExp(
                            `(?<=[${MATH_OPERATORS_WITH_CONVERTED_CHARS.join('')}])`,
                            'g',
                        ),
                    ),
                );
                break;
            }
            case WordContext.COMPUTER_RELATED: {
                chunks.push(
                    ...wordChunk.split(
                        new RegExp(
                            `(?<=[${COMPUTER_RELATED_DIVIDERS.join('')}])`,
                            'g',
                        ),
                    ),
                );
                break;
            }
        }
    }
    return chunks.filter((chunk) => chunk.trim().length);
}

const REGEXP_EMPTY_MARK_CHAR_EMPTY = new RegExp(
    `[${[...MARK_CHAR_EMPTY_SET.values()].join('')}]`,
    'g',
);

const REGEXP_ALL_MARK_CHAR_EMPTY = new RegExp(
    `[${[...MARK_CHAR_SET.values()].join('')}]`,
    'g',
);

/**
 * @param brailleData {string}
 * @returns {string}
 */
export function removeEmptyMarksFromBrailleData(brailleData) {
    return brailleData.replace(REGEXP_EMPTY_MARK_CHAR_EMPTY, '');
}

/**
 * @param brailleData {string}
 * @returns {string}
 */
export function removeAllMarksFromBrailleData(brailleData) {
    return brailleData.replace(REGEXP_ALL_MARK_CHAR_EMPTY, '');
}

/**
 * @param brailleData {string}
 * @returns {number}
 */
export function getBrailleDataRealLength(brailleData) {
    brailleData = removeEmptyMarksFromBrailleData(brailleData);
    return brailleData.length;
}

/**
 * @param text {string} Sinble line text in braille facil format (with raw conversion)
 * @param brailleCellColCount {number}
 * @param hyphenation {boolean}
 * @param hyphenationLettersMin {number}
 * @param hyphenationSyllablesMin {number}
 * @param hyphenationParagraphMax {number}
 * @param hyphenationDistanceBetweenHyphens {number}
 * @return {BreakParagraphsResult}
 */
export function breakParagraphToFit(
    text,
    {
        brailleCellColCount,
        hyphenation,
        hyphenationLettersMin,
        hyphenationSyllablesMin,
        hyphenationParagraphMax,
        hyphenationDistanceBetweenHyphens,
    },
) {
    if (text.indexOf('\n') !== -1) {
        console.warn(
            'Only a paragraph can be processed per time in this function: ' +
                text.replaceAll('\n', '\\n').replaceAll('\r', '\\r'),
        );
    }

    // text come ready, no interventions is required
    if (
        text.startsWith(MARK_CHAR.RAW_DATA) &&
        text.endsWith(MARK_CHAR.RAW_DATA)
    ) {
        return {
            resultingLines: [text.substring(1, text.length - 1)],
            breaks: [],
        };
    }

    /**
     * @type {string[]}
     */
    const resultingLines = [];
    /**
     * @type {ParagraphBreak[]}
     */
    const breaks = [];

    const wordsRegexp = new RegExp(
        `(${WORD_DIVIDERS.join('+|')}|[^\\s${WORD_DIVIDERS.join('')}]+|\\s)`,
        'g',
    );

    const words = text.match(wordsRegexp) ?? [];

    let currentLine = '';

    let startNewLine = '';
    let hyphenationCount = 0;
    let insideRecoilBlock = false;
    let insideMathBlock = false;
    let insideComputerRelatedBlock = false;
    let insideCenterBlock = false;
    let currentProcessedText = '';

    /**
     * @param line {string}
     */
    const addFinishedLine = (line) => {
        if (insideCenterBlock) {
            line = line.trim();
            const diff = brailleCellColCount - getBrailleDataRealLength(line);
            line = ' '.repeat(diff / 2) + line;
        }
        resultingLines.push(line);
    };

    for (let idxWord = 0; idxWord < words.length; idxWord++) {
        const word = words[idxWord];

        if (word.startsWith(MARK_CHAR.RECOIL_BLOCK)) {
            insideRecoilBlock = !insideRecoilBlock;
        } else if (word.startsWith(MARK_CHAR.MATH_BLOCK)) {
            insideMathBlock = !insideMathBlock;
        } else if (word.startsWith(MARK_CHAR.COMPUTER_RELATED_BLOCK)) {
            insideComputerRelatedBlock = !insideComputerRelatedBlock;
        } else if (word.startsWith(MARK_CHAR.CENTER_BLOCK)) {
            // without this, the centralization is messed in last line
            // because the last line is dumped after the words processing
            if (idxWord + 1 < words.length) {
                insideCenterBlock = !insideCenterBlock;
            }
        }
        let wordContext = WordContext.TEXT;
        if (insideMathBlock) {
            wordContext = WordContext.MATH;
        } else if (insideComputerRelatedBlock) {
            wordContext = WordContext.COMPUTER_RELATED;
        }

        let availableSpace = brailleCellColCount - currentLine.length;
        const wordLength = getBrailleDataRealLength(word);
        if (!wordLength) continue;
        if (availableSpace - wordLength < 0) {
            /**
             * @type {string[]}
             */
            let wordChunks;
            let lastSyllableIdx = 0;
            let breakEntireWord = false;

            /**
             * @type {ParagraphBreakType}
             */
            let type;
            if (insideRecoilBlock) {
                type = ParagraphBreakType.HYPHEN_WITH_RECOIL;
                startNewLine = '  ';
            } else if (insideMathBlock) {
                type = ParagraphBreakType.MATH;
                startNewLine = '  ';
            } else if (insideComputerRelatedBlock) {
                type = ParagraphBreakType.RECOIL;
                startNewLine = '  ';
            } else {
                type = ParagraphBreakType.HYPHEN;
                startNewLine = '';
            }

            const typeEntireWord =
                type !== ParagraphBreakType.NORMAL &&
                type !== ParagraphBreakType.HYPHEN
                    ? ParagraphBreakType.RECOIL
                    : ParagraphBreakType.NORMAL;

            if (
                hyphenation &&
                hyphenationCount < hyphenationParagraphMax &&
                (wordChunks = breakWordToChunks(word, wordContext)).length >=
                    hyphenationSyllablesMin
            ) {
                if (
                    wordChunks.length &&
                    wordChunks.length >= hyphenationSyllablesMin
                ) {
                    const initialSyllablesLength = wordChunks.length;
                    let chunkLength;
                    while (
                        wordChunks.length &&
                        (chunkLength = getBrailleDataRealLength(
                            wordChunks[0],
                        )) +
                            1 <=
                            availableSpace &&
                        (removeAllMarksFromBrailleData(wordChunks[0]).length >=
                            hyphenationLettersMin ||
                            !resultingLines.length ||
                            wordContext !== WordContext.TEXT)
                    ) {
                        const currentIdx = currentProcessedText.length - 1;
                        // check if hyphenation is possible with the current document configuration
                        if (
                            lastSyllableIdx &&
                            currentIdx - lastSyllableIdx <
                                hyphenationDistanceBetweenHyphens
                        ) {
                            break;
                        }

                        const chunk = wordChunks.shift();
                        currentLine += chunk;
                        availableSpace -= chunkLength;
                        currentProcessedText += chunk;
                    }

                    let wordHyphenated;
                    if (initialSyllablesLength === wordChunks.length) {
                        if (startNewLine === currentLine) {
                            // avoiding deadlock condition
                            // force add the chunk anyway
                            const chunk = wordChunks.shift();
                            currentLine += chunk;
                            availableSpace -= chunkLength;
                            currentProcessedText += chunk;
                        } else {
                            wordHyphenated = false;
                        }
                    } else {
                        wordHyphenated = true;
                    }

                    if (wordHyphenated) {
                        // if the syllable broke, put the hyphen signal
                        lastSyllableIdx = currentProcessedText.length - 1;
                        hyphenationCount++;
                        if (type !== ParagraphBreakType.MATH) {
                            if (wordContext === WordContext.COMPUTER_RELATED) {
                                currentLine += '~';
                            } else {
                                currentLine += '-';
                            }
                        } else {
                            startNewLine =
                                '  ' +
                                // math operator is last char
                                // and must repeat in start of next line
                                currentLine.substring(
                                    currentLine.length - 1,
                                    currentLine.length,
                                );
                        }
                    }
                    const currentIdx = currentProcessedText.length - 1;
                    breaks.push(
                        getParagraphBreak(
                            currentProcessedText,
                            currentIdx,
                            wordHyphenated ? type : typeEntireWord,
                        ),
                    );
                    addFinishedLine(currentLine);
                    if (wordChunks.length) {
                        let wordRemain = wordChunks.join('');
                        words.splice(idxWord + 1, 0, wordRemain);
                    }
                    currentLine = startNewLine;
                } else {
                    breakEntireWord = true;
                }
            } else {
                breakEntireWord = true;
            }
            if (breakEntireWord) {
                let textToGetParagraphBreak = currentProcessedText;

                // avoids to break in a space
                if (word === ' ') {
                    textToGetParagraphBreak += word;
                }

                const currentIdx = textToGetParagraphBreak.length - 1;
                breaks.push(
                    getParagraphBreak(
                        textToGetParagraphBreak,
                        currentIdx,
                        typeEntireWord,
                    ),
                );
                addFinishedLine(currentLine);
                currentProcessedText += word;
                if (word.trim()) {
                    currentLine = startNewLine + word;
                } else {
                    currentLine = startNewLine;
                }
            }
        } else if (word || currentLine.trim()) {
            currentLine += word;
            currentProcessedText += word;
        }

        if (word.length !== 1) {
            if (word.endsWith(MARK_CHAR.RECOIL_BLOCK)) {
                insideRecoilBlock = !insideRecoilBlock;
            } else if (word.endsWith(MARK_CHAR.COMPUTER_RELATED_BLOCK)) {
                insideComputerRelatedBlock = !insideComputerRelatedBlock;
            } else if (word.endsWith(MARK_CHAR.MATH_BLOCK)) {
                insideMathBlock = !insideMathBlock;
            }
        }
    }

    if (currentLine) {
        addFinishedLine(currentLine);
    }

    if (!resultingLines.length) resultingLines.push('');

    return {
        resultingLines,
        breaks,
    };
}

/**
 * @enum {string}
 */
export const ParagraphBreakType = {
    NORMAL: 'paragraph-break',
    RECOIL: 'paragraph-break-2sp',
    HYPHEN: 'paragraph-break-hyphen',
    HYPHEN_WITH_RECOIL: 'paragraph-break-hyphen-2sp',
    MATH: 'paragraph-break-math',
};

/**
 * @param container {HTMLElement}
 * @param onlyCustomParagraphBreak {boolean | undefined}
 */
export function removeParagraphBreaks(
    container,
    onlyCustomParagraphBreak = false,
) {
    const elements = [];
    for (const type of Object.values(ParagraphBreakType)) {
        elements.push(
            ...container.querySelectorAll(`editor-element[type="${type}"]`),
        );
    }
    for (let e of elements) {
        const isProtected =
            e.getAttribute('data-custom-paragraph-break') === 'true';
        if (isProtected !== onlyCustomParagraphBreak) continue;
        const previousNode = e.previousSibling;
        while (previousNode?.textContent?.endsWith(ZERO_WIDTH_SPACE_CHAR)) {
            previousNode.textContent = previousNode.textContent.substring(
                0,
                previousNode.textContent.length - 1,
            );
        }
        e.remove();
    }
}

/**
 * @param container {HTMLElement}
 */
export function mergeSiblingElements(container) {
    /**
     * @type {HTMLElement}
     */
    let child;
    for (child of [...container.querySelectorAll('editor-element')]) {
        let siblingElement;
        while (
            !!(siblingElement = child.nextSibling) &&
            !isEditorElementParagraphBreak(siblingElement) &&
            siblingElement.getAttribute &&
            siblingElement.getAttribute('type') === child.getAttribute('type')
        ) {
            for (const siblingChild of [...siblingElement.childNodes]) {
                child.append(siblingChild);
            }
            siblingElement.remove();
        }
    }
    mergeSiblingTextNodes(container);
}

/**
 * @param container {HTMLElement}
 */
export function mergeSiblingElementsByClassname(container) {
    /**
     * @type {HTMLElement}
     */
    let child;
    for (child of [...container.childNodes]) {
        let siblingElement;
        while (
            child.nodeType === Node.ELEMENT_NODE &&
            !!(siblingElement = child.nextSibling) &&
            siblingElement.nodeType === Node.ELEMENT_NODE &&
            siblingElement['className'] === child['className']
        ) {
            for (const siblingChild of [...siblingElement.childNodes]) {
                child.append(siblingChild);
            }
            siblingElement.remove();
        }
    }
    mergeSiblingTextNodes(container);
}

/**
 * @param node {Node}
 */
export function mergeSiblingTextNodes(node) {
    if (node.nodeType === Node.TEXT_NODE) {
        let siblingNode;
        while (
            !!(siblingNode = node.nextSibling) &&
            siblingNode.nodeType === Node.TEXT_NODE
        ) {
            node.textContent += siblingNode.textContent;
            siblingNode.remove();
        }
    } else {
        for (const child of node.childNodes) {
            mergeSiblingTextNodes(child);
        }
    }
}

const hasIntersection = (arr1, arr2) => {
    const set1 = new Set(arr1);
    const intersection = arr2.filter((item) => set1.has(item));
    return intersection.length > 0;
};

/**
 * @param charA {string}
 * @param charB {string}
 * @return {boolean}
 */
function checkOccurrence(charA, charB) {
    if (typeof charA !== 'string' || typeof charB !== 'string') {
        return false;
    }
    /**
     * @param char {string}
     * @returns {string[]}
     */
    function normalize(char) {
        if (char === 'ÿ') {
            return ['÷', '—', '/'];
        } else if (char === '"') {
            return ['×', 'x'];
        }
        return [normalizeSpaces(char?.toLowerCase())];
    }
    return hasIntersection(normalize(charA), normalize(charB));
}

/**
 * @param text {string}
 * @param breaks {ParagraphBreak[]}
 * @return {string[]}
 */
export function backPropagateBreaksToText(text, breaks) {
    /**
     * @type {string[]}
     */
    const resultingParagraphs = [];

    function backPropagateBreaksToParagraph(text, breaks) {
        const resultingParagraphs = [];

        let startNewLine = '';
        let processedText = '';
        for (const brk of breaks) {
            let occurrences = 0;

            for (let i = 0; i < processedText.length; i++) {
                const char = processedText[i];
                if (normalizeSpaces(char.toLowerCase()) === brk.char) {
                    occurrences++;
                }
            }

            for (let i = 0; i < text.length; i++) {
                const char = text[i];
                if (normalizeSpaces(char.toLowerCase()) === brk.char) {
                    occurrences++;
                }

                if (occurrences === brk.occurrences) {
                    let paragraph = text.substring(0, i + 1);
                    processedText += paragraph;
                    text = text.substring(i + 1);
                    const preText = startNewLine;
                    switch (brk.type) {
                        case ParagraphBreakType.HYPHEN: {
                            paragraph += '-';
                            break;
                        }
                        case ParagraphBreakType.HYPHEN_WITH_RECOIL: {
                            paragraph += '-';
                            startNewLine = '  ';
                            break;
                        }
                        case ParagraphBreakType.MATH: {
                            paragraph += brk.char;
                            startNewLine = '  ' + brk.char;
                            break;
                        }
                        case ParagraphBreakType.RECOIL: {
                            startNewLine = '  ';
                            break;
                        }
                        default: {
                            startNewLine = '';
                            break;
                        }
                    }
                    resultingParagraphs.push(preText + paragraph);
                    break;
                }
            }
        }
        if (text) {
            resultingParagraphs.push(startNewLine + text);
        }
        return resultingParagraphs;
    }

    const paragraphs = text.split('\r\n');
    for (const [i, paragraph] of paragraphs.entries()) {
        const paragraphBreaks = breaks.filter((brk) => brk.paragraph === i);
        resultingParagraphs.push(
            ...backPropagateBreaksToParagraph(paragraph, paragraphBreaks),
        );
    }
    return resultingParagraphs;
}

/**
 * @param element {HTMLElement}
 * @param breaks {ParagraphBreak[]}
 * @param insideElements {boolean | undefined}
 */
export function backPropagateBreaksToElement(
    element,
    breaks,
    insideElements = false,
) {
    const contextElement = element;
    for (const brk of breaks) {
        let occurrences = 0;
        let lastPathLength = -1;
        /**
         * @type {null | HTMLElement}
         */
        let lastNode = null;
        let occurrencesInNode = 0;
        let found = false;
        /**
         * @type {string | null}
         */
        let lastOccurrenceChar;
        /**
         * @param path {(string | HTMLElement)[]}
         * @param paragraph {number}
         * @param word {number}
         * @param node {HTMLElement}
         * @return {boolean}
         */
        let paragraphOffset = 0;
        /**
         * @type {null | HTMLElement}
         */
        let lastElement = null;
        const scanIterator = (path, paragraph, word, node) => {
            if (lastPathLength === path.length) {
                return true;
            }
            lastPathLength = path.length;
            if (lastNode !== node) {
                lastNode = node;
                occurrencesInNode = 0;
            }

            const element = getClosestEditorElementWithDataLinesCount(node);
            let elementParagraphOffset = getEditorElementLinesCount(element);
            if (element !== lastElement) {
                lastElement = element;
                if (elementParagraphOffset != null) {
                    paragraphOffset += elementParagraphOffset - 1;
                }
            }

            const currentPath = path[path.length - 1];
            if (paragraph + paragraphOffset === brk.paragraph) {
                if (!currentPath) return true;
                if (checkOccurrence(currentPath, brk.char)) {
                    lastOccurrenceChar = currentPath;
                    occurrences++;
                    occurrencesInNode++;
                }
                if (occurrences === brk.occurrences) {
                    found = true;
                    if (contextElement?.getAttribute) {
                        let contextElementId =
                            contextElement.getAttribute('id');
                        if (!contextElementId) {
                            contextElementId = generateId(null, 'element');
                            contextElement.setAttribute('id', contextElementId);
                        }
                        brk.contextElement = contextElementId;
                    }
                    addParagraphBreak(
                        node,
                        brk.char,
                        occurrencesInNode,
                        brk.type,
                        brk.startBreakWithChar
                            ? lastOccurrenceChar ?? brk.startBreakWithChar
                            : brk.startBreakWithChar,
                        JSON.stringify(brk),
                    );
                    return false;
                }
            } else if (paragraph > brk.paragraph) {
                return false;
            }
            return true;
        };

        scanCaretPath(element, null, null, scanIterator, insideElements);

        if (!found) {
            // debugger;
            console.warn('Paragraph break not found.', brk);
        }
    }
}

/**
 * @param node {Node}
 * @param char {string}
 * @param occurrencesInNode {number}
 * @param type {ParagraphBreakType}
 * @param dataOperator {string | null}
 * @param dataBreak {string}
 */
function addParagraphBreak(
    node,
    char,
    occurrencesInNode,
    type,
    dataOperator,
    dataBreak,
) {
    const text = node.textContent;
    let occurrences = 0;
    for (let i = 0; i < text.length; i++) {
        if (checkOccurrence(text[i], char)) {
            occurrences++;
        }
        if (occurrences === occurrencesInNode) {
            let beforeBreak = text.substring(0, i + 1);
            const afterBreak = text.substring(i + 1);
            /**
             * @type {Element}
             */
            const paragraphBreak = document.createElement('editor-element');
            paragraphBreak.setAttribute('type', type ?? 'paragraph-break');
            paragraphBreak.setAttribute('data-break', dataBreak);
            if (dataOperator) {
                paragraphBreak.setAttribute('data-operator', dataOperator);
            }
            // this solves problems with spell checker
            if (
                type?.indexOf('hyphen') === -1 &&
                !beforeBreak.endsWith(ZERO_WIDTH_SPACE_CHAR)
            ) {
                beforeBreak += ZERO_WIDTH_SPACE_CHAR;
            }
            node.textContent = beforeBreak;
            node.after(paragraphBreak);
            if (afterBreak) {
                /**
                 * @type {Text}
                 */
                const textNode = document.createTextNode(afterBreak);
                paragraphBreak.after(textNode);
            }
            break;
        }
    }
}
