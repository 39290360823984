function getUseAltFontKey() {
    return `useAlternateFont:${window.location.pathname}`;
}

export function getUseAltFont() {
    return localStorage.getItem(getUseAltFontKey()) === 'true';
}

function setUseAltFont(useAltFont) {
    localStorage.setItem(getUseAltFontKey(), useAltFont.toString());
}

/**
 * @param editor {EditorCustom}
 * @return {boolean}
 */
export function toggleUseAltFont(editor) {
    applyUseAltFont(editor, !getUseAltFont());
}

/**
 * @param editor {EditorCustom}
 * @param useAltFont {boolean}
 */
export function applyUseAltFont(editor, useAltFont) {
    const { body } = editor.dom.doc;
    if (useAltFont) {
        body.classList.add('use-alt-font');
    } else {
        body.classList.remove('use-alt-font');
    }
    setUseAltFont(useAltFont);
}
