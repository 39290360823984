import React, { useState, useRef, useEffect } from 'react';
import CloseModalButton from '../components/modal/CloseModalButton';
import FieldText from '../components/FieldText';
import {
    getPages,
    goToPage,
} from './editor-mods/modules/core/PageManipulation';
import {
    cacheContent,
    setCacheDisabled,
    uncacheContent,
} from './editor-mods/modules/core/Cache';
import {
    scanCaretPath,
    setCaretPosition,
    selectRangePath,
} from './editor-mods/modules/core/CaretPath';
import {
    BoldReplace,
    BoldReplace2x,
    ItalicFontReplace,
    ItalicFontReplace2x,
    Settings,
    Settings2x,
} from '../components/images';

/**
 * @param show {boolean | undefined}
 * @param onFinished
 * @param editor {EditorCustom}
 * @param textSearch  {string | undefined}
 * @returns {JSX.Element}
 * @constructor
 */
export default function FindReplaceModal({
    show,
    onFinished,
    editor,
    textSearch,
}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [replaceTerm, setReplaceTerm] = useState('');
    const [caseSensitive, setCaseSensitive] = useState(false);
    const [italic, setItalic] = useState(false);
    const [bold, setBold] = useState(false);
    const [wholeWords, setWholeWords] = useState(false);
    const [results, setResults] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [replaceAll, setReplaceAll] = useState(false);
    const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
    const inputNameRef = useRef();
    const modalRef = useRef();
    const showRef = useRef(false);

    useEffect(() => {
        if (!editor) return;
        setCurrentMatchIndex(0);

        if (showRef.current === show) return;
        showRef.current = show;
        if (show) {
            setResults([]);
            setReplaceTerm('');
            const data = getStoredSearchResults();
            setSearchTerm(textSearch ? textSearch : data);

            setCacheDisabled(editor, true);
            uncacheContent(editor);
        } else {
            setCacheDisabled(editor, false);
            cacheContent(editor);
        }
    }, [show, textSearch, editor]);

    useEffect(() => {
        if (show) selectMatch();
    }, [currentMatchIndex]);

    useEffect(() => {
        selectMatch();
    }, [results]);

    useEffect(() => {
        if (searchTerm.length) {
            findTextInPages();
        }
    }, [caseSensitive]);

    useEffect(() => {
        if (searchTerm.length) {
            findTextInPages();
        }
    }, [wholeWords]);

    useEffect(() => {
        if (replaceAll && results.length) {
            replaceText();
            findTextInPages();
        } else {
            setReplaceAll(false);
        }
    }, [replaceAll, results]);

    const findTextInPages = () => {
        const result = [];

        /**
         * @param {(string | HTMLElement)[]} array
         * @param {string} searchText
         * @param {number} pageIndex
         * @param {string[]} path
         * @returns { (HTMLElement | Node)[] }
         */
        function searchInArray(array, searchText, pageIndex, path) {
            array.forEach((element, index) => {
                const currentPath = [...path, index];
                if (!caseSensitive) searchText = searchText.toLowerCase();

                if (typeof element === 'string') {
                    const matches =
                        array
                            .slice(index, index + searchText.length)
                            .join('') === searchText;

                    if (
                        matches &&
                        (!wholeWords ||
                            (wholeWords &&
                                checkPreviousAndLastCharacters(
                                    array,
                                    index,
                                    index + searchText.length,
                                )))
                    ) {
                        result.push({
                            page: pageIndex,
                            path: currentPath,
                            sequencePath: Array.from(
                                { length: searchText.length },
                                (_, i) => index + i,
                            ),
                        });
                    }
                } else {
                    Array.from(element.children).forEach(
                        (child, childIndex) => {
                            let caretPathOnChild = scanCaretPath(child);
                            if (!caseSensitive)
                                caretPathOnChild = caretPathOnChild.map((e) =>
                                    typeof e === 'string' ? e.toLowerCase() : e,
                                );
                            searchInArray(
                                caretPathOnChild,
                                searchTerm,
                                pageIndex,
                                [...currentPath, childIndex],
                            );
                        },
                    );
                }
            });
        }

        if (searchTerm.length) {
            const pages = getPages(editor);
            pages.forEach((page, pageIndex) => {
                let caretPathOnPage = scanCaretPath(page);
                if (!caseSensitive)
                    caretPathOnPage = caretPathOnPage.map((e) =>
                        typeof e === 'string' ? e.toLowerCase() : e,
                    );
                searchInArray(caretPathOnPage, searchTerm, pageIndex, []);
            });

            setResults(result);
        } else {
            setResults([]);
        }
    };

    /**
     * @param {(string | HTMLElement)[]} array
     * @param {number} startOffset
     * @param {number} endOffset
     * @returns { boolean }
     */
    const checkPreviousAndLastCharacters = (array, startOffset, endOffset) => {
        const prevChar = startOffset - 1;
        const lastChar = endOffset + 1;

        const acceptChars = ['', ' ', '.', ';', '?', '!', '\n'];

        // Check if the prevChar is space or is out of range
        const checkPrevChar =
            prevChar < 0 || acceptChars.includes(array[prevChar]);

        // Check if the lastChar is some of the accepted characters
        const checkLastChar =
            acceptChars.includes(array[lastChar]) || lastChar >= array.length;

        return checkPrevChar && checkLastChar;
    };

    //region TODO: Ajustar esse código para salvar/recuperar as últimas pesquisas
    function getSearch() {
        return `searchResults:${window.location.pathname}`;
    }

    function storeSearchResults(matches) {
        sessionStorage.setItem(getSearch(), JSON.stringify(matches));
    }

    function clearSearch() {
        sessionStorage.removeItem(getSearch());
    }

    function getStoredSearchResults() {
        const results = sessionStorage.getItem(getSearch());
        return results ? JSON.parse(results) : [];
    }
    //endregion

    const nextItem = () => {
        if (!results.length) return;
        setCurrentMatchIndex((currentMatchIndex + 1) % results.length);
    };

    const previousItem = () => {
        if (!results.length) return;
        setCurrentMatchIndex(
            (currentMatchIndex - 1 + results.length) % results.length,
        );
    };

    const handleOnKeyPress = (event) => {
        if (event.key === 'Enter') {
            findTextInPages();
        }
    };

    const selectMatch = () => {
        const currentMatchResult = results[currentMatchIndex];

        if (currentMatchResult) {
            const pages = getPages(editor);
            let currentMatchElement = pages[currentMatchResult.page];

            for (let i = 0; i < currentMatchResult.path.length; i++) {
                const path = currentMatchResult.path[i];
                const caretPath = scanCaretPath(currentMatchElement);
                if (typeof caretPath[path] !== 'string') {
                    currentMatchElement = Array.from(caretPath[path].children)[
                        currentMatchResult.path[++i]
                    ];
                }
            }

            const caretPath = scanCaretPath(currentMatchElement);
            setCaretPosition(
                editor,
                currentMatchElement,
                caretPath.slice(0, currentMatchResult.sequencePath[0]),
            );

            selectRangePath(
                editor,
                currentMatchElement,
                caretPath.slice(0, currentMatchResult.sequencePath[0]),
                caretPath.slice(
                    0,
                    currentMatchResult.sequencePath.slice(-1)[0] + 1,
                ),
            );
            goToPage(editor, currentMatchResult.page);
        }
    };

    const replaceText = () => {
        let textToReplace = replaceTerm;
        if (italic) textToReplace = `<i>${textToReplace}</i>`;
        if (bold) textToReplace = `<b>${textToReplace}</b>`;
        editor.selection.setContent(textToReplace);
    };

    const handleReplace = () => {
        replaceText();
        findTextInPages();
    };

    const handleReplaceAll = () => {
        setReplaceAll(true);
    };

    return (
        <div
            ref={modalRef}
            className={`modal-search default-modal ${show ? 'show' : ''}`}
        >
            <div className={'container'} style={{ width: '48%' }}>
                <div style={{ display: 'flex' }}>
                    <h4
                        style={{
                            fontSize: '20px',
                            position: 'absolute',
                            top: '24px',
                            left: '5.5rem',
                            cursor: 'pointer',
                        }}
                    >
                        {'Localizar e Substituir'}
                    </h4>
                    <CloseModalButton onClick={onFinished} />
                </div>
                <div className={'gd-inner'}>
                    <div>
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '2.6rem',
                                }}
                            >
                                <FieldText
                                    ref={inputNameRef}
                                    style={{ width: '280px' }}
                                    placeholder={'Localizar'}
                                    value={searchTerm}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                    onKeyUp={(e) => handleOnKeyPress(e)}
                                />

                                <span
                                    style={{
                                        // marginLeft: '-160px',
                                        marginTop: '6px',
                                        fontSize: '18px',
                                    }}
                                >
                                    {results.length
                                        ? `(${currentMatchIndex + 1} de  ${results.length})`
                                        : ''}
                                </span>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: '0.6rem',
                                }}
                            >
                                <FieldText
                                    placeholder={'Substituir'}
                                    style={{ width: '26rem' }}
                                    value={replaceTerm}
                                    onChange={(e) =>
                                        setReplaceTerm(e.target.value)
                                    }
                                />
                                <div
                                    style={{
                                        backgroundColor:
                                            bold === true
                                                ? '#d3d3d3'
                                                : '#ffffff',
                                        height: '48px',
                                    }}
                                >
                                    <img
                                        style={{
                                            marginTop: '10px',
                                            cursor: 'pointer',
                                        }}
                                        src={`${BoldReplace}`}
                                        srcSet={`${BoldReplace} 1x, ${BoldReplace2x} 2x`}
                                        width={18}
                                        height={18}
                                        onClick={() => {
                                            setBold(!bold);
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        backgroundColor: italic
                                            ? '#d3d3d3'
                                            : '#ffffff',
                                        height: '48px',
                                    }}
                                >
                                    <img
                                        style={{
                                            marginTop: '10px',
                                            cursor: 'pointer',
                                        }}
                                        src={`${ItalicFontReplace}`}
                                        srcSet={`${ItalicFontReplace} 1x, ${ItalicFontReplace2x} 2x`}
                                        width={18}
                                        height={18}
                                        onClick={() => {
                                            setItalic(!italic);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'controls'}>
                    <div className={'dropdown'}>
                        <img
                            style={{
                                cursor: 'pointer',
                            }}
                            src={`${Settings}`}
                            onClick={() => setIsOpen(!isOpen)}
                            srcSet={`${Settings} 1x, ${Settings2x} 2x`}
                            width={22}
                            height={22}
                            alt={''}
                        />
                        {isOpen && (
                            <div className={'dropdown-content'}>
                                <label>
                                    <input
                                        type={'checkbox'}
                                        name={'caseSensitive'}
                                        checked={caseSensitive}
                                        onChange={() =>
                                            setCaseSensitive(!caseSensitive)
                                        }
                                    />
                                    {/*I18N*/}
                                    {'Diferenciar maiúsculas e minúsculas'}
                                </label>
                                <label>
                                    <input
                                        type={'checkbox'}
                                        name={'wholeWord'}
                                        checked={wholeWords}
                                        onChange={() =>
                                            setWholeWords(!wholeWords)
                                        }
                                    />
                                    {/*I18N*/}
                                    {'Buscar somente palavras completas'}
                                </label>
                            </div>
                        )}
                    </div>

                    <button
                        className={
                            replaceTerm.length && results.length
                                ? 'primary'
                                : 'button'
                        }
                        style={{
                            cursor:
                                replaceTerm.length && results.length
                                    ? 'pointer'
                                    : 'not-allowed',
                        }}
                        onClick={handleReplace}
                    >
                        {/*I18N*/}
                        {'Substituir'}
                    </button>
                    <button
                        className={
                            replaceTerm.length && results.length
                                ? 'primary'
                                : 'button'
                        }
                        style={{
                            cursor:
                                replaceTerm.length && results.length
                                    ? 'pointer'
                                    : 'not-allowed',
                        }}
                        onClick={handleReplaceAll}
                    >
                        {/*I18N*/}
                        {'Substituir todos'}
                    </button>

                    <button
                        onClick={() => {
                            if (results.length) {
                                previousItem();
                            } else {
                                findTextInPages();
                            }
                        }}
                        className={'primary'}
                    >
                        {/*I18N*/}
                        {'Anterior'}
                    </button>
                    <button
                        onClick={() => {
                            if (results.length) {
                                nextItem();
                            } else {
                                findTextInPages();
                            }
                        }}
                        className={'primary'}
                    >
                        {/*I18N*/}
                        {'Próximo'}
                    </button>
                </div>
            </div>
        </div>
    );
}
