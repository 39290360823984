import nextId from 'react-id-generator';
import Field from './Field';
import { forwardRef, useRef } from 'react';

/**
 * @typedef {object} SelectOptions
 * @param {string} key
 * @param {string} value
 */

/**
 * @param options
 * @param validationError
 * @param required
 * @param placeholder
 * @param children
 * @param className
 * @param onChange
 * @param value
 * @param props
 * @param ref
 * @return {JSX.Element}
 * @constructor
 */
function FieldSelect(
    {
        options,
        validationError,
        required,
        placeholder,
        children,
        className,
        onChange,
        value,
        ...props
    },

    ref,
) {
    const id = props.id ?? nextId('field-select');
    const idError = `${id}-error`;
    const fieldRef = useRef();

    function createPlaceholderOption() {
        if (placeholder) {
            return (
                <option key={'-1'} value={'-1'} disabled>
                    {placeholder}
                </option>
            );
        } else {
            return <></>;
        }
    }

    function createOptions() {
        let array = [];
        for (const option of options) {
            array.push(
                <option key={option.key} value={option.key}>
                    {option.value}
                </option>,
            );
        }
        return array;
    }

    return (
        <Field
            ref={fieldRef}
            {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
            hasIcon={true}
            validationError={validationError}
        >
            <select
                ref={ref}
                id={id}
                className={className}
                required={Boolean(required)}
                aria-required={Boolean(required)}
                aria-invalid={Boolean(validationError)}
                aria-errormessage={idError}
                onChange={onChange}
                value={value}
            >
                {createPlaceholderOption()}
                {createOptions()}
            </select>
        </Field>
    );
}

export default forwardRef(FieldSelect);
