/**
 * @param px {number}
 * @return {number}
 */
export function pxToMm(px) {
    const mmPerInch = 25.4;
    const cssPxPerInch = 96;
    return (px / cssPxPerInch) * mmPerInch;
}

/**
 * @param px {number}
 * @return {number}
 */
export function pxToPt(px) {
    return px * 0.75;
}

/**
 * @param pt {number}
 * @return {number}
 */
export function ptToMm(pt) {
    return pt / 2.8346;
}
