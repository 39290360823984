import CloseModalButton from '../components/modal/CloseModalButton';
import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FiguresModalList from '../figures/FiguresModalList';
import { getBrailleDocument } from './editor-mods/modules/core/EditorUtil';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { EDITOR_ELEMENT_FIGURE } from './editor-mods/modules/core/editor-element/EditorElementFigure';

/**
 * @param show {boolean}
 * @param onClose {function}
 * @param editor {EditorCustom}
 * @returns {JSX.Element}
 * @constructor
 */
function FiguresModal({ show, onClose, editor }) {
    if (!editor) {
        return <></>;
    }

    const { setInfoModal } = useContext(EnvironmentContext);

    /**
     * @type {React.MutableRefObject<FiguresModalListFunctions | null>}
     */
    const figuresModalListRef = useRef(null);
    /**
     * @type {FiguresDto | null}
     */
    const [selectedFigure, setSelectedFigure] = useState(null);

    /**
     * @returns {boolean}
     */
    function insertFigure() {
        const brailleDocument = getBrailleDocument(editor);

        const splitedFigure = selectedFigure.figure.split('\n');

        const maxColumn = Math.max(
            ...splitedFigure.map((str) => str.length + 2),
        );

        const figureApproximateLength = splitedFigure.length + 1;

        if (brailleDocument.brailleCellRowCount < figureApproximateLength) {
            setInfoModal({
                // I18N
                title: 'Inserir imagem',
                // I18N
                message: `Não foi possível inserir a imagem. O documento possui uma grade de ${brailleDocument.brailleCellRowCount} linhas para a versão Braille e são necessárias ${figureApproximateLength} linhas para inserir a imagem`,
                show: true,
            });
            return false;
        } else if (brailleDocument.brailleCellColCount < maxColumn) {
            setInfoModal({
                // I18N
                title: 'Inserir imagem',
                // I18N
                message: `Não foi possível inserir a imagem. O documento possui uma grade de ${brailleDocument.brailleCellColCount} colunas para a versão Braille e são necessárias ${maxColumn} colunas para inserir a imagem`,
                show: true,
            });
            return false;
        } else {
            const { editorElements } = editor.custom.coreModule;
            const editorElementImage = editorElements.getEditorElementInstance(
                EDITOR_ELEMENT_FIGURE,
            );
            editorElementImage.insertElementAtCursor(editor, {
                id: selectedFigure.id,
                figure: splitedFigure,
                figureBraille: selectedFigure.figureBraille,
            });
            return true;
        }
    }

    const classList = ['modal', 'default-modal', 'figure-modal'];
    if (show) {
        classList.push('show');
    }

    return (
        <div className={classList.join(' ')}>
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={onClose} />
                {/*I18N*/}
                <h2>{'Figuras'}</h2>
                <div className={'gd-inner'}>
                    <div className={'gd-col gd-col-8--desktop'}>
                        <div className={'list-container'}>
                            <FiguresModalList
                                ref={figuresModalListRef}
                                selected={selectedFigure?.id}
                                showPreviewBraille={true}
                                onSelected={(selected) => {
                                    setSelectedFigure(selected);
                                }}
                                showingModal={show}
                                showLabel={false}
                                onDoubleClick={() => {
                                    if (insertFigure()) {
                                        onClose();
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                {selectedFigure && (
                    <div className={'controls'}>
                        <div>
                            <button
                                className={'button primary'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (insertFigure()) {
                                        onClose();
                                    }
                                }}
                            >
                                {/*I18N*/}
                                {'Inserir figura'}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

FiguresModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export default FiguresModal;
