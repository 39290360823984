import HtmlToBrailleFacil from './HtmlToBrailleFacil';
import * as FileSaver from 'file-saver';
import * as windows1252 from 'windows-1252';
import JSZip from 'jszip';

function htmlToNodes(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    let pages = [];
    const children = doc.body.children;
    for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.tagName === 'EDITOR-PAGE') {
            child.setAttribute('num', (i + 1).toString());
            pages.push(child);
        }
    }
    return pages;
}

/**
 * @param html {string}
 * @param editorElements {EditorElements}
 * @param doc {BrailleDocument}
 * @return {string}
 */
function htmlToBrailleFacil(html, editorElements, doc) {
    const nodes = htmlToNodes(html);
    const htmlToBrailleFacil = new HtmlToBrailleFacil(
        nodes,
        editorElements,
        doc,
    );

    // source: https://sgm.codebit.com.br/manutencao/39980
    // I18N
    const header =
        `<*${doc.brailleCellColCount} caracteres x ${doc.brailleCellRowCount}>\r\n` +
        `<${!doc.interPoint ? 'sem' : 'com'} interponto>\r\n` +
        `<${!doc.hyphenation ? 'sem' : 'com'} separação de sílabas>\r\n`;

    return header + htmlToBrailleFacil.convertToBraille();
}

/**
 * @param fileName {string}
 * @param splittedTxt {string[]}
 */
async function downloadFile(fileName, splittedTxt) {
    function stringToBlob(txt) {
        const encodedBuffer = windows1252
            .encode(txt, { mode: 'replacement' })
            .map((byte) => (byte === 0xfffd ? 239 : byte)); // replaces invalids characters to "ï"
        const bytes = new Uint8Array(encodedBuffer, 0, encodedBuffer.length);
        return new Blob([bytes], {
            type: 'text/plain;charset=iso-8859-1',
        });
    }

    if (splittedTxt.length > 1) {
        const zip = new JSZip();
        splittedTxt.forEach((txt, index) => {
            //I18N
            zip.file(`parte-${index + 1}.txt`, stringToBlob(txt));
        });
        const content = await zip.generateAsync({ type: 'blob' });
        FileSaver.saveAs(content, `${fileName}.zip`);
    } else {
        FileSaver.saveAs(stringToBlob(splittedTxt[0]), `${fileName}.txt`);
    }
}

export { htmlToNodes, htmlToBrailleFacil, downloadFile };
