import LoginContainer from '../components/login/LoginContainer';
import RecoveryPasswordForm from './RecoveryPasswordForm';

function RecoveryPassword() {
    return (
        <LoginContainer>
            <RecoveryPasswordForm />
        </LoginContainer>
    );
}

export default RecoveryPassword;
