import { forwardRef, useContext, useRef } from 'react';
import FieldUserRole from './FieldUserRole';
import FieldUsers from './FieldUsers';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import PropTypes from 'prop-types';
import './FieldShareDocument.scss';

const FieldShareDocument = forwardRef(
    ({ teamSelected, setTeamSelected, userSelected, setUserSelected }, ref) => {
        const fieldUsersRef = useRef();

        const { isAdmin, setNewUserModal } = useContext(EnvironmentContext);

        function showNewUserModal() {
            setNewUserModal({
                show: true,
                onFinished: () => {
                    setNewUserModal({ show: false });
                    // noinspection JSIgnoredPromiseFromCall
                    fieldUsersRef.current?.fetchUsers();
                },
            });
        }

        return (
            <div className={'field-share-document'}>
                <div className={'tables-container'}>
                    <FieldUserRole
                        selected={teamSelected}
                        onSelected={(teamSelected) =>
                            setTeamSelected(teamSelected)
                        }
                    />
                    <FieldUsers
                        ref={fieldUsersRef}
                        selected={userSelected}
                        onSelected={(userSelected) => {
                            setUserSelected(userSelected);
                        }}
                        filterRole={teamSelected}
                    />
                </div>
                {isAdmin && (
                    <div className={'bottom-controls'}>
                        <a onClick={showNewUserModal}>
                            {/*I18N*/}
                            {'Adicionar usuário não cadastrado'}
                        </a>
                    </div>
                )}
            </div>
        );
    },
);

FieldShareDocument.displayName = 'FieldShareDocument';
FieldShareDocument.propTypes = {
    teamSelected: PropTypes.string,
    setTeamSelected: PropTypes.func,
    userSelected: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            email: PropTypes.string,
            roles: PropTypes.arrayOf(PropTypes.string),
            name: PropTypes.string,
        }),
    ),
    setUserSelected: PropTypes.func,
};
export default FieldShareDocument;
