import LoginContainer from '../components/login/LoginContainer';
import LoginForm from './LoginForm';

function Login() {
    return (
        <LoginContainer>
            <LoginForm />
        </LoginContainer>
    );
}

export default Login;
