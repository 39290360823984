import './SummaryModule.scss';
import { getCurrentPage, getPagesAndBreaks } from '../core/PageManipulation';
import { delay } from '../../../../util/Delay';
import {
    EditorSummaryIcon,
    EditorSummaryIcon2x,
} from '../../../../components/images';

/**
 * @typedef {object} SummaryCheck
 * @property {function(pageNumber: number, page: HTMLElement | Node): Promise<import('SummaryRecord').SummaryRecord[]>} check
 * @property {function()} releaseWorker
 */

const CHECK_DOCUMENT_DELAY = 1500;

export class SummaryModule {
    /**
     * @type {EditorCustom}
     */
    editor;
    /**
     * @type {SummaryModalFunctions}
     */
    summaryModal;

    /**
     * @type {HTMLElement | null}
     */
    bar = null;

    /**
     * @type {HTMLElement | null}
     */
    barIcon = null;

    /**
     * @type {HTMLElement | null}
     */
    barText = null;

    /**
     * @type {object[]}
     */
    summaryRecords = [];

    /**
     * @type {number | null}
     */
    checkDocumentTimer = null;

    /**
     * @type {boolean}
     */
    checkDocumentInExecution = false;

    /**
     * @type {number}
     */
    pageDataChangedLastTime = 0;

    /**
     * @param {EditorCustom} editor
     * @param {SummaryModalFunctions} summaryModal
     */
    constructor(editor, summaryModal) {
        this.editor = editor;
        this.summaryModal = summaryModal;
    }

    /**
     * @return {Promise<void>}
     */
    async checkDocument() {
        if (this.checkDocumentInExecution) return;
        clearTimeout(this.checkDocumentTimer);

        const self = this;
        this.checkDocumentTimer = setTimeout(async () => {
            this.checkDocumentInExecution = true;
            let checkDocumentAgain = false;
            try {
                this.summaryRecords = [];
                const pagesAndBreaks = getPagesAndBreaks(this.editor);

                if (pagesAndBreaks.length) {
                    const breaks = pagesAndBreaks
                        .map((reg, idx) => ({ reg, idx }))
                        .filter((e) => e.reg.tagName === 'DOCUMENT-BREAK');

                    if (breaks?.length) {
                        //Manually set the first page-break
                        this.summaryRecords.push({
                            breakPageNumber: 1,
                            nextPage: 0,
                        });
                        breaks.forEach((pageBreak, idx) =>
                            //Fixme: expand this to differentiate breaks and titles
                            this.summaryRecords.push({
                                breakPageNumber: idx + 2,
                                nextPage: pageBreak.idx - idx, //Remove the idx because the gotToPage function search only for page, not pages and breaks
                            }),
                        );
                    } else {
                        this.summaryRecords = [];
                    }
                }

                if (
                    new Date().getTime() - self.pageDataChangedLastTime <
                    CHECK_DOCUMENT_DELAY
                ) {
                    checkDocumentAgain = true;
                    return;
                }
                await delay(0);

                self.checkDocumentTimer = null;
                // this.updateCurrentStatus().then();
            } finally {
                this.checkDocumentInExecution = false;
                if (checkDocumentAgain) self.checkDocument().then();
            }
        }, CHECK_DOCUMENT_DELAY);
    }

    showSummaryModal() {
        this.summaryModal.showSummaryModal(this.editor, this.summaryRecords);
    }

    /**
     * @param page {HTMLElement | null}
     */
    pageNeedsSummary(page) {
        page?.setAttribute('data-needs-check-summary', 'true');
    }

    createBarItem() {
        const container = this.editor.custom.customStatusBarContainer;
        this.bar = document.createElement('div');
        this.bar.className = 'summary-module-bar';
        this.barIcon = document.createElement('img');
        this.barIcon.src = `${EditorSummaryIcon}`;
        this.barIcon.srcset = `${EditorSummaryIcon} 1x, ${EditorSummaryIcon2x} 2x`;
        this.barIcon.className = 'summary-icon';
        this.bar.appendChild(this.barIcon);

        this.barText = document.createElement('div');
        this.barText.className = 'status-text';
        // I18N
        this.barText.innerText = 'Sumário';
        this.bar.appendChild(this.barText);

        container.appendChild(this.bar);

        this.bar.addEventListener('click', () => this.showSummaryModal());

        // this.updateBarStatus();

        const self = this;
        this.editor.on('pageDataChanged', (e) => {
            /**
             * @type {CaretPosition}
             */
            const caretPosition = e['caretPosition'];
            self.pageNeedsSummary(caretPosition?.page);
            self.pageDataChangedLastTime = new Date().getTime();
            self.checkDocument().then();
        });

        this.editor.on('keyDown', (e) => {
            getCurrentPage(this.editor);
            self.checkDocument().then();
            self.pageDataChangedLastTime = new Date().getTime();
        });

        this.editor.on('pageRemoved', async ({ pageIdx }) => {
            delete this.summaryRecords[pageIdx];
            self.checkDocument().then();
            self.pageDataChangedLastTime = new Date().getTime();
        });
        this.editor.on('documentReady', () => {
            self.checkDocument().then();
        });
        this.editor.on('pageOutdated', (e) => {
            /**
             *@type {HTMLElement}
             */
            const page = e['page'];
            self.pageNeedsSummary(page);
        });
    }

    install() {
        this.createBarItem();
    }
}
