import PropTypes from 'prop-types';
import { RevisionErrorEnumToString } from './RevisionErrorEnum';

/**
 * @param record {RevisionRecord}
 * @param onClick {function(RevisionRecord)}
 * @return {JSX.Element}
 * @constructor
 */
export function RevisionModalRecord({ record, onClick }) {
    return (
        <div className={'record'} onClick={() => onClick(record)}>
            <div className={'description'}>
                {RevisionErrorEnumToString(record.inspectionError)}
                {record.word && (
                    <div
                        className={'detail'}
                    >{`"${record.word.replaceAll(' ', '\u00A0')}"`}</div>
                )}
            </div>
            <div className={'addressing'}>
                <div className={'page'}>
                    {/*I18N*/}
                    {(record.page + 1).toLocaleString('pt-BR')}
                </div>
                <div className={'paragraph'}>
                    {/*I18N*/}
                    {(record.paragraph + 1).toLocaleString('pt-BR')}
                </div>
            </div>
        </div>
    );
}

RevisionModalRecord.propTypes = {
    record: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};
