/**
 * @param base64 {string}
 * @return {string | null}
 */
export function getImageFormatFromBase64(base64) {
    const match = base64.match(/data:image\/(\w+);base64/);
    if (match && match[1]) {
        return match[1];
    }
    return null;
}
