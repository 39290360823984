import {
    ButtonLockLocked,
    ButtonLockLocked2x,
    ButtonLockUnlocked,
    ButtonLockUnlocked2x,
} from './images';
import './ButtonLock.scss';

function ButtonLock(props) {
    return (
        <div
            tabIndex={0}
            className={'button-lock'}
            onClick={props.onClick}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    if (props.onClick) props.onClick();
                }
            }}
        >
            {props.locked ? (
                <img
                    src={ButtonLockLocked}
                    srcSet={`${ButtonLockLocked} 1x, ${ButtonLockLocked2x} 2x`}
                    onDragStart={(e) => {
                        e.preventDefault();
                    }}
                    alt={'Bloqueado'}
                />
            ) : (
                <img
                    src={ButtonLockUnlocked}
                    srcSet={`${ButtonLockUnlocked} 1x, ${ButtonLockUnlocked2x} 2x`}
                    onDragStart={(e) => {
                        e.preventDefault();
                    }}
                    alt={'Desbloqueado'}
                />
            )}
        </div>
    );
}

export default ButtonLock;
