/**
 * @param array1 {any[]}
 * @param array2 {any[]}
 * @param count {number}
 * @returns {boolean}
 */
export function compareArrays(array1, array2, count) {
    for (let i = 0; i < count; i++) {
        let obj1 = array1[i];
        if (typeof obj1 === 'object')
            obj1 = obj1.getAttribute('id') ?? JSON.stringify(obj1);
        let obj2 = array2[i];
        if (typeof obj2 === 'object')
            obj2 = obj2.getAttribute('id') ?? JSON.stringify(obj1);
        if (obj1 !== obj2) {
            return false;
        }
    }
    return true;
}
