import { RoleEnum, RoleEnumToString } from 'plataforma-braille-common';
import { useRef } from 'react';
import './FieldUserRole.scss';
import PropTypes from 'prop-types';

/**
 * @param onSelected {function(string|null): void }
 * @param selected {string|null}
 * @return {JSX.Element}
 * @constructor
 */
export default function FieldUserRole({ onSelected, selected = null }) {
    const tableContainerRef = useRef();

    function renderTableRows() {
        let rows = [];
        for (const role in RoleEnum) {
            if (
                role === RoleEnum.ADMINISTRATOR ||
                role === RoleEnum.MODERATION
            ) {
                break;
            }
            let team = RoleEnumToString(role);
            const isSelected = selected === role;

            function roleSelected(role) {
                if (selected === role) {
                    onSelected(null);
                }
                onSelected(role);
            }

            rows.push(
                <tr
                    key={team}
                    onClick={() => roleSelected(role)}
                    className={`${isSelected ? 'selected' : ''}`}
                >
                    <td>{team}</td>
                </tr>,
            );
        }
        return rows;
    }

    function renderTable() {
        return (
            <table className={'selectable'}>
                <tbody>{renderTableRows()}</tbody>
            </table>
        );
    }

    return (
        <>
            <div className={'field-user-role'}>
                <label className={'field-label'}>{'Equipe'}</label>
                <div ref={tableContainerRef} className={'table-container'}>
                    {renderTable()}
                </div>
            </div>
        </>
    );
}

FieldUserRole.propTypes = {
    onSelected: PropTypes.func.isRequired,
    selected: PropTypes.string,
};
