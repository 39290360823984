// noinspection JSCheckFunctionSignatures

import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useContext, useState } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import FieldText from '../components/FieldText';
import FieldRole from '../components/FieldRole';
import * as UserService from '../services/UserService';
import {
    isValidEmail,
    SaveUserValidationErrorTxt,
    RoleEnum,
} from 'plataforma-braille-common';
import CrudContainer from '../components/logged/CrudContainer';

export function Form({
    formData,
    validationError,
    updateFormData,
    inputNameRef,
}) {
    return (
        <div className={'gd-inner'}>
            <div className={'gd-col-5--desktop'}>
                <div>
                    <FieldText
                        ref={inputNameRef}
                        // I18N
                        label={'Nome do usuário'}
                        // I18N
                        placeholder={'Nome do usuário'}
                        autoComplete={'name'}
                        maxLength={255}
                        validationError={validationError.name}
                        required={true}
                        value={formData.name}
                        onChange={(e) =>
                            updateFormData({ name: e.target['value'] })
                        }
                    />

                    <FieldText
                        // I18N
                        label={'E-mail profissional ou corporativo'}
                        // I18N
                        placeholder={'E-mail'}
                        autoComplete={'email'}
                        maxLength={255}
                        required={true}
                        validationError={validationError.email}
                        value={formData.email}
                        onChange={(e) =>
                            updateFormData({ email: e.target['value'] })
                        }
                    />
                </div>
            </div>

            <div className={'gd-col-3--desktop'}>
                <div>
                    <FieldRole
                        // I18N
                        label={'Responsabilidades'}
                        onChange={updateFormData}
                        roles={formData.roles}
                        validationError={validationError.roles}
                        className={'two-columns'}
                    />
                </div>
            </div>
        </div>
    );
}

export function hasValidationErrorFn(setValidationErrorFn, formData) {
    let hasErrors = false;
    setValidationErrorFn({});

    if (!formData.name || formData.name.trim() === '') {
        setValidationErrorFn((validationError) => ({
            ...validationError,
            // I18N
            name: 'Campo obrigatório',
        }));
        hasErrors = true;
    }

    if (!formData.email || formData.email.trim() === '') {
        // I18N
        setValidationErrorFn((validationError) => ({
            ...validationError,
            // I18N
            email: 'Campo obrigatório',
        }));
        hasErrors = true;
    } else if (!isValidEmail(formData.email)) {
        setValidationErrorFn((validationError) => ({
            ...validationError,
            // I18N
            email: 'Digite um e-mail válido',
        }));
        hasErrors = true;
    }

    return hasErrors;
}

export async function sendInviteFn(
    hasValidationError,
    loadingFn,
    formData,
    setInfoModal,
    backendConnectionError,
    onFinished,
) {
    if (hasValidationError()) return;
    loadingFn(true);

    const title = 'Enviar convite';
    try {
        await UserService.inviteUser(
            formData.email,
            formData.name,
            formData.roles,
        );
        loadingFn(false);
        setInfoModal({
            title,
            message: 'Foi enviado um convite para o e-mail informado.',
            show: true,
            onClose: onFinished,
        });
    } catch (e) {
        backendConnectionError(
            'Fail to send invite to user.',
            e,
            null,
            title,
            SaveUserValidationErrorTxt,
        );
        loadingFn(false);
    }
}

function UserForm() {
    const navigate = useNavigate();
    const {
        backendConnectionError,
        setInfoModal,
        setConfirmModal,
        setLoading,
        user,
    } = useContext(EnvironmentContext);

    function backToList() {
        navigate('/gestao-usuarios');
    }

    let { id } = useParams();
    if (id) id = parseInt(id);
    const newUser = id == null;

    let title = newUser ? 'Novo cadastro' : 'Editar cadastro';
    let subtitle = newUser ? 'Preenchimento de dados' : 'Atualização de dados';

    const emptyFormData = {
        id: '',
        name: '',
        email: '',
        roles: [],
        inviteAccepted: false,
        enabled: null,
    };
    const [formData, setFormData] = useState(emptyFormData);
    const emptyValidationError = {
        name: '',
        email: '',
        roles: '',
    };
    const [validationError, setValidationError] =
        useState(emptyValidationError);

    const [validateOnChange, setValidateOnChange] = useState(false);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    const [userLoading, setUserLoading] = useState(!newUser);
    const [sendInviteLoading, setSendInviteLoading] = useState(false);
    const [resendInviteLoading, setResendInviteLoading] = useState(false);
    const [removeUserLoading, setRemoveUserLoading] = useState(false);
    const [saveUserLoading, setSaveUserLoading] = useState(false);
    const [enableUserLoading, setEnableUserLoading] = useState(false);

    const { user: loggedUser } = useContext(EnvironmentContext);
    if (!loggedUser.roles.includes(RoleEnum.ADMINISTRATOR)) {
        return <Navigate to={'/'} />;
    }

    useEffect(() => {
        setLoading(
            sendInviteLoading ||
                resendInviteLoading ||
                removeUserLoading ||
                saveUserLoading,
            true,
        );
    }, [
        sendInviteLoading,
        resendInviteLoading,
        removeUserLoading,
        saveUserLoading,
    ]);

    async function fetchUser() {
        try {
            setUserLoading(true);
            const { data } = await UserService.getUser(id);
            setFormData(data);
            setUserLoading(false);
        } catch (e) {
            setUserLoading(false);
            backendConnectionError('Fail to fetch user.', e, backToList);
        }
    }

    useEffect(() => {
        if (id) {
            if (isNaN(id)) {
                console.warn('Invalid user ID in param.');
                backToList();
                return;
            }

            // noinspection JSIgnoredPromiseFromCall
            fetchUser();
        }
    }, []);

    function updateFormData(obj) {
        setFormData((formData) => {
            return { ...formData, ...obj };
        });
    }

    function hasValidationError() {
        setValidateOnChange(true);
        return hasValidationErrorFn(setValidationError, formData);
    }

    /**
     * @param enable {boolean | undefined}
     * @return {Promise<void>}
     */
    async function save(enable = undefined) {
        if (hasValidationError()) return;

        // I18N
        const title = 'Salvar';
        formData.enabled = enable ?? undefined;
        try {
            if (enable != null) {
                setEnableUserLoading(true);
            } else {
                setSaveUserLoading(true);
            }
            await UserService.editUser(formData);
            if (enable != null) {
                setEnableUserLoading(false);
            } else {
                setSaveUserLoading(false);
            }
            setInfoModal({
                title,
                // I18N
                message: 'Dados do usuário atualizados com sucesso!',
                onClose: backToList,
                show: true,
            });
        } catch (e) {
            setSaveUserLoading(false);
            backendConnectionError(
                'Fail to update user.',
                e,
                null,
                title,
                SaveUserValidationErrorTxt,
            );
        }
    }

    async function resendInvite() {
        // I18N
        const title = 'Reenviar e-mail de boas vindas';

        try {
            setResendInviteLoading(true);
            await UserService.resendInviteUser(id);
            setInfoModal({
                title,
                // I18N
                message:
                    'E-mail enviado com sucesso. Entre em contato com o colaborador para validar o cadastro.',
                show: true,
                onClose: () => backToList(),
            });
        } catch (e) {
            backendConnectionError(
                'Fail to resend invite.',
                e,
                null,
                title,
                SaveUserValidationErrorTxt,
            );
        } finally {
            setResendInviteLoading(false);
        }
    }

    async function removeUser() {
        // I18N
        const title = 'Excluir usuário';

        try {
            setRemoveUserLoading(true);
            await UserService.removeUser(id);

            setRemoveUserLoading(false);
            setInfoModal({
                title,
                // I18N
                message: 'Usuário removido com sucesso!',
                onClose: () => backToList(),
                show: true,
            });
        } catch (e) {
            backendConnectionError(
                'Fail to remove user.',
                e,
                null,
                title,
                SaveUserValidationErrorTxt,
            );
        } finally {
            setRemoveUserLoading(false);
        }
    }

    function removeUserConfirmation() {
        if (user.email === formData.email) {
            // I18N
            setInfoModal({
                title: 'Excluir usuário',
                message:
                    'Você não pode se excluir. Solicite a um outro administrador a exclusão desse usuário.',
                show: true,
            });
        } else {
            // I18N
            setConfirmModal({
                title: 'Excluir usuário',
                message:
                    'Se prosseguir com a exclusão, este usuário não terá mais acesso ao sistema.',
                confirmText: 'Confirmar e excluir',
                onConfirm: removeUser,
                show: true,
            });
        }
    }

    async function sendInvite() {
        await sendInviteFn(
            hasValidationError,
            setSendInviteLoading,
            formData,
            setInfoModal,
            backendConnectionError,
            backToList,
        );
    }

    return (
        <CrudContainer
            // I18N
            title={title}
            // I18N
            subtitle={subtitle}
            onBack={backToList}
            gdConfigClassName={
                'gd-col-8--desktop gd-skip-2--desktop gd-skip-1--tablet'
            }
            loading={userLoading}
        >
            <div className={'form-container'}>
                <form>
                    <Form
                        validationError={validationError}
                        formData={formData}
                        updateFormData={updateFormData}
                    />
                </form>

                {!newUser && (
                    <div className={'gd-col--align-between controls'}>
                        <div>
                            <button
                                onClick={() => save(!formData.enabled)}
                                className={`primary ${enableUserLoading ? 'loading' : ''}`}
                                title={
                                    formData.enabled
                                        ? 'Desativar usuário'
                                        : 'Ativar usuário'
                                }
                            >
                                {formData.enabled ? 'Desativar' : 'Ativar'}
                            </button>
                            <button
                                onClick={removeUserConfirmation}
                                className={`primary ${removeUserLoading ? 'loading' : ''}`}
                                title={'Excluir usuário'}
                            >
                                {'Excluir'}
                            </button>
                        </div>
                        {/*I18N*/}
                        {!formData.inviteAccepted ? (
                            // I18N
                            <button
                                onClick={resendInvite}
                                className={`secondary ${resendInviteLoading ? 'loading' : ''}`}
                            >
                                {'Reenviar e-mail de boas vindas'}
                            </button>
                        ) : null}
                        <div>
                            {/*I18N*/}
                            <button
                                onClick={() => backToList()}
                                className={'secondary'}
                            >
                                {'cancelar'}
                            </button>
                            {/*I18N*/}
                            <button
                                onClick={() => save()}
                                className={`primary ${saveUserLoading ? 'loading' : ''}`}
                            >
                                {/*I18N*/}
                                {'Salvar'}
                            </button>
                        </div>
                    </div>
                )}
                {newUser && (
                    <div className={'gd-col--align-center controls'}>
                        {/* I18N */}
                        <button
                            onClick={sendInvite}
                            className={`primary ${sendInviteLoading ? 'loading' : ''}`}
                        >
                            {'Enviar convite'}
                        </button>
                    </div>
                )}
            </div>
        </CrudContainer>
    );
}

export default UserForm;
