export enum ChangePasswordValidationErrorEnum {
    PASSWORD_NOT_MEET_STRENGTH_REQUIREMENTS = "PASSWORD_NOT_MEET_STRENGTH_REQUIREMENTS",
}

export function ChangePasswordValidationErrorTxt(key: string): string {
    const validationError = ChangePasswordValidationErrorEnum[key];
    switch (validationError) {
        case ChangePasswordValidationErrorEnum.PASSWORD_NOT_MEET_STRENGTH_REQUIREMENTS:
            // I18N
            return "A senha não cumpre os requisitos de segurança desejados";
        default:
            throw new Error("Invalid enum value");
    }
}
