import CloseModalButton from '../components/modal/CloseModalButton';
import { useEffect, useMemo, useState } from 'react';
import IdentificationModalForm from './IdentificationModalForm';
import './IdentificationModal.scss';
import PropTypes from 'prop-types';
import { EDITOR_ELEMENT_HEADER } from '../edit-document/editor-mods/modules/core/editor-element/EditorElementHeader';
import { getCurrentPage } from '../edit-document/editor-mods/modules/core/PageManipulation';

/**
 * @typedef {object} FormData
 * @property {string | null | undefined} value
 * @property {boolean | null | undefined} suppress
 * @property {string | null | undefined} textAlignment
 * @property {boolean | null | undefined} affectAllPages
 */

/**
 * @param show {boolean}
 * @param onClose {function}
 * @param editor {EditorCustom}
 * @returns {JSX.Element}
 * @constructor
 */
function IdentificationModal({ show, onClose, editor }) {
    /**
     * @type {FormData | null}
     */
    const formDataInitialValue = {
        value: '',
        suppress: false,
        textAlignment: 'center',
        affectAllPages: false,
    };
    const [formData, setFormData] = useState(formDataInitialValue);

    useEffect(() => {
        const currentPage = getCurrentPage(editor);
        if (currentPage) {
            const header = currentPage.querySelector(
                "editor-element[type='header']",
            );
            const identification = header?.querySelector('.identification');
            const value = identification?.textContent || '';
            const suppress =
                identification?.getAttribute('data-suppress') === 'true';
            const textAlignment =
                identification?.getAttribute('data-text-alignment') || 'center';

            setFormData({ value, suppress, textAlignment });
        }
    }, [show]);

    /**
     * @param formData {FormData | null}
     */
    function updateFormData(formData) {
        if (!formData) {
            setFormData(null);
        } else {
            setFormData((prevState) => {
                return { ...prevState, ...formData };
            });
        }
    }

    // I18N
    const modalTitle = 'Identificação';

    async function save() {
        if (!editor) return console.error('"editor" is missing');

        const { editorElements } = editor.custom.coreModule;
        editorElements.insertElementAtCursor(EDITOR_ELEMENT_HEADER, {
            identification: formData,
        });

        onClose();
    }

    const form = useMemo(() => {
        return (
            <IdentificationModalForm
                formData={formData}
                onSave={save}
                onFormUpdate={updateFormData}
            />
        );
    }, [formData]);

    return (
        <div
            className={`modal default-modal identification-modal ${show ? 'show' : ''}`}
        >
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={onClose} />
                <h2>{modalTitle}</h2>
                <div className={'gd-inner table-form-container'}>
                    <div className={'gd-col gd-col-5--desktop'}>
                        <div className={'form-container'}>{form}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

IdentificationModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export default IdentificationModal;
