import { ButtonBackArrow, ButtonBackArrow2x } from './images';
import './ButtonBack.scss';

function ButtonBack(props) {
    return (
        <button className={'button-back'} {...props}>
            <img
                src={`${ButtonBackArrow}`}
                srcSet={`${ButtonBackArrow} 1x, ${ButtonBackArrow2x} 2x`}
                alt={''}
            />
            {/*I18N*/}
            {'Voltar'}
        </button>
    );
}

export default ButtonBack;
