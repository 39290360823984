import React from 'react';
import MainMenu from './MainMenu';
import './LoggedContainer.scss';

class LoggedContainer extends React.Component {
    render() {
        return (
            <div className={'logged-container'}>
                <div className={'background'} />
                <div className={'main-container'}>
                    <MainMenu />
                    <div className={'container'}>{this.props.children}</div>
                </div>
            </div>
        );
    }
}

export default LoggedContainer;
