import { ModalClose, ModalClose2x } from '../images';

function CloseModalButton(props) {
    return (
        <a
            className={'close-button'}
            onClick={props.onClick}
            onDragStart={(e) => e.preventDefault()}
        >
            {/*I18N*/}
            <img
                src={`${ModalClose}`}
                srcSet={`${ModalClose} 1x, ${ModalClose2x} 2x`}
                alt={'Fechar'}
            />
        </a>
    );
}

export default CloseModalButton;
