import './RevisionModal.scss';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { RevisionModalRecord } from './RevisionModalRecord';
import PropTypes from 'prop-types';
import { goToPage } from '../core/PageManipulation';
import CloseModalButton from '../../../../components/modal/CloseModalButton';

/**
 * @typedef {object} RevisionModalParams
 */

/**
 * @typedef {object} RevisionModalFunctions
 * @property {function(editor: EditorCustom, records: RevisionRecord[])} showRevisionModal
 */

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<RevisionModalParams>>}
 */
export const RevisionModal = forwardRef(({}, ref) => {
    const [show, setShow] = useState(false);
    /**
     * @type {React.MutableRefObject<EditorCustom | null>}
     */
    const editorRef = useRef(null);
    /**
     * @type {RevisionRecord[]}
     */
    const recordsInitialValue = [];
    const [records, setRecords] = useState(recordsInitialValue);

    useImperativeHandle(ref, () => ({ showRevisionModal, closeRevisionModal }));

    const classList = ['modal', 'default-modal', 'revision-modal'];
    if (show) {
        classList.push('show');
    }

    /**
     * @param editor {EditorCustom}
     * @param records {RevisionRecord[]}
     */
    function showRevisionModal(editor, records) {
        editorRef.current = editor;
        setRecords(records);
        setShow(true);
    }

    function closeRevisionModal() {
        setShow(false);
    }

    function revisionModalRecords() {
        return records.map((record, idx) => (
            <RevisionModalRecord
                key={idx}
                record={record}
                onClick={(record) => revisionErrorClicked(record)}
            />
        ));
    }

    /**
     *
     * @param record {RevisionRecord}
     */
    function revisionErrorClicked(record) {
        closeRevisionModal();
        const editor = editorRef.current;
        goToPage(editor, record.page);
        setTimeout(() => {
            const element = editor
                .getDoc()
                .getElementById(record.editorElementId);
            if (!element) {
                console.warn(
                    'Cannot find element in document to highlight.',
                    record,
                );
                return;
            }
            element.classList.add('highlight');
            setTimeout(() => {
                element.classList.remove('highlight');
            }, 3000);
        }, 800);
    }

    return (
        <div className={classList.join(' ')}>
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={closeRevisionModal} />
                {/*I18N*/}
                <h2>{'Revisão do documento'}</h2>

                {records.length ? (
                    <div className={'record-container'}>
                        {revisionModalRecords()}
                    </div>
                ) : (
                    <div className={'message'}>
                        {/*I18N*/}
                        {'Nenhum problema encontrado no documento.'}
                    </div>
                )}

                <div className={'controls'}>
                    <a
                        className={'button primary'}
                        onClick={closeRevisionModal}
                    >
                        {/*I18N*/}
                        {'Fechar'}
                    </a>
                </div>
            </div>
        </div>
    );
});

RevisionModal.propTypes = {};

RevisionModal.displayName = 'RevisionModal';
