import {
    EditorAngle,
    EditorAngle2x,
    EditorApproximatelyEqual,
    EditorApproximatelyEqual2x,
    EditorBullet,
    EditorBullet2x,
    EditorCharMap,
    EditorCharMap2x,
    EditorCongruent,
    EditorCongruent2x,
    EditorDash,
    EditorDash2x,
    EditorDelta,
    EditorDelta2x,
    EditorElementOf,
    EditorElementOf2x,
    EditorLambda,
    EditorLambda2x,
    EditorLineSegment,
    EditorLineSegment2x,
    EditorMicrometer,
    EditorMicrometer2x,
    EditorNotEqual,
    EditorNotEqual2x,
    EditorOmega,
    EditorOmega2x,
    EditorPlusMinus,
    EditorPlusMinus2x,
    EditorSquareRoot,
    EditorSquareRoot2x,
    EditorThetaLowerCase,
    EditorThetaLowerCase2x,
    EditorAlfaLowerCase,
    EditorAlfaLowerCase2x,
    EditorBetaLowerCase2x,
    EditorBetaLowerCase,
    EditorChiLowerCase,
    EditorChiLowerCase2x,
    EditorEpsilonLowerCase,
    EditorEpsilonLowerCase2x,
    EditorEtaLowerCase,
    EditorEtaLowerCase2x,
    EditorGamaLowerCase,
    EditorGamaLowerCase2x,
    EditorIotaLowerCase2x,
    EditorIotaLowerCase,
    EditorKappaLowerCase,
    EditorKappaLowerCase2x,
    EditorMiLowerCase,
    EditorMiLowerCase2x,
    EditorNiLowerCase,
    EditorNiLowerCase2x,
    EditorOmicronLowerCase2x,
    EditorOmicronLowerCase,
    EditorPhiLowerCase2x,
    EditorPiLowerCase,
    EditorPhiLowerCase,
    EditorPiLowerCase2x,
    EditorPsiLowerCase2x,
    EditorPsiLowerCase,
    EditorRhoLowerCase,
    EditorRhoLowerCase2x,
    EditorSigmaLowerCase,
    EditorSigmaLowerCase2x,
    EditorTauLowerCase,
    EditorTauLowerCase2x,
    EditorUpsilonLowerCase,
    EditorUpsilonLowerCase2x,
    EditorXiLowerCase,
    EditorXiLowerCase2x,
    EditorZetaLowerCase,
    EditorZetaLowerCase2x,
    EditorOmegaLowerCase2x,
    EditorLambdaUpperCase,
    EditorLambdaUpperCase2x,
    EditorDeltaLowerCase2x,
    EditorDeltaLowerCase,
    EditorDeltaUpperCase,
    EditorDeltaUpperCase2x,
    EditorThetaUpperCase,
    EditorThetaUpperCase2x,
    EditorAlfaUpperCase,
    EditorAlfaUpperCase2x,
    EditorBetaUpperCase,
    EditorBetaUpperCase2x,
    EditorChiUpperCase2x,
    EditorEpsilonUpperCase2x,
    EditorEpsilonUpperCase,
    EditorGamaUpperCase,
    EditorGamaUpperCase2x,
    EditorIotaUpperCase,
    EditorIotaUpperCase2x,
    EditorKappaUpperCase,
    EditorKappaUpperCase2x,
    EditorMiUpperCase2x,
    EditorMiUpperCase,
    EditorNiUpperCase,
    EditorNiUpperCase2x,
    EditorOmicronUpperCase,
    EditorOmicronUpperCase2x,
    EditorPhiUpperCase,
    EditorPhiUpperCase2x,
    EditorPiUpperCase,
    EditorPiUpperCase2x,
    EditorPsiUpperCase2x,
    EditorPsiUpperCase,
    EditorRhoUpperCase,
    EditorRhoUpperCase2x,
    EditorSigmaUpperCase,
    EditorSigmaUpperCase2x,
    EditorTauUpperCase,
    EditorTauUpperCase2x,
    EditorUpsilonUpperCase,
    EditorUpsilonUpperCase2x,
    EditorXiUpperCase,
    EditorXiUpperCase2x,
    EditorZetaUpperCase,
    EditorZetaUpperCase2x,
    EditorEtaUpperCase,
    EditorEtaUpperCase2x,
    EditorArrowUp,
    EditorArrowUp2x,
    EditorArrowDown,
    EditorArrowDown2x,
    EditorTheta,
    EditorTheta2x,
    EditorArrowForward,
    EditorArrowForward2x,
    EditorSquare,
    EditorSquare2x,
} from '../../../components/images';
import { addIcon } from './menu/MenuModule';
import { getCaretPosition } from './core/CaretPath';
import { getCurrentPage } from './core/PageManipulation';
import { EDITOR_ELEMENT_ANGLE } from './core/editor-element/EditorElementAngle';
import { EDITOR_ELEMENT_NTH_ROOT } from './core/editor-element/EditorElementNthRoot';
import { EDITOR_ELEMENT_LINE_SEGMENT } from './core/editor-element/EditorElementLineSegment';
import {
    EDITOR_ELEMENT_ARROW_DOWN,
    EDITOR_ELEMENT_ARROW_UP,
} from './core/editor-element/EditorElementArrow';

/**
 * @param editor {EditorCustom}
 */
export function install(editor) {
    addIcon(editor, 'customEditorCharMap', EditorCharMap, EditorCharMap2x);
    addIcon(
        editor,
        'customEditorSquareRoot',
        EditorSquareRoot,
        EditorSquareRoot2x,
    );
    addIcon(editor, 'customEditorNotEqual', EditorNotEqual, EditorNotEqual2x);
    addIcon(
        editor,
        'customEditorCongruent',
        EditorCongruent,
        EditorCongruent2x,
    );
    addIcon(
        editor,
        'customEditorMicrometer',
        EditorMicrometer,
        EditorMicrometer2x,
    );
    addIcon(
        editor,
        'customEditorPlusMinus',
        EditorPlusMinus,
        EditorPlusMinus2x,
    );
    addIcon(
        editor,
        'customEditorElementOf',
        EditorElementOf,
        EditorElementOf2x,
    );
    addIcon(
        editor,
        'customEditorLineSegment',
        EditorLineSegment,
        EditorLineSegment2x,
    );
    addIcon(
        editor,
        'customEditorApproximatelyEqual',
        EditorApproximatelyEqual,
        EditorApproximatelyEqual2x,
    );
    addIcon(editor, 'customEditorOmega', EditorOmega, EditorOmega2x);
    addIcon(
        editor,
        'customEditorLambdaLowerCase',
        EditorLambda,
        EditorLambda2x,
    );
    addIcon(
        editor,
        'customEditorLambda',
        EditorLambdaUpperCase,
        EditorLambdaUpperCase2x,
    );
    addIcon(editor, 'customEditorBullet', EditorBullet, EditorBullet2x);
    addIcon(
        editor,
        'customEditorArrowForward',
        EditorArrowForward,
        EditorArrowForward2x,
    );
    addIcon(editor, 'customEditorSquare', EditorSquare, EditorSquare2x);
    addIcon(editor, 'customEditorDash', EditorDash, EditorDash2x);
    addIcon(editor, 'customEditorDelta', EditorDelta, EditorDelta2x);
    addIcon(
        editor,
        'customEditorDeltaLowerCase',
        EditorDeltaLowerCase,
        EditorDeltaLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorOmegaLowerCase',
        EditorOmegaLowerCase2x,
        EditorOmegaLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorThetaLowerCase',
        EditorThetaLowerCase,
        EditorThetaLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorThetaUpperCase',
        EditorThetaUpperCase,
        EditorThetaUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorLambdaLowerCase',
        EditorLambda,
        EditorLambda2x,
    );
    addIcon(
        editor,
        'customEditorAlfaLowerCase',
        EditorAlfaLowerCase,
        EditorAlfaLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorBetaLowerCase',
        EditorBetaLowerCase,
        EditorBetaLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorChiLowerCase',
        EditorChiLowerCase,
        EditorChiLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorEpsilonLowerCase',
        EditorEpsilonLowerCase,
        EditorEpsilonLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorEtaLowerCase',
        EditorEtaLowerCase,
        EditorEtaLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorGamaLowerCase',
        EditorGamaLowerCase,
        EditorGamaLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorIotaLowerCase',
        EditorIotaLowerCase,
        EditorIotaLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorKappaLowerCase',
        EditorKappaLowerCase,
        EditorKappaLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorMiLowerCase',
        EditorMiLowerCase,
        EditorMiLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorNiLowerCase',
        EditorNiLowerCase,
        EditorNiLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorOmicronLowerCase',
        EditorOmicronLowerCase,
        EditorOmicronLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorPhiLowerCase',
        EditorPhiLowerCase,
        EditorPhiLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorPiLowerCase',
        EditorPiLowerCase,
        EditorPiLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorPsiLowerCase',
        EditorPsiLowerCase,
        EditorPsiLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorRhoLowerCase',
        EditorRhoLowerCase,
        EditorRhoLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorSigmaLowerCase',
        EditorSigmaLowerCase,
        EditorSigmaLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorTauLowerCase',
        EditorTauLowerCase,
        EditorTauLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorUpsilonLowerCase',
        EditorUpsilonLowerCase,
        EditorUpsilonLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorXiLowerCase',
        EditorXiLowerCase,
        EditorXiLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorZetaLowerCase',
        EditorZetaLowerCase,
        EditorZetaLowerCase2x,
    );
    addIcon(
        editor,
        'customEditorAngleLowerCase',
        EditorCongruent,
        EditorCongruent2x,
    );
    addIcon(
        editor,
        'customEditorDeltaUpperCase',
        EditorDeltaUpperCase,
        EditorDeltaUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorThetaUpperCase',
        EditorThetaUpperCase,
        EditorThetaUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorLambdaUpperCase',
        EditorLambda,
        EditorLambda2x,
    );
    addIcon(
        editor,
        'customEditorAlfaUpperCase',
        EditorAlfaUpperCase,
        EditorAlfaUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorBetaUpperCase',
        EditorBetaUpperCase,
        EditorBetaUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorChiUpperCase',
        EditorChiUpperCase2x,
        EditorChiUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorEpsilonUpperCase',
        EditorEpsilonUpperCase,
        EditorEpsilonUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorEtaUpperCase',
        EditorEtaUpperCase,
        EditorEtaUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorGamaUpperCase',
        EditorGamaUpperCase,
        EditorGamaUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorIotaUpperCase',
        EditorIotaUpperCase,
        EditorIotaUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorKappaUpperCase',
        EditorKappaUpperCase,
        EditorKappaUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorMiUpperCase',
        EditorMiUpperCase,
        EditorMiUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorNiUpperCase',
        EditorNiUpperCase,
        EditorNiUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorOmicronUpperCase',
        EditorOmicronUpperCase,
        EditorOmicronUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorPhiUpperCase',
        EditorPhiUpperCase,
        EditorPhiUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorPiUpperCase',
        EditorPiUpperCase,
        EditorPiUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorPsiUpperCase',
        EditorPsiUpperCase,
        EditorPsiUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorRhoUpperCase',
        EditorRhoUpperCase,
        EditorRhoUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorSigmaUpperCase',
        EditorSigmaUpperCase,
        EditorSigmaUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorTauUpperCase',
        EditorTauUpperCase,
        EditorTauUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorUpsilonUpperCase',
        EditorUpsilonUpperCase,
        EditorUpsilonUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorXiUpperCase',
        EditorXiUpperCase,
        EditorXiUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorZetaUpperCase',
        EditorZetaUpperCase,
        EditorZetaUpperCase2x,
    );
    addIcon(
        editor,
        'customEditorAngleUpperCase',
        EditorCongruent,
        EditorCongruent2x,
    );
    addIcon(editor, 'customEditorArrowUp', EditorArrowUp, EditorArrowUp2x);
    addIcon(
        editor,
        'customEditorArrowDown',
        EditorArrowDown,
        EditorArrowDown2x,
    );

    editor.ui.registry.addMenuButton(
        'customEditorInsertSymbolsAndSpecialCharacters',
        {
            icon: 'customEditorCharMap',
            tooltip: 'Inserir símbolo ou caractere especial',
            fetch: function (callback) {
                callback(getSubmenuItems(editor));
            },
        },
    );
    addIcon(editor, 'customEditorAngle', EditorAngle, EditorAngle2x);
}

/**
 * @param editor {EditorCustom}
 * @returns {[{onAction: *, icon: string, text: string, type: string}]}
 */
export function getSubmenuItems(editor) {
    return [
        {
            type: 'menuitem',
            // I18N
            text: 'Travessão',
            icon: 'customEditorDash',
            onAction: function () {
                insertDash(editor);
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Bullet',
            icon: 'customEditorBullet',
            onAction: function () {
                insertBullet(editor);
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Seta',
            icon: 'customEditorArrowForward',
            onAction: function () {
                insertArrowForward(editor);
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Quadrado',
            icon: 'customEditorSquare',
            onAction: function () {
                insertSquare(editor);
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Seta para cima',
            icon: 'customEditorArrowUp',
            onAction: function () {
                const { editorElements } = editor.custom.coreModule;
                editorElements.insertElementAtCursor(EDITOR_ELEMENT_ARROW_UP);
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Seta para baixo',
            icon: 'customEditorArrowDown',
            onAction: function () {
                const { editorElements } = editor.custom.coreModule;
                editorElements.insertElementAtCursor(EDITOR_ELEMENT_ARROW_DOWN);
            },
        },
        {
            type: 'nestedmenuitem',
            text: 'Alfabeto grego',
            icon: 'customEditorOmega',
            getSubmenuItems: () => {
                return [
                    {
                        type: 'nestedmenuitem',
                        text: 'Maiúsculos',
                        getSubmenuItems: () => {
                            return [
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Alfa',
                                    icon: 'customEditorAlfaUpperCase',
                                    onAction: function () {
                                        insertUppercaseAlfa(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Beta',
                                    icon: 'customEditorBetaUpperCase',
                                    onAction: function () {
                                        insertUppercaseBeta(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Gama',
                                    icon: 'customEditorGamaUpperCase',
                                    onAction: function () {
                                        insertUppercaseGama(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Delta',
                                    icon: 'customEditorDeltaUpperCase',
                                    onAction: function () {
                                        insertDelta(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Épsilon',
                                    icon: 'customEditorEpsilonUpperCase',
                                    onAction: function () {
                                        insertUppercaseEpsilon(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Zeta',
                                    icon: 'customEditorZetaUpperCase',
                                    onAction: function () {
                                        insertUppercaseZeta(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Eta',
                                    icon: 'customEditorEtaUpperCase',
                                    onAction: function () {
                                        insertUppercaseEta(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Theta',
                                    icon: 'customEditorThetaUpperCase',
                                    onAction: function () {
                                        insertUppercaseTheta(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Iota',
                                    icon: 'customEditorIotaUpperCase',
                                    onAction: function () {
                                        insertUppercaseIota(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Kappa',
                                    icon: 'customEditorKappaUpperCase',
                                    onAction: function () {
                                        insertUppercaseKappa(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Lambda',
                                    icon: 'customEditorLambdaUpperCase',
                                    onAction: function () {
                                        insertUppercaseLambda(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Mi',
                                    icon: 'customEditorMiUpperCase',
                                    onAction: function () {
                                        insertUppercaseMi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Ni',
                                    icon: 'customEditorNiUpperCase',
                                    onAction: function () {
                                        insertUppercaseNi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Xi',
                                    icon: 'customEditorXiUpperCase',
                                    onAction: function () {
                                        insertUppercaseXi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Omicron',
                                    icon: 'customEditorOmicronUpperCase',
                                    onAction: function () {
                                        insertUppercaseOmicron(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Pi',
                                    icon: 'customEditorPiUpperCase',
                                    onAction: function () {
                                        insertUppercasePi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Rho',
                                    icon: 'customEditorRhoUpperCase',
                                    onAction: function () {
                                        insertUppercaseRho(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Sigma',
                                    icon: 'customEditorSigmaUpperCase',
                                    onAction: function () {
                                        insertUppercaseSigma(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Tau',
                                    icon: 'customEditorTauUpperCase',
                                    onAction: function () {
                                        insertUppercaseTau(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Upsilon',
                                    icon: 'customEditorUpsilonUpperCase',
                                    onAction: function () {
                                        insertUppercaseUpsilon(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Phi',
                                    icon: 'customEditorPhiUpperCase',
                                    onAction: function () {
                                        insertUppercasePhi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Chi',
                                    icon: 'customEditorChiUpperCase',
                                    onAction: function () {
                                        insertUppercaseChi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Psi',
                                    icon: 'customEditorPsiUpperCase',
                                    onAction: function () {
                                        insertUppercasePsi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Ômega',
                                    icon: 'customEditorOmega',
                                    onAction: function () {
                                        insertOmega(editor);
                                    },
                                },
                            ];
                        },
                    },
                    {
                        type: 'nestedmenuitem',
                        text: 'Minúsculos',
                        getSubmenuItems: () => {
                            return [
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Alfa',
                                    icon: 'customEditorAlfaLowerCase',
                                    onAction: function () {
                                        insertLowercaseAlfa(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Beta',
                                    icon: 'customEditorBetaLowerCase',
                                    onAction: function () {
                                        insertLowercaseBeta(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Gama',
                                    icon: 'customEditorGamaLowerCase',
                                    onAction: function () {
                                        insertLowercaseGama(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Delta',
                                    icon: 'customEditorDeltaLowerCase',
                                    onAction: function () {
                                        insertLowercaseDelta(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Épsilon',
                                    icon: 'customEditorEpsilonLowerCase',
                                    onAction: function () {
                                        insertLowercaseEpsilon(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Zeta',
                                    icon: 'customEditorZetaLowerCase',
                                    onAction: function () {
                                        insertLowercaseZeta(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Eta',
                                    icon: 'customEditorEtaLowerCase',
                                    onAction: function () {
                                        insertLowercaseEta(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Theta',
                                    icon: 'customEditorThetaLowerCase',
                                    onAction: function () {
                                        insertLowercaseTheta(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Iota',
                                    icon: 'customEditorIotaLowerCase',
                                    onAction: function () {
                                        insertLowercaseIota(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Kappa',
                                    icon: 'customEditorKappaLowerCase',
                                    onAction: function () {
                                        insertLowercaseKappa(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Lambda',
                                    icon: 'customEditorLambdaLowerCase',
                                    onAction: function () {
                                        insertLambda(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Mi',
                                    icon: 'customEditorMiLowerCase',
                                    onAction: function () {
                                        insertLowercaseMi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Ni',
                                    icon: 'customEditorNiLowerCase',
                                    onAction: function () {
                                        insertLowercaseNi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Xi',
                                    icon: 'customEditorXiLowerCase',
                                    onAction: function () {
                                        insertLowercaseXi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Omicron',
                                    icon: 'customEditorOmicronLowerCase',
                                    onAction: function () {
                                        insertLowercaseOmicron(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Pi',
                                    icon: 'customEditorPiLowerCase',
                                    onAction: function () {
                                        insertLowercasePi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Rho',
                                    icon: 'customEditorRhoLowerCase',
                                    onAction: function () {
                                        insertLowercaseRho(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Sigma',
                                    icon: 'customEditorSigmaLowerCase',
                                    onAction: function () {
                                        insertLowercaseSigma(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Tau',
                                    icon: 'customEditorTauLowerCase',
                                    onAction: function () {
                                        insertLowercaseTau(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Upsilon',
                                    icon: 'customEditorUpsilonLowerCase',
                                    onAction: function () {
                                        insertLowercaseUpsilon(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Phi',
                                    icon: 'customEditorPhiLowerCase',
                                    onAction: function () {
                                        insertLowercasePhi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Chi',
                                    icon: 'customEditorChiLowerCase',
                                    onAction: function () {
                                        insertLowercaseChi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Psi',
                                    icon: 'customEditorPsiLowerCase',
                                    onAction: function () {
                                        insertLowercasePsi(editor);
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    // I18N
                                    text: 'Ômega',
                                    icon: 'customEditorOmegaLowerCase',
                                    onAction: function () {
                                        insertLowercaseOmega(editor);
                                    },
                                },
                            ];
                        },
                    },
                ];
            },
        },
        {
            type: 'nestedmenuitem',
            // I18N
            text: 'Matemático',
            icon: 'customEditorMath',
            getSubmenuItems: () => {
                return [
                    {
                        type: 'menuitem',
                        // I18N
                        text: 'Raiz n-ésima',
                        icon: 'customEditorSquareRoot',
                        onAction: function () {
                            const { editorElements } = editor.custom.coreModule;
                            editorElements.insertElementAtCursor(
                                EDITOR_ELEMENT_NTH_ROOT,
                            );
                        },
                    },
                    {
                        type: 'menuitem',
                        // I18N
                        text: 'Seguimento de reta',
                        icon: 'customEditorLineSegment',
                        onAction: function () {
                            const { editorElements } = editor.custom.coreModule;
                            editorElements.insertElementAtCursor(
                                EDITOR_ELEMENT_LINE_SEGMENT,
                            );
                        },
                    },
                    {
                        type: 'menuitem',
                        // I18N
                        text: 'Ângulo',
                        icon: 'customEditorAngle',
                        onAction: function () {
                            const { editorElements } = editor.custom.coreModule;
                            editorElements.insertElementAtCursor(
                                EDITOR_ELEMENT_ANGLE,
                            );
                        },
                    },
                    {
                        type: 'menuitem',
                        // I18N
                        text: 'Diferente',
                        icon: 'customEditorNotEqual',
                        onAction: function () {
                            insertNotEqual(editor);
                        },
                    },
                    {
                        type: 'menuitem',
                        // I18N
                        text: 'Congruente',
                        icon: 'customEditorCongruent',
                        onAction: function () {
                            insertCongruent(editor);
                        },
                    },
                    {
                        type: 'menuitem',
                        // I18N
                        text: 'Aproximadamente igual',
                        icon: 'customEditorApproximatelyEqual',
                        onAction: function () {
                            insertApproximatelyEqual(editor);
                        },
                    },
                    {
                        type: 'menuitem',
                        // I18N
                        text: 'Delta',
                        icon: 'customEditorDelta',
                        onAction: function () {
                            insertDelta(editor);
                        },
                    },
                    {
                        type: 'menuitem',
                        // I18N
                        text: 'Micrômetro',
                        icon: 'customEditorMicrometer',
                        onAction: function () {
                            insertMicrometer(editor);
                        },
                    },
                    {
                        type: 'menuitem',
                        // I18N
                        text: 'Mais ou menos',
                        icon: 'customEditorPlusMinus',
                        onAction: function () {
                            insertPlusMinus(editor);
                        },
                    },
                    {
                        type: 'menuitem',
                        // I18N
                        text: 'Pertence a',
                        icon: 'customEditorElementOf',
                        onAction: function () {
                            insertElementOf(editor);
                        },
                    },
                ];
            },
        },
    ];
}

/**
 * @param editor {EditorCustom}
 */
function insertNotEqual(editor) {
    insertChar(editor, '≠');
}

/**
 * @param editor {EditorCustom}
 */
function insertCongruent(editor) {
    insertChar(editor, '≡');
}

/**
 * @param editor {EditorCustom}
 */
function insertDelta(editor) {
    insertChar(editor, '∆');
}

/**
 * @param editor {EditorCustom}
 */
function insertMicrometer(editor) {
    insertChar(editor, 'µm');
}

/**
 * @param editor {EditorCustom}
 */
function insertPlusMinus(editor) {
    insertChar(editor, '±');
}

/**
 * @param editor {EditorCustom}
 */
function insertElementOf(editor) {
    insertChar(editor, '∈');
}

/**
 * @param editor {EditorCustom}
 */
function insertApproximatelyEqual(editor) {
    insertChar(editor, '≅');
}

/**
 * @param editor {EditorCustom}
 */
function insertOmega(editor) {
    insertChar(editor, '&Omega;');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseOmega(editor) {
    insertChar(editor, 'ω');
}

/**
 * @param editor {EditorCustom}
 */
function insertLambda(editor) {
    insertChar(editor, 'λ');
}

/**
 * @param editor {EditorCustom}
 */
function insertBullet(editor) {
    insertChar(editor, '• ');
}

/**
 * @param editor {EditorCustom}
 */
function insertSquare(editor) {
    insertChar(editor, '▢ ');
}

/**
 * @param editor {EditorCustom}
 */
function insertArrowForward(editor) {
    insertChar(editor, '→ ');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseTheta(editor) {
    insertChar(editor, 'θ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseTheta(editor) {
    insertChar(editor, 'Θ'); // looks like lower, but isn't the same char
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseAlfa(editor) {
    insertChar(editor, 'α');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseAlfa(editor) {
    insertChar(editor, 'Α');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseBeta(editor) {
    insertChar(editor, 'β');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseBeta(editor) {
    insertChar(editor, 'Β');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseGama(editor) {
    insertChar(editor, 'γ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseGama(editor) {
    insertChar(editor, 'Γ');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseDelta(editor) {
    insertChar(editor, 'δ');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseEpsilon(editor) {
    insertChar(editor, 'ε');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseEpsilon(editor) {
    insertChar(editor, 'Ε');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseZeta(editor) {
    insertChar(editor, 'ζ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseZeta(editor) {
    insertChar(editor, 'Ζ');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseEta(editor) {
    insertChar(editor, 'η');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseEta(editor) {
    insertChar(editor, 'Η');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseIota(editor) {
    insertChar(editor, 'ι');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseIota(editor) {
    insertChar(editor, 'Ι');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseKappa(editor) {
    insertChar(editor, 'κ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseKappa(editor) {
    insertChar(editor, 'Κ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseLambda(editor) {
    insertChar(editor, 'Λ'); // looks like lower, but isn't the same char
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseMi(editor) {
    insertChar(editor, 'μ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseMi(editor) {
    insertChar(editor, 'Μ');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseNi(editor) {
    insertChar(editor, 'ν');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseNi(editor) {
    insertChar(editor, 'Ν');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseXi(editor) {
    insertChar(editor, 'ξ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseXi(editor) {
    insertChar(editor, 'Ξ');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseOmicron(editor) {
    insertChar(editor, 'ο');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseOmicron(editor) {
    insertChar(editor, 'Ο'); // looks like lower, but isn't the same char
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercasePi(editor) {
    insertChar(editor, 'π');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercasePi(editor) {
    insertChar(editor, 'Π');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseRho(editor) {
    insertChar(editor, 'ρ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseRho(editor) {
    insertChar(editor, 'Ρ'); // looks like lower, but isn't the same char
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseSigma(editor) {
    insertChar(editor, 'σ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseSigma(editor) {
    insertChar(editor, 'Σ');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseTau(editor) {
    insertChar(editor, 'τ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseTau(editor) {
    insertChar(editor, 'Τ');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseUpsilon(editor) {
    insertChar(editor, 'υ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseUpsilon(editor) {
    insertChar(editor, 'Υ');
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercasePhi(editor) {
    insertChar(editor, 'φ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercasePhi(editor) {
    insertChar(editor, 'Φ'); // looks like lower, but isn't the same char
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercaseChi(editor) {
    insertChar(editor, 'χ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercaseChi(editor) {
    insertChar(editor, 'Χ'); // looks like lower, but isn't the same char
}

/**
 * @param editor {EditorCustom}
 */
function insertLowercasePsi(editor) {
    insertChar(editor, 'Ψ');
}

/**
 * @param editor {EditorCustom}
 */
function insertUppercasePsi(editor) {
    insertChar(editor, 'ψ'); // looks like lower, but isn't the same char
}

/**
 * @param editor {EditorCustom}
 * @param char {string}
 */
function insertChar(editor, char) {
    if (!getCurrentPage(editor)) return;
    editor.selection.setContent(char);
    editor.focus();
    /**
     * @type {PageDataChangedEvent}
     */
    const pageDataChangedEvent = { caretPosition: getCaretPosition(editor) };
    editor.fire('pageDataChanged', pageDataChangedEvent);
}

/**
 * @param editor {EditorCustom}
 */
function insertDash(editor) {
    insertChar(editor, '— ');
}

export const symbols = [
    '•',
    '→',
    '▢',
    '≠',
    '≡',
    '≅',
    '∆',
    'µ',
    '±',
    '∈',
    'Ω',
    'λ',
    'θ',
];
