import nextId from 'react-id-generator';
import { forwardRef, useRef } from 'react';
import Field from './Field';

function FieldCheckbox({ children, inputs, validationError, ...props }, ref) {
    const id = props.id ?? nextId('field-checkbox');
    const idError = `${id}-error`;
    const fieldRef = useRef();

    function createInputs() {
        let array = [];
        for (let input of inputs) {
            const { label } = input;
            input = { ...input };
            delete input.label;
            const type = input.type === 'radio' ? input.type : 'checkbox';
            array.push(
                <label key={nextId(`$input-${type}`)}>
                    <input
                        type={type}
                        aria-invalid={!!validationError}
                        aria-errormessage={idError}
                        name={id}
                        {...input}
                    />
                    {label}
                </label>,
            );
        }
        return array;
    }

    return (
        <Field
            ref={fieldRef}
            {...props}
            idInput={id}
            idError={idError}
            bottomChildren={children}
            validationError={validationError}
        >
            <div className={'field-inputs'}>{createInputs()}</div>
        </Field>
    );
}

export default forwardRef(FieldCheckbox);
