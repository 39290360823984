import {
    RoleEnum,
    SaveDocumentValidationErrorTxt,
} from 'plataforma-braille-common';
import {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import CloseModalButton from '../components/modal/CloseModalButton';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import * as DocumentService from '../services/DocumentService';
import FieldShareDocument from '../components/FieldShareDocument';

/**
 * @typedef {object} ShareDocumentModalFunctions
 * @property {function(DocumentDto, function(UserWithAccessDto[]))} showShareDocumentModal
 */

/**
 * @type {React.ForwardRefExoticComponent<>}
 */
const ShareDocumentModal = forwardRef(({}, ref) => {
    const [show, setShow] = useState(false);
    /**
     * @type {DocumentDto | null}
     */
    const docInitialValue = null;
    const [doc, setDoc] = useState(docInitialValue);
    /**
     * @type {UserWithAccessDto[]}
     */
    const initialUserSelected = [];
    const [userSelected, setUserSelected] = useState(initialUserSelected);
    // linearization as default because it's the first on the list
    const [teamSelected, setTeamSelected] = useState(RoleEnum.LINEARIZATION);
    const [loadingDoc, setLoadingDoc] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const { backendConnectionError, setLoading } =
        useContext(EnvironmentContext);
    /**
     * @type {React.MutableRefObject<function(UserWithAccessDto[]):void | null>}
     */
    const onUpdatedCallbackRef = useRef(null);
    const currentShowRef = useRef(false);

    useImperativeHandle(ref, () => ({ showShareDocumentModal }));

    async function fetchDocument() {
        try {
            setLoadingDoc(true);
            const { userWithAccess } = await DocumentService.getDocument(
                doc.id,
                null,
                false,
            );
            setUserSelected(userWithAccess);
        } catch (e) {
            setShow(false);
            backendConnectionError('Fail to fetch document.', e);
        } finally {
            setLoadingDoc(false);
        }
    }

    useEffect(() => {
        setLoading(loadingSave | loadingDoc, true);
    }, [loadingSave, loadingDoc]);

    useEffect(() => {
        if (currentShowRef.current === show) return;
        currentShowRef.current = show;

        if (show) {
            // noinspection JSIgnoredPromiseFromCall
            fetchDocument();
        } else {
            onUpdatedCallbackRef.current = null;
        }
    }, [show]);

    /**
     * @param document {BrailleDocument}
     * @param onUpdatedCallback {function(UserWithAccessDto[]):void}
     */
    function showShareDocumentModal(document, onUpdatedCallback) {
        setDoc(document);
        onUpdatedCallbackRef.current = onUpdatedCallback;
        setShow(true);
    }

    async function saveDocument() {
        const title = 'Usuários com acesso';
        try {
            setLoadingSave(true);
            await DocumentService.editDocument(doc.id, {
                userWithAccess: userSelected,
            });
            setShow(false);
            if (onUpdatedCallbackRef.current) {
                onUpdatedCallbackRef.current(userSelected);
            }
        } catch (e) {
            backendConnectionError(
                'Fail to edit document.',
                e,
                null,
                title,
                SaveDocumentValidationErrorTxt,
            );
        } finally {
            setLoadingSave(false);
        }
    }

    return (
        <>
            <div
                className={`modal default-modal share-document-modal ${show ? 'show' : ''}`}
            >
                <div className={'backdrop'} />
                <div className={'container'} style={{ minWidth: '60%' }}>
                    <CloseModalButton onClick={() => setShow(false)} />
                    <h2>
                        {/*I18N*/}
                        {'Compartilhar com'}
                    </h2>
                    <section>
                        <h3>{`${doc?.name ?? 'Compartilhar'}`}</h3>

                        <FieldShareDocument
                            setUserSelected={setUserSelected}
                            userSelected={userSelected}
                            setTeamSelected={setTeamSelected}
                            teamSelected={teamSelected}
                        />

                        <div className={'controls'}>
                            <button
                                className={'button'}
                                onClick={() => setShow(false)}
                            >
                                {/*I18N*/}
                                {'Cancelar'}
                            </button>
                            <button
                                className={`button primary ${loadingSave ? 'loading' : ''}`}
                                onClick={saveDocument}
                            >
                                {/*I18N*/}
                                {'Compartilhar'}
                            </button>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
});

ShareDocumentModal.displayName = 'ShareDocumentModal';

export default ShareDocumentModal;
