import './TemplateDetailsModal.scss';
import CloseModalButton from '../components/modal/CloseModalButton';
import TemplateMiniature from './TemplateMiniature';
import PropTypes from 'prop-types';

function TemplateDetailModal({ template, show, onClose, onTemplateSelected }) {
    const name = template?.name;
    const pageWidth = template?.pageWidth;
    const pageHeight = template?.pageHeight;
    const pageMeasure = template?.pageMeasure?.toLowerCase();
    const brailleCellColCount = template?.brailleCellColCount;
    const brailleCellRowCount = template?.brailleCellRowCount;
    const inkPageMarginLeft = template?.inkPageMarginLeft;
    const inkPageMarginRight = template?.inkPageMarginRight;
    const inkPageMarginTop = template?.inkPageMarginTop;
    const inkPageMarginBottom = template?.inkPageMarginBottom;

    const miniatureProps = {
        ...template,
        maxWidth: 400,
        showDetails: false,
    };

    return (
        <div
            className={`modal default-modal template-details-modal ${show ? 'show' : ''}`}
        >
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={onClose} />
                <div className={'gd'}>
                    <div className={'gd-col-4--desktop gd-skip-1--desktop'}>
                        <div>
                            {template ? (
                                <TemplateMiniature {...miniatureProps} />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className={'gd-col-3--desktop gd-skip-1--desktop'}>
                        <div className={'info-container'}>
                            <h2>{name}</h2>

                            <div className={'info-block'}>
                                <div className={'info-grid'}>
                                    <div>
                                        <strong>
                                            {/*I18N*/}
                                            {'Largura'}
                                        </strong>
                                        <div>
                                            {`${pageWidth}${pageMeasure}`}
                                        </div>
                                    </div>
                                    <div>
                                        <strong>
                                            {/*I18N*/}
                                            {'Altura'}
                                        </strong>
                                        <div>
                                            {`${pageHeight}${pageMeasure}`}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'info-block'}>
                                <h3>{'Grade Braille'}</h3>
                                <div className={'info-grid'}>
                                    <div>
                                        <strong>
                                            {/*I18N*/}
                                            {'Caracteres'}
                                        </strong>
                                        <div>{brailleCellColCount}</div>
                                    </div>
                                    <div>
                                        <strong>
                                            {/*I18N*/}
                                            {'Linhas'}
                                        </strong>
                                        <div>{brailleCellRowCount}</div>
                                    </div>
                                </div>
                            </div>

                            <div className={'info-block'}>
                                <h3>{'Margens'}</h3>
                                <div className={'info-grid'}>
                                    <div>
                                        <strong>
                                            {/*I18N*/}
                                            {'Esquerda'}
                                        </strong>
                                        <div>{`${inkPageMarginLeft}mm`}</div>
                                    </div>
                                    <div>
                                        <strong>
                                            {/*I18N*/}
                                            {'Superior'}
                                        </strong>
                                        <div>{`${inkPageMarginTop}mm`}</div>
                                    </div>
                                </div>
                                <div className={'info-grid'}>
                                    <div>
                                        <strong>
                                            {/*I18N*/}
                                            {'Direita'}
                                        </strong>
                                        <div>{`${inkPageMarginRight}mm`}</div>
                                    </div>
                                    <div>
                                        <strong>
                                            {/*I18N*/}
                                            {'Inferior'}
                                        </strong>
                                        <div>{`${inkPageMarginBottom}mm`}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={'controls'}>
                                <button className={'button'} onClick={onClose}>
                                    {/*I18N*/}
                                    {'Cancelar'}
                                </button>
                                <button
                                    className={'button primary'}
                                    onClick={onTemplateSelected}
                                >
                                    {/*I18N*/}
                                    {'Selecionar'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={'gd-col-1--desktop'}></div>
                </div>
            </div>
        </div>
    );
}

TemplateDetailModal.propTypes = {
    template: PropTypes.object,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onTemplateSelected: PropTypes.func.isRequired,
};

export default TemplateDetailModal;
