/**
 * @param char {string}
 * @returns {boolean}
 */
function isLatinChar(char) {
    const code = char.charCodeAt(0);
    return (
        (code >= 0x0041 && code <= 0x005a) || // Upper-case Latin
        (code >= 0x0061 && code <= 0x007a) || // Lower-case Latin
        (code >= 0x00c0 && code <= 0x00ff) || // Latin letters with diacritics
        (code >= 0x0100 && code <= 0x017f)
    ); // Extended Latin letters (with some exclusions)
}

/**
 * @param text {string}
 * @returns {boolean}
 */
export function isLatinText(text) {
    if (!text) return false;
    text = text.trim();
    for (let i = 0; i < text.length; i++) {
        if (!isLatinChar(text.charAt(i))) {
            return false;
        }
    }
    return true;
}

/**
 * Check if char needs a returner signal
 * @param letter
 * @return {boolean}
 */
export function isLetterBetweenAtoJLatin(letter) {
    const validLatinLetters = [
        'a',
        'á',
        'à',
        'ã',
        'ä',
        'e',
        'é',
        'è',
        'ê',
        'ë',
        'i',
        'í',
        'ì',
        'î',
        'ï',
        'ç',
    ];
    const lowerCaseLetter = letter.toLowerCase();
    // ASCII letter check.
    if (
        lowerCaseLetter.charCodeAt(0) >= 97 &&
        lowerCaseLetter.charCodeAt(0) <= 106
    ) {
        return true;
    }
    return validLatinLetters.includes(lowerCaseLetter);
}

/**
 * @param text {string}
 * @returns {boolean}
 */
export function isUpperCase(text) {
    return (
        isLatinText(text) &&
        text === text.toUpperCase() &&
        text.toLowerCase() !== text.toUpperCase()
    );
}

/**
 * @param text {string}
 * @returns {boolean}
 */
export function isLowerCase(text) {
    return (
        isLatinText(text) &&
        text === text.toLowerCase() &&
        text.toLowerCase() !== text.toUpperCase()
    );
}

/**
 * @param text {string}
 * @returns {number}
 */
export function wordCount(text) {
    return text.trim().split(/\s+/).length;
}

/**
 * @param text {string}
 * @returns {boolean}
 */
export function isBrNumber(text) {
    const number = parseFloat(text.replaceAll('.', '').replaceAll(',', '.'));
    return !isNaN(number);
}

/**
 * @param text {string}
 * @returns {number}
 */
export function upperCharCount(text) {
    let count = 0;
    for (let char of text) {
        if (isUpperCase(char) && isLatinText(char)) count++;
    }
    return count;
}

/**
 * @param text {string}
 * @returns {string | null}
 */
export function removeAccents(text) {
    if (text == null) return null;
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * @param txt {string}
 * @return {boolean}
 */
export function isRomanNumeral(txt) {
    const regex = /^(M{0,3})(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/;
    return regex.test(txt);
}

/**
 * @param text {string}
 * @returns {boolean}
 */
export function containsBrailleChar(text) {
    for (const char of text) {
        if (char.charCodeAt(0) >= 0x2800 && char.charCodeAt(0) <= 0x28ff) {
            return true;
        }
    }
    return false;
}

/**
 * @param text {string}
 * @returns {string | null}
 */
export function removeInvisibleSpacesFromText(text) {
    if (text == null) return null;
    return text.replace(/\uFEFF/g, '').replace(/\u200B/g, '');
}

/**
 *
 * @param text {string}
 * @returns {string | null}
 */
export function normalizeSpaces(text) {
    if (text == null) return null;
    return removeInvisibleSpacesFromText(text).replace(/[^\S\r\n]/g, ' ');
}
