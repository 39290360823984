/**
 * @param duration {number}
 * @param onProgress {(progress: number) => void}
 * @returns {{cancel: () => void}}
 */
function startSlowingTimer(duration, onProgress) {
    let timerId;
    const start = Date.now();
    const theoreticalEnd = start + duration;
    let stepSize = 100 / ((theoreticalEnd - start) / 1000);
    let slowdownFactor = 0.5;
    let progress = 0;
    let delay = 1000;

    function tick() {
        if (progress >= 90) {
            stepSize *= slowdownFactor;
            if (stepSize <= 1) {
                stepSize = 1;
                delay = delay / slowdownFactor;
            }
        }
        progress += stepSize;
        if (progress >= 100) {
            progress = 99;
        }
        onProgress(progress / 100);
        timerId = setTimeout(tick, delay);
    }

    tick();

    function cancel() {
        onProgress(100);
        clearTimeout(timerId);
    }

    return { cancel };
}

export default startSlowingTimer;
