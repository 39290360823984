import PropTypes from 'prop-types';
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import './FloatDialog.scss';

/**
 * @typedef {object} Position
 * @property {number} top
 * @property {number} offset
 * @property {number} left
 */

/**
 * @typedef {object} FloatDialogParams
 * @property {EditorCustom} editor
 * @property {HTMLElement | null | undefined} attachEditorElement
 * @property {Node} children
 * @property {number | null | undefined} topOffset
 * @property {function(rootDiv: HTMLElement, attachedElementRect: DOMRect, toolbarOffset: number): Position | null | undefined} calculatePosition
 */

/**
 * @typedef {object} FloatDialogFunctions
 * @property {function()} recalculePosition
 */

/**
 * @typedef {object} FloatDialogFunctions
 * @property {function()} recalculePosition
 */

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<FloatDialogParams>>}
 */
export const FloatDialog = forwardRef(
    (
        {
            editor,
            attachEditorElement,
            children,
            topOffset = 0,
            calculatePosition = null,
        },
        ref,
    ) => {
        /**
         * @type {null | number}
         */
        const initialValue = null;
        const [left, setLeft] = useState(initialValue);
        const [top, setTop] = useState(initialValue);
        const attachEditorElementImageRef = useRef(attachEditorElement);
        /**
         * @type {React.MutableRefObject<HTMLElement | null>}
         */
        const rootDivRef = useRef(null);
        const hasVisibleRef = useRef(true);

        /**
         * @return { Position | null }
         */
        function getPosition() {
            if (!attachEditorElementImageRef.current) {
                return null;
            }
            const toolbar = document.querySelector('.tox-editor-header');
            const offset = toolbar?.offsetHeight ?? 0;

            const rect =
                attachEditorElementImageRef.current.getBoundingClientRect();

            if (calculatePosition) {
                return calculatePosition(rootDivRef.current, rect, offset);
            } else {
                return {
                    left:
                        rect.left +
                        rect.width / 2 -
                        (rootDivRef.current?.offsetWidth ?? 0) / 2,
                    top: rect.top + offset + rect.height,
                    offset,
                };
            }
        }

        function recalculePosition() {
            const position = getPosition();
            if (position) {
                const { left, top, offset } = position;
                setLeft(left);
                setTop(top);
                hasVisibleRef.current = top >= offset;
            } else {
                hasVisibleRef.current = false;
            }
        }

        useEffect(() => {
            attachEditorElementImageRef.current = attachEditorElement;
            if (attachEditorElement) {
                recalculePosition();
            }
        }, [attachEditorElement]);

        useImperativeHandle(ref, () => ({ recalculePosition }));

        function scroll() {
            if (!attachEditorElementImageRef.current) return;
            const { left, top, offset } = getPosition();
            setLeft(left);
            setTop(top);
            hasVisibleRef.current = top >= offset;
        }

        useEffect(() => {
            editor?.getDoc().addEventListener('scroll', scroll);
            return () => {
                editor?.getDoc()?.removeEventListener('scroll', scroll);
            };
        }, [editor]);

        return (
            <div
                ref={rootDivRef}
                className={`float-dialog${left != null && top != null && attachEditorElement && hasVisibleRef.current ? ' show' : ''}`}
                style={{
                    left: left ?? 0,
                    top: (top ?? 0) + topOffset,
                }}
            >
                {children}
            </div>
        );
    },
);

FloatDialog.displayName = 'FloatDialog';

FloatDialog.propTypes = {
    editor: PropTypes.any,
    attachEditorElement: PropTypes.any,
    children: PropTypes.node.isRequired,
    topOffset: PropTypes.number,
    calculatePosition: PropTypes.func,
};

export default FloatDialog;
