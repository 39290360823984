import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import {
    generateId,
    getRootNodeInPage,
    getClosestTextNode,
    isEditorElement,
    isEditorPage,
} from '../EditorUtil';
import {
    breakParagraphsEditorElementContainer,
    clearEditorElementLinesCount,
    createInvisibleParagraphBreak,
    elementCanBeInsertedAtSelection,
    fixInvisibleParagraphBreak,
    isElementContinuation,
    markLines,
    setEditorElementLinesCount,
} from '../EditorElements';
import { getCaretPosition } from '../CaretPath';
import { registerEditorElement } from './Instances';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import {
    backPropagateBreaksToText,
    convertElementToBraille,
    ParagraphBreakType,
} from '../../../../../conversion/braille/HtmlToBraille';
import { getBrailleParagraphs } from '../BrailleView';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';

export const EDITOR_ELEMENT_LIST = 'EDITOR_ELEMENT_LIST';

/**
 * @typedef {object} Data
 * @property {('•'|'→'|'▢')} symbol
 * @property {false} isMenuAction
 */

/**
 * @param node {HTMLElement | Node | null}
 * @returns {HTMLElement | null}
 */
export function getClosestEditorElementList(node) {
    if (!node) return null;
    let walk = node;
    while (walk) {
        if (isEditorElementList(walk)) return walk;
        walk = walk.parentNode;
    }
    return node;
}

/**
 * @param editor {EditorCustom}
 * @param element {HTMLElement | ChildNode}
 * @return {undefined}
 */
export function focusOnCurrentEditorElementList(editor, element) {
    const value = element.querySelector('.value');
    const text = getClosestTextNode(value.lastChild);
    if (text) {
        editor.selection.setCursorLocation(text, text.textContent.length);
    }
}

/**
 * @param editor {EditorCustom}
 * @return {undefined}
 */
export function insertEditorElementListOnEnterPressed(editor) {
    const element = getClosestEditorElementList(editor.selection.getNode());
    const symbol = element
        .querySelector('.symbol')
        ?.textContent.replace(/\u00A0/g, '');
    const { editorElements } = editor.custom.coreModule;
    const editorElementList =
        editorElements.getEditorElementInstance(EDITOR_ELEMENT_LIST);
    editorElementList.insertElementAtCursor(editor, { symbol });
}

/**
 * @param editor {EditorCustom}
 * @return {undefined}
 */
export function removeCurrentEditorElementList(editor) {
    const element = getClosestEditorElementList(editor.selection.getNode());
    editor.dom.remove(element);
    editor.focus();
}

/**
 * @param editor {EditorCustom}
 * @returns {[{onAction: *, icon: string, text: string, type: string}]}
 */
export function getSubmenuItems(editor) {
    const { editorElements } = editor.custom.coreModule;
    const editorElementList =
        editorElements.getEditorElementInstance(EDITOR_ELEMENT_LIST);
    return [
        {
            type: 'menuitem',
            // I18N
            text: 'Lista tipo Bullet',
            icon: 'customEditorBullet',
            onAction: function () {
                editorElementList.insertElementAtCursor(editor, {
                    symbol: '•',
                    isMenuAction: true,
                });
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Lista tipo Seta',
            icon: 'customEditorArrowForward',
            onAction: function () {
                editorElementList.insertElementAtCursor(editor, {
                    symbol: '→',
                    isMenuAction: true,
                });
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Lista tipo Quadrado',
            icon: 'customEditorSquare',
            onAction: function () {
                editorElementList.insertElementAtCursor(editor, {
                    symbol: '▢',
                    isMenuAction: true,
                });
            },
        },
    ];
}

/**
 * @param editor {EditorCustom | undefined | null}
 * @param data {Data}
 * @return {HTMLElement}
 */
export function createEditorElementList(editor = null, data) {
    /**
     * @type {HTMLElement}
     */
    const editorElement = document.createElement('editor-element');
    const idPrefix = 'editor-element-list';
    const elementId = generateId(editor, idPrefix);
    editorElement.setAttribute('contentEditable', 'false');
    editorElement.setAttribute('type', 'list');
    editorElement.setAttribute('id', elementId);

    /**
     * @type {HTMLElement}
     */
    const symbolDiv = document.createElement('div');
    symbolDiv.setAttribute('class', 'symbol');
    symbolDiv.setAttribute('contentEditable', 'false');
    symbolDiv.innerHTML = data?.symbol + '&nbsp;';
    editorElement.appendChild(symbolDiv);

    /**
     * @type {HTMLElement}
     */
    const valueDiv = document.createElement('div');
    valueDiv.setAttribute('class', 'value');
    valueDiv.setAttribute('contentEditable', 'true');
    editorElement.appendChild(valueDiv);

    const content = editor.selection.getContent();
    if (content) {
        let selectedElement = editor.selection.getNode();
        while (selectedElement) {
            if (
                isEditorPage(selectedElement) ||
                isEditorPage(selectedElement.parentElement)
            ) {
                break;
            } else {
                selectedElement = selectedElement.parentElement;
            }
        }
        valueDiv.innerHTML = isEditorPage(selectedElement)
            ? content
            : selectedElement.outerHTML;
    } else {
        valueDiv.innerText = ZERO_WIDTH_NB_CHAR;
    }

    return editorElement;
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementList(node) {
    if (!node) return false;
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.toLowerCase() === 'list'
    );
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {boolean}
 */
export function isInsideEditorElementList(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementList(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @param editor {EditorCustom}
 * @param editorElement {HTMLElement}
 */

function focusOnValue(editor, editorElement) {
    const valueDiv = editorElement.querySelector('.value');
    editorElement.replaceWith(editorElement, createInvisibleParagraphBreak());
    editor.focus();
    const text = getClosestTextNode(valueDiv.lastChild);
    if (text) {
        editor.selection.setCursorLocation(text, text.textContent.length);
    } else {
        editor.selection.setCursorLocation(valueDiv, 0);
    }
}

/**
 * @param editor {EditorCustom}
 */
function dataChanged(editor) {
    /**
     * @type {PageDataChangedEvent}
     */
    const pageDataChangedEvent = {
        caretPosition: getCaretPosition(editor),
    };
    editor.fire('pageDataChanged', pageDataChangedEvent);
}

/**
 * @implements {EditorElement}
 */
export class EditorElementList {
    constructor() {}

    /**
     * @param editor {EditorCustom}
     */
    initialize(editor) {
        /**
         * @param e {InputEvent}
         */
        const onBeforeInput = (e) => {
            const selectedNode = editor.selection.getNode();
            const isSelectedNodeEmpty = !selectedNode.textContent.trim();

            if (e?.inputType === 'insertLineBreak') {
                if (isInsideEditorElementList(selectedNode)) {
                    e.preventDefault();
                    if (!isSelectedNodeEmpty) {
                        insertEditorElementListOnEnterPressed(editor);
                    } else {
                        removeCurrentEditorElementList(editor);
                    }
                }
            } else if (e?.inputType === 'insertParagraph') {
                if (isInsideEditorElementList(selectedNode)) {
                    editor.focus();
                    const brNode = editor.getDoc().createElement('br');
                    const zeroWidthNode = editor
                        .getDoc()
                        .createTextNode(ZERO_WIDTH_NB_CHAR);
                    editor.selection.getRng().insertNode(zeroWidthNode);
                    editor.selection.getRng().insertNode(brNode);
                    const range = editor.selection.getRng();
                    range.setStartAfter(zeroWidthNode);
                    range.setEndAfter(zeroWidthNode);
                    editor.selection.setRng(range);
                }
            } else if (e?.inputType === 'deleteContentBackward') {
                if (isInsideEditorElementList(selectedNode)) {
                    if (isSelectedNodeEmpty) {
                        e.preventDefault();
                        removeCurrentEditorElementList(editor);
                    }
                } else {
                    const caretPosition = getCaretPosition(editor);
                    if (!caretPosition) return;
                    let previousElement =
                        caretPosition.nodePath[caretPosition.path.length - 1];
                    if (
                        isEditorElementList(previousElement) ||
                        (previousElement?.tagName === 'BR' &&
                            previousElement?.style?.display === 'none' &&
                            isEditorElementList(
                                previousElement?.previousSibling,
                            ))
                    ) {
                        e.preventDefault();
                        focusOnCurrentEditorElementList(
                            editor,
                            previousElement.previousSibling,
                        );
                    }
                }
            }
        };

        editor.on('beforeInput', onBeforeInput);
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'list';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementList(node);
    }

    /**
     * @return {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.value'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @param data {Data}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null, data) {
        return createEditorElementList(editor, data);
    }

    /**
     * @param {EditorCustom} editor
     * @param {Data} data
     * @return {boolean}
     */
    insertElementAtCursor(editor, data) {
        const selectedNode = editor.selection.getNode();

        if (data.isMenuAction && isInsideEditorElementList(selectedNode)) {
            const currentElement = getClosestEditorElementList(selectedNode);
            const currentSymbolElement =
                currentElement.querySelector('.symbol');
            currentSymbolElement.innerHTML = data?.symbol + '&nbsp;';
            focusOnValue(editor, currentElement);
            dataChanged(editor);
            return true;
        }

        let editorElement = this.createEditorElement(editor, data);
        if (!isInsideEditorElementList(selectedNode)) {
            if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
                return false;
            }
        }

        editor.undoManager.transact(() => {
            const id = editorElement.getAttribute('id');
            // attach the editor-element at cursor
            if (isEditorPage(selectedNode)) {
                // noinspection JSCheckFunctionSignatures
                editor.selection.setNode(editorElement);
            } else {
                editor.selection.setContent('');
                let insertAt = selectedNode;
                insertAt = getRootNodeInPage(insertAt);

                if (!insertAt) {
                    console.warn(
                        'No container found to insert editor element list.',
                    );
                    return;
                }

                insertAt.replaceWith(insertAt, editorElement);
            }
            editorElement = editor.dom.get(id);
            focusOnValue(editor, editorElement);
            dataChanged(editor);
        });

        return true;
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [...container.querySelectorAll('editor-element[type="list"]')];
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        /**
         * @type {HTMLElement}
         */
        let value = element.querySelector('.value');
        if (!value) {
            value = document.createElement('div');
            value.className = 'value';
            element.innerHTML = ZERO_WIDTH_NB_CHAR;
            element.appendChild(value);
        }
        if (!value.innerText.trim().length) {
            value.innerHTML = ZERO_WIDTH_NB_CHAR;
        }
        fixInvisibleParagraphBreak(element);
    }

    /**
     * @returns {string[]}
     */
    getContextMenu() {
        return [
            'customContextMenuAddParagraphBreakAbove',
            'customContextMenuAddParagraphBreakBellow',
            '|',
            'customContextMenuRemove',
        ];
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        const rawOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );
        const ignoreElement = flags.includes(
            BrailleFacilConversionFlag.IGNORE_CUSTOM_ELEMENT,
        );

        const symbolContainer = element.querySelector('.symbol');
        const valueContainer = element.querySelector('.value');

        const symbol = symbolContainer.textContent;

        let brailleData = convertElementToBraille(
            document.createTextNode(symbol),
            editorElements,
            brailleDocument,
            [...flags],
        );

        brailleData += convertElementToBraille(
            valueContainer,
            editorElements,
            brailleDocument,
            [...flags],
        );

        brailleData = markLines(brailleData, MARK_CHAR.RECOIL_BLOCK);

        if (!ignoreElement) {
            clearEditorElementLinesCount(element);
        }

        const { breaks, paragraphs } = getBrailleParagraphs(
            brailleData,
            brailleDocument,
        );

        if (!rawOutput) {
            let txtData = extractRecursively(
                document.createTextNode(symbol),
                flags,
                editorElements,
                brailleDocument,
            );

            txtData += extractRecursively(
                valueContainer,
                flags,
                editorElements,
                brailleDocument,
            );
            const textParagraphs = backPropagateBreaksToText(txtData, breaks);
            let output = '';

            /**
             * @type {ChildNode | HTMLElement}
             */
            let previousElement = element.previousSibling;
            /**
             *
             * @type {ChildNode | HTMLElement}
             */
            let nextElement = element.nextSibling;
            if (
                previousElement?.tagName === 'BR' &&
                previousElement?.style?.display === 'none'
            ) {
                previousElement = previousElement.previousSibling;
            }
            if (
                nextElement?.tagName === 'BR' &&
                nextElement?.style?.display === 'none'
            ) {
                nextElement = nextElement.nextSibling;
            }
            const firstListElement = !isEditorElementList(previousElement);
            const lastListElement = !isEditorElementList(nextElement);

            if (firstListElement) {
                output += '\r\n<R+>\r\n';
            } else {
                // https://sgm.codebit.com.br/manutencao/57800#commment-22
                output += ' ';
            }

            if (isElementContinuation(element)) {
                output += '  ';
            }
            output += textParagraphs.join('\r\n');

            if (lastListElement) {
                output += '\r\n<R->';
            }
            return output;
        }

        if (!ignoreElement) {
            for (const brk of breaks) {
                if (brk.type === ParagraphBreakType.RECOIL) {
                    brk.type = ParagraphBreakType.NORMAL;
                } else if (brk.type === ParagraphBreakType.HYPHEN_WITH_RECOIL) {
                    brk.type = ParagraphBreakType.HYPHEN;
                }
            }

            breakParagraphsEditorElementContainer(element, breaks, true);
            setEditorElementLinesCount(element, paragraphs.length);
        }
        return markLines(paragraphs, MARK_CHAR.RAW_DATA).join('\r\n');
    }
}

registerEditorElement(EDITOR_ELEMENT_LIST, new EditorElementList());
