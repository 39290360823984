import { syllable } from 'stress-pt';
import { isBrNumber } from './TextUtil';

/**
 * @param str {string}
 * @returns {boolean}
 */
function hasAccent(str) {
    // A expressão regular corresponde a qualquer um dos caracteres com algum tipo de acento
    const regex = /[áéíóúÁÉÍÓÚâêîôûÂÊÎÔÛãõÃÕ]/;
    return regex.test(str);
}

/**
 * @param word {string}
 * @returns {string[]}
 */
export function breakWordToSyllables(word) {
    // numbers should not be broken (#43708)
    if (isBrNumber(word)) {
        return [word];
    }

    const syllables = syllable(word).split('|');

    // pt-BR exceptions
    if (
        ['ci'].includes(syllables[syllables.length - 2]?.toLowerCase()) &&
        syllables[syllables.length - 1]?.endsWith('a')
    ) {
        syllables.splice(syllables.length - 1, 1);
        syllables[syllables.length - 1] += 'a';
    }
    if (
        ['pi', 'ri'].includes(syllables[syllables.length - 2]?.toLowerCase()) &&
        syllables[syllables.length - 1]?.endsWith('a') &&
        hasAccent(syllables[syllables.length - 3])
    ) {
        syllables.splice(syllables.length - 1, 1);
        syllables[syllables.length - 1] += 'a';
    }
    if (
        ['pi'].includes(syllables[syllables.length - 2]?.toLowerCase()) &&
        syllables[syllables.length - 1]?.endsWith('o')
    ) {
        syllables.splice(syllables.length - 1, 1);
        syllables[syllables.length - 1] += 'o';
    }
    return syllables;
}
