import slugify from "slugify";

export function getDocumentUrl(document: {
    id: number | string;
    name: string;
}): string {
    // I18N
    return `/documentos/${document.id}/${slugify(document.name, {
        lower: true,
    })}`;
}
