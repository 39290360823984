import {
    isEditorElement,
    replaceNodeEdgeSpacesWithNbsp,
} from './modules/core/EditorUtil';

/**
 * @enum {number}
 */
export const TextCaseEnum = {
    LOWER_CASE: 0,
    UPPER_CASE: 1,
    TITLE_CASE: 2,
};

export function modifyTextCase(html, textCase) {
    const tmpContainer = document.createElement('div');
    tmpContainer.innerHTML = html;

    // recursive function to access html nodes
    function recursiveTransform(node) {
        if (isEditorElement(node)) return;
        if (node.nodeType === Node.TEXT_NODE) {
            switch (textCase) {
                case TextCaseEnum.LOWER_CASE:
                    node.textContent = node.textContent.toLowerCase();
                    break;
                case TextCaseEnum.UPPER_CASE:
                    node.textContent = node.textContent.toUpperCase();
                    break;
                case TextCaseEnum.TITLE_CASE:
                    node.textContent = titleCase(node.textContent);
                    break;
                default:
                    break;
            }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            for (const childNode of node.childNodes) {
                recursiveTransform(childNode);
            }
        }
    }
    recursiveTransform(tmpContainer);
    replaceNodeEdgeSpacesWithNbsp(tmpContainer);
    return tmpContainer.innerHTML;
}

function titleCase(str) {
    // converts the string to lower case and separates each word
    str = str.toLowerCase().split(' ');
    // iterates over each word, putting the first letter in upper case
    for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
}
