import axios from 'axios';

const Api = axios.create({
    baseURL: `${process.env.PUBLIC_URL}`,
    withCredentials: true,
    timeout: 5000,
});

export async function getServerVersion() {
    return (
        await Api.get(`/version.txt?t=${new Date().getTime()}`, {})
    )?.data?.trim();
}
