export enum MeasureEnum {
    CENTIMETER = "CM",
    MILLIMETER = "MM",
}

export type MeasureEnumValue = (typeof MeasureEnum)[keyof typeof MeasureEnum];

export function MeasureEnumToString(key: string): string {
    const measure = MeasureEnum[key];
    switch (measure) {
        // I18N
        case MeasureEnum.CENTIMETER:
            return "Centímetros";
        // I18N
        case MeasureEnum.MILLIMETER:
            return "Milímetros";
        default:
            throw new Error("Invalid enum value");
    }
}
