import { removeNonPrintableChars } from './ShowNonPrintableChars';
import nextId from 'react-id-generator';
import { ZERO_WIDTH_NB_CHAR, ZERO_WIDTH_SPACE_CHAR } from '../KeyboardModule';

import {
    getBrailleView,
    getPages,
    getPagesAndBreaks,
    isDocumentBreaks,
} from './PageManipulation';
import { getPageCache } from './Cache';
import { isEditorElementAlignment } from './editor-element/EditorElementAlignment';

import { getInnerContextContainerClasses } from './editor-element/Instances';
import { isEditorBrailleView } from './BrailleView';
import { getEditorElementLinesCount } from './EditorElements';
import { removeInvisibleSpacesFromText } from '../../../../util/TextUtil';
import { getActiveEditor } from '../../../EditDocument';

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isTinyCaret(node) {
    return (
        node?.tagName === 'EDITOR-PAGE' &&
        // tinymce creates a virtual caret using editor-page tag in some occasions
        node?.getAttribute('data-mce-caret') != null
    );
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorPage(node) {
    return !isTinyCaret(node) && node?.tagName === 'EDITOR-PAGE';
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElement(node) {
    return node?.tagName === 'EDITOR-ELEMENT';
}

/**
 * @param element {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementRepresentation(element) {
    if (!element) return false;
    return (
        isEditorElement(element) &&
        element
            .getAttribute('type')
            ?.toLowerCase()
            .startsWith('representation-')
    );
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementImageLayout(node) {
    if (!node) return false;
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.toLowerCase().startsWith('image-layout')
    );
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementRecoil(node) {
    if (!node) return false;
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.toLowerCase() === 'recoil'
    );
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementFigure(node) {
    if (!node) return false;
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.toLowerCase() === 'figure'
    );
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isInsideEditorElementRepresentation(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementRepresentation(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {boolean}
 */
export function isInsideEditorElementRecoil(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementRecoil(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {boolean}
 */
export function isInsideEditorElementFigure(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementFigure(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @param node {Node}
 * @returns {HTMLElement | null}
 */
export function getClosestElementRepresentation(node) {
    let walk = node;
    while (walk) {
        if (isEditorElementRepresentation(walk)) return walk;
        walk = walk.parentNode;
    }
    return null;
}

/**
 * @param node {Node}
 * @returns {HTMLElement | null}
 */
export function getClosestElementRepresentationLineBreak(node) {
    let walk = node;
    while (walk) {
        if (isEditorElementRepresentationParagraphBreak(walk)) return walk;
        walk = walk.parentNode;
    }
    return null;
}

/**
 * @param element {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementRepresentationParagraphBreak(element) {
    if (!element) return false;
    return (
        isEditorElementRepresentation(element) &&
        element.getAttribute('type').toLowerCase().endsWith('-line-break')
    );
}

/**
 * @param node {Node}
 * @returns {Node}
 */
export function getClosestElementRepresentationGenericSpace(node) {
    let walk = node;
    while (walk) {
        if (
            isEditorElementRepresentationSpace(walk) ||
            isEditorElementRepresentationNbsp(walk)
        )
            return walk;
        walk = walk.parentNode;
    }
    return null;
}

/**
 * @param node {HTMLElement | DocumentFragment | ChildNode}
 * @returns {boolean}
 */
export function isEditorElementParagraphBreak(node) {
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.startsWith('paragraph-break')
    );
}

/**
 * @param node {Node | HTMLElement}
 * @returns {boolean}
 */
export function isEditorElementParagraphBreakHyphen(node) {
    return (
        isEditorElement(node) &&
        node.getAttribute('type').startsWith('paragraph-break-hyphen')
    );
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideEditorElementParagraphBreak(node) {
    return isInside(node, (node) => {
        return isEditorElementParagraphBreak(node);
    });
}

/**
 * @param element {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementRepresentationSpace(element) {
    return (
        isEditorElementRepresentation(element) &&
        element.getAttribute('type').toLowerCase().endsWith('-space')
    );
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementRepresentationNbsp(node) {
    return (
        isEditorElementRepresentation(node) &&
        node.getAttribute('type').toLowerCase().endsWith('-nbsp')
    );
}

/**
 * @param node {Node}
 * @returns {HTMLElement | Node | null}
 */
export function getRootNodeInPage(node) {
    if (node == null) return null;
    if (
        node.parentNode &&
        (isEditorPage(node.parentNode) || node.parentNode.nodeType === 11)
    ) {
        return node;
    }
    return getRootNodeInPage(node.parentNode);
}

/**
 * @param editor {EditorCustom}
 * @returns {Node[]}
 */
export function getSelectedNodes(editor) {
    const selRng = editor.selection.getRng();
    let {
        startContainer,
        startOffset,
        endContainer,
        endOffset,
        commonAncestorContainer,
    } = selRng;
    if (startContainer.nodeType !== Node.TEXT_NODE && startOffset) {
        startContainer = startContainer.childNodes[startOffset];
    }
    if (endContainer.nodeType !== Node.TEXT_NODE && endOffset) {
        endContainer = endContainer.childNodes[endOffset];
    }
    let nodes = [];
    let pageSelection = false;
    for (let child of commonAncestorContainer.childNodes) {
        if (isEditorPage(child)) {
            pageSelection = true;
            for (let i = startOffset; i < child.childNodes.length; i++) {
                const pageChild = child.childNodes[i];
                if (isEditorBrailleView(pageChild)) continue;
                nodes.push(pageChild);
            }
        } else {
            nodes.push(getRootNodeInPage(child));
        }
    }
    if (pageSelection) return nodes;

    function seekRecursively(node, seekNode) {
        if (node === seekNode) {
            return true;
        } else {
            if (!node) return false;
            for (let child of node.childNodes) {
                if (seekRecursively(child, seekNode)) {
                    return true;
                }
            }
        }
        return false;
    }
    let startFound = false;
    const entries = nodes.entries();
    nodes = [];
    for (let [, node] of entries) {
        if (!startFound) {
            startFound = seekRecursively(node, startContainer);
        }
        if (startFound) {
            nodes.push(node);
        }
        if (seekRecursively(node, endContainer)) {
            break;
        }
    }
    return nodes;
}

/**
 * Useful to locate child node in a cloned node structure
 *
 * @param child {Node}
 * @returns {number[]}
 */
export function getBottomNodeInPageToChildPath(child) {
    let path = [];
    let walkNode = child;
    while (walkNode.parentNode && !isEditorPage(walkNode.parentNode)) {
        const parent = walkNode.parentNode;
        for (let [idx, child] of parent.childNodes.entries()) {
            if (child === walkNode) {
                path.splice(0, 0, idx);
                break;
            }
        }
        walkNode = parent;
    }
    return path;
}

/**
 * @param topNode {Node}
 * @param path {number[]}
 * @returns {Node}
 */
export function getNodeByChildPath(topNode, path = []) {
    path = [...path];
    while (path.length && topNode) {
        topNode = topNode.childNodes[path.shift()];
    }
    return topNode;
}

/**
 * @param node {Node}
 * @param stopAtParent {Node}
 */
export function removeNextSiblingChain(node, stopAtParent) {
    if (!node || node === stopAtParent) return;
    while (node.nextSibling) {
        node.nextSibling.remove();
    }
    if (stopAtParent) {
        removeNextSiblingChain(node.parentNode, stopAtParent);
    }
}

/**
 * @param node {Node}
 * @param stopAtParent {Node}
 */
export function removePreviousSiblingChain(node, stopAtParent) {
    if (node === stopAtParent) return;
    while (node.previousSibling) {
        node.previousSibling.remove();
    }
    if (stopAtParent) {
        removePreviousSiblingChain(node.parentNode, stopAtParent);
    }
}

/**
 * @param nodes {Node[]}
 * @returns {HTMLElement[]}
 */
export function getPagesInvolved(nodes) {
    const pageSet = new Set();
    for (let node of nodes) {
        pageSet.add(getRootNodeInPage(node).parentNode);
    }
    return [...pageSet];
}

/**
 * @param page {Node}
 * @returns {number}
 */
export function pageHasText(page) {
    const brailleView = getBrailleView(page);
    if (brailleView) {
        brailleView.remove();
    }
    const hasText = removeInvisibleSpacesFromText(page.textContent).trim()
        .length;
    if (brailleView) {
        page.appendChild(brailleView);
    }
    return hasText;
}

/**
 * @param node {Node}
 */
export function removeZombieNodes(node) {
    for (let child of node.childNodes) {
        if (child.nodeType === Node.TEXT_NODE) {
            if (!child.textContent.length) {
                child.remove();
            } else {
                removeZombieNodes(child);
            }
        }
    }
}

/**
 * @param editor {EditorCustom}
 * @param toReplace {Node[] | Element[] | undefined}
 */
export function replaceSelectedNodes(editor, toReplace = []) {
    const selRng = editor.selection.getRng();
    const { startContainer, startOffset, endContainer, endOffset } = selRng;

    const selectedNodes = getSelectedNodes(editor);
    const pagesInvolved = getPagesInvolved(selectedNodes);
    if (startContainer !== endContainer) {
        if (startContainer.nodeType === Node.TEXT_NODE) {
            startContainer.textContent = startContainer.textContent.substring(
                0,
                startOffset,
            );
            const rootNode = getRootNodeInPage(startContainer);
            removeNextSiblingChain(startContainer, rootNode);
        }
        const lastNodePartial =
            endContainer.nodeType === Node.TEXT_NODE && endOffset;
        if (lastNodePartial) {
            endContainer.textContent =
                endContainer.textContent.substring(endOffset);
            const rootNode = getRootNodeInPage(endContainer);
            selectedNodes.splice(selectedNodes.indexOf(rootNode), 1);
            removePreviousSiblingChain(endContainer, rootNode);
        }
        for (let i = 1; i < selectedNodes.length; i++) {
            selectedNodes[i].remove();
        }

        let reference = selectedNodes[0]?.nextSibling;
        if (reference) {
            for (const node of toReplace) {
                reference.parentNode.insertBefore(node, reference);
            }
        } else {
            reference =
                selectedNodes[0]?.parentNode ?? editor.selection.getNode();
            for (const node of toReplace) {
                reference.appendChild(node);
            }
        }
    } else {
        if (startContainer.nodeType === Node.TEXT_NODE) {
            const topElement = getRootNodeInPage(startContainer);
            const path = getBottomNodeInPageToChildPath(startContainer);
            const clonedTopElement = topElement.cloneNode(true);
            const clonedNode = getNodeByChildPath(clonedTopElement, path);

            startContainer.textContent = startContainer.textContent.substring(
                0,
                startOffset,
            );
            clonedNode.textContent =
                clonedNode.textContent.substring(endOffset);

            if (topElement.nodeType !== Node.TEXT_NODE) {
                removeNextSiblingChain(startContainer, topElement);
                removePreviousSiblingChain(clonedNode, clonedTopElement);
            }

            topElement.after(clonedTopElement);
            for (const node of toReplace) {
                clonedTopElement.parentElement.insertBefore(
                    node,
                    clonedTopElement,
                );
            }
        } else {
            let reference = startContainer.childNodes[startOffset];
            if (!reference) {
                reference = startContainer.nextSibling?.childNodes[0];
            }

            if (reference) {
                if (isEditorPage(reference)) {
                    reference.innerHTML = ''; // clean the page
                    for (const node of toReplace) {
                        reference.appendChild(node);
                    }
                } else {
                    for (const node of toReplace) {
                        reference.parentElement.insertBefore(node, reference);
                    }
                }
            } else {
                reference = startContainer.nextSibling;
                if (reference) {
                    for (const node of toReplace) {
                        if (reference.nodeType === Node.TEXT_NODE) {
                            // noinspection JSCheckFunctionSignatures
                            reference.after(node);
                        } else {
                            reference.appendChild(node);
                        }
                    }
                }
            }
        }
    }
    // remove empty page in process
    let toRemove = [];
    for (let pageInvolved of pagesInvolved) {
        if (!pageHasText(pageInvolved)) {
            toRemove.push(pageInvolved);
        }
        removeZombieNodes(pageInvolved);
    }
    // keeps first page if all is replaced
    if (pagesInvolved.length === toRemove.length) {
        toRemove.shift();
    }
    for (let page of toRemove) page.remove();
}

// /**
//  * @param formatting {Node}
//  * @returns {HTMLSpanElement}
//  */
// export function createFormattingCaretContainer(formatting) {
//     const selection = document.createElement('span');
//     selection.setAttribute('id', '_mce_caret');
//     selection.setAttribute('data-mce-bogus', '1');
//     selection.setAttribute('data-mce-type', 'format-caret');
//     selection.appendChild(formatting);
//     return selection;
// }

/**
 * @param node {Node}
 * @returns {boolean}
 * */
export function isInsideEmptyFormattingCaretContainer(node) {
    return isInside(node, (node) => {
        return (
            node?.getAttribute &&
            node.getAttribute('data-mce-type') === 'format-caret' &&
            removeInvisibleSpacesFromText(node.textContent).length === 0
        );
    });
}

/**
 * @param rect {DOMRect}
 * @param container {HTMLElement}
 * @returns {boolean}
 */
export function isRectVisibleInContainer(rect, container) {
    const containerRect = container.getBoundingClientRect();
    return (
        rect.top >= containerRect.top &&
        rect.bottom <= containerRect.bottom &&
        rect.left >= containerRect.left &&
        rect.right <= containerRect.right
    );
}

/**
 * @param container {HTMLElement}
 * @param element {HTMLElement}
 * @param zoom {number | undefined}
 * @returns {boolean}
 */
export function isElementVisible(container, element, zoom = 1) {
    if (!container || !element) return false;
    const containerTop = container.scrollTop / zoom;
    const containerBottom = containerTop + container.clientHeight / zoom;

    const elementTop = element.offsetTop - container.offsetTop / zoom;
    const elementBottom = elementTop + element.offsetHeight;

    return elementBottom > containerTop && elementTop < containerBottom;
}

/**
 * @param editor {EditorCustom}
 */
export function scrollToCursorIfNeeded(editor) {
    setTimeout(() => {
        const id = generateId(editor, 'caret-tracker');
        let caretTracker = editor.dom.create(
            'span',
            { id },
            ZERO_WIDTH_NB_CHAR,
        );
        editor.selection.setContent(caretTracker.outerHTML);
        caretTracker = editor.dom.get(id);
        if (
            !isRectVisibleInContainer(
                caretTracker.getBoundingClientRect(),
                editor.getContainer(),
            )
        ) {
            caretTracker.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest',
            });
        }
        caretTracker.remove();
    }, 0);
}

/**
 * @param char {string}
 * @returns {boolean}
 */
export function isInvisibleSpace(char) {
    return char === ZERO_WIDTH_NB_CHAR || char === ZERO_WIDTH_SPACE_CHAR;
}

/**
 * @param node {Node}
 * @returns {Node | null}
 */
export function getClosestTextNode(node) {
    if (!node) return null;
    if (node.nodeType === Node.TEXT_NODE) {
        return node;
    }
    for (const child of node.childNodes) {
        let textNode = getClosestTextNode(child);
        if (textNode) {
            return textNode;
        }
    }
    return null;
}

/**
 * @param node {HTMLElement | Node}
 * @returns {Node | null}
 */
export function getNextSiblingElement(node) {
    let walk = node;
    while (walk) {
        let next = walk.nextElementSibling;
        if (next) {
            return next;
        }
        walk = walk.parentElement;
        if (isEditorPage(walk) || !getClosestPage(walk)) {
            return null;
        }
    }
    return null;
}

/**
 * @param node {Node}
 * @param testFn {(node: Node | HTMLElement | ChildNode) => boolean}
 * @returns {boolean}
 */
export function isInside(node, testFn) {
    let walk = node;
    while (walk) {
        if (testFn(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @param node {Node}
 * @param page {Node}
 */
export function isInsidePage(node, page) {
    return isInside(node, (node) => node === page);
}

/**
 * @param node {Node}
 * @returns {HTMLElement | null}
 */
export function getClosestPage(node) {
    let page = null;
    isInside(node, (node) => {
        if (isEditorPage(node)) {
            page = node;
            return false;
        }
    });
    return page;
}

/**
 * @param node {Node}
 * @returns {DocumentFragment | null}
 */
export function getClosestDocumentFragment(node) {
    let page = null;
    isInside(node, (node) => {
        if (node.nodeType === Node.DOCUMENT_FRAGMENT_NODE) {
            page = node;
            return false;
        }
    });
    return page;
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideFormattingBold(node) {
    return isInside(node, (node) => node.tagName === 'STRONG');
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideFormattingItalic(node) {
    return isInside(node, (node) => node.tagName === 'EM');
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideFormattingUnderline(node) {
    return isInside(
        node,
        (node) =>
            node.tagName === 'SPAN' &&
            node.style.textDecoration === 'underline',
    );
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideFormattingSub(node) {
    return isInside(node, (node) => node.tagName === 'SUB');
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideFormattingSup(node) {
    return isInside(node, (node) => node.tagName === 'SUP');
}

/**
 * @param node {Node|HTMLElement}
 * @return {boolean}
 */
export function isContextSuppression(node) {
    return isEditorElement(node) && node.getAttribute('type') === 'suppression';
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideContextSuppression(node) {
    return isInside(node, (node) => isContextSuppression(node));
}

/**
 * @param node {Node|HTMLElement}
 * @return {boolean}
 */
export function isContextMath(node) {
    return isEditorElement(node) && node.getAttribute('type') === 'math';
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideContextMath(node) {
    return isInside(node, (node) => isContextMath(node));
}

/**
 * @param node {Node|HTMLElement}
 * @return {boolean}
 */
export function isContextComputerRelated(node) {
    return (
        isEditorElement(node) &&
        (node.getAttribute('type') === 'computer-related' ||
            // informative is kept here only to document version 6 update
            node.getAttribute('type') === 'informative')
    );
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideContextComputerRelated(node) {
    return isInside(node, (node) => isContextComputerRelated(node));
}

/**
 * @param node {Node|HTMLElement}
 * @return {boolean}
 */
export function isContextCatalog(node) {
    return isEditorElement(node) && node.getAttribute('type') === 'catalog';
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideContextCatalog(node) {
    return isInside(node, (node) => isContextCatalog(node));
}

/**
 * @param node {Node|HTMLElement}
 * @return {boolean}
 */
export function isContextInspectionError(node) {
    return (
        isEditorElement(node) && node.getAttribute('type') === 'revision-error'
    );
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideContextInspectionError(node) {
    return isInside(node, (node) => isContextInspectionError(node));
}

/**
 * Check if node is a context, except suppression
 * @param node {Node|HTMLElement}
 * @return {boolean}
 */
export function isContext(node) {
    return (
        isContextMath(node) ||
        isContextCatalog(node) ||
        isContextComputerRelated(node)
    );
}

/**
 * @param node {Node}
 * @param parentNode {Node}
 * @returns {boolean}
 */
export function isInsideNode(node, parentNode) {
    return isInside(node, (node) => {
        return node === parentNode;
    });
}

/**
 * @typedef {('bold' | 'italic' | 'underline' | 'sub' | 'sup' | 'context-supreession' | 'context-math' | 'context-computer-related' | 'context-catalog' | 'revision-error')} FormattingTypeValue
 */

/**
 * @type {Object<FormattingTypeValue>}
 */
export const FormattingType = {
    CONTEXT_SUPPRESSION: 'context-suppression',
    CONTEXT_MATH: 'context-math',
    CONTEXT_COMPUTER_RELATED: 'context-computer-related',
    CONTEXT_CATALOG: 'context-catalog',
    BOLD: 'bold',
    ITALIC: 'italic',
    UNDERLINE: 'underline',
    SUB: 'sub',
    SUP: 'sup',
    REVISION_ERROR: 'revision-error',
};

/**
 * @param editor
 * @returns {boolean}
 */
export function isMultipleSelection(editor) {
    const selRng = editor.selection.getRng();
    return (
        selRng.startContainer !== selRng.endContainer ||
        selRng.startOffset !== selRng.endOffset
    );
}

/**
 * @param editor {EditorCustom}
 * @returns {Node[][]}
 */
export function getSelectedParagraphs(editor) {
    /**
     * @type {Node[][]}
     */
    const result = [];
    /**
     * @type {Node[]}
     */
    let paragraph = [];

    const selectedNodes = getSelectedNodes(editor);
    if (selectedNodes.length) {
        for (const node of selectedNodes) {
            if (node?.tagName === 'BR') {
                result.push(paragraph);
                paragraph = [];
            } else {
                if (isEditorElementAlignment(node)) {
                    for (let child of node.childNodes) {
                        paragraph.push(child);
                    }
                } else {
                    paragraph.push(node);
                }
            }
        }
        if (paragraph.length) result.push(paragraph);
        // complete paragraphs
        for (paragraph of result) {
            let walk = paragraph[0]?.previousSibling;
            while (walk && walk.tagName !== 'BR') {
                paragraph.splice(0, 0, walk);
                walk = walk.previousSibling;
            }
            walk = paragraph[paragraph.length - 1]?.nextSibling;
            while (walk && walk.tagName !== 'BR') {
                paragraph.push(walk);
                walk = walk.nextSibling;
            }
        }
    } else {
        const paragraph = [];
        let walk = getRootNodeInPage(editor.selection.getRng().endContainer);
        let lastNode;
        // first found the start of the paragraph walking behind
        while (walk) {
            if (walk.tagName === 'BR') break;
            lastNode = walk;
            walk = walk.previousSibling;
        }
        // then walk forward to find the end of the paragraph
        walk = lastNode;
        while (walk) {
            if (walk.tagName === 'BR') break;
            if (isEditorElementAlignment(walk)) {
                for (let child of walk.childNodes) {
                    paragraph.push(child);
                }
            } else {
                paragraph.push(walk);
            }
            walk = walk.nextSibling;
        }
        if (paragraph.length) result.push(paragraph);
    }
    return result;
}

/**
 * @param node {Node}
 * @returns {HTMLElement | null}
 */
export function getClosestEditorElement(node) {
    let walk = node;
    while (walk) {
        if (isEditorElement(walk)) return walk;
        walk = walk.parentNode;
    }
    return null;
}

/**
 * @param node {Node}
 * @returns {HTMLElement | null}
 */
export function getClosestEditorElementWithDataLinesCount(node) {
    /**
     * @type {HTMLElement | Node}
     */
    let walk = node;
    while (walk) {
        if (walk?.getAttribute && getEditorElementLinesCount(walk) != null) {
            return walk;
        }
        walk = walk.parentNode;
    }
    return null;
}

/**
 * @param node {Node}
 * @returns {HTMLElement | null}
 */
export function getClosestEditorElementRecoil(node) {
    let walk = node;
    while (walk) {
        if (isEditorElementRecoil(walk)) return walk;
        walk = walk.parentNode;
    }
    return null;
}

/**
 * @param node {Node}
 * @returns {HTMLElement | null}
 */
export function getClosestEditorElementFigure(node) {
    let walk = node;
    while (walk) {
        if (isEditorElementFigure(walk)) return walk;
        walk = walk.parentNode;
    }
    return null;
}

/**
 * @param node {Node | HTMLElement}
 * @returns {boolean}
 */
export function isEditorElementInnerContextContainer(node) {
    for (const innerContextContainerClass of getInnerContextContainerClasses()) {
        if (
            node?.classList?.contains(innerContextContainerClass.substring(1))
        ) {
            return true;
        }
    }
    return false;
}

/**
 * @param node {Node}
 * @returns {HTMLElement | null}
 */
export function getClosestEditorElementInnerContextContainer(node) {
    let walk = node;
    while (walk) {
        if (isEditorElementInnerContextContainer(walk)) return walk;
        walk = walk.parentNode;
    }
    return null;
}

/**
 * @param node {Node}
 */
export function replaceNodeEdgeSpacesWithNbsp(node) {
    function traverse(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            let value = node.nodeValue;

            // if node started with space, replace with &nbsp;
            if (value.startsWith(' ')) {
                value = '\u00A0' + value.slice(1);
            }

            // if node finished with space, replace with &nbsp;
            if (value.endsWith(' ')) {
                value = value.slice(0, -1) + '\u00A0';
            }

            node.nodeValue = value;
        } else {
            for (let i = 0; i < node.childNodes.length; i++) {
                traverse(node.childNodes[i]);
            }
        }
    }
    traverse(node);
}

/**
 * @param html {string}
 * @returns {string}
 */
export function replaceHtmlEdgeSpacesWithNbsp(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    replaceNodeEdgeSpacesWithNbsp(doc.body);
    return doc.body.innerHTML;
}

/**
 * @param fragment {DocumentFragment}
 * @returns {string}
 */
export function extractTextFromFragment(fragment) {
    function recursively(node) {
        if (!node) return '';
        if (node.nodeType === Node.TEXT_NODE) {
            return node.textContent;
        } else if (node.tagName === 'BR') {
            return '\n';
        } else {
            let txt = '';
            for (let child of node.childNodes) {
                txt += recursively(child);
            }
            return txt;
        }
    }
    let txt = '';
    for (let child of fragment.childNodes) {
        txt += recursively(child);
    }
    return txt;
}

/**
 * @param txt {string}
 * @returns {string}
 */
export function removeInnerSpaces(txt) {
    if (txt.trim() === '') return txt;
    const spaceStart = txt.length - txt.trimStart().length;
    const spaceEnd = txt.length - txt.trimEnd().length;
    let result = '';
    result += ' '.repeat(spaceStart);
    result += txt.trim().replaceAll(' ', '');
    result += ' '.repeat(spaceEnd);
    return result;
}

/**
 * @param imgSrc
 * @return {Promise<{base64: string|undefined, type: string|undefined, url: string|undefined}>}
 */
export async function extractImgData(imgSrc) {
    const regExpEmbedded = /^data:image\/\w+;base64,/;
    if (imgSrc.match(regExpEmbedded)) {
        const type = imgSrc.split(';')[0].split('/')[1];
        const base64 = imgSrc.replace(regExpEmbedded, '');
        return { type, base64 };
    } else if (imgSrc.startsWith('blob:')) {
        const blob = await fetch(imgSrc).then((response) => response.blob());
        const base64 = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
        return {
            type: blob.type.split('/')[1],
            base64,
        };
    } else if (imgSrc.startsWith(process.env.REACT_APP_API_URL + '/files')) {
        return { url: imgSrc };
    } else {
        throw new Error(`Invalid image source: ${imgSrc}`);
    }
}

/**
 * @param editor {EditorCustom | null}
 * @param prefix {string}
 * @returns {string}
 */
export function generateId(editor, prefix) {
    let exists;
    let id;
    if (!editor) editor = getActiveEditor();
    do {
        id = nextId(prefix);
        exists = editor && !!editor.dom.get(id);
    } while (exists);
    return id;
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isInsideEditorBrailleView(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorBrailleView(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @param editor {EditorCustom}
 * @param node {Node}
 */
export function selectAllTextNode(editor, node) {
    if (node.nodeType !== Node.TEXT_NODE) {
        console.warn('Node is not a text node', node);
        return;
    }
    const range = editor.dom.createRng();
    range.setStart(node, 0);
    range.setEnd(node, node.textContent.length);
    editor.selection.setRng(range);
}

/**
 * @param node {Node | HTMLElement}
 * @param prior {boolean} Will return nodes prior to the node, otherwise, after the node
 * @returns {Node[]}
 */
function getParagraphSiblingNode(node, prior) {
    if (node.tagName === 'BR') {
        return [];
    }
    // inner context elements act like a single paragraph regardless of paragraph breaks
    const innerContextElement =
        getClosestEditorElementInnerContextContainer(node);

    function findParentWithSibling(node) {
        if (!node) return null;
        const siblingNode = prior ? node.previousSibling : node.nextSibling;
        return siblingNode
            ? siblingNode
            : findParentWithSibling(node.parentNode);
    }

    let nodes = [];
    let walk = findParentWithSibling(node);
    while (walk) {
        if (!innerContextElement && walk.tagName === 'BR') {
            break;
        }
        if (
            innerContextElement &&
            getClosestEditorElementInnerContextContainer(walk) !==
                innerContextElement
        ) {
            break;
        }
        if (isEditorPage(walk)) {
            break;
        }
        if (!getClosestPage(walk) && !getClosestDocumentFragment(walk)) {
            break;
        }
        nodes.splice(0, 0, walk);
        walk = findParentWithSibling(walk);
    }
    return nodes;
}

/**
 * @param node
 * @return {Node[]}
 */
export function getParagraphNodesUntilNode(node) {
    return getParagraphSiblingNode(node, true);
}

// /**
//  * @param node
//  * @return {Node[]}
//  */
// export function getParagraphNodesAfterNode(node) {
//     return getParagraphSiblingNode(node, false);
// }

/**
 * @param node {Node}
 * @param prior {boolean}
 * @return {string}
 */
function getParagraphTextSiblingNode(node, prior) {
    const nodes = getParagraphSiblingNode(node, prior);
    const fragment = document.createDocumentFragment();
    while (nodes.length) fragment.append(nodes.shift().cloneNode(true));
    return fragment.textContent;
}

/**
 * @param node
 * @return {string}
 */
export function getParagraphTextUntilNode(node) {
    return getParagraphTextSiblingNode(node, true);
}

/**
 * @param node
 * @return {string}
 */
export function getParagraphTextAfterNode(node) {
    return getParagraphTextSiblingNode(node, false);
}

/**
 * @param page {HTMLElement}
 */
export function mergeSiblingEqualsNodes(page) {
    const allElements = page.querySelectorAll('*');
    for (const element of allElements) {
        if (!element.parentElement) {
            // probably already merged
            continue;
        }
        let walk = element.nextSibling;
        let embeddable;
        let embeddableBetween = [];
        while (
            walk &&
            ((embeddable =
                isEditorElementRepresentation(walk) ||
                isEditorElementParagraphBreak(walk)) ||
                walk?.tagName === element.tagName)
        ) {
            if (embeddable) {
                embeddableBetween.push(walk);
                walk = walk.nextSibling;
                continue;
            }
            if (
                walk?.tagName === 'SPAN' &&
                walk?.style.textDecoration !== 'underline' &&
                element.style.textDecoration !== 'underline'
            ) {
                break;
            }
            if (isEditorElement(walk)) {
                if (
                    walk?.getAttribute('type') !== element.getAttribute('type')
                ) {
                    break;
                }
            } else if (
                !['EM', 'STRONG', 'SUB', 'SUP'].includes(element.tagName)
            ) {
                break;
            }
            while (embeddableBetween.length) {
                element.appendChild(embeddableBetween.shift());
            }
            element.innerHTML += walk?.innerHTML ?? '';
            const toRemove = walk;
            walk = walk.nextSibling;
            toRemove.remove();
        }
    }
}

/**
 * @param txt {string}
 * @returns {string}
 */
export function standardizeChar(txt) {
    return txt
        .replaceAll('–', '—') // Improves the dash visualization (#44940)
        .replaceAll('‑', '-') // Fix some variations of hyphen (#47886)
        .replaceAll('−', '-') // #47675: hyphen variation
        .replace(/ﬁ ?/g, 'fi') // #47673: stranger char conversion
        .replace(/ﬂ ?/g, 'fl'); // #47673: stranger char conversion
}

/**
 * @returns {BrailleDocument | null}
 */
export function getBrailleDocument(editor) {
    return editor?.custom?.brailleDocument;
}

/**
 * @param editor {EditorCustom}
 * @returns {string}
 */
export async function getHtmlToSave(editor) {
    const container = document.createElement('div');
    const rootElements = getPagesAndBreaks(editor);
    // const pages = getPages(editor);
    /**
     * @type {HTMLElement | null}
     */
    let rootElement = null;
    for (rootElement of rootElements) {
        if (isDocumentBreaks(rootElement)) {
            rootElement = rootElement.cloneNode(true);
            container.appendChild(rootElement);
        } else {
            rootElement = rootElement.cloneNode(true);
            rootElement.removeAttribute('cached');
            const pageCache = getPageCache(editor, rootElement);
            if (pageCache) {
                rootElement.appendChild(pageCache.cloneNode(true));
            }
            getBrailleView(rootElement)?.remove();
            removeNonPrintableChars(rootElement);
            // bugfix #52514
            for (const element of rootElement.querySelectorAll(
                '[data-mce-selected="1"]',
            )) {
                element.removeAttribute('data-mce-selected');
            }
            // noinspection JSCheckFunctionSignatures
            container.appendChild(rootElement);
        }
    }
    return container.innerHTML;
}

/**
 * @param editor {EditorCustom}
 */
export function preventScroll(editor) {
    const htmlElement = editor.getBody().parentElement;
    let scrollX = htmlElement.scrollLeft;
    let scrollY = htmlElement.scrollTop;
    htmlElement.scrollTo({
        behavior: 'instant',
        left: scrollX,
        top: scrollY,
    });
    console.debug('Scroll prevented.');
}
