import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import './FieldUsers.scss';
import Loading from './Loading';
import * as UserService from '../services/UserService';
import ErrorLoading from './ErrorLoading';
import { removeDuplicatedObjectsFromArray } from '../util/RemoveDuplicatedObjectsFromArray';
import slugify from 'slugify';
import PropTypes from 'prop-types';
import { RoleEnum } from 'plataforma-braille-common';

const FieldUsers = forwardRef(({ onSelected, selected, filterRole }, ref) => {
    const [filter, setFilter] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(new AbortController());
    const tableContainerRef = useRef();

    useImperativeHandle(ref, () => ({ fetchUsers }));

    async function fetchUsers() {
        if (loading?.abort) {
            loading.abort();
        }
        const abortController = new AbortController();
        try {
            setLoading(abortController);
            let users = [];
            let page = 0;
            let response;
            do {
                response = await UserService.getUsers(
                    { page: page++, filter },
                    {
                        signal: abortController.signal,
                    },
                );
                users = [...users, ...response.records];
            } while (response.records.length);
            users = removeDuplicatedObjectsFromArray(
                [...response.records, ...users],
                'email',
            );
            setUsers(users);
            setLoading(null);
        } catch (e) {
            if (e.name === 'CanceledError') return;
            console.error('Failed to fetch users.', e);
            setLoading(e);
        }
    }

    function renderTable(users) {
        if (loading) {
            if (loading instanceof Error) {
                return <ErrorLoading onTryAgain={fetchUsers} />;
            }
            return (
                <div className={'loading-container'}>
                    <Loading />
                </div>
            );
        }

        let rows = [];

        /**
         * @param user {UsersSummaryDto}
         * @return {boolean}
         */
        function isUserSelected(user) {
            user = selected.find((u) => u.email === user.email);
            return !!user?.roles?.includes(filterRole);
        }

        users = users.filter((user) => {
            const inRole =
                (filterRole && user.roles?.includes(filterRole)) ||
                user.roles?.includes(RoleEnum.ADMINISTRATOR) ||
                user.roles?.includes(RoleEnum.EVALUATION) ||
                user.roles?.includes(RoleEnum.MODERATION);
            if (!inRole) {
                return false;
            }

            if (filter && filter.trim()) {
                const namePrepared = slugify(user.name, { lower: true });
                const emailPrepared = slugify(user.email, { lower: true });
                const filterPrepared = slugify(filter, { lower: true });

                if (
                    namePrepared.indexOf(filterPrepared) === -1 &&
                    emailPrepared.indexOf(filterPrepared) === -1
                ) {
                    return false;
                }
            }

            return true;
        });

        users.sort((userA, userB) => {
            if (isUserSelected(userA) && !isUserSelected(userB)) {
                return -1;
            } else if (!isUserSelected(userA) && isUserSelected(userB)) {
                return 1;
            } else {
                return userA.name.localeCompare(userB.name);
            }
        });

        for (const user of users) {
            const onClick = () => {
                const currentlySelected = isUserSelected(user);
                let newSelection = [...selected];
                let selectedUser = newSelection.find(
                    (u) => u.email === user.email,
                );
                if (!selectedUser) {
                    selectedUser = {
                        ...user,
                        roles: [],
                    };
                    newSelection.push(selectedUser);
                }
                if (currentlySelected) {
                    const roleIndex = selectedUser.roles.findIndex(
                        (role) => role === filterRole,
                    );
                    selectedUser.roles.splice(roleIndex, 1);
                } else {
                    selectedUser.roles.push(filterRole);
                }
                onSelected(newSelection);
            };

            const userSelected = isUserSelected(user);

            rows.push(
                <tr key={user.email} onClick={onClick}>
                    <td>
                        <input
                            type={'checkbox'}
                            checked={userSelected}
                            // just to avoid annoying react warns
                            onChange={() => {}}
                        />
                    </td>
                    <td>{user.name}</td>
                </tr>,
            );
        }

        return (
            <table>
                <tbody>{rows}</tbody>
            </table>
        );
    }

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        fetchUsers();
    }, []);

    return (
        <>
            <div className={'field-user'}>
                <label className={'field-label'}>{'Pessoas'}</label>
                <div className={'search-field'}>
                    <input
                        type={'text'}
                        // I18N
                        placeholder={'Buscar usuário...'}
                        className={'small search-field'}
                        defaultValue={filter.search ?? ''}
                        onChange={(e) => {
                            setFilter(e.target.value);
                        }}
                    />
                </div>
                <div ref={tableContainerRef} className={'table-container'}>
                    {renderTable(users)}
                </div>
            </div>
        </>
    );
});

FieldUsers.displayName = 'FieldUsers';

FieldUsers.propTypes = {
    onSelected: PropTypes.func,
    selected: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            email: PropTypes.string,
            roles: PropTypes.arrayOf(PropTypes.string),
            name: PropTypes.string,
        }),
    ),
    filterRole: PropTypes.string,
};

// Define defaultProps
FieldUsers.defaultProps = { selected: [] };

export default FieldUsers;
