import { checkAncestorsElement } from './EditorElements';

/**
 * @param editor {EditorCustom}
 * @param htmlString {string}
 * @returns {string}
 */
export function sanitizeHtml(editor, htmlString) {
    htmlString = htmlString?.normalize('NFC');

    // transform word upper case (#43703)
    htmlString = htmlString.replace(
        /(<[\w:]+\s+[^>]*style=['"][^'"]*)text-transform:\s*uppercase([^'"]*['"][^>]*>.*?<\/[\w:]+>)/gi,
        function (match, start, end) {
            return start + end.toUpperCase();
        },
    );
    // remove braille view
    htmlString = htmlString.replace(
        /<editor-braille-view\b[^>]*>[\s\S]*?<\/editor-braille-view>/gi,
        '',
    );
    // avoid duplicated rows
    htmlString = htmlString.replace(
        /(<p\b[^>]*>)\s*<br\s*\/?>\s*(<\/p>)/g,
        '<p></p>',
    );
    htmlString = htmlString.replace(/<\/(p|editor-page)[^>]*>/gi, '<br>');
    htmlString = htmlString.replace(/<(span)[^>]*>/gi, '');
    htmlString = htmlString.replace(
        /(<[^>]*editor-element[^>]*>)((?:.|[\r\n])*?)(<[^>]*\/[^>]*editor-element[^>]*>)/gi,
        (match, g1, g2, g3) => {
            return `${g1}${encodeURI(g2)}${g3}`;
        },
    );

    htmlString = htmlString.replace(
        /<(?!\/?(strong|sub|sup|em|editor-element|br|span))[^>]+>/g,
        '',
    );
    htmlString = htmlString.replace(
        /(<[^>]*editor-element[^>]*>)((?:.|[\r\n])*?)(<[^>]*\/[^>]*editor-element[^>]*>)/gi,
        (match, g1, g2, g3) => {
            return `${g1}${decodeURI(g2)}${g3}`;
        },
    );

    // disallow stacks of editor-element (removes tags and past only the content)
    if (htmlString.match(/<[^>]*editor-element[^>]*>/gi)) {
        const insideEditorElement = checkAncestorsElement(
            editor.selection.getNode(),
            (element) => element.tagName === 'EDITOR-ELEMENT',
        );
        if (insideEditorElement) {
            htmlString = htmlString.replace(
                /<(?!\/?(strong|sub|sup|em|br|span))[^>]+>/g,
                '',
            );
        }
    }
    return htmlString;
}
