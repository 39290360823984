import LoginContainer from '../components/login/LoginContainer';
import ChangePasswordForm from './ChangePasswordForm';

function ChangeInvitedPassword() {
    return (
        <LoginContainer>
            <ChangePasswordForm
                title={'Convite plataforma'}
            ></ChangePasswordForm>
        </LoginContainer>
    );
}

export default ChangeInvitedPassword;
