import { useContext, useEffect, useRef, useState } from 'react';
import * as DocumentService from '../services/DocumentService';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import CloseModalButton from '../components/modal/CloseModalButton';
import Loading from '../components/Loading';
import {
    SaveDocumentValidationErrorTxt,
    MeasureEnum,
    MeasureEnumToString,
    OrientationEnum,
    isDocumentTypeImageDescription,
} from 'plataforma-braille-common';
import FieldInteger from '../components/FieldInteger';
import { unmask } from '../components/MeasureInput';
import FieldCheckbox from '../components/FieldCheckbox';
import { hasValidationErrorDocument } from '../dashboard/DocumentModal';
import PropTypes from 'prop-types';
import FieldSelect from '../components/FieldSelect';
import FieldMeasure from '../components/FieldMeasure';
import ButtonOrientation from '../components/ButtonOrientation';
import ButtonLock from '../components/ButtonLock';
import './DocumentSettingsModal.scss';
import { enumToSelectOptions } from '../util/EnumToSelectOptions';
import { FieldsFontType } from './FieldsFontType';

function DocumentSettingsModal({ show, documentId, onFinished }) {
    /**
     * @type {FormData}
     */
    const initialFormData = {};
    /**
     * @type {FormError}
     */
    const initialFormError = {};

    const [formData, setFormData] = useState(initialFormData);
    const [formError, setFormError] = useState(initialFormError);
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { setLoading, backendConnectionError } =
        useContext(EnvironmentContext);
    const hyphenationLettersMinErrorRef = useRef();

    const title = 'Propriedades do documento';

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    useEffect(() => {
        if (!show) {
            setTimeout(() => {
                setValidateOnChange(false);
                setFormData({});
                setFormError({});
            }, 200); // clear the form after css transition (200ms)
        } else {
            setTimeout(() => {
                hyphenationLettersMinErrorRef.current?.inputElement.focus();
            }, 200); // css transition
        }
    }, [show]);

    async function fetchDocument() {
        try {
            setLoadingForm(true);
            const dto = await DocumentService.getDocument(
                documentId,
                null,
                false,
            );

            let formData = dto;

            const modalFields = new Set([
                'pageMeasure',
                'pageWidth',
                'pageHeight',
                'pageOrientation',
                'pageCount',
                'pageLimit',
                'brailleCellColCount',
                'brailleCellRowCount',
                'interPoint',
                'braillePageMarginLeft',
                'braillePageMarginTop',
                'braillePageMarginRight',
                'braillePageMarginBottom',
                'braillePageMarginLocked',
                'inkFontType',
                'inkFontSize',
                'inkPageMarginTop',
                'inkPageMarginRight',
                'inkPageMarginLeft',
                'inkPageMarginBottom',
                'inkPageMarginLocked',
                'inkPageLineHeight',
            ]);

            for (const key of Object.keys(formData)) {
                if (!modalFields.has(key)) {
                    delete formData[key];
                }
            }

            setFormData(formData);
        } catch (e) {
            backendConnectionError('Fail to fetch document.', e);
        } finally {
            setLoadingForm(false);
        }
    }

    useEffect(() => {
        if (show) {
            // noinspection JSIgnoredPromiseFromCall
            fetchDocument();
        }
    }, [show]);

    useEffect(() => {
        setLoading(loadingSave | loadingForm, true);
    }, [loadingSave, loadingForm]);

    /**
     * @param obj {FormType | object}
     */
    function updateFormData(obj) {
        setFormData((oldData) => {
            return { ...oldData, ...obj };
        });
    }

    async function saveDocument() {
        if (hasValidationError()) return;

        try {
            setLoadingSave(true);
            const response = await DocumentService.editDocument(
                documentId,
                formData,
            );
            onFinished(response.entity);
        } catch (e) {
            backendConnectionError(
                'Fail to edit document.',
                e,
                null,
                title,
                SaveDocumentValidationErrorTxt,
            );
        } finally {
            setLoadingSave(false);
        }
    }

    function hasValidationError() {
        let hasErrors = false;
        let errors = {};

        const hyphenation = hasValidationErrorDocument(formData, false);
        hasErrors = hasErrors || hyphenation.hasErrors;
        errors = { ...errors, ...hyphenation.errors };

        if (document) setValidateOnChange(true);
        setFormError(errors);
        return hasErrors;
    }

    function updateInkPageMargin(value) {
        updateFormData({
            inkPageMarginLeft: unmask(value),
            inkPageMarginTop: unmask(value),
            inkPageMarginRight: unmask(value),
            inkPageMarginBottom: unmask(value),
        });
    }

    function updateBraillePageMargin(value) {
        updateFormData({
            braillePageMarginLeft: unmask(value),
            braillePageMarginTop: unmask(value),
            braillePageMarginRight: unmask(value),
            braillePageMarginBottom: unmask(value),
        });
    }

    return (
        <div
            className={`modal default-modal document-settings-modal ${show ? 'show' : ''}`}
        >
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={() => onFinished(null)} />
                <div className={'gd-col-5--desktop'}>
                    <div>
                        <div className={'gd-inner'}>
                            <div className={'gd-col gd-col-5--desktop'}>
                                <h2>{title}</h2>
                            </div>
                        </div>

                        {loadingForm ? (
                            <div className={'gd-inner field-group'}>
                                <div className={'gd-col gd-col-4--desktop'}>
                                    <div className={'loading-container'}>
                                        <Loading />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <form>
                                <div className={'gd-inner'}>
                                    <div className={'gd-col gd-col-3--desktop'}>
                                        <FieldSelect
                                            // I18N
                                            label={'Medida'}
                                            // I18N
                                            placeholder={'Selecione uma medida'}
                                            options={enumToSelectOptions(
                                                MeasureEnum,
                                                MeasureEnumToString,
                                            )}
                                            required={true}
                                            validationError={
                                                formError.pageMeasure
                                            }
                                            value={formData.pageMeasure ?? '-1'}
                                            onChange={(e) =>
                                                updateFormData({
                                                    pageMeasure: e.target.value,
                                                    pageWidth: '',
                                                    pageHeight: '',
                                                })
                                            }
                                        />
                                    </div>
                                    <div className={'gd-col gd-col-1--desktop'}>
                                        <FieldMeasure
                                            // I18N
                                            label={'Largura'}
                                            placeholder={
                                                formData.pageMeasure ===
                                                MeasureEnum.MILLIMETER
                                                    ? '000mm'
                                                    : '00cm'
                                            }
                                            measure={
                                                formData.pageMeasure ??
                                                MeasureEnum.MILLIMETER
                                            }
                                            required={true}
                                            validationError={
                                                formError.pageWidth
                                            }
                                            disabled={!formData.pageMeasure}
                                            value={formData.pageWidth}
                                            onChange={(e) =>
                                                updateFormData({
                                                    pageWidth: unmask(
                                                        e.target.value,
                                                    ),
                                                })
                                            }
                                        />
                                    </div>
                                    <div className={'gd-col gd-col-1--desktop'}>
                                        <FieldMeasure
                                            // I18N
                                            label={'Altura'}
                                            placeholder={
                                                formData.pageMeasure ===
                                                MeasureEnum.MILLIMETER
                                                    ? '000mm'
                                                    : '00cm'
                                            }
                                            measure={
                                                formData.pageMeasure ??
                                                MeasureEnum.MILLIMETER
                                            }
                                            required={true}
                                            validationError={
                                                formError.pageHeight
                                            }
                                            disabled={!formData.pageMeasure}
                                            value={formData.pageHeight}
                                            onChange={(e) =>
                                                updateFormData({
                                                    pageHeight: unmask(
                                                        e.target.value,
                                                    ),
                                                })
                                            }
                                        />
                                    </div>
                                </div>

                                <div className={'gd-inner'}>
                                    <div
                                        className={
                                            'gd-col gd-col-1--desktop gd-col--valign-bottom'
                                        }
                                    >
                                        <div
                                            className={`field-group ${formError.pageOrientation ? 'error' : ''}`}
                                        >
                                            <label className={'field-label'}>
                                                {/*I18N*/}
                                                {'Orientação'}
                                            </label>
                                            <div
                                                className={
                                                    'orientation-controls'
                                                }
                                            >
                                                <ButtonOrientation
                                                    orientation={
                                                        OrientationEnum.PORTRAIT
                                                    }
                                                    selected={
                                                        formData.pageOrientation ===
                                                        OrientationEnum.PORTRAIT
                                                    }
                                                    onClick={() =>
                                                        updateFormData({
                                                            pageOrientation:
                                                                OrientationEnum.PORTRAIT,
                                                        })
                                                    }
                                                />
                                                <ButtonOrientation
                                                    orientation={
                                                        OrientationEnum.LANDSCAPE
                                                    }
                                                    selected={
                                                        formData.pageOrientation ===
                                                        OrientationEnum.LANDSCAPE
                                                    }
                                                    onClick={() =>
                                                        updateFormData({
                                                            pageOrientation:
                                                                OrientationEnum.LANDSCAPE,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div
                                                id={'pageOrientationError'}
                                                className={'validation-error '}
                                            >
                                                {formError.pageOrientation}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            'gd-col gd-col-2--desktop gd-col--valign-bottom'
                                        }
                                    >
                                        <FieldInteger
                                            // I18N
                                            label={'Limite de pág.'}
                                            className={'no-wrap-label'}
                                            required={true}
                                            placeholder={'150'}
                                            validationError={
                                                formError.pageLimit
                                            }
                                            value={formData.pageLimit}
                                            onChange={(e) =>
                                                updateFormData({
                                                    pageLimit: unmask(
                                                        e.target.value,
                                                    ),
                                                })
                                            }
                                        />
                                    </div>

                                    <div
                                        className={'gd-col gd-col-1--desktop'}
                                    />
                                    <div className={'gd-col gd-col-1--desktop'}>
                                        <FieldCheckbox
                                            // I18N
                                            label={'Interponto'}
                                            validationError={
                                                formError.interPoint
                                            }
                                            inputs={(() => [
                                                {
                                                    // I18N
                                                    label: 'Ativar',
                                                    onChange: (e) =>
                                                        updateFormData({
                                                            interPoint:
                                                                e.target[
                                                                    'checked'
                                                                ],
                                                        }),
                                                    checked:
                                                        formData.interPoint,
                                                },
                                            ])()}
                                        />
                                    </div>
                                </div>

                                {!isDocumentTypeImageDescription(
                                    formData.type,
                                ) && (
                                    <section>
                                        <h3>
                                            {/*I18N*/}
                                            {'Propriedades Braille'}
                                        </h3>
                                        <div className={'gd-inner'}>
                                            <div
                                                className={
                                                    'gd-col gd-col-2--desktop'
                                                }
                                            >
                                                <fieldset>
                                                    <legend>
                                                        {/*I18N*/}
                                                        {'Grade braille'}
                                                    </legend>
                                                    <div className={'gd-inner'}>
                                                        <div
                                                            className={
                                                                'gd-col gd-col-1--desktop'
                                                            }
                                                        >
                                                            <FieldInteger
                                                                // I18N
                                                                label={
                                                                    'Caracteres'
                                                                }
                                                                placeholder={
                                                                    '00'
                                                                }
                                                                required={true}
                                                                validationError={
                                                                    formError.brailleCellColCount
                                                                }
                                                                value={
                                                                    formData.brailleCellColCount
                                                                }
                                                                onChange={(e) =>
                                                                    updateFormData(
                                                                        {
                                                                            brailleCellColCount:
                                                                                unmask(
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                ),
                                                                        },
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className={
                                                                'gd-col gd-col-1--desktop'
                                                            }
                                                        >
                                                            <FieldInteger
                                                                // I18N
                                                                label={'Linhas'}
                                                                placeholder={
                                                                    '00'
                                                                }
                                                                required={true}
                                                                validationError={
                                                                    formError.brailleCellRowCount
                                                                }
                                                                value={
                                                                    formData.brailleCellRowCount
                                                                }
                                                                onChange={(e) =>
                                                                    updateFormData(
                                                                        {
                                                                            brailleCellRowCount:
                                                                                unmask(
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                ),
                                                                        },
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className={'gd-inner'}>
                                            <div
                                                className={
                                                    'gd-col gd-col-5--desktop'
                                                }
                                            >
                                                <fieldset>
                                                    <legend>
                                                        {'Margens em braille'}
                                                    </legend>
                                                    <div className={'gd-inner'}>
                                                        <div
                                                            className={
                                                                'gd-col gd-col-1--desktop'
                                                            }
                                                        >
                                                            <FieldMeasure
                                                                // I18N
                                                                label={
                                                                    'Esquerda'
                                                                }
                                                                placeholder={
                                                                    '000mm'
                                                                }
                                                                measure={
                                                                    MeasureEnum.MILLIMETER
                                                                }
                                                                required={true}
                                                                validationError={
                                                                    formError.braillePageMarginLeft
                                                                }
                                                                value={
                                                                    formData.braillePageMarginLeft
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    if (
                                                                        formData.braillePageMarginLocked
                                                                    ) {
                                                                        updateBraillePageMargin(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                        );
                                                                    } else {
                                                                        updateFormData(
                                                                            {
                                                                                braillePageMarginLeft:
                                                                                    unmask(
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                    ),
                                                                            },
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className={
                                                                'gd-col gd-col-1--desktop'
                                                            }
                                                        >
                                                            <FieldMeasure
                                                                // I18N
                                                                label={
                                                                    'Superior'
                                                                }
                                                                placeholder={
                                                                    '000mm'
                                                                }
                                                                measure={
                                                                    MeasureEnum.MILLIMETER
                                                                }
                                                                required={true}
                                                                validationError={
                                                                    formError.braillePageMarginTop
                                                                }
                                                                value={
                                                                    formData.braillePageMarginTop
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    if (
                                                                        formData.braillePageMarginLocked
                                                                    ) {
                                                                        updateBraillePageMargin(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                        );
                                                                    } else {
                                                                        updateFormData(
                                                                            {
                                                                                braillePageMarginTop:
                                                                                    unmask(
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                    ),
                                                                            },
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className={
                                                                'gd-col gd-col-1--desktop'
                                                            }
                                                        >
                                                            <FieldMeasure
                                                                // I18N
                                                                label={
                                                                    'Direita'
                                                                }
                                                                placeholder={
                                                                    '000mm'
                                                                }
                                                                measure={
                                                                    MeasureEnum.MILLIMETER
                                                                }
                                                                required={true}
                                                                validationError={
                                                                    formError.braillePageMarginRight
                                                                }
                                                                value={
                                                                    formData.braillePageMarginRight
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    if (
                                                                        formData.braillePageMarginLocked
                                                                    ) {
                                                                        updateBraillePageMargin(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                        );
                                                                    } else {
                                                                        updateFormData(
                                                                            {
                                                                                braillePageMarginRight:
                                                                                    unmask(
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                    ),
                                                                            },
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className={
                                                                'gd-col gd-col-1--desktop'
                                                            }
                                                        >
                                                            <FieldMeasure
                                                                // I18N
                                                                label={
                                                                    'Inferior'
                                                                }
                                                                placeholder={
                                                                    '000mm'
                                                                }
                                                                measure={
                                                                    MeasureEnum.MILLIMETER
                                                                }
                                                                required={true}
                                                                validationError={
                                                                    formError.braillePageMarginBottom
                                                                }
                                                                value={
                                                                    formData.braillePageMarginBottom
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    if (
                                                                        formData.braillePageMarginLocked
                                                                    ) {
                                                                        updateBraillePageMargin(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                        );
                                                                    } else {
                                                                        updateFormData(
                                                                            {
                                                                                braillePageMarginBottom:
                                                                                    unmask(
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                    ),
                                                                            },
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className={
                                                                'gd-col gd-col-1--desktop'
                                                            }
                                                        >
                                                            <div>
                                                                <label>
                                                                    &nbsp;
                                                                </label>{' '}
                                                                {/* just to keep control aligned */}
                                                                <ButtonLock
                                                                    locked={
                                                                        formData.braillePageMarginLocked
                                                                    }
                                                                    onClick={() => {
                                                                        updateFormData(
                                                                            {
                                                                                braillePageMarginLocked:
                                                                                    !formData.braillePageMarginLocked,
                                                                            },
                                                                        );
                                                                        updateBraillePageMargin(
                                                                            formData.braillePageMarginLeft +
                                                                                'mm',
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </section>
                                )}

                                <section>
                                    <h3>
                                        {/*I18N*/}
                                        {'Propriedades tinta'}
                                    </h3>
                                    <div className={'gd-inner'}>
                                        <div
                                            className={
                                                'gd-col gd-col-5--desktop'
                                            }
                                        >
                                            <fieldset>
                                                <legend>
                                                    {/*I18N*/}
                                                    {'Margens tinta'}
                                                </legend>
                                                <div className={'gd-inner'}>
                                                    <div
                                                        className={
                                                            'gd-col gd-col-1--desktop'
                                                        }
                                                    >
                                                        <FieldMeasure
                                                            // I18N
                                                            label={'Esquerda'}
                                                            placeholder={
                                                                '000mm'
                                                            }
                                                            measure={
                                                                MeasureEnum.MILLIMETER
                                                            }
                                                            required={true}
                                                            validationError={
                                                                formError.inkPageMarginLeft
                                                            }
                                                            value={
                                                                formData.inkPageMarginLeft
                                                            }
                                                            onChange={(e) => {
                                                                if (
                                                                    formData.inkPageMarginLocked
                                                                ) {
                                                                    updateInkPageMargin(
                                                                        e.target
                                                                            .value,
                                                                    );
                                                                } else {
                                                                    updateFormData(
                                                                        {
                                                                            inkPageMarginLeft:
                                                                                unmask(
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                ),
                                                                        },
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            'gd-col gd-col-1--desktop'
                                                        }
                                                    >
                                                        <FieldMeasure
                                                            // I18N
                                                            label={'Superior'}
                                                            placeholder={
                                                                '000mm'
                                                            }
                                                            measure={
                                                                MeasureEnum.MILLIMETER
                                                            }
                                                            required={true}
                                                            validationError={
                                                                formError.inkPageMarginTop
                                                            }
                                                            value={
                                                                formData.inkPageMarginTop
                                                            }
                                                            onChange={(e) => {
                                                                if (
                                                                    formData.inkPageMarginLocked
                                                                ) {
                                                                    updateInkPageMargin(
                                                                        e.target
                                                                            .value,
                                                                    );
                                                                } else {
                                                                    updateFormData(
                                                                        {
                                                                            inkPageMarginTop:
                                                                                unmask(
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                ),
                                                                        },
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            'gd-col gd-col-1--desktop'
                                                        }
                                                    >
                                                        <FieldMeasure
                                                            // I18N
                                                            label={'Direita'}
                                                            id={
                                                                'inkPageMarginRight'
                                                            }
                                                            placeholder={
                                                                '000mm'
                                                            }
                                                            measure={
                                                                MeasureEnum.MILLIMETER
                                                            }
                                                            required={true}
                                                            validationError={
                                                                formError.inkPageMarginRight
                                                            }
                                                            value={
                                                                formData.inkPageMarginRight
                                                            }
                                                            onChange={(e) => {
                                                                if (
                                                                    formData.inkPageMarginLocked
                                                                ) {
                                                                    updateInkPageMargin(
                                                                        e.target
                                                                            .value,
                                                                    );
                                                                } else {
                                                                    updateFormData(
                                                                        {
                                                                            inkPageMarginRight:
                                                                                unmask(
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                ),
                                                                        },
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            'gd-col gd-col-1--desktop'
                                                        }
                                                    >
                                                        <FieldMeasure
                                                            // I18N
                                                            label={'Inferior'}
                                                            placeholder={
                                                                '000mm'
                                                            }
                                                            measure={
                                                                MeasureEnum.MILLIMETER
                                                            }
                                                            required={true}
                                                            validationError={
                                                                formError.inkPageMarginBottom
                                                            }
                                                            value={
                                                                formData.inkPageMarginBottom
                                                            }
                                                            onChange={(e) => {
                                                                if (
                                                                    formData.inkPageMarginLocked
                                                                ) {
                                                                    updateInkPageMargin(
                                                                        e.target
                                                                            .value,
                                                                    );
                                                                } else {
                                                                    updateFormData(
                                                                        {
                                                                            inkPageMarginBottom:
                                                                                unmask(
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                ),
                                                                        },
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            'gd-col gd-col-1--desktop'
                                                        }
                                                    >
                                                        <div>
                                                            <label>
                                                                &nbsp;
                                                            </label>{' '}
                                                            {/* just to keep control aligned */}
                                                            <ButtonLock
                                                                locked={
                                                                    formData.inkPageMarginLocked
                                                                }
                                                                onClick={() => {
                                                                    updateFormData(
                                                                        {
                                                                            inkPageMarginLocked:
                                                                                !formData.inkPageMarginLocked,
                                                                        },
                                                                    );
                                                                    updateInkPageMargin(
                                                                        formData.inkPageMarginLeft +
                                                                            'mm',
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>

                                    <div className={'gd-inner'}>
                                        <div
                                            className={
                                                'gd-col gd-col-5--desktop'
                                            }
                                        >
                                            <fieldset>
                                                <legend>
                                                    {/*I18N*/}
                                                    {'Fonte'}
                                                </legend>

                                                <div className={'gd-inner'}>
                                                    <FieldsFontType
                                                        formData={formData}
                                                        formError={formError}
                                                        onChange={(formData) =>
                                                            updateFormData(
                                                                formData,
                                                            )
                                                        }
                                                    />

                                                    <div
                                                        className={
                                                            'gd-col gd-col-1--desktop'
                                                        }
                                                    >
                                                        <FieldMeasure
                                                            // I18N
                                                            label={
                                                                'Altura da linha'
                                                            }
                                                            placeholder={'24pt'}
                                                            measure={'pt'}
                                                            className={
                                                                'no-wrap-label'
                                                            }
                                                            required={true}
                                                            validationError={
                                                                formError.inkPageLineHeight
                                                            }
                                                            value={
                                                                formData.inkPageLineHeight
                                                            }
                                                            onChange={(e) => {
                                                                updateFormData({
                                                                    inkPageLineHeight:
                                                                        unmask(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                        ),
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </section>
                            </form>
                        )}

                        <div className={'controls'}>
                            <button
                                className={'button'}
                                onClick={() => onFinished(null)}
                            >
                                {/*I18N*/}
                                {'Cancelar'}
                            </button>
                            <button
                                className={`button primary ${loadingSave ? 'loading' : ''}`}
                                onClick={saveDocument}
                            >
                                {/*I18N*/}
                                {'Salvar'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

DocumentSettingsModal.propTypes = {
    show: PropTypes.bool,
    documentId: PropTypes.string,
    onFinished: PropTypes.func,
};

export default DocumentSettingsModal;
