/**
 * @typedef {(-1 | 0 | 1 | 2)} StatusEnumValue
 */

/**
 * @type {Object<StatusEnumValue>}
 */
export const StatusEnum = {
    UNAVAILABLE: -1,
    OK: 0,
    WARNING: 1,
    ERROR: 2,
};
