/**
 * @type {Object<string, EditorElement>}
 */
let editorElementsMap = {};
/**
 * @type {Object<string, EditorElement>}
 */
let editorElementTypeMap = {};

export function registerEditorElement(id, instance) {
    if (editorElementsMap[id]) {
        if (process.env.FAST_REFRESH) {
            console.warn(`Element ${id} already registered.`);
        }
        return;
    }
    editorElementsMap[id] = instance;

    const type = instance.getEditorElementType();
    if (editorElementTypeMap[type]) {
        throw new Error(`Element type ${type} already registered.`);
    }
    editorElementTypeMap[type] = instance;
}

/**
 * @return {Object<string, EditorElement>}
 */
export function getRegisteredEditorElements() {
    return editorElementsMap;
}

/**
 * @param type {string}
 * @returns {EditorElement | null}
 */
export function getEditorElementByType(type) {
    if (!type) return null;
    return editorElementTypeMap[type];
}

/**
 * @type {null | string[]}
 */
let innerContextContainerClasses = null;

/**
 * @returns {string[]}
 */
export function getInnerContextContainerClasses() {
    if (innerContextContainerClasses) {
        return innerContextContainerClasses;
    }

    /**
     * @type {string[]}
     */
    const uniqueClasses = [];
    /**
     * @type {Set<string>}
     */
    const classNamesSet = new Set();

    for (const editorElement of Object.values(editorElementsMap)) {
        for (const className of editorElement.getInnerContextContainerCssClass()) {
            if (!classNamesSet.has(className)) {
                classNamesSet.add(className);
                uniqueClasses.push(className);
            }
        }
    }
    innerContextContainerClasses = uniqueClasses;
    return innerContextContainerClasses;
}
