import Api from './Api';

/**
 * @typedef {object} DictionaryUnbreakableDto
 * @property {string} name
 * @property {Date | null} deletedAt
 * @property {Date | null} updatedAt
 * @property {string | null} description
 */

/**
 * @param filter { { page: number, lastUpdateDate: Date | null | undefined, search: string | null} }
 * @param config {import('axios').AxiosRequestConfig | null | undefined}
 * @returns {Promise<PaginationResDto<DictionaryUnbreakableDto>>}
 */
export async function getUnbreakableWords(filter, config = null) {
    const result = (
        await Api.get('/dictionary/unbreakable', {
            params: filter,
            ...(config ?? {}),
        })
    )?.data;
    result.records = result.records.map((word) => ({
        ...word,
        deletedAt: word.deletedAt ? new Date(word.deletedAt) : null,
        updatedAt: word.updatedAt ? new Date(word.updatedAt) : null,
    }));
    return result;
}

/**
 * @param name {string}
 * @param formData {FormData}
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export async function editDictionary(name, formData) {
    return await Api.patch(`/dictionary/${encodeURIComponent(name)}`, formData);
}

export async function createDictionary(body) {
    return await Api.put('/dictionary/unbreakable', body);
}

export async function deleteDictionary(name) {
    return await Api.delete(`/dictionary/${encodeURIComponent(name)}`);
}
