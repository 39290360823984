import versions from './versions';

/**
 * @typedef {object} DocumentUpdateVersion
 * @property {function(): number} version
 * @property {function(editor: EditorCustom, page: HTMLElement): Promise<void>} updatePage
 */

export class DocumentUpdate {
    /**
     * @param editor {EditorCustom}
     */
    constructor(editor) {
        this.editor = editor;
        this._documentVersion = 0;
        for (const version of versions) {
            const updateVersion = version.version();
            if (updateVersion > this._documentVersion) {
                this._documentVersion = updateVersion;
            }
        }
    }

    /**
     * @return {number}
     */
    getDocumentVersion() {
        return this._documentVersion;
    }

    /**
     * @param page {HTMLElement}
     * @return {Promise<void>}
     */
    async updatePage(page) {
        const documentVersion = parseInt(
            page.getAttribute('data-document-version') ?? '0',
        );

        for (const version of versions) {
            if (isNaN(documentVersion) || documentVersion < version.version()) {
                await version.updatePage(this.editor, page);
                page.setAttribute(
                    'data-document-version',
                    version.version().toString(),
                );
            }
        }
    }
}
