import { generateId, getBrailleDocument, isElementVisible } from './EditorUtil';
import { getPages } from './PageManipulation';

/**
 * @param editor {EditorCustom}
 * @param disabled {boolean}
 */
export function setCacheDisabled(editor, disabled) {
    // console.trace('Cache disabled', disabled);
    editor.custom.cacheDisabled = disabled;
}

/**
 * @param editor {EditorCustom}
 * @return {boolean}
 */
export function isCacheDisabled(editor) {
    return editor.custom.isShowingTinyDialog || editor.custom.cacheDisabled;
}

/**
 * @param page {HTMLElement | Node}
 * @return {boolean}
 */
export function getPageCacheLock(page) {
    return page.getAttribute('cached') === 'locked';
}

/**
 * @param editor {EditorCustom}
 * @param page {HTMLElement | Node}
 * @param lock {boolean}
 */
export function setPageCacheLock(editor, page, lock) {
    if (isPageCached(page)) {
        uncachePage(editor, page);
    }
    if (lock) {
        page.setAttribute('cached', 'locked');
    } else {
        page.removeAttribute('cached');
    }
}

/**
 * @param editor {EditorCustom}
 * @param page {HTMLElement | Node}
 * @param fireEvent {boolean | undefined}
 */
export function cachePage(editor, page, fireEvent = false) {
    const ignoreCache = isCacheDisabled(editor);
    if (ignoreCache || !editor.custom.pageCache || getPageCacheLock(page)) {
        return;
    }
    let pageId = page.getAttribute('id');
    if (!pageId) {
        pageId = generateId(editor, 'editor-page');
        page.setAttribute('id', pageId);
    } else {
        if (editor.custom.pageCache[pageId]) {
            // already cached
            return;
        }
    }
    const fragment = document.createDocumentFragment();
    if (page.getAttribute('data-converted-to-braille') !== 'true') {
        const pageHeight = page.clientHeight;
        page.style.minHeight = `${pageHeight}px`;
    }
    while (page.childNodes[0]) {
        fragment.appendChild(page.childNodes[0]);
    }
    page.appendChild(editor.dom.create('br'));
    if (fragment.childNodes.length) {
        editor.custom.pageCache[pageId] = fragment;
    }
    page.setAttribute('cached', 'true');
    if (fireEvent) {
        editor.fire('pageCached', {
            page,
        });
    }
}

/**
 *
 * @param editor {EditorCustom}
 * @param page {string | Element | Node}
 * @param removeFromCache {boolean | undefined}
 * @return {DocumentFragment | null}
 */
export function getPageCache(editor, page, removeFromCache = false) {
    if (!editor.custom.pageCache) return null;
    let pageId;
    if (typeof page === 'string') {
        pageId = page;
    } else {
        pageId = page.getAttribute('id');
    }
    const cache = editor.custom.pageCache[pageId];
    if (removeFromCache) {
        delete editor.custom.pageCache[pageId];
    }
    return cache;
}

/**
 * @param editor {EditorCustom}
 * @param page {HTMLElement | Node}
 * @param fireEvent {boolean | undefined}
 */
export function uncachePage(editor, page, fireEvent = false) {
    const ignoreCache = isCacheDisabled(editor);
    if (ignoreCache || !editor.custom.pageCache || getPageCacheLock(page)) {
        return;
    }
    const fragment = getPageCache(editor, page, true);
    page.setAttribute('cached', 'false');
    if (!fragment) {
        // page isn't cached
        return;
    }
    page.innerHTML = '';
    page.appendChild(fragment);
    if (page.getAttribute('data-converted-to-braille') !== 'true') {
        page.style.removeProperty('min-height');
    }
    if (fireEvent) {
        editor.fire('pageUncached', {
            page,
        });
    }
}

/**
 * @param editor {EditorCustom}
 * @param clear {boolean|undefined}
 */
export function cacheContent(editor, clear = false) {
    if (!editor.custom.pageCache || clear === true)
        editor.custom.pageCache = {};
    const pages = getPages(editor);
    for (const page of pages) {
        if (getPageCacheLock(page)) {
            page.removeAttribute('cached');
        }
        if (
            !isElementVisible(
                editor.getBody()?.parentElement,
                page,
                editor.custom.zoom,
            )
        ) {
            cachePage(editor, page);
        }
    }
}

/**
 * @param editor {EditorCustom}
 */
export function uncacheContent(editor) {
    if (!editor.custom.pageCache) return;
    for (const pageId in editor.custom.pageCache) {
        const page = editor.dom.get(pageId);
        if (page) {
            page.setAttribute('cached', 'locked');
            const fragment = getPageCache(editor, page);
            if (!fragment) continue;
            page.innerHTML = '';
            page.appendChild(fragment);
        }
    }
    editor.custom.pageCache = {};
}

/**
 * @param editor {EditorCustom}
 * @param page {HTMLElement}
 * @param fireEvent {boolean | undefined}
 */
export function cachePageIfNotVisible(editor, page, fireEvent = false) {
    if (
        !isElementVisible(
            editor.getBody()?.parentElement,
            page,
            editor.custom.zoom,
        )
    ) {
        cachePage(editor, page, fireEvent);
    }
}

/**
 * @param editor {EditorCustom}
 */
export function unCacheUndoManager(editor) {
    const braileDocument = getBrailleDocument(editor);
    if (!braileDocument || braileDocument.readOnly) {
        return;
    }
    const start = new Date().getTime();
    let data = editor.undoManager.data[editor.undoManager.data.length - 1];
    if (!data) return;

    const div = document.createElement('div');
    div.innerHTML = data.content;
    /**
     * @type {HTMLElement[]}
     */
    const pages = [...div.querySelectorAll('editor-page[cached="true"]')];
    for (let page of pages) {
        const cache = getPageCache(editor, page, false);
        if (!cache) {
            console.warn('Cache not found to uncache undoManager data', cache);
            continue;
        }
        page.innerHTML = '';
        page.appendChild(cache.cloneNode(true));
        if (page.getAttribute('data-converted-to-braille') !== 'true') {
            page.style.removeProperty('min-height');
        }
    }
    data.content = div.innerHTML;
    console.debug(
        `Time to uncache data in undoManager: ${new Date().getTime() - start} ms`,
    );
}

/**
 * @param page {HTMLElement | Node}
 * @return {boolean}
 */
export function isPageCached(page) {
    return page.getAttribute('cached') === 'true';
}
