// noinspection JSCheckFunctionSignatures

import './RecoveryPasswordForm.scss';
import * as LoginService from '../services/LoginService';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import {
    passwordStrength,
    ChangePasswordValidationErrorTxt,
} from 'plataforma-braille-common';
import FieldText from '../components/FieldText';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function ChangePasswordForm(params) {
    const [checkingToken, setCheckingToken] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { setInfoModal, backendConnectionError, setLoading } =
        useContext(EnvironmentContext);
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
    });
    let { email, token } = useParams();
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const title = params.title ?? 'Recuperar senha';

    async function checkToken() {
        try {
            const validToken = await LoginService.checkToken(email, token);
            setCheckingToken(false);
            if (!validToken) {
                setInfoModal({
                    // I18N
                    title,
                    // I18N
                    message:
                        'O token informado é inválido ou está expirado. Inicie o processo de recuperação de senha e tente novamente',
                    show: true,
                    onClose: () => navigate('/'),
                });
            } else {
                console.debug('Valid token.');
            }
        } catch (e) {
            setCheckingToken(false);
            backendConnectionError(
                'Fail to check recovery password token.',
                e,
                () => navigate('/'),
            );
        }
    }

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        checkToken();
    }, []);

    function updateFormData(obj) {
        setFormData((formData) => {
            return { ...formData, ...obj };
        });
    }

    const [validateOnChange, setValidateOnChange] = useState(false);
    const [passwordError, setPasswordError] = useState();
    const [confirmPasswordError, setConfirmPasswordError] = useState();

    function hasValidationError() {
        let hasErrors = false;
        setPasswordError(null);
        setConfirmPasswordError(null);

        if (!formData.password || formData.password.trim() === '') {
            // I18N
            setPasswordError('Campo obrigatório.');
            hasErrors = true;
        } else if (!passwordStrength(formData.password)) {
            // I18N
            setPasswordError(
                'A senha deve conter 8 caracteres ou mais, letras maiúsculas, minúsculas, símbolos e números.',
            );
            hasErrors = true;
        }

        if (
            !formData.confirmPassword ||
            formData.confirmPassword.trim() === ''
        ) {
            // I18N
            setConfirmPasswordError('Campo obrigatório.');
            hasErrors = true;
        } else {
            if (formData.password !== formData.confirmPassword) {
                // I18N
                setConfirmPasswordError('As senhas não conferem.');
                hasErrors = true;
            }
        }

        setValidateOnChange(true);
        setButtonLoading(false);

        return hasErrors;
    }

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    async function changePassword(e) {
        e.preventDefault();
        if (hasValidationError()) return;

        try {
            setButtonLoading(true);
            let recaptchaToken;
            if (process.env.REACT_APP_RECAPTCHA_KEY && executeRecaptcha) {
                // I18N
                recaptchaToken = await executeRecaptcha('changePassword');
            } else {
                console.warn('Recaptcha is disabled on this environment.');
            }

            const passwordChanged = await LoginService.changePassword(
                formData.password,
                email,
                token,
                recaptchaToken,
            );
            setButtonLoading(false);
            if (passwordChanged) {
                setInfoModal({
                    title,
                    // I18N
                    message:
                        'A senha foi alterada com sucesso. Você será redirecionado ao login após fechar essa janela.',
                    show: true,
                    onClose: () => navigate('/'),
                });
            } else {
                setInfoModal({
                    title,
                    // I18N
                    message:
                        'O token é inválido ou está expirado. Inicie o processo de recuperação de senha e tente novamente.',
                    show: true,
                    onClose: () => navigate('/'),
                });
            }
        } catch (e) {
            // I18N
            backendConnectionError(
                'An error has occurred.',
                e,
                'Recuperar senha',
                ChangePasswordValidationErrorTxt,
            );
        } finally {
            setButtonLoading(false);
        }
    }

    useEffect(() => {
        setLoading(checkingToken);
    }, [checkingToken]);

    useEffect(() => {
        setLoading(buttonLoading, true);
    }, [buttonLoading]);

    return (
        <div
            className={
                'gd-col-7--desktop gd-col--valign-middle recovery-password-form'
            }
        >
            <div>
                <form
                    // I18N
                    aria-label={'Definir nova senha'}
                >
                    <h2>{title}</h2>

                    {/*I18N*/}
                    <p>{'Defina a senha para realizar seu login.'}</p>

                    <FieldText
                        // I18N
                        label={'Senha'}
                        // I18N
                        placeholder={'Senha'}
                        type={'password'}
                        autoComplete={'password'}
                        required={true}
                        validationError={passwordError}
                        value={formData.password}
                        onChange={(e) =>
                            updateFormData({ password: e.target['value'] })
                        }
                    />

                    <FieldText
                        // I18N
                        label={'Confirme a senha'}
                        // I18N
                        placeholder={'Confirme a senha'}
                        type={'password'}
                        autoComplete={'password'}
                        required={true}
                        validationError={confirmPasswordError}
                        value={formData.confirmPassword}
                        onChange={(e) =>
                            updateFormData({
                                confirmPassword: e.target['value'],
                            })
                        }
                    />

                    <div className={'form-controls'}>
                        <button
                            className={`primary ${buttonLoading ? 'loading' : ''}`}
                            disabled={buttonLoading}
                            onClick={changePassword}
                        >
                            {/*I18N*/}
                            {'Enviar'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ChangePasswordForm;
