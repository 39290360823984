import { toggleNonPrintableChars } from '../core/ShowNonPrintableChars';
import { getUseAltFont, toggleUseAltFont } from '../core/UseAltFont';
import { DocumentStatusEnum } from 'plataforma-braille-common';
import {
    EditorFontFamily,
    EditorFontFamily2x,
} from '../../../../components/images';
import { addIcon } from './MenuModule';

/**
 * @param editor {EditorCustom}
 * @param params {MenuModuleParams}
 */
export function createViewMenu(editor, params) {
    addIcon(
        editor,
        'customEditorFontFamily',
        EditorFontFamily,
        EditorFontFamily2x,
    );

    editor.ui.registry.addToggleMenuItem('customViewNonPrintableChars', {
        // I18N
        text: 'Mostrar caracteres não imprimíveis',
        onAction: () => {
            editor.custom.isShowingNonPrintableChars =
                toggleNonPrintableChars(editor);
        },
        onSetup: (api) => {
            api.setActive(editor.custom.isShowingNonPrintableChars);
        },
        icon: 'visualchars',
    });
    if (
        [
            DocumentStatusEnum.LINEARIZATION,
            DocumentStatusEnum.DESCRIPTION,
        ].includes(params.status)
    ) {
        editor.ui.registry.addToggleMenuItem('customViewAlternateFont', {
            text: 'Usar fonte alternativa',
            onAction: () => {
                toggleUseAltFont(editor);
            },
            onSetup: (api) => {
                const useAltFont = getUseAltFont();
                api.setActive(useAltFont);
            },
            icon: 'customEditorFontFamily',
        });
    }
}
