// noinspection JSCheckFunctionSignatures

import './RecoveryPasswordForm.scss';
import { useContext, useEffect, useState } from 'react';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import FieldText from '../components/FieldText';
import { useNavigate } from 'react-router-dom';
import ButtonBack from '../components/ButtonBack';
import * as LoginService from '../services/LoginService';
import { isValidEmail } from 'plataforma-braille-common';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function RecoveryPasswordForm() {
    const [buttonLoading, setButtonLoading] = useState(false);
    const { setInfoModal, backendConnectionError, setLoading } =
        useContext(EnvironmentContext);
    const [formData, setFormData] = useState({ email: '' });

    function updateFormData(obj) {
        setFormData((formData) => {
            return { ...formData, ...obj };
        });
    }

    const [validateOnChange, setValidateOnChange] = useState(false);
    const [emailError, setEmailError] = useState();
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        setLoading(buttonLoading, true);
    }, [buttonLoading]);

    function hasValidationError() {
        let hasErrors = false;
        setEmailError(null);

        if (!formData.email || formData.email.trim() === '') {
            // I18N
            setEmailError('Campo obrigatório.');
            hasErrors = true;
        }

        if (!isValidEmail(formData.email)) {
            // I18N
            setEmailError('Digite um e-mail válido.');
            hasErrors = true;
        }

        setValidateOnChange(true);
        setButtonLoading(false);
        return hasErrors;
    }

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    async function recoveryPassword(e) {
        e.preventDefault();
        if (hasValidationError()) return;

        try {
            setButtonLoading(true);
            let recaptchaToken;
            if (process.env.REACT_APP_RECAPTCHA_KEY && executeRecaptcha) {
                // I18N
                recaptchaToken = await executeRecaptcha('recoveryPassword');
            } else {
                console.warn('Recaptcha is disabled on this environment.');
            }

            await LoginService.recoveryPassword(formData.email, recaptchaToken);

            setButtonLoading(false);
            // I18N
            setInfoModal({
                title: 'Redefinição de senha',
                message:
                    'Se o endereço informado estiver correto, enviaremos um e-mail com instruções para redefinição de senha.',
                show: true,
                onClose: () => navigate('/'),
            });
        } catch (e) {
            backendConnectionError('Fail to recovery password.', e);
        } finally {
            setButtonLoading(false);
        }
    }

    return (
        <div
            className={
                'gd-col-7--desktop gd-col--valign-middle recovery-password-form'
            }
        >
            <div>
                <ButtonBack onClick={() => navigate('/')} />

                <form
                    // I18N
                    aria-label={'Recuperar senha'}
                >
                    <h2>
                        {/*I18N*/}
                        {'Login - Esqueceu sua senha?'}
                    </h2>

                    <p>
                        {
                            'Preencha o campo. Enviaremos um e-mail com os passos para a redefinição de senha.'
                        }
                    </p>

                    <FieldText
                        // I18N
                        label={'E-mail'}
                        // I18N
                        placeholder={'E-mail'}
                        autoComplete={'email'}
                        required={true}
                        validationError={emailError}
                        value={formData.email}
                        onChange={(e) =>
                            updateFormData({ email: e.target['value'] })
                        }
                    />

                    <div className={'form-controls'}>
                        <button
                            className={`primary ${buttonLoading ? 'loading' : ''}`}
                            disabled={buttonLoading}
                            onClick={recoveryPassword}
                        >
                            {/*I18N*/}
                            {'Enviar'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default RecoveryPasswordForm;
