import React from 'react';
import LoginLeftBar from './LoginLeftBar';
import './LoginContainer.scss';

class LoginContainer extends React.Component {
    render() {
        return (
            <div className={'gd-container login-container'}>
                <div className={'gd'}>
                    <LoginLeftBar />
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default LoginContainer;
