import './components/scss/Main.scss';
import './edit-document/RichEditor.scss';
import moment from 'moment';
import 'moment/min/locales';
import { EnvironmentProvider } from './contexts/EnviromentContext';
import RootRouter from './routers';

// I18N
moment.locale('pt-br');

function App() {
    return (
        <EnvironmentProvider>
            <RootRouter />
        </EnvironmentProvider>
    );
}

export default App;
