export function measureInPx(value) {
    const div = document.createElement('div');
    div.style.display = 'block';
    div.style.height = value;
    div.style.position = 'fixed';
    div.style.visibility = 'hidden';
    div.style.left = '-999999px';
    div.style.top = '-999999px';
    document.body.appendChild(div);
    const px = parseFloat(window.getComputedStyle(div, null).height);
    div.parentNode.removeChild(div);
    return px;
}
