import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param records {DictionaryUnbreakableDto[] | null}
 * @param loadingRow {Element | null}
 * @param selected {string | null}
 * @param onSelected {function(DictionaryUnbreakableDto)}
 * @return {JSX.Element}
 * @constructor
 */

function DictionaryTable({ records, loadingRow, selected, onSelected }) {
    return (
        <table className={'selectable'}>
            <tbody>
                {records
                    ?.filter((dictionary) => !dictionary.deletedAt)
                    .map((dictionary) => (
                        <tr
                            key={dictionary.name}
                            onClick={() => onSelected(dictionary)}
                            className={`${
                                selected === dictionary.name ? 'selected' : ''
                            }`}
                        >
                            {/* I18N */}
                            <td>{dictionary.name}</td>
                            {/* I18N */}
                            <td>{dictionary.description}</td>
                        </tr>
                    ))}
                {loadingRow && (
                    <tr>
                        <td colSpan={6}>{loadingRow}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

DictionaryTable.propTypes = {
    records: PropTypes.arrayOf(PropTypes.object),
    loadingRow: PropTypes.node,
    selected: PropTypes.string,
    onSelected: PropTypes.func.isRequired,
};

export default DictionaryTable;
