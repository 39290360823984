import './BraillePreview.scss';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { EditorElements } from '../edit-document/editor-mods/modules/core/EditorElements';
import { convertElementToBraille } from '../conversion/braille/HtmlToBraille';
import { showBrailleInGrid } from '../edit-document/editor-mods/modules/core/BrailleView';
import { BrailleFacilConversionFlag } from '../conversion/txt/BrailleFacilConversionFlag';

/**
 * @param text {string}
 * @param onConvertToBraille {function(brailleData: string)}
 * @param widthOffset {number | undefined}
 * @param heightOffset {number | undefined}
 * @param alignCenter {boolean | undefined}
 * @returns {JSX.Element}
 * @constructor
 */
export function BraillePreview({
    text,
    onConvertToBraille,
    widthOffset = 0,
    heightOffset = 0,
    alignCenter = false,
}) {
    /**
     * @type {React.MutableRefObject<HTMLDivElement | undefined>}
     */
    const previewRef = useRef();

    useEffect(() => {
        if (!previewRef.current) return;

        previewRef.current.innerHTML = '';
        if (!text || !text.trim().length) {
            if (onConvertToBraille) {
                onConvertToBraille('');
            }
            return;
        }

        const parentElement = previewRef.current.parentElement;
        const parentWidth = parentElement.offsetWidth - widthOffset;
        const parentHeight = parentElement.offsetHeight - heightOffset;

        const tmpDiv = document.createElement('div');
        tmpDiv.innerHTML = text.replace(/\r?\n/g, '<br>');
        const brailleData = convertElementToBraille(
            tmpDiv,
            new EditorElements(null),
            {},
            [BrailleFacilConversionFlag.CONTEXT_FIGURE],
        );

        if (onConvertToBraille) {
            onConvertToBraille(brailleData);
        }

        const rows = brailleData.split('\r\n');
        const maxColumn = Math.max(...rows.map((str) => str.length));
        previewRef.current.style.gridTemplateRows = `repeat(${rows.length}, 20px)`;
        previewRef.current.style.gridTemplateColumns = `repeat(${maxColumn}, 12px)`;

        showBrailleInGrid(rows, previewRef.current, {
            brailleCellColCount: maxColumn,
            brailleCellRowCount: rows.length,
        });

        let scaleX = 1;
        if (previewRef.current.offsetWidth > parentWidth) {
            scaleX = parentWidth / previewRef.current.offsetWidth;
        }
        let scaleY = 1;
        if (previewRef.current.offsetHeight > parentHeight) {
            scaleY = parentHeight / previewRef.current.offsetHeight;
        }
        const scale = scaleX < scaleY ? scaleX : scaleY;
        previewRef.current.style.transform = `scale(${scale})`;

        if (alignCenter) {
            const width = previewRef.current.offsetWidth * scale;
            const height = previewRef.current.offsetHeight * scale;

            const marginLeft = (parentWidth - width) / 2;
            const marginTop = (parentHeight - height) / 2;

            previewRef.current.style.position = 'absolute';
            previewRef.current.style.marginLeft = `${marginLeft}px`;
            previewRef.current.style.marginTop = `${marginTop}px`;
        }
    }, [text]);

    return <div ref={previewRef} className={'braille-preview'}></div>;
}

BraillePreview.propTypes = {
    text: PropTypes.string,
    onConvertToBraille: PropTypes.func,
    widthOffset: PropTypes.number,
    heightOffset: PropTypes.number,
    alignCenter: PropTypes.bool,
};
