export enum SaveFigureValidationErrorEnum {
    NAME_ALREADY_EXISTS = "NAME_ALREADY_EXISTS",
}

export function SaveFigureValidationErrorEnumTxt(
    saveFigureValidationErrorEnum: SaveFigureValidationErrorEnum,
): string {
    switch (saveFigureValidationErrorEnum) {
        case SaveFigureValidationErrorEnum.NAME_ALREADY_EXISTS:
            // I18N
            return "Já existe uma figura com esse nome";
    }
    throw new Error("Invalid enum value");
}
